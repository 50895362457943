<div class="row justify-content-start align-items-center w-100">

  <div class="msb fs-xl black col-12 col-md-6 col-lg-7">
    <span class="black">{{ 'Te ayudamos a elegir' | translate }}</span> <br>
    <span class="green">{{ 'correctamente' | translate }}</span>
  </div>

  <div class="fs-l black  mt-3 mt-lg-0 col-md-6 col-12 col-lg-5">
    {{ 'Platícanos sobre tu proyecto por' | translate }}
    <a class="black font-weight-bold clickable" (click)="onOpenChat()">chat</a>
    {{ 'o' | translate }}
    <a class="black font-weight-bold clickable" (click)="onOpenScheduleModal()" data-toggle="modal"> {{ 'agenda una cita
      telefónica' | translate }}</a>
    {{ 'y te asesoramos para que obtengas el mejor plan.' | translate }}
  </div>
</div>

<!-- Shedules dialog -->
<app-dialog [enableCloseDialogButton]="false" [show]="showScheduleModal" [preventCloseFromBackDrop]="true">
  <div class="access-content p-4">
    <span class="clickable" (click)="onShedulerBackClicked()">
      <img loading="lazy" class="mb-3" data-src="{{ '/icons/Black/arrow-left.svg' | blobstorage }}" alt="Arrow left"
        src="{{ '/icons/Black/arrow-left.svg' | blobstorage }}">
    </span>
    <div class="calendarContainer">
      <iframe *ngIf="schedulerURL" [src]="schedulerURL | safe: 'resourceUrl'" width="100%" height="100%" scrolling="yes"
        class="border-0" enablejsapi="true"></iframe>
    </div>
  </div>
</app-dialog>