import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { LanguageConst } from '@core-constants/language.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { BroadcastService } from '@shared-services/broadcast.service';
import { BehaviorSubject, Observable } from "rxjs";
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class TranslateService
{
  private lang: string = LanguageConst.ES;
  private data: {[key: string]: string } | undefined = undefined;
  private langChangeSource$: BehaviorSubject<string> = new BehaviorSubject<string>(LanguageConst.ES);

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected http: HttpClient,
    protected localStorageService: LocalStorageService) { }

  public onTranslateChange(): Observable<string>
  {
    return this.langChangeSource$.asObservable();
  }

  public get languageCode(): string
  {
    if (this.lang !== undefined && this.lang !== null && this.lang)
    {
      return this.lang;
    }
    return LanguageConst.ES;
  }

  private initTranslation(): Promise<unknown>
  {
    if(this.data != undefined)
    {
      return Promise.resolve(this.data);
    }

    return new Promise<unknown>((resolve) =>
    {
      this.http.get(`../../../assets/translations/translations.json`).subscribe({
        next: (translation: { [key: string]: string }) =>
        {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error: () =>
        {
          this.data = undefined;
          resolve(this.data);
        }
      });
    });
  }

  public initService(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.initTranslation();
    }
  }

  public use(lang: string): void
  {
    let stLang = this.lang;

    if (lang !== stLang)
    {
      stLang = LanguageConst.ES;

      if (lang == LanguageConst.EN)
      {
        stLang = LanguageConst.EN;
      }
      this.localStorageService.setItem(LocalStorageConst.SelectedLang, stLang);
      this.lang = stLang;
      this.langChangeSource$.next(stLang);
      BroadcastService.Instance.broadcast(EAppEventName.OnLanguageChange);
    }
  }

  public getElement(key: string): string
  {
    let message = key;

    if (this.lang != LanguageConst.ES && this.data != undefined && key)
    {
      const lkey = key.toLowerCase();

      message = this.data[lkey] || key;
    }

    return message;
  }
}
