import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, DestroyRef, ElementRef, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { AditionalServiceInfo, IAditionalServiceInfoTranslation } from '@core-models/aditional-service.model';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-base-service',
  templateUrl: './base-service.component.html',
  styleUrls: ['./base-service.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseServiceComponent
{
  @Input() public showSpecs: boolean = true;
  @Input() public showSpecsTitle: boolean = true;
  @Input() public showExamples: boolean = true;

  public serviceInfo: any = {};
  public examples: any = undefined;
  public section1: any = undefined;
  public adicionalDescription: any = undefined;
  public infoAddon: any = undefined;
  public showFeatures: boolean = true;

  public id: number | undefined = undefined;

  public bannerBackground: string = "";
  public bannerImage: string = "";
  public bannerText: string = "";

  public complementaryServices: any[] = [];
  public titleComplementaryServices: any;

  @ContentChild('dynamicContentTemplate') public dynamicContentTemplate: TemplateRef<ElementRef>;

  @Input() public set serviceData(value: AditionalServiceInfo)
  {
    if (value)
    {
      this.update(value);
    }
  }

  constructor(protected translateService: TranslateService,
    protected seoService: SEOService,
    private ref: ChangeDetectorRef,
    public metaTagService: Meta,
    public toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.onShowFeatures()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: any) =>
      {
        this.showFeatures = value;
      }
    });
  }

  public update(service: AditionalServiceInfo): void
  {
    if (service)
    {
      const data = service.traducciones[0];

      if (data)
      {
        this.seoService.updateSeoElements(data?.seo_titulo, data?.seo_descripcion);
        this.setBannerInfo(data);
        this.setSection1(data);
        this.setExamplesSection(data);
        this.setInfoAddon(data);

        this.titleComplementaryServices = data.titulo_complementos;
      }

      if (service.servicios_complementarios)
      {
        this.complementaryServices = service.servicios_complementarios.map((x: any) => x.servicios_complementarios_id);
      }

      this.id = service.id;
    }

    this.ref.detectChanges();
  }

  public setBannerInfo(data: IAditionalServiceInfoTranslation): void
  {
    this.bannerBackground = data.imagen_banner?.id;
    this.bannerImage = data.imagen_titulo?.id;
    this.bannerText = data.texto_banner;

    this.serviceInfo.subtitle = data.subtitulo;
    this.serviceInfo.title = data.titulo;
    this.serviceInfo.description = data.descripcion_general;
  }

  public setSection1(data: IAditionalServiceInfoTranslation): void
  {
    this.section1 = {};

    if (this.showSpecs === true)
    {
      this.section1.title = data.titulo_seccion1;
      this.section1.img_section1 = data.img_seccion1;
      this.section1.specs_reverse = Object.prototype.hasOwnProperty.call(data, 'specs_reverse');

      this.section1.specs1 = {};
      this.section1.specs2 = {};
      this.section1.specs3 = {};

      this.section1.specs1.title = data.titulo_caracteristica1;
      this.section1.specs1.image = data?.img_caracteristica_1?.id;
      this.section1.specs1.description = data.descripcion_caracteristica1;

      this.section1.specs2.title = data.titulo_caracteristica2;
      this.section1.specs2.image = data?.img_caracteristica_2?.id;
      this.section1.specs2.description = data.descripcion_caracteristica2;

      this.section1.specs3.title = data.titulo_caracteristica3;
      this.section1.specs3.image = data?.img_caracteristica_3?.id;
      this.section1.specs3.description = data.descripcion_caracteristica3;
    }
  }

  public setInfoAddon(data: IAditionalServiceInfoTranslation): void
  {
    this.infoAddon = undefined;

    if (data.descripcion_addon != '' && data.descripcion_addon != undefined && data.titulo_addon != '' && data.titulo_addon != undefined)
    {
      this.infoAddon = {};
      this.infoAddon.description = data.descripcion_addon;
      this.infoAddon.title = data.titulo_addon;
    }
  }

  public setExamplesSection(data: IAditionalServiceInfoTranslation): void
  {
    this.examples = undefined;

    if (this.showExamples === true)
    {
      this.examples = {};

      this.examples.title = data.titulo_ejemplos;
      this.examples.images = [];

      this.addImage(data.img_ejemplo1);
      this.addImage(data.img_ejemplo2);
      this.addImage(data.img_ejemplo3);
    }
  }

  public addImage(image: string): void
  {
    if (image != '' && image != undefined)
    {
      this.examples.images.push(image);
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }

}
