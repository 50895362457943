import { IComparativeTable } from "@core-models/service-table-comparative.model";

export namespace ServicesDataComparativeConst
{
  export const HostingComparative: IComparativeTable[] =
    [
      {
        headers: [
          {
            title: "Hosting Básico"
          },
          {
            title: "Hosting Plus"
          },
          {
            title: "Hosting Premium"
          }
        ],
        rows: [
          {
            cells: [
              {
                title: "Almacenamiento SSD",
                description: "Total de capacidad para el contenido de tu hosting (correos, imágenes, archivos, etc.)."
              },
              {
                title: "25 GB"
              },
              {
                title: "50 GB"
              },
              {
                title: "100 GB"
              }
            ],
            hasBackground: true
          },
          {
            cells: [
              {
                title: "Alojamiento de dominios",
                description: "Total de dominios que podrás enlazar en el mismo hosting (Se adquieren por separado)."
              },
              {
                title: "3"
              },
              {
                title: "5"
              },
              {
                title: "20"
              }
            ],
            hasBackground: false
          },
          {
            cells: [
              {
                title: "Cuentas de correo",
                description: "Crea cuentas de correo con tu dominio (@tudominio.mx)."
              },
              {
                title: "10"
              },
              {
                title: "30"
              },
              {
                title: "60"
              }
            ],
            hasBackground: true
          },
          {
            cells: [
              {
                title: "Subdominios",
                description: "Crea extensiones para tu dominio principal (ejemplo.tudominio.mx)."
              },
              {
                title: "10"
              },
              {
                title: "30"
              },
              {
                title: "Ilimitados"
              }
            ],
            hasBackground: false
          },
          {
            cells: [
              {
                title: "Cuentas FTP",
                description: "Cuentas te permiten gestionar el contenido de tu web hosting de una manera rápida y sencilla."
              },
              {
                title: "2"
              },
              {
                title: "5"
              },
              {
                title: "10"
              }
            ],
            hasBackground: true
          },
          {
            cells: [
              {
                title: "Bases de datos",
                description: "Recopila y organiza la información mediante MySQL."
              },
              {
                title: "1"
              },
              {
                title: "5"
              },
              {
                title: "25"
              }
            ],
            hasBackground: false
          },
          {
            cells: [
              {
                title: "Dominios para redireccionar",
                description: "Crea alias que redireccionen a tu dominio principal."
              },
              {
                title: "5"
              },
              {
                title: "10"
              },
              {
                title: "20"
              }
            ],
            hasBackground: true
          }
        ]
      }
    ];
}
