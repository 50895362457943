import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EventLocationSite } from '@core-constants/gtm-const';
import { DomainDataService } from '@core-data-services/domains.data-service';
import { DomainsManager } from '@core-managers/domains.manager';
import { ILanguageComponent } from '@core-models/generic.model';
import { RegisterDomainsResponse } from "@core-models/register-domains.model";
import { SEOService } from '@shared-services/seo.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register-domains',
  templateUrl: './register-domains.component.html',
  styleUrls: ['./register-domains.component.css'],
  encapsulation: ViewEncapsulation.None,
  host: { ngSkipHydration: 'true' },
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RegisterDomainsComponent implements OnInit, OnDestroy, ILanguageComponent
{
  public title: string = "";
  public titleIA: string = "";
  public backgroundImage: string = "";

  public mainDomains: string = "";
  public domainTips: string = "";
  public domainInformation: string = "";
  public otherDomains: string = "";

  public isToggleActive$: Observable<boolean>;
  public isToggleActive: boolean;
  public changeTitle: string = "";

  public isToggleActiveDomain$: Observable<boolean>;
  public isToggleActiveDomain: boolean;

  public pageTitle: string = "Akky | Registra un dominio";
  public registerDomainTranslate: RegisterDomainsResponse.IDomainsTranslation;

  public panelTldsMain: RegisterDomainsResponse.IPanelTld[] = [];
  public panelOtherTlds: RegisterDomainsResponse.IPanelTld[] = [];

  constructor(protected translateService: TranslateService,
    private domainDataService: DomainDataService,
    private domainsManager: DomainsManager,
    private router: Router,
    protected toast: ToastService,
    public seoService: SEOService,
    private ref: ChangeDetectorRef,
    private destroyRef$: DestroyRef)
  {
    this.isToggleActiveDomain$ = domainsManager.getIsToggleActiveDomain();
  }

  public get hasSuggestions(): boolean
  {
    return this.domainsManager.hasSuggestions();
  }

  public get hasSuggestionsAI(): boolean
  {
    return this.domainsManager.hasSuggestionsAI();
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (lang) =>
      {
        this.seoService.updateSeoElements(this.translateService.getElement(this.title));
        this.getBundleDomains(lang);
      }
    });

    this.isToggleActiveDomain$
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: boolean) =>
      {
        this.changeTexts(value);
        this.isToggleActiveDomain = value;
      }
    });

  }

  public onTransferDomainClicked(): void
  {
    const link = ['ayuda/temas-de-ayuda'];
    const params = { queryParams: { tema: "Solicitar" } };

    this.router.navigate(link, params);
  }

  public getBundleDomains(lang: string = this.translateService.languageCode): void
  {
    this.domainDataService.getBundleDomains(lang).subscribe({
      next: (response: RegisterDomainsResponse.IGetBundle) =>
      {
        this.backgroundImage = response?.domains_register?.banner_fondo.id;
        this.registerDomainTranslate = response?.domains_register?.traducciones[0];
        this.title = this.registerDomainTranslate.titulo_pantalla_busqueda;
        this.titleIA = this.registerDomainTranslate.titulo_pantalla_busqueda_ia;
        this.mainDomains = this.registerDomainTranslate.seccion_dominios_principales;
        this.otherDomains = this.registerDomainTranslate.seccion_otros_dominios;
        this.domainInformation = this.registerDomainTranslate.seccion_informacion_dominios;
        this.domainTips = this.registerDomainTranslate.seccion_consejos_dominios;

        this.seoService.updateSeoElements(this.registerDomainTranslate?.seo_titulo, this.registerDomainTranslate?.seo_descripcion);

        this.panelTldsMain = response.main_tlds;
        this.panelOtherTlds = response.other_tlds;

        this.ref.detectChanges();
      }
    });
  }

  public onMultipleSearch(): void
  {
    this.domainsManager.setIsToggleActive(this.isToggleActiveDomain);
    this.domainsManager.searchMultipleDomains(this.translateService.languageCode);
    this.domainsManager.searchOnlySuggestionAI();
  }

  public onSingleSearch(): void
  {
    this.domainsManager.setIsToggleActive(this.isToggleActiveDomain);
    this.domainsManager.setAllDomainsEnabled(this.isToggleActiveDomain ? this.hasSuggestions : this.hasSuggestionsAI);
    this.handleSearchSuggestionStrategy();
  }

  private handleSearchSuggestionStrategy(): void
  {
    if(this.isToggleActiveDomain)
    {
      this.domainsManager.searchSimpleDomain(this.translateService.languageCode);
    }
    else
    {
      this.domainsManager.searchSimpleDomain(this.translateService.languageCode);
      this.domainsManager.searchOnlySuggestionAI();
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }

  public ngOnDestroy(): void
  {
    this.domainsManager.restartDomainsState();
  }

  public onAddAllDomainsToCartClicked(): void
  {
    this.domainsManager.addAllDomainsToCart(this.isToggleActive, (this.isToggleActive ? EventLocationSite.SearchDomainIASuggestion : EventLocationSite.SearchDomainSuggestion));
  }

  public changeTexts(value: boolean): void
  {
    this.changeTitle = (value) ? this.title : this.titleIA;
  }

  public domainToggleClick(value: boolean): void
  {
    this.changeTexts(value);
    this.isToggleActiveDomain = value;
    this.domainsManager.setIsToggleActiveDomain(value);
    this.isToggleActiveDomain$ = this.domainsManager.getIsToggleActiveDomain();
  }
}
