<!-- Banner content -->
<div class="container-fluid px-0 basic-content pb-3 pb-md-2 background-bg"
  [style.backgroundImage]="backgroundImage | directus:'style'">
  <div class="container row mx-auto pt-3 pt-md-5">
    <div class="d-flex col-12 col-md-12 justify-content-center">
      <h3 class="akky-container-text my-auto mx-auto" [innerHTML]="titleTransfer | safe: 'html'"></h3>
    </div>
    <div class="mx-auto col-12 col-md-8 col-lg-6 mb-4 px-0 py-3 py-md-4">
      <app-search-domains-input [textButton]="textButton" [textMultipleSearch]="textMultipleSearch"
        [textSimpleSearch]="textSimpleSearch" [placeHolderInput]="placeholder" (onSingleSearch)="onSingleSearch()"
        (onMultipleSearch)="onMultipleSearch()" (onSearchChangeMode)="onSearchChangeMode()"></app-search-domains-input>
    </div>
  </div>
</div>
<div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
  <div class="row">
    <ng-container *ngIf="isSearchLoading$ | async as isSearchLoading">
      <app-loading class="search-result" text="{{ 'Validando el nombre de dominio' | translate }}"></app-loading>
    </ng-container>

    <div class="empty-error-container search-result p-4" *ngIf="errorSearchEmptyVisible$ | async as errorSearchVisible">
      <span class="emptyResult-error-description">{{ 'Por favor intenta de nuevo, es necesario que indiques un nombre de
        dominio.' | translate}}</span>
    </div>

    <div class="empty-error-container search-result p-4" *ngIf="!isValidForTransfer && msgErrorForTransfer !== ''">
      <span class="searchResult-error-description" [innerHTML]="msgErrorForTransfer"></span>
    </div>
  </div>

  <div class="row p-0 m-0">
    <h1 class="col-12" [innerHTML]="titleSection1 | safe: 'html'"></h1>
    <div class="col-12 fs-m" [innerHTML]="descriptionSeccion1 | safe: 'html'"></div>
    <h3 class="col-12 mt-5" [innerHTML]="titleSection2 | safe: 'html'"></h3>
    <div class="col-12 mb-3 fs-m" [innerHTML]="descriptionSection2 | safe: 'html'"></div>

    <div class="col-12 mt-5" [innerHTML]="faqTitle | safe: 'html'"></div>

    <!--componente de FAQ's-->
    <div class="col-8 mx-auto  fs-m">
      <app-accordion-page [prefix]="prefix" [items]="itemsFaqs"></app-accordion-page>

    </div>
  </div>
</div>