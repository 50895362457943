import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textcolor',
  pure: false
})
//TODO: Cambiar el color por clases de css
export class TextColorPipe implements PipeTransform
{
  constructor() { }

  public transform(value: string, word?: string, color?: string): string
  {
    let res = "";

    if (value && word && color)
    {
        res = value.replace(new RegExp(word, 'g'), `<span style="color: ${color};">${word}</span>`);
        return res;
    }
    return value;
  }
}
