import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EAppEventName } from '@core-constants/app-events.const';
import { EInteractiveToasterOperation } from '@core-constants/interactive-toaster.const';
import { IToasterData } from '@core-models/interactive-toaster.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';

@Component({
  selector: 'app-interactive-toaster',
  templateUrl: './interactive-toaster.component.html',
  styleUrls: ['./interactive-toaster.component.css']
})

export class InteractiveToasterComponent implements OnInit
{
  public timeoutId: NodeJS.Timeout;
  public startAnimation: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public interactiveToasterService: InteractiveToasterService,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  private registerEventListeners(): void
  {
    this.interactiveToasterService.open
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (data: IToasterData) =>
      {
        if (data.show)
        {
          this.countDown();
        }
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        if (this.interactiveToasterService.data?.show)
        {
          this.hideAndClearTime();
        }
      }
    });

  }
  private countDown(): void
  {
    if (this.timeoutId)
    {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() =>
    {
      this.hideAndClearTime();

    }, this.interactiveToasterService.data.showTime);
  }

  public onOpenCartClicked(): void
  {
    this.hideAndClearTime();

    if (this.interactiveToasterService.data.operation == EInteractiveToasterOperation.OpenCart)
    {
      BroadcastService.Instance.broadcast(EAppEventName.OnOpenRemoteCartPopUp);
    }

  }

  public hideAndClearTime(): void
  {
    if (this.timeoutId)
    {
      clearTimeout(this.timeoutId);
    }
    this.animate();
  }

  private animate(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.startAnimation = true;
      setTimeout(() =>
      {
        this.interactiveToasterService.hide();
        this.startAnimation = false;
      }, 1000);
    }
  }
}
