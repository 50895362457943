<div id="body-bg" class="p-0 m-0 d-flex" [style.backgroundImage]="backgroundImage | blobstorage:'style' ">
  <div class="container p-0 px-sm-3 px-md-3 px-lg-5 d-flex justify-content-center align-items-center">
    <div class="row justify-content-center p-0 m-0">
      <div class="col-12 col-md-4 p-0 m-0 d-flex align-items-center">
        <div class="w-100 text-center text-md-start">
          <!-- language -->
          <div id="changeIdiom" class="green">
            <span class="green text-uppercase clickable" (click)="ngOnLanguageChange('es')">ES</span>
            /
            <span class="green text-uppercase clickable" (click)="ngOnLanguageChange('en')">EN</span>
          </div>

          <div id="akkyLogo" class="py-3">
            <img id="akkyLogo_img" loading="lazy" [src]="'/logo-akky.svg' | blobstorage"
              data-src="{{ '/logo-akky.svg' | blobstorage}}" title="Akky" width="100%" alt="Akky logo">
          </div>

          <h4 id="mtto-title" class="my-3 msb green">
            <span class="d-block">{{'Estamos mejorando' | translate}}</span>
            <span class="d-block">{{'nuestros servicios' | translate}}</span>
          </h4>

          <div class="msb d-block my-3">
            {{'Estaremos de vuelta' | translate}}
          </div>

          <div class="my-3" *ngIf="data">
            <span *ngIf="langCode === 'es'">
              El <span id="day">{{ data.dayName | translate}}</span><span id="date"> {{ data.dayNumber }} de {{
                data.month | translate}}</span>
            </span>
            <span *ngIf="langCode === 'en'">
              <span id="day">{{ data.dayName | translate}},</span><span id="date"> {{ data.month | translate}} {{
                data.dayNumber }} </span>
            </span>
            <br />
            {{'a las'| translate}} <span id="time">{{ data.hour }}</span>.
          </div>

          <div class="my-3">
            <span class="msb d-block my-3">{{'¡Sigue a la liebre!'| translate}}</span>
            <div id="d-flex">
              <a href="https://www.facebook.com/akkyMx" target="_blank">
                <img [src]="'/maintenance/facebook.svg' | blobstorage"
                  data-src="{{ '/maintenance/facebook.svg' | blobstorage}}" loading="lazy" alt="facebook"
                  class="mx-auto" height="20">
              </a>&nbsp;&nbsp;
              <a href="https://twitter.com/akkyMx" target="_blank">
                <img [src]="'/maintenance/twitter.svg' | blobstorage"
                  data-src="{{'/maintenance/twitter.svg' | blobstorage }}" loading="lazy" alt="twitter" class="mx-auto"
                  height="20">
              </a>&nbsp;&nbsp;
              <a href="https://www.youtube.com/channel/UCRYwktxrxbg9SNlOPcHMzrA/videos" target="_blank">
                <img [src]="'/maintenance/youtube.svg' | blobstorage"
                  data-src="{{ '/maintenance/youtube.svg' | blobstorage }}" alt="youtube" loading="lazy" alt="youtube"
                  class="mx-auto" height="20">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 p-0 m-0 d-flex align-items-center justify-content-center">
        <img id="mt-img" loading="lazy" alt="maintenance" class="ms-5 ms-md-0"
          [src]="'/maintenance/mantenimiento_web.svg' | blobstorage"
          data-src="{{ '/maintenance/mantenimiento_web.svg' | blobstorage }}">
      </div>
    </div>
  </div>
</div>