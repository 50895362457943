import { Component, Input } from '@angular/core';
import { IBenefitItem } from '@core-models/benefits.model';

@Component({
  selector: 'app-seasonal-discounts',
  templateUrl: './seasonal-discounts.component.html',
  styleUrls: ['./seasonal-discounts.component.css']
})
export class SeasonalDiscountsComponent
{
  @Input() public title: string = "";
  @Input() public card: string = "";
  @Input() public seasonalItems: IBenefitItem[] | undefined = undefined;
  public bigItem: IBenefitItem | undefined = undefined;


  public identify(index: any, item: any): any
  {
    return item;
  }

}
