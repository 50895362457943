import { ErrorMessageConst } from "./error-message.const";

export const enum EDomainType
{
  cc = '.cc',
  tv = '.tv',
  lat = '.lat',
  com_mx = '.com.mx',
  gob_mx = '.gob.mx',
  net_mx = '.net.mx',
  edu_mx = '.edu.mx',
  org_mx = '.org.mx',
  mx = '.mx',
  com = '.com',
  net = '.net',
  xyz = '.xyz',
  autos = '.autos',
  lol = '.lol',
  mom = '.mom'
}

export const enum EDomainServiceType
{
  Security = "Seguridad avanzada de dominio",
  SMS = "Alertas SMS",
  DNS = "DNS Personalizado"
}

export const enum EDomainStatus
{
  NotAvailable = 'notAvailable',
  Available = "available",
  InvalidRequest = "invalidRequest",
  ErrorTld = "errorTld"
}

export const enum EDomainCodeError
{
  TLDError = "errorTld",
  InvalidRequest = "invalidRequest",
  DomainMaintenance = "domainMaintenance"
}

export namespace DomainConst
{
  export const FreeDomains = [".edu.mx", ".org.mx", ".net.mx"];
  export const CloseDomains = [".edu.mx", ".gob.mx", ".net.mx"];

  export const CodeErrors = new Map<EDomainCodeError, string>(
    [
      [EDomainCodeError.TLDError, ErrorMessageConst.InvalidTLD],
      [EDomainCodeError.InvalidRequest, ErrorMessageConst.InvalidDomainCharacters],
      [EDomainCodeError.DomainMaintenance, ErrorMessageConst.DomainMaintenance]
    ]);

  export const Types = [
    { name: EDomainType.cc, group: 3 },
    { name: EDomainType.tv, group: 3 },
    { name: EDomainType.lat, group: 4 },
    { name: EDomainType.com_mx, group: 1 },
    { name: EDomainType.gob_mx, group: 1 },
    { name: EDomainType.net_mx, group: 1 },
    { name: EDomainType.edu_mx, group: 1 },
    { name: EDomainType.org_mx, group: 1 },
    { name: EDomainType.mx, group: 1 },
    { name: EDomainType.com, group: 2 },
    { name: EDomainType.net, group: 2 },
    { name: EDomainType.xyz, group: 4 },
    { name: EDomainType.autos, group: 4 },
    { name: EDomainType.lol, group: 4 },
    { name: EDomainType.mom, group: 4 }
  ];
}
