import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environments';
import { Observable } from 'rxjs';

@Injectable()
export class AddApikeyInterceptor implements HttpInterceptor
{
  constructor() { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    request = request.clone({
      setHeaders: {
        'C-E-ApiKey': Environment.C_E_ApiKey
      }
    });
    return next.handle(request);
  }
}
