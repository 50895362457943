/* eslint-disable */
interface Array<T> {
  getFirst(): T | undefined;
  isNullOrEmpty(): boolean;
  moveFirstToEnd(): void;
  moveLastToStart(): void;
}
/* eslint-enable */

Array.prototype.moveFirstToEnd = function<T>(this: T[]): void
{
  if (this.length > 0)
  {
    const firstItem = this.shift();
    if (firstItem !== undefined)
    {
      this.push(firstItem);
    }
  }
};

Array.prototype.moveLastToStart = function<T>(this: T[]): void
{
  if (this.length > 0)
  {
    const lastItem = this.pop();
    if (lastItem !== undefined)
    {
      this.unshift(lastItem);
    }
  }
};

Array.prototype.getFirst = function<T>(this: T[]): T | undefined
{
  return this.length > 0 ? this[0] : undefined;
};

Array.prototype.isNullOrEmpty = function<T>(this: T[]): boolean
{
  return this.length === 0;
};