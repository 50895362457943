import { isPlatformBrowser } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { EPymesDocument } from "@core-constants/pymes.const";
import { AddToCartParams } from "@core-constants/url-param.const";
import { RouteHelper } from "@core-helpers/route.helper";
import { TokenManager } from "@core-managers/token.manager";
import { Environment } from '@environments';
import { AssetsService } from '@shared-services/assets.service';
import * as $ from "jquery";

@Component({
  selector: 'app-pymes',
  templateUrl: './pymes.component.html',
  styleUrls: ['./pymes.component.css']
})
export class PymesComponent implements OnInit
{
  public isOpen: boolean = false;
  public pymeBackgroundImgUrl = "url(" + this.assetsService.getAssetFromBlob('/images/pymes-hero-background.png') + ")";
  public pymeBackgroundImgUrlMobile = "url(" + this.assetsService.getAssetFromBlob('/images/pymes-hero-background-mobile.png') + ")";
  @ViewChild('pymeBenefits', { static: false }) public pymeBenefits: ElementRef<HTMLButtonElement>;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public routeHelper: RouteHelper,
    public tokenManager: TokenManager,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private assetsService: AssetsService
  ) { }

  public get accountRegistrationPDF(): string
  {
    return EPymesDocument.AccountRegistration;
  }

  public get domainRegistrationPDF(): string
  {
    return EPymesDocument.DomainRegistration;
  }

  public get emailConfigurationPDF(): string
  {
    return EPymesDocument.EmailConfiguration;
  }

  public get wixConfigurationPDF(): string
  {
    return EPymesDocument.WixConfiguration;
  }

  public get termsConditionsPDF(): string
  {
    return EPymesDocument.TermsConditions;
  }

  public scrollToBenefits(): void
  {
    this.pymeBenefits.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.titleService.setTitle("Akky | Digitalízate PYME");
    }
    this.initializeAnimation();
    this.registerRouteEventListeners();
  }

  public get homeURL(): string
  {
    return this.routeHelper.ecommerceURL;
  }

  public get homePackageURL(): string
  {
    let packageToAdd: number | undefined = this.tokenManager.getPackageIdToAdd();

    if (!packageToAdd)
    {
      packageToAdd = Environment.PYMESPackage;
    }

    return `${this.routeHelper.ecommerceURL}?packageToAdd=${packageToAdd}`;
  }

  public registerRouteEventListeners(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.activatedRoute.queryParams
      .subscribe({
        next: (params) =>
        {
          this.buildToAddParams(params);
        }
      });
    }
  }

  private buildToAddParams(params: object): void
  {
    if (params[AddToCartParams.Coupon] || params[AddToCartParams.Package])
    {
      const pkg = params[AddToCartParams.Package];
      const coupon = params[AddToCartParams.Coupon];

      this.tokenManager.saveCartToAddParams(pkg, coupon);
    }
    else
    {
      this.tokenManager.remoteToAdd();
    }
  }

  public initializeAnimation(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      $(window).scroll(function ()
      {
        $('.hideme').each(function ()
        {
          const bottomObject = $(this).position().top + $(this).outerHeight();
          const bottomWindow = $(window).scrollTop() + $(window).height();

          if (bottomWindow > bottomObject)
          {
            $(this).animate({ 'opacity': '1' }, 1500);
          }
        });
      });
    }
  }

  public toggleDropdown(): void
  {
    this.isOpen = !this.isOpen;
  }
}
