import { isPlatformBrowser } from "@angular/common";
import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { EAppEventName } from "@core-constants/app-events.const";
import { EGtmEvent } from "@core-constants/gtm-const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { RouteHelper } from "@core-helpers/route.helper";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { SettingsManager } from "@core-managers/settings.manager";
import { TokenManager } from "@core-managers/token.manager";
import { EPurchaseSuggestionLocation, IShoppingCartGeneralStoredSuggestionPlus, IPurchaseSuggestionItemAdded } from '@core-models/purchase-suggestion.model';
import { GroupedShoppingCart } from "@core-models/shopping-cart-items.model";
import { UserAccessResponse } from "@core-models/user-access.model";
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { BroadcastService } from "@shared-services/broadcast.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-popup-shopping-cart',
  templateUrl: './popup-shopping-cart.component.html',
  styleUrls: ["./popup-shopping-cart.component.css"]
})
export class PopupShoppingCartComponent implements OnInit
{
  public cartItems: GroupedShoppingCart[];
  public purchaseSuggestions: IShoppingCartGeneralStoredSuggestionPlus[];

  public disableCheckout: boolean = false;
  public isValidUser: boolean = true;

  @Input() public show: boolean;
  @Output() public onHide = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    protected tokenManager: TokenManager,
    protected toast: ToastService,
    protected routeHelper: RouteHelper,
    protected shoppingCartDataService: ShoppingCartDataService,
    protected settingsManager: SettingsManager,
    protected checkoutManager: CheckoutManager,
    private router: Router,
    private destroyRef$: DestroyRef
  ) { }

  public get cartItemsCount(): number
  {
    return this.checkoutManager.cartItemsCount;
  }

  public get hasCouponToApply(): boolean
  {
    return this.checkoutManager.hasCouponToApply;
  }

  public get title(): string
  {
    return this.isValidUser ? "Tu Carrito" : "Iniciar Sesión";
  }

  public get total(): number
  {
    return this.checkoutManager.total;
  }

  public get servicesLabel(): string
  {
    const count = this.checkoutManager.cartItemsCount;
    const text = count > 1 || count == 0 ? "Servicios" : "Servicio";

    return count + " " + this.translateService.getElement(text);
  }

  public get showSuggestions(): boolean
  {
    return this.purchaseSuggestions && this.purchaseSuggestions.length > 0;
  }

  public get maxCartItems(): number
  {
    return this.settingsManager.itemsLimit;
  }

  public ngOnInit(): void
  {
    this.cartItems = this.checkoutManager.cartItems;
    this.registerEventListeners();
    this.getPurchaseSuggestions();
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnUpdateCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.cartItems = this.checkoutManager.cartItems;
        this.getPurchaseSuggestions();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.onOpenCartPopup(this.cartItems);
      }
    });
  }
  // ********************************************************************
  //#region UserAcces
  // ********************************************************************

  public onLogInClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnOpenLogin);
  }

  public onSignUpClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnOpenLogin);
    BroadcastService.Instance.broadcast(EAppEventName.OnOpenSignUp);
  }

  public onCheckoutClicked(): void
  {
    if (this.isPurchaseSuggestionPageValid())
    {
      this.router.navigate(['/recomendaciones']);
    }
    else
    {
      this.changeLocationHref(this.routeHelper.shoppingCartWebURL);
    }
  }

  private isUserLoggedIn(): boolean
  {
    const user: UserAccessResponse.IUserInfo = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();

    return !!(user && token);
  }

  // #endregion

  // ********************************************************************
  //#region Purchase suggestions
  // ********************************************************************

  private getPurchaseSuggestions(): void
  {
    this.shoppingCartDataService.getPurchaseSuggestions(EPurchaseSuggestionLocation.PopupShoppingCart).subscribe({
      next: (response) =>
      {
        this.purchaseSuggestions = response.map(suggestion => this.purchaseSuggestionAdapter(suggestion));
      }
    });
  }

  private purchaseSuggestionAdapter(purchaseSuggestion: IShoppingCartGeneralStoredSuggestionPlus): IShoppingCartGeneralStoredSuggestionPlus
  {
    return {
      ...purchaseSuggestion,
      coverage: purchaseSuggestion.coverage[0]
    };
  }

  public onAddSuggestion($event: IPurchaseSuggestionItemAdded): void
  {
    this.purchaseSuggestions = this.purchaseSuggestions.filter(x => x.suggestionId != $event.suggestionId);
    this.getPurchaseSuggestions();

    BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, $event.cartId);
    this.toast.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
  }

  // #endregion
  public onShoppingCartItemChanges($event: boolean): void
  {
    this.disableCheckout = $event;
  }

  public onOpenCartPopup(items: GroupedShoppingCart[]): void
  {
    const productList = GtmTrackingService.collectGTMCartItems(items);
    GtmTrackingService.collectGTMDomainServices(items, productList);
    GtmTrackingService.viewCartEvent(EGtmEvent.ViewCart, GtmTrackingService.mapProducts(productList), this.total);
  }

  public goToCart(): void
  {
    if (this.isUserLoggedIn())
    {
      if (this.isPurchaseSuggestionPageValid())
      {
        this.router.navigate(['/recomendaciones']);
      }
      else
      {
        GtmTrackingService.beginCheckoutEvent(EGtmEvent.BeginCheckout, this.cartItems, this.total);
        this.changeLocationHref(this.routeHelper.shoppingCartWebURL);
      }
    }
    else
    {
      this.isValidUser = false;
    }
  }

  public changeLocationHref(url: string): void
  {
    if (isPlatformBrowser(this.platformId) && url)
    {
      window.location.href = url;
    }
  }

  public onHideShoppingCartClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnCloseCartPopup);
    this.show = false;
    this.isValidUser = true;
    this.onHide.emit(false);
  }

  private isPurchaseSuggestionPageValid(): boolean
  {
    return this.settingsManager.purchaseSuggestionPageEnabled === true &&
      !this.router.url.includes('/recomendaciones') &&
      this.checkoutManager.cartItemsCount > 0;
  }

  // ********************************************************************
  //#region UI Control
  // ********************************************************************
  public trackByQuantityGroup(index, item: GroupedShoppingCart): number
  {
    return item.quantityGroup;
  }
  // #endregion
}
