<div id="aboutUs-subheader" class="container my-5">
  <div class="text-uppercase fs-l font-weight-bold silver">{{'Quiénes somos ' | translate}}</div>
  <h1 class="msb fs-xxl green">{{'Acerca de Akky' | translate}}</h1>
  <div class="fs-l font-weight-bold blackGray">{{'Nuestro propósito es:' | translate}}</div>
</div>

<div id="aboutUs-under-options" class="container service-under-options-container my-3">
  <div class="service-under-options row ps-0">
    <div class="service-under-options-text mx-0 col-12 col-md-10 col-lg-6">
      <div class="font-weight-bold blackGray fs-l mb-2">
        {{'Ofrecemos servicios y asesoría a empresas, emprendedores y PyMES para que puedan desarrollar y crecer su negocio a través de la transformación digital de sus operaciones.' | translate}}.
      </div>
      <div class="fs-l font-weight-bold d-none blackGray">
        {{'Proveemos diversos servicios como el registro y la administración de nombre de dominio, galardonados constructores de sitios web y otras herramientas necesarias para tus proyectos digitales.' | translate}}
      </div>
    </div>
  </div>
</div>

<div id="aboutUs-differentiators" class="container mt-5">
  <div class="text-uppercase fs-l font-weight-bold silver">
    {{'Valor' | translate}}
  </div>

  <div class="msb fs-xl green">
    {{'Nuestros principales diferenciadores' | translate}}
  </div>

  <div class="row d-md-flex">
    <div class="mx-auto col-10 col-md-4 col-lg-3 p-4">
      <div class="d-flex flex-column ml-3" align="center">
        <div>
          <img class="limited-size-service-img" loading="lazy" data-src="{{ '/images/experiencia.svg' | blobstorage }}" alt="Experiencia probada" src="{{ '/images/experiencia.svg' | blobstorage }}">
        </div>
        <div class="text-uppercase font-weight-bold aqua fs-l mb-2">
          {{'Experiencia' | translate}}
        </div>
        <div class="font-weight-bold silver fs-l mb-2">
          {{'Más de 30 años de experiencia en la gestión de nombres de dominio y otros recursos de Internet.' | translate}}
        </div>
      </div>
    </div>
    <div class="mx-auto col-10 col-md-4 col-lg-3 p-4">
      <div class="d-flex flex-column ml-3" align="center">
        <div>
          <img class="limited-size-service-img" loading="lazy" data-src="{{ '/images/perzonalidaza.svg' | blobstorage }}" alt="Atención Personalizada" src="{{ '/images/perzonalidaza.svg' | blobstorage }}">
        </div>
        <div class="text-uppercase font-weight-bold aqua fs-l mb-2">
          {{'Atención 100% Personalizada' | translate}}
        </div>
        <div class="font-weight-bold silver fs-l mb-2">
          {{'Contamos con un equipo altamente capacitado listo para atender todas tus dudas y necesidades.' | translate}}
        </div>
      </div>
    </div>
    <div class="mx-auto col-10 col-md-4 col-lg-3 p-4">
      <div class="d-flex flex-column ml-3" align="center">
        <div>
          <img class="limited-size-service-img" loading="lazy" data-src="{{ '/images/seguridad.svg' | blobstorage }}" alt="Seguridad y satisfacción" src="{{ '/images/seguridad.svg' | blobstorage }}">
        </div>
        <div class="text-uppercase font-weight-bold aqua fs-l mb-2">
          {{'Seguridad y Satisfacción' | translate}}
        </div>
        <div class="font-weight-bold silver fs-l mb-2">
          {{'Contamos con los más altos estándares de seguridad para la protección de tus datos.' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
