<app-navbar></app-navbar>
<div class="basic-content benefits-container">
    <app-exclusive-promotion *ngIf="exclusiveItems" [title]="benefits?.layout?.translations[0]?.titleExclusive"
                             [exclusiveItems]="exclusiveItems">
    </app-exclusive-promotion>
    <app-promotion-month *ngIf="monthItems" [title]="benefits?.layout?.translations[0]?.titleMonth"
                         [monthItems]="monthItems"></app-promotion-month>
    <app-seasonal-discounts *ngIf="seasonalItems" [title]="benefits?.layout?.translations[0]?.titleSeasonal"
                            [seasonalItems]="seasonalItems"></app-seasonal-discounts>

    <div class="px-2 px-sm-3 px-md-5 mt-3 mb-5 ng-star-inserted d-flex justify-content-center">
        <a class="d-block black fs-5 text-center container-benefits" target="_blank"
           href="{{ termsConditionsPDF | blobstorage}}">
            <u>Consulta los términos y condiciones</u>
        </a>
</div>
</div>
<!-- Footer -->
<div class="my-5">
    <br />
    <app-footer></app-footer>
</div>
