export namespace LanguageConst
{
  export const ES = "es";
  export const EN = "en";


  export const Languages = [
    { name: "Español", code: ES },
    { name: "English", code: EN }
  ];

}