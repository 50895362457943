import { IToggleData } from "@core-models/generic.model";
import { EServiceCode } from "./product-service.const";

export namespace NavigationConst
{
    export class SiteRoutes
    {
        //Wordpress
        public static WordPressSites = "/servicios/wordpress/sitio-web";
        public static WordPressStore = "/servicios/wordpress/tienda-en-linea";

        //SSL
        public static SSLCertificates = "/servicios/certificados-ssl";

        //Online Store
        public static OnlineStoreForYou = "/servicios/creador-sitios/tienda-en-linea/por-ti";
        public static OnlineStoreWithYou = "/servicios/creador-sitios/tienda-en-linea/contigo";
        //Website
        public static WebsiteForYou = "/servicios/creador-sitios/creador-de-paginas/por-ti";
        public static WebsiteWithYou = "/servicios/creador-sitios/creador-de-paginas/contigo";
        //MKTService
        public static MTKService = "/servicios/marketing-digital";
        public static MKTBranding = "/servicios/marketing-digital/branding";
        public static MKTSearchEngineAds = "/servicios/marketing-digital/guia-ads-en-buscadores";
        public static MKTSocialMedia = "/servicios/marketing-digital/redes-sociales";

        //Email
        public static EmailOX = "/servicios/email";
        public static EmailPremium = "/servicios/email-personalizado";

        //Microsoft
        public static Microsoft = "/servicios/microsoft/365";
        public static Office = "/servicios/microsoft/office";
        public static McftOthers = "/servicios/microsoft/otros";

        //About us
        public static AboutAKKY = "/acerca/quienes-somos";
        public static ExecutiveCommittee = "/acerca/nuestroequipo";
    }

    export class ServicesTogglerInfo
    {
        public static emailServices: IToggleData[] = [
            { id: 0, title: 'E-mail', service: EServiceCode.EmailOX, path: SiteRoutes.EmailOX },
            { id: 1, title: 'Otros planes', service: EServiceCode.EmailPremium, path: SiteRoutes.EmailPremium }
        ];

        public static digitalMtkServices: IToggleData[] = [
            { id: 0, title: 'Branding', service: EServiceCode.MKTBranding, path: SiteRoutes.MKTBranding },
            { id: 1, title: 'Kit de redes', service: EServiceCode.MKTSocialMedia, path: SiteRoutes.MKTSocialMedia },
            { id: 2, title: 'Guía Ads', service: EServiceCode.MKTSearchEngineAds, path: SiteRoutes.MKTSearchEngineAds }
        ];

        public static onlineStoreServices: IToggleData[] = [
            { id: 0, title: 'Por ti', service: EServiceCode.OnlineStorePT, path: SiteRoutes.OnlineStoreForYou },
            { id: 1, title: 'Contigo', service: EServiceCode.OnlineStoreC, path: SiteRoutes.OnlineStoreWithYou }
        ];

        public static wordPressServices: IToggleData[] = [
            { id: 0, title: 'Sitios Web', service: EServiceCode.WordPressSites, path: SiteRoutes.WordPressSites },
            { id: 1, title: 'Tienda en línea', service: EServiceCode.WordPressStore, path: SiteRoutes.WordPressStore }
        ];

        public static sslCertificateServices: IToggleData[] = [
            { id: 0, title: 'Certificados SSL', service: EServiceCode.CertificateSSL, path: SiteRoutes.SSLCertificates },
            { id: 1, title: 'Subdominios ilimitados', service: EServiceCode.WildcardSSL, path: SiteRoutes.SSLCertificates },
            { id: 2, title: 'Multiples dominios', service: EServiceCode.MultidomainSSL, path: SiteRoutes.SSLCertificates }
        ];

        public static websiteBuilderServices: IToggleData[] = [
            { id: 0, title: 'Por ti', service: EServiceCode.WebSitePT, path: SiteRoutes.WebsiteForYou },
            { id: 1, title: 'Contigo', service: EServiceCode.WebSiteC, path: SiteRoutes.WebsiteWithYou }
        ];

        public static microsoftServices: IToggleData[] = [
            { id: 0, title: '365', service: EServiceCode.Microsoft, path: SiteRoutes.Microsoft },
            { id: 1, title: 'Office', service: EServiceCode.Office, path: SiteRoutes.Office },
            { id: 2, title: 'Otros', service: EServiceCode.MicrosoftOthers, path: SiteRoutes.McftOthers }
        ];
    }

    export const AboutOptions: any[] = [{
        title: 'Acerca de Akky',
        route: SiteRoutes.AboutAKKY,
        name: 'Akky descubre quiénes somos | Akky',
        description: "Akky ofrece servicios y asesorías a empresas, emprendedores y PyMES para que desarrollen y crezcan su negocio, dando el mejor servicio a cliente y soporte"
    },
    {
        title: 'Nuestro Equipo',
        route: NavigationConst.SiteRoutes.ExecutiveCommittee,
        name: 'Conoce a nuestro Equipo | Akky',
        description: 'Nuestro equipo se encarga de darte el mejor servicio, personalizado y a la medida de tu empresa, conócenos'
    }];

}
