import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IHelpMenuItem } from '@core-models/help.model';
import { AssetsService } from '@shared-services/assets.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpMenuComponent implements OnInit, AfterViewInit
{
  @Input() public menuOptions: IHelpMenuItem[] = [];
  @Input() public selectedItemInfo: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId,
    public assetsService: AssetsService,
    private destroyRef$: DestroyRef
  ) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public ngAfterViewInit(): void
  {
    this.setImages();
  }

  public registerEventListeners(): void
  {
    this.assetsService.onBlobURLChanges()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.setImages();
      }
    });
  }

  public setImages(): void
  {
    const backgroundImage = this.assetsService.getAssetFromBlob('/icons/down_arrow.svg');
    if (isPlatformBrowser(this.platformId))
    {
      Tools.cssRuleSet('.accordion-button::after', 'background-image', `url(${backgroundImage})`, 'important');
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
