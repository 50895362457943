import { EServiceCode } from "@core-constants/product-service.const";
import { IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { IItemProductLite } from "@core-models/shopping-cart-items.model";

export abstract class ShoppingCartSuggestionItemBase
{
    private _parentId: number;
    private _suggestion: IShoppingCartIndividualStoredSuggestionPlus = undefined;
    private _itemProduct: IItemProductLite;

     public isAdded: boolean = false;
     public addedIsChanging: boolean = false;

    constructor() { }

    protected get suggestion(): IShoppingCartIndividualStoredSuggestionPlus
    {
        return this._suggestion;
    }

    protected set suggestion(value: IShoppingCartIndividualStoredSuggestionPlus)
    {
        if (!value)
        {
            this._suggestion = undefined;
            return;
        }

        this.isAdded = value.individualFeatures.isAdded;
        this._suggestion = value;
    }

    public get buttonId(): string
    {
        const itemData = `${this.suggestion.id}-${this._parentId}`;
        return `individual-suggestion-${itemData}`;
    }

    public get parentId(): number
    {
        return this._parentId;
    }

    public set parentId(value: number)
    {
        this._parentId = value;
    }

    public get label(): string
    {
       return this.suggestion.translations.textLabel;
    }

    public get title(): string
    {
       return this.suggestion.individualFeatures.displayName ?? this.suggestion.translations.title;
    }

    public get subtitle(): string
    {
       return this.suggestion.translations.subtitle;
    }

    public get finalAmount(): number
    {
        const rate = this.suggestion.coverage.length > 0 ? this.suggestion.coverage.find(x => x.id === this.suggestion.coverageId)?.rate?.finalAmount : undefined;
        return rate || 0;
    }

    public get toggleTitle(): string
    {
        return this.isAdded === true ? "Eliminar" : "Agregar a carrito";
    }

    public get isDomain(): boolean
    {
        return this.suggestion.serviceToOffer.includes(EServiceCode.DOT);
    }

    public get itemProduct(): IItemProductLite
    {
        return this._itemProduct;
    }

    public set itemProduct(value: IItemProductLite)
    {
        if (!value)
        {
            this._itemProduct = undefined;
            return;
        }
        this._itemProduct = value;
    }

    public get isUpgradeSuggestion(): boolean
    {
        return this.suggestion.individualFeatures.isValidUpgrade;
    }

    protected getTitleClass(title: string, addedCondition: boolean = false): string
    {
        const titleClass = {
        nowrap: 'text-nowrap',
        wrap: 'text-wrap'
        };
        const key = this.isAdded == addedCondition && title.length <= 16 ? 'nowrap' : 'wrap';
        return titleClass[key] || '';
    }

    protected getContainerClass(title: string, label: string, price: string): string
    {
        const containerClass = {
            column: 'flex-column align-items-end',
            row: 'flex-row gap-0'
        };

        const key = !this.isAdded && title?.length > 12 && label?.length > 6 ? 'column' :
                    !this.isAdded && title?.length > 6 && (price?.length > 6 && label?.length > 6) ? 'column' : 'row';

        return containerClass[key] || '';
    }
}