import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-faqs-menu',
  templateUrl: './faqs-menu.component.html',
  styleUrls: ['./faqs-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FaqsMenuComponent 
{
  private _menuOptions: any[] = [];

  @Input() public set menuOptions(value: any[])
  {
    this._menuOptions = value;
    this.changeDetectorRef.detectChanges();
  }

  public get menuOptions(): any[]
  {
    return this._menuOptions;
  }

  @Input() public htmlId: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public identify(index: any, item: any): any
  {
    return item;
  }

  public getHtmlId(optionItem: any): any
  {
    return optionItem?.htmlId;
  }
}
