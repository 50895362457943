import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ToggleHelper
{
    private _toggleChange: number = 0;
    private _showFeatures: boolean = true;

    private toggleChange$: BehaviorSubject<number> = new BehaviorSubject<number>(this._toggleChange);
    private showFeatures$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._showFeatures);

    public onToggleChange(): Observable<number>
    {
        return this.toggleChange$.asObservable();
    }

    public onShowFeatures(): Observable<boolean>
    {
        return this.showFeatures$.asObservable();
    }

    public set changeToggle(value: number)
    {
        this._toggleChange = value;
        this.toggleChange$.next(this._toggleChange);
    }

    public get changeToggle(): number
    {
        return this._toggleChange;
    }

    public set showFeatures(value: boolean)
    {
        this._showFeatures = value;
        this.showFeatures$.next(this._showFeatures);
    }

    public get showFeatures(): boolean
    {
        return this._showFeatures;
    }
}
