import { isPlatformBrowser } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.css']
})

export class SolutionComponent
{
  public _solutions: any;
  public _translations: any;

  @Input() public set solution(value: any)
  {
    if (value)
    {
      this._solutions = value;
      this._translations = this._solutions.traducciones[0];
      this.ref.detectChanges();
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    private ref: ChangeDetectorRef,
    protected translateService: TranslateService,
    private router: Router) { }


  public redirect(link: string): void
  {
    if (link)
    {
      if (isPlatformBrowser(this.platformId) && link.includes('http'))
      {
        window.open(link, '_blank');
      }
      else
      {
        const links: any[] = [];

        links.push(link);

        this.router.navigate(links);
      }
    }
  }
}
