export const ForYouSrc =
{
    en: 'https://www.youtube.com/embed/7V6N_6YhD_I',
    es: 'https://www.youtube.com/embed/CgCI-C0BJks'
};

export const WithYourSrc =
{
    en: 'https://www.youtube.com/embed/xwkw-JlZixo',
    es: 'https://www.youtube.com/embed/32QXuJTNC5A'
};