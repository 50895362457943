<app-base-service [serviceData]="_serivceData">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serivceData">

      <!-- Toggler -->
      <div class="d-flex w-100 justify-content-center align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <app-service-plans [plans]="plans" [imgOnBottom]="false" [locationInit]="locationServicePage"></app-service-plans>

      <ng-container *ngIf="selectedIndex === 2">
        <div id="ssl-multidomain-description"></div>
        <div class="mb-5" [innerHTML]="exampleHtmlText | safe: 'html'"></div>
      </ng-container>

      <ng-container *ngIf="selectedIndex === 1">
        <div id="ssl-wildcard-description"></div>
        <div class="row mt-5 mt-md-3 mb-5">
          <div class="col-12 col-md-6 fs-m font-weight-bold blackGray d-flex flex-column">
            <div class="mt-auto"><span class="msb">{{ 'Certificados SSL Subdominios Ilimitados:' | translate }} </span>
              {{ 'Están diseñados para proteger todos los subdominios de tu sitio web siempre que compartan el mismo
              nombre de dominio principal (se indican con un asterisco en el campo de nombre común).' | translate }}
            </div>
            <div class="mt-3 mb-auto"> {{ 'No hay límite para la cantidad de subdominios que se pueden cubrir con el
              mismo certificado. Es decir, en lugar de tener que comprar un certificado SSL para todos y cada unos de
              los subdominios, podrás protegerlos a todos con un solo certificado SSL Wildcard.' | translate }}</div>
          </div>
          <div class="col-12 col-md-6">
            <img loading="lazy" id="ssl-wildcard-img" class="limited-size-img" alt="ssl image"
              src="{{ '/images/services/ssl_domain_es.svg' | translate | blobstorage }} ">
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</app-base-service>
