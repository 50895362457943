import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { ICupon } from '@core-models/service-package.model';

@Component({
  selector: 'app-home-service-item',
  templateUrl: './home-service-item.component.html',
  styleUrls: ['./home-service-item.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeServiceItemComponent implements OnInit
{
  public _service: any;
  public _translations: any;

  public packageId: number = 0;
  public comboServices: ICupon = undefined;

  @Input() public set service(value: any)
  {
    if (value)
    {
      this._service = value;

      this.packageId = value.id_paquetes?.id;

      if (this.packageId)
      {
        this.getServicesPackage();
      }
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    private router: Router) { }

  public ngOnInit(): void
  {
    this.initializeData();
  }

  public initializeData(): void
  {
    this._translations = this._service.traducciones[0];
  }

  public redirect(link: string): void
  {
    if (link)
    {
      if (isPlatformBrowser(this.platformId) && link.includes('http'))
      {
        window.open(link, '_blank');
      }
      else
      {
        const links: any[] = [];

        links.push(link);

        this.router.navigate(links);
      }
    }
  }

  public getServicesPackage(): void
  {
    this.servicesDataService.getPackageById(this.packageId).subscribe({
      next: (response : ICupon) =>
      {
        if (response)
        {
          this.comboServices = response;
        }
      }
    });
  }

  public onComboButtonClicked(): void
  {
    this.promotionHelper.addToCart(this.comboServices, EGtmSection.Home, EventLocationSite.HomeCard);
  }
}
