<ng-template [ngTemplateOutlet]="loadingTemplate || defaultTemplate"
  [ngTemplateOutletContext]="{ iconTemplate: defaultIconTemplate, textTemplate: defaultTextTemplate }">
</ng-template>

<ng-template #defaultTemplate>
  <div class="mb-4 w-100">
    <div class="jumbotron dashed px-2 mb-0">
      <div class="d-flex align-items-center">
        <ng-template [ngTemplateOutlet]="defaultIconTemplate"></ng-template>
        <ng-template class="ms-3" [ngTemplateOutlet]="defaultTextTemplate"></ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultIconTemplate>
  <span class="d-inline-block animated infinite rotate line-height-1" [ngClass]="{'fs-xl': xlSize }">
    <img loading="lazy" data-src="{{ '/icons/Black/sync-alt.svg' | blobstorage }}" alt="Loading"
      src="{{ '/icons/Black/sync-alt.svg' | blobstorage }}">
  </span>
</ng-template>

<ng-template #defaultTextTemplate>
  <span [ngClass]="{'fs-xl': xlSize }"> {{ text | translate}} </span>
</ng-template>