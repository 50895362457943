
export const enum ECartItemOperation
{
  Registration,
  PendingPayment,
  Renewal,
  Recovery,
  RecoveryDeleted,
  Transfer,
  Upgrade
}

export const enum ECartItemStatus
{
  Registration,
  Renewal
}

export namespace ShoppingCartConst
{
  export class CoverageLabel
  {
    public static readonly yearsLabels = { one: 'año', many: 'años' };
    public static readonly monthsLabels = { one: 'mes', many: 'meses' };
  }

  export const OperationLabels = new Map<ECartItemOperation, string>([
    [ECartItemOperation.Registration, 'Registro'],
    [ECartItemOperation.Renewal, 'Renovación'],
    [ECartItemOperation.Transfer, 'Transferencia'],
    [ECartItemOperation.PendingPayment, 'Pendiente de pago'],
    [ECartItemOperation.Upgrade, 'Mejora'],
    [ECartItemOperation.RecoveryDeleted, 'Recuperación'],
    [ECartItemOperation.Recovery, 'Recuperación']
  ]);
}
