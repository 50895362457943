
export namespace PageHelper
{
  export const MobileWidth: number = 767;

  export class NewsLetterType
  {
    public static NewsLetter: string = "boletin";
    public static Notice: string = "aviso";
  }

  export class BannerType
  {
    public static NewsLetter: string = "redakky";
    public static Notice: string = "avisos";
  }
}

//#region Enums

export const enum ENavBarCategory
{
  Dominios = 1,
  SitiosTiendas,
  EmailSeguridad,
  MarketingDigital,
  Microsoft
}

export const enum EToastrPosition
{
  TopRightClass = "top-right",
  TopLeftClass = "top-left",
  BottomRightClass = "bottom-right",
  BottonLeftClass = "bottom-left"
}

export const enum EHTMLDataAtribute
{
  //home-popup
  ExpirationDate = "data-expiration-date",
  ExpirationMessage = "data-expiration-message",

  //slides
  ActionType = "data-tipo-accion",
  CartService = "data-servicio-carrito"
}
//#endregion
