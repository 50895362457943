import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PurchaseSuggestionManager } from '@core-managers/purchasse-suggestion.manager';
import { TokenManager } from '@core-managers/token.manager';
import { IFeature, IShoppingCartGeneralStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { AssetsService } from '@shared-services/assets.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-additional-purchase-suggestion-item',
  templateUrl: './additional-purchase-suggestion-item.component.html',
  styleUrls: ['./additional-purchase-suggestion-item.component.css']
})
export class AdditionalPurchaseSuggestionItemComponent
{
  private readonly _defaultBackgroundImage: string = '/icons/Colores/chevron_down-verde.svg';

  @Input() public classType!: string;
  @Input() public index: number;

  private _item: IShoppingCartGeneralStoredSuggestionPlus;
  public isSelected: boolean = false;
  public featuresTranslations: IFeature[] = [];

  @Input() public set purchaseSuggestion(value: IShoppingCartGeneralStoredSuggestionPlus)
  {
    if (value)
    {
      this.setPurchaseSuggestionData(value);
      this.registerEventListeners();
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private purchaseSuggestionManager: PurchaseSuggestionManager,
    public assetsService: AssetsService,
    private tokenManager: TokenManager,
    private destroyRef$: DestroyRef
  ) { }

  public get item(): IShoppingCartGeneralStoredSuggestionPlus
  {
    return this._item;
  }

  public get image(): string
  {
    return this.isSelected ? '/checks/checkbox-on.svg' : '/checks/checkbox-off.svg';
  }

  public get controlName(): string
  {
    return `serviceName_${this.index}`;
  }

  public get subtitle(): string
  {
    return this._item.translationToShow.subtitle;
  }

  public get imgRoute(): string
  {
    return this._item.serviceFeatures.imgRoute;
  }

  public get title(): string
  {
    return this._item.translationToShow.title;
  }

  public get textLabel(): string
  {
    return this._item.translationToShow.textLabel;
  }

  public get hasFeatures(): boolean
  {
    return this.featuresTranslations?.length > 0;
  }

  public get hasOfferAmount(): boolean
  {
    return this._item.coverage.rate.offerAmount === null ? false : true;
  }

  public get offerAmount(): number
  {
    return this._item.coverage.rate.offerAmount;
  }

  public get finalAmount(): number
  {
    return this._item.coverage.rate.finalAmount;
  }

  public registerEventListeners(): void
  {
    this.assetsService.onBlobURLChanges()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.setImages();
      }
    });

    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (lang) =>
      {
        this.setTranslatePurchaseSuggestion(lang);
      }
    });
  }

  public onSelectItem(): void
  {
    this.isSelected = !this.isSelected;
    this.purchaseSuggestionManager.addPurchaseSuggestion(this.item, this.isSelected);
  }

  private setPurchaseSuggestionData(value: IShoppingCartGeneralStoredSuggestionPlus): void
  {
      this._item = value;
      const suggestions: IShoppingCartGeneralStoredSuggestionPlus[] = this.tokenManager.getPurchaseSuggestionSelected() !== null ? this.tokenManager.getPurchaseSuggestionSelected().suggestions : [];

      if (suggestions.length > 0)
      {
        this.isSelected = suggestions.some(item => item.suggestionId === value.suggestionId);
      }
      else
      {
        this.isSelected = this._item.isPreselected;
        if (this.isSelected)
        {
          this.purchaseSuggestionManager.addPurchaseSuggestion(this._item, this.isSelected);
        }
      }
  }

  private setTranslatePurchaseSuggestion(lang: string): void
  {
    this.featuresTranslations = [];
    this._item.translationToShow = this._item.translations.find(x => x.language === lang.toUpperCase());
    this.featuresTranslations = this._item.serviceFeatures?.features?.filter(x => x.language == lang.toUpperCase());
  }

  public setImages(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const backgroundImage = this.assetsService.getAssetFromBlob(this._defaultBackgroundImage);
      Tools.cssRuleSet('.accordion-button::after', 'background-image', `url(${backgroundImage})`, 'important');
    }
  }
}
