import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { ILanguageComponent } from '@core-models/generic.model';
import { ISEOComponent } from '@core-models/seo.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs/operators';

declare function clickChat(): void;

@Component({
  selector: 'app-help-main',
  templateUrl: './help-main.component.html',
  styleUrls: ['./help-main.component.css']
})

export class HelpMainComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public pageTitle: string = 'Ayuda Akky';
  public backgroundImage: string = '/images/banner_gray.svg';
  @ViewChild('buttonChat') public buttonChat: ElementRef;

  private pageSEOTitle: string = "¿Con qué te ayudamos? Contáctanos | Akky";
  private pageSEODescription: string = "Conoce los servicios adicionales que ofrecemos para responder a todas tus necesidades. Contáctanos.";

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    public seoService: SEOService,
    private router: Router,
    private destroyRef$: DestroyRef)
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/ayuda/ayuda'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });
  }

  public ngOnInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);

    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }

  public openChat(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      clickChat();
    }
  }

  public goToBlog(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = ReferenceConst.BlogAkkyUrl;
    }
  }

  public redirectTo(route: string): void
  {
    this.router.navigate([route], { relativeTo: this.activatedRoute });
  }
}
