import { Component } from '@angular/core';

@Component({
  selector: 'app-about-who-we-are',
  templateUrl: './who-we-are.component.html'
})

export class WhoWeAreComponent
{
  constructor() { }
}
