<ng-container *ngIf="isIndividualSuggestion">
    <ng-container *ngTemplateOutlet="individualSuggestions"></ng-container>
</ng-container>

<ng-container *ngIf="isAdditionalServiceSuggestion">
    <ng-container *ngTemplateOutlet="additionalServiceAdded"></ng-container>
</ng-container>

<ng-template #individualSuggestions>
    <ng-container>
        <div class="suggestion-card row p-0 m-0"
             [class.active]="isAdded">
            <div class="col-12 d-flex my-2 align-items-start">
                <div class="flex-grow-1">
                    <span *ngIf="title"
                          class="fs-m word-break-all fw-medium">{{title}}</span>
                </div>
                <div *ngIf="label"
                     class="offer-radius bg-aqua white fs-xs px-2 py-1 ms-0 ms-md-3">
                    {{label}}
                </div>
            </div>
            <div class="col-12 d-flex my-1 align-items-center justify-content-start">
                <app-input-toggler [parentId]="buttonId"
                                   [value]="isAdded"
                                   [title]="toggleTitle"
                                   [disabled]="addedIsChanging"
                                   (onValueChanges)="onCheckChanges($event)"></app-input-toggler>
                <span *ngIf="subtitle"
                      class="fs-md ms-2">{{subtitle}}</span>
            </div>
            <div class="col-12 d-flex my-2 justify-content-end align-items-center">
                <span *ngIf="finalAmount"
                      class="price fw-bold fs-m">{{finalAmount | currency}} MXN</span>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #additionalServiceAdded>
    <ng-container>
        <div class="row p-0 m-0">
            <p class="green fw-bold fs-l m-0 p-0">
                {{ 'Servicios Adicionales' | translate }}
            </p>
            <div class="col-12 d-flex flex-row my-2 align-items-center justify-content-between p-0">
                <div class="d-flex align-items-center gap-2 mb-2 mb-md-0 p-0">
                    <span class="i-green-check"></span>
                    <span *ngIf="title"
                          class="fw-medium fs-m p-2"
                          [ngClass]="getTitleClass(title)">{{title}}</span>
                </div>
                <div class="d-flex justify-content-end gap-2 p-0"
                     [ngClass]="getContainerClass(title,label)">
                    <span *ngIf="finalAmount"
                          class="price fw-bold fs-m"> {{ finalAmount | currency }} MXN</span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>