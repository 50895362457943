import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EHubspotFormPlace } from '@core-constants/hubspot.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { SettingsManager } from '@core-managers/settings.manager';
import { ILanguageComponent } from '@core-models/generic.model';
import { ISEOComponent } from '@core-models/seo.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { SEOService } from '@shared-services/seo.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs/operators';

declare function clickChat(): void;

@Component({
  selector: 'app-help-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public formType = EHubspotFormPlace.Contact;
  public pageTitle: string = "Contáctanos Akky";

  @ViewChild('buttonChat') public buttonChat: ElementRef;

  private pageSEOTitle: string = "Contáctanos | Akky";
  private pageSEODescription: string = "Conoce nuestros horarios de atención al cliente y nuestra ayuda en línea. Akky brinda la mejor atención al cliente y soporte.";
  private _googleMapsURL = ReferenceConst.GoogleMapsURL;
  public urlWhatsApp = ReferenceConst.WhatsAppUrl;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public translateService: TranslateService,
    public settingsManager: SettingsManager,
    public toast: ToastService,
    public seoService: SEOService,
    private router: Router,
    private destroyRef$: DestroyRef)
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/contacto'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });
  }

  public get isChatIconEnabled(): boolean
  {
    return this.settingsManager.isChatIconEnabled;
  }

  public ngOnInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);

    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
     this.translateService.onTranslateChange()
     .pipe(takeUntilDestroyed(this.destroyRef$))
     .subscribe({
      next: () =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }

  public openChat(): void
  {
    if (!this.isChatIconEnabled)
    {
      this.toast.setErrorToast(ErrorMessageConst.ChatUnavailable);
    }
    else
    {
      if (isPlatformBrowser(this.platformId))
      {
        clickChat();
      }
    }
  }

  public openMap(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(this._googleMapsURL, '_blank');
    }
  }
}
