import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IIterations } from '@core-models/generic.model';

@Component({
  selector: 'app-complementary-service-list',
  templateUrl: './complementary-service-list.component.html',
  styleUrls: ['./complementary-service-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ComplementaryServiceListComponent implements IIterations
{
  public complementaryServicesValue: any[] = [];

  @Input() public set complementaryServices(value: any[])
  {
    this.complementaryServicesValue = value;
  }

  public get complementaryServices(): any[]
  {
    return this.complementaryServicesValue;
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
