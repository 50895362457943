import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { ClickActions } from '@core-constants/action-type.const';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { ICarousel, ICarouselItem } from '@core-models/deals.model';
import { ICupon } from '@core-models/service-package.model';

@Component({
  selector: 'app-featured-deals',
  templateUrl: './featured-deals.component.html',
  styleUrls: ['./featured-deals.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedDealsComponent implements OnChanges, OnInit
{
  @Input() public title: string = "";
  @Input() public description: string = "";
  @Input() public dealItems: ICarousel[] | undefined = undefined;

  public items: ICarouselItem[] = [];
  public activeIndex: number = 1;
  public activeDeal: ICarouselItem | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void
  {
    if (this.dealItems.length === 1)
    {
      this.activeIndex = 0;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.dealItems)
    {
      this.assignTitles();
      if (isPlatformBrowser(this.platformId))
      {
        this.ref.detectChanges();
      }
    }
  }

  private updateActiveDeal(): void
  {
    this.activeDeal = this.items[this.activeIndex];
  }

  public assignTitles(): void
  {
    if (this.dealItems.length >= 1 && this.dealItems.length <= 3)
    {
      this.items = this.dealItems.sort((a, b) => a.orden - b.orden).map((dealItem) =>
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { translations, pakageToAdd } = dealItem;
        const translation = translations[0];
        const packageId = pakageToAdd?.id;
        return {
          title: translation.titulo,
          description: translation.descripcion,
          button: translation.texto_boton,
          alt: translation.img_promocion.title || '',
          src: translation.img_promocion.id,
          pakageId: packageId || 0,
          accion_boton: dealItem.accion_boton,
          url_redireccionamiento: dealItem.url_redireccionamiento
        };
      });
      this.updateActiveDeal();
    }
  }

  public onPreviousClicked(): void
  {
    this.items.moveLastToStart();
    this.updateActiveDeal();
    this.ref.detectChanges();
  }

  public onNextClicked(): void
  {
    this.items.moveFirstToEnd();
    this.updateActiveDeal();
    this.ref.detectChanges();
  }

  public onButtonClick(index: number): void
  {
    if (index === this.activeIndex)
    {
      this.exceuteAction(this.activeDeal);
    }

    else
    {
      index > this.activeIndex ? this.onNextClicked() : this.onPreviousClicked();
    }
  }

  private exceuteAction(item: ICarouselItem): void
  {
    if (item.accion_boton === ClickActions.Types.Redirect)
    {
      if (isPlatformBrowser(this.platformId))
      {
        window.location.href = item.url_redireccionamiento;
      }
    }
    else
    {
      this.getServicesPackage(item.pakageId);
    }
  }

  private getServicesPackage(pakageId: number): void
  {
    this.servicesDataService.getPackageById(pakageId).subscribe({
      next: (response: ICupon) =>
      {
        if (response)  
        {
          this.promotionHelper.addToCart(response, EGtmSection.Deals, EventLocationSite.FeaturedDeal);
        }
      }
    });
  }

  public identify(item: ICarouselItem): ICarouselItem
  {
    return item;
  }


}
