import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';

@Injectable()
export class AddLanguageInterceptor implements HttpInterceptor
{
  constructor(private translateService: TranslateService) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    const languageCode = this.translateService.languageCode;

    if (languageCode)
    {
      request = request.clone({ setHeaders: { 'Content-Language': languageCode } });
    }

    return next.handle(request);
  }
}
