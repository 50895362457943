<div class="dgt-header">
  <img loading="lazy" class="dgt-logo-desktop" alt="Digitalízate Logo"
    src="{{ '/images/dgt-logo.png' | blobstorage }}" />
  <img loading="lazy" class="dgt-logo-movil" alt="Digitalízate Logo"
    src="{{ '/images/dgt-logo-mobile.png' | blobstorage }}" />
  <div class="landing-dropdown">
    <button class="dropbtn" (click)="toggleDropdown()">
      Descarga las guías de usuario
      <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="[!isOpen ? 'dgt-arrow-rotate' : 'dgt-arrow-rotate-rev']"
        width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4.07992 15.05L10.5999 8.53C11.3699 7.76 12.6299 7.76 13.3999 8.53L19.9199 15.05" stroke="#5643F2"
          stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <div class="landing-dropdown-content" [class.show]="isOpen">
      <a target="_blank"
         href="{{ accountRegistrationPDF | blobstorage}}">
        Alta de cuenta en Akky
      </a>
      <a target="_blank"
         href="{{ domainRegistrationPDF | blobstorage}}">
        Alta de dominio
      </a>
      <a target="_blank"
         href="{{ emailConfigurationPDF | blobstorage}}">
        Configuración de E-mail
      </a>
      <a target="_blank"
         href="{{ wixConfigurationPDF | blobstorage}}">
        Habilitación de sitio WIX
      </a>
    </div>
  </div>
</div>
<div class="dgt-hero-container pt-2 pt-md-5">
  <div class="container d-flex flex-column flex-xl-row">
    <img loading="lazy" class="d-block d-lg-none mb-3" alt="Digitalízate Tags"
    src="{{ '/images/dgt-tags-mobile.png' | blobstorage }}"/>
    <div class="d-flex">
      <div class="d-flex flex-column mb-3 mb-md-5 text-center align-items-center row-gap-4">
        <img loading="lazy" class="d-none d-lg-block w-100 dgt-tags-w-max" alt="Digitalízate Tags"
        src="{{ '/images/dgt-tags.png' | blobstorage }}"/>
      <span class="dgt-banner-text px-1 px-mb-5 white">Potencializa tus oportunidades 
        estudiantiles y profesionales.</span>
      <button class="dgt-button" (click)="scrollToButton()">
        Activa tus beneficios
      </button>
      </div>
      <img loading="lazy" class="d-none d-md-block" alt="Digitalízate"
      src="{{ '/images/dgt-banner.png' | blobstorage }}"/>
      <img loading="lazy" class="d-block d-md-none" alt="Digitalízate"
      src="{{ '/images/dgt-banner-mobile.png' | blobstorage }}"/>
    </div>
  </div>
</div>
<div class="container py-2 py-md-5">
  <div class="dgt-sectionone d-flex px-1 px-md-5 flex-column text-center align-items-center">
    <span class="dgt-stnone-ttl">¡Construye tu página web!</span>
    <span class="dgt-stnone-sub-ttl"> Y transforma tus ideas en una marca única con los beneficios 
      que te ofrecemos</span>
  </div>
</div>

<div class="dgt-benefits py-5">
  <div class="text-white flex-wrap justify-content-center d-flex align-items-center container">
    <div class="dgt-bnft-txt">
      <div class="dgt-max">
        <span class="dgt-stnone-ttl">Beneficios Digitalízate</span>
        <hr class="dgt-hr" />
      </div>
      <ul class="dgt-list dgt-stnone-prg">
        <li>Dominio <img loading="lazy" alt="dominio mx" src="{{ '/images/dgt-mx.png' | blobstorage }}" /> primer año sin
          costo</li>
        <li>E-mail personalizado 3 meses sin costo</li>
        <li>Creador de sitios web
          <img loading="lazy" alt="Wix Logo" src="{{ '/images/dgt-wix.png' | blobstorage }}"> incluye certificado SSL, Soporte Técnico y Asesoria para iniciar
        </li>
        <li>Guías y herramientas digitales</li>
      </ul>
    </div>
    <img loading="lazy" alt="taza Digitalízate" class="dgt-bnft-img-desktop"
      src="{{ '/images/dgt-bnts-desktop.png' | blobstorage }}" />
    <img loading="lazy" alt="taza Digitalízate" class="dgt-bnft-img-mobile"
      src="{{ '/images/dgt-bnts-mobile.png' | blobstorage }}" />
  </div>
</div>
<div #formContainer class="container py-5 justify-content-center text-center d-flex flex-column">
  <span class="dgt-stnone-ttl mb-3">¡Activa todos los beneficios en 2 sencillos pasos!</span>
  <span class="text-center dgt-stnone-sub-ttl mb-5">Si ya cuentas con un cupón generado, ve directo al paso 2 para activarlo</span>
  <div class="dgt-squares">
    <div class="dgt-square">
      <div class="dgt-square-title">
        <span class="dgt-circle">1</span>
        <span class="dgt-center-txt dgt-step-txt">Genera tu cupón</span>
      </div>
      <span class="dgt-square-pdd dgt-stnone-prg">Solo necesitas tu matrícula y correo institucional</span>
      <div [ngClass]="{'dgt-animation' : isExpand}" class="dgt-form">
        <form [formGroup]="couponForm" class="formpro" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input type="text" class="dgt-stnone-prg dgt-input" [formControl]="enrollment" placeholder="Matrícula"
              required maxlength="255">
            <div class="input-invalid-form" *ngIf="enrollment.touched && enrollment.errors">
              Matrícula Inválida
            </div>
          </div>
          <br />
          <br />
          <div class="form-group">
            <input type="text" class="dgt-stnone-prg dgt-input" [formControl]="institutionalMail"
              placeholder="E-mail institucional" required title="Utilice el dominio @tec.mx o @itesm.mx" maxlength="24">
            <div class="input-invalid-form"
              *ngIf="institutionalMail.touched && institutionalMail.errors && !institutionalMail.errors.onlyAlpha">
              Tu e-mail no es válido, debe comenzar con la letra A seguido de 8 números y pertenecer al dominio @tec.mx,
              @itesm.mx o @tecmilenio.mx.
            </div>
          </div>
          <br>
          <div class="d-flex w-100 align-items-center justify-content-center">
            <button id="btn-send-form" (click)="onSubmit()" type="button" [disabled]="couponForm.invalid"
              class="dgt-button">Enviar</button>
          </div>
          <br>
        </form>
      </div>
      <button (click)="onAnimationToggle()" class="dgt-button" [ngClass]="{'d-none': isExpand}">
        Obtenlo ahora
      </button>
    </div>
    <div class="dgt-square static-height">
      <div class="dgt-square-title">
        <span class="dgt-circle">2</span>
        <span class="dgt-center-txt dgt-step-txt">Empieza a digitalizarte</span>
      </div>
      <span class="dgt-square-pdd dgt-stnone-prg">Crea tu usuario en www.akky.mx</span>
      <a href="{{homePackageURL}}" target="_blank" class="dgt-button">
        Activa tus beneficios
      </a>
    </div>
  </div>
</div>
<div class="dgt-tc">
  <a class="dgt-link" target="_blank"
    href="{{ termsConditionsPDF | blobstorage}}">
    <u>Consulta los términos y condiciones</u>
  </a>
</div>
<app-footer></app-footer>

<div class="modal modal-lg fade" id="modalsucces" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body mt-2 ps-3 pe-3">
        <div class="dgt-modal-container">
          <span *ngIf="hasError" class="fas d-inline-block i-check-remove"></span>
          <div>
            <p class="dgt-stnone-prg text-center">
              <b>{{responseTitleMssg}}</b>
            </p>
            <p class="dgt-stnone-prg text-center">
              {{responseMssg}}
            </p>
            <br>
            <div class="d-flex justify-content-center align-items-center">
              <button (click)="onCloseModal()" class="dgt-button">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
