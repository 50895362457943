<h2 class="deal-title">
    {{title | translate | titlecase}}
</h2>
<span class="deal-subtitle">
    {{description | translate}}
</span>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-auto my-3 my-md-0">
            <app-filter-list></app-filter-list>
        </div>
        <div class="col">
            <div class="deals-container">
                <div *ngFor="let deal of displayedDeals; let i = index" class="card-deal p-4">
                    <div class="d-flex align-items-center">
                        <div class="pe-2 py-2">
                            <img [src]="deal.icon.id | directus" [alt]="deal.icon.title" class="img-fluid">
                        </div>
                        <h5 class="card-title">{{deal.translations[0]?.title}}</h5>
                    </div>
                    <span class="card-text text-muted">{{deal.translations[0]?.description}}</span>
                    <div [innerHtml]="deal.translations[0]?.price"></div> 
                    <span class="remove-margin"><strong>{{'Cupón:' | translate}}</strong>
                        {{deal.pakageToAdd?.cupon}}</span>
                    <span class="font-small">{{deal.translations[0]?.expiration}}</span>
                    <div class="position-relative">
                        <span class="font-small text-black clickable tooltip-text">
                            {{'Términos y condiciones' | translate}}
                        </span>
                        <div class="tooltip-content" [innerHTML]="deal.translations[0]?.termsConditions | safe: 'html'">
                        </div>
                    </div>
                    <button class="btn mx-2 btn-white" (click)="onDealClick(deal)">{{'Agregar al Carrito' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <nav *ngIf="dealItemsFiltered.length > itemsPerPage" class="pagination-container">
        <div class="pagination">
            <button (click)="onPreviousClicked()" [disabled]="currentPage === 1" class="pagination-button">
                <span class="pagination-text text-uppercase">{{'página:' | translate}}</span>
                <span class="i-arrow-left"></span>
                <span class="current-page">{{ currentPage }}</span>
            </button>
            <button (click)="nextPage()" [disabled]="currentPage === getTotalPages()" class="pagination-button">
                <span class="pagination-text">{{'de' | translate}}</span>
                <span class="total-pages">{{ getTotalPages() }}</span>
                <span class="i-arrow-left rotate-180"></span>
            </button>
        </div>
    </nav>
</div>