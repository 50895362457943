<ng-container *ngIf="show">
  <div class="d-flex w-100 fw-bold align-items-center">
    <div class="form-group w-100 floating-label position-relative">

      <div class="min-icon d-flex align-items-center" [class.clickable]="!disabled"
        (click)="onDecrementQuantityClicked()">
        <img src="{{ '/icons/minus-icon.svg' | blobstorage }}" [class.disabled-icon]="disabled" alt="ícono menos">
      </div>

      <div class="plus-icon d-flex align-items-center" [class.clickable]="!disabled"
        (click)="onIncrementQuantityClicked()">
        <img src="{{ '/icons/plus-icon.svg' | blobstorage }}" [class.disabled-icon]="disabled" alt="ícono mas">
      </div>

      <input itemQuantity type="number" name="quantity" [ngModel]="quantity" maxlength="3" min="1" [max]="maxItems"
        (onValueChanges)="onValueChanges($event)" onKeyPress="if (this.value.length === 3) return false;"
        class="form-control form-input quantity-input" [disabled]="disabled" [class.gray]="disabled"
        [currentItem]="currentItem" />
    </div>
  </div>
</ng-container>