<div class="d-block complementary-services-container">
  <div class="row justify-content-evenly">
    <div class="square-layout d-flex">
      <div class="col-12 card-body my-auto w-100">
        <div class="d-flex">
          <div class="row">
            <div *ngIf="title" class="col-12" [innerHTML]="title | safe: 'html'"></div>
            <div *ngIf="description" class="col-12" [innerHTML]="description | safe: 'html'"></div>
            <div *ngIf="price" class="col-12" [innerHTML]="price | safe: 'html'"></div>
            <div class="clickable button-primary-blue cursor-pointer mt-3 text-center font-weight-bold" (click)="onButtonClick()">
              <span [ngClass]="action === 'cart' ? 'btn-primary' : 'btn-secondary' + ' text-decoration-underline'" 
                    [attr.data-gtm-key]="gtmKey" 
                    id="{{ gtmKey }}">
                  {{ button }}
              </span>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</div>
