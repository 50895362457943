import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FaqCategoriesDataService } from '@core-data-services/faq.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { AssetsService } from '@shared-services/assets.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-faqs-detail',
  templateUrl: './faqs-detail.component.html',
  styleUrls: ['./faqs-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqsDetailComponent implements OnInit, ILanguageComponent, AfterViewInit
{
  public faqs: any[] = [];
  public title: string = "";
  public htmlId: string = "";

  @Input() public set idHtml(value: string)
  {
    this.htmlId = value;
    if (this.htmlId)
    {
      this.translateService.onTranslateChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: () =>
        {
          this.getCategoryDetail(this.htmlId);
        }
      });
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    public assetsService: AssetsService,
    private faqCategoriesDataService: FaqCategoriesDataService,
    private destroyRef$: DestroyRef
  ) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public ngAfterViewInit(): void
  {
    this.setImages();
  }

  public registerEventListeners(): void
  {
    this.assetsService.onBlobURLChanges()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.setImages();
      }
    });
  }

  public setImages(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const backgroundImage = this.assetsService.getAssetFromBlob('/icons/Colores/chevron_down-verde.svg');
      Tools.cssRuleSet('.accordion-button::after', 'background-image', `url(${backgroundImage})`, 'important');
    }
  }

  public getCategoryDetail(htmlId: string): void
  {
    this.faqCategoriesDataService.getFaqsByCategory(htmlId, this.translateService.languageCode).subscribe({
      next: (result: any) =>
      {
        if (result)
        {
          this.faqs = result.response;
          this.title = result.response[0]?.categoria?.traducciones[0]?.titulo ?? "";

          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
