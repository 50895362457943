import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { ILanguageComponent } from '@core-models/generic.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-about-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit, ILanguageComponent
{
  public pageTitle: string = "Métodos de pago";
  public backgroundImage: string = '/images/servicios_banner.svg';

  private pageSEOTitle: string = "Conoce las formas de pago que manejamos para tí | Akky";
  private pageSEODescription: string = "Descubre todos los métodos de pago que contamos para tu facilidad.";

  constructor(public translateService: TranslateService,
    public seoService: SEOService,
    public router: Router,
    private destroyRef$: DestroyRef)
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/formas-de-pago'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });
  }

  public ngOnInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }
}
