import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `<div class="modal hide" tabindex="-1" [ngClass]="{'show': show}">
              <div class="modal-dialog modal-dialog-centered modal-dialog-custom">
                <div class="modal-content modal-custom">
                  <span class="modal-header mb-3 justify-content-end icon-close-error" (click)="show = false" *ngIf="enableCloseDialogButton">
                    <img loading="lazy" data-src="{{ '/icons/Black/times.svg' | blobstorage }}" alt="About Title" src="{{ ''/icons/Black/times.svg' | blobstorage }}">
                  </span>
                   <div class="modal-body modal-custom-body">
                    <ng-content></ng-content>
                  </div>
                   <div class="modal-footer" *ngIf="enableConfirmButton">
                    <button type="button" class="btn btn btn-primary" data-dismiss="modal" (click)="show = false">{{ confirmButtonText }}</button>
                  </div>
                </div>
              </div>
            </div>`,
  styleUrls: ['./dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DialogComponent implements OnChanges
{
  @Input() public enableCloseDialogButton: boolean = true;
  @Input() public enableConfirmButton: boolean = false;
  @Input() public confirmButtonText: string = "SAMPLE";

  @ViewChild('modal') public modal: ElementRef;

  /*Show/Hide*/
  @Output() public showChange = new EventEmitter<boolean>();

  private _show: boolean = false;

  @Input() public set show(show: boolean)
  {
    this._show = show;
    this.showChange.emit(this._show);
  }

  public get show(): boolean
  {
    return this._show;
  }

  private _preventCloseFromBackDrop: boolean = false;

  @Input() public set preventCloseFromBackDrop(preventCloseFromBackDrop: boolean)
  {
    this._preventCloseFromBackDrop = preventCloseFromBackDrop;
  }

  public get preventCloseFromBackDrop(): boolean
  {
    return this._preventCloseFromBackDrop;
  }

  constructor(private renderer: Renderer2)
  {
    this.renderer.listen('window', 'click', (e: Event) =>
    {
      if (e.target === this.modal?.nativeElement && !this._preventCloseFromBackDrop)
      {
        this.show = false;
        this.showChange.emit(this._show);
      }
    });
  }

  public ngOnChanges(): void
  {
    this._show = this.show;
  }
}
