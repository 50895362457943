import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { Tools } from '@shared-utils/tools.util';

@Injectable({ providedIn: 'root' })
export class LocalStorageService
{
  public length: number;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState) { }

  [name: string]: any;

  public clear(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      localStorage.clear();
    }
  }

  public getItem(key: string): any | null
  {
    const res = "";

    const stateKey: StateKey<string> = makeStateKey<string>(key);

    if (isPlatformBrowser(this.platformId))
    {
      const storagedItemArray = localStorage[key] ? localStorage[key].split("//") : "";

      if (storagedItemArray && this.isValidStorageDate(storagedItemArray))
      {
        return storagedItemArray[0];
      }
      else
      {
        this.removeItem(key);
      }
    }
    else
    {
      const transferStateVlaue = this.transferState.get<any>(stateKey, null);

      if (transferStateVlaue)
      {
        this.transferState.remove(stateKey);
        return transferStateVlaue;
      }
    }

    return res;
  }

  public removeItem(key: string): void
  {
    const stateKey: StateKey<string> = makeStateKey<string>(key);

    if (isPlatformBrowser(this.platformId))
    {
      localStorage.removeItem(key);
    }
    else
    {
      this.transferState.remove(stateKey);
    }
  }

  public setItem(key: any, value: any): void
  {
    const stateKey: StateKey<string> = makeStateKey<string>(key);

    const valueWithDate: any = value + "//" + new Date().getTime();

    if (isPlatformBrowser(this.platformId))
    {
      localStorage[key] = valueWithDate;
    }
    else
    {
      this.transferState.set(stateKey, valueWithDate);
    }
  }

  public isValidStorageDate(itemArray: any[]): boolean
  {
    if (!Tools.isNullOrEmpty(itemArray))
    {
      const date = itemArray[itemArray.length - 1];
      const oneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000);

      return parseInt(date) < oneDay;
    }

    return false;
  }
}
