<section *ngIf="_solutions !== undefined && _translations !== undefined">
  <div class="d-flex">
    <div class="solution-icon-container">
      <img class="icon-solution" *ngIf="_solutions.icon !== null" [alt]="_solutions.icon.title" [src]="_solutions.icon.id | directus"
        width="25" height="25" />
    </div>
    <h3 class="solution-title">{{_translations.titulo}}</h3>
  </div>
  <br>
  <div class="fs-m">
    {{_translations.descripcion}}
  </div>
  <br>
  <a class="link" href="{{_solutions.url}}">
    {{_translations.texto_link | uppercase}} <span class="arrow-right"></span>
  </a>
</section>
