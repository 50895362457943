export namespace DateConst
{
  export const ESMonthLablel = "mes";
  export const ESYearLablel = "año";

  export const ENMonthLablel = "month";
  export const ENYearLablel = "year";

  export const Years = [
    { name: 'AÑO', value: 0 },
    { name: '2024', value: 2024 },
    { name: '2023', value: 2023 },
    { name: '2022', value: 2022 },
    { name: '2021', value: 2021 },
    { name: '2020', value: 2020 }
  ];

  export const Months = [
    { name: 'MES', value: -1 },
    { name: 'ENERO', value: 0 },
    { name: 'FEBRERO', value: 1 },
    { name: 'MARZO', value: 2 },
    { name: 'ABRIL', value: 3 },
    { name: 'MAYO', value: 4 },
    { name: 'JUNIO', value: 5 },
    { name: 'JULIO', value: 6 },
    { name: 'AGOSTO', value: 7 },
    { name: 'SEPTIEMBRE', value: 8 },
    { name: 'OCTUBRE', value: 9 },
    { name: 'NOVIEMBRE', value: 10 },
    { name: 'DICIEMBRE', value: 11 }
  ];
}