import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EGtmSection } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { EServiceName } from '@core-constants/product-service.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { AddServicePackage } from '@core-models/add-to-cart.model';
import { IResultDomain } from '@core-models/domain.model';
import { ICupon, PackageModel } from '@core-models/service-package.model';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Injectable({ providedIn: "root" })
export class PromotionHelper
{
  constructor(private shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    protected interactiveToast: InteractiveToasterService,
    protected toast: ToastService) { }

  public buildPromotionFromDomainPackage(domain: IResultDomain, domainPackage: PackageModel): AddServicePackage[]
  {
    let promotionHelpers: AddServicePackage[] = [];

    if (domainPackage && domain)
    {
      domainPackage.ids_servicios_combo.map((combo: any) =>
      {
        const type = combo.servicios_combo_id.valor.includes(".") ? EServiceName.Domain : EServiceName.Service;

        const element = {
          serviceData: type == EServiceName.Domain && combo.servicios_combo_id.valor == domain.domainType ? domain.domainName : combo.servicios_combo_id.valor,
          coverageId: combo.servicios_combo_id.cobertura_meses,
          type: type
        };

        const packageServices: any[] = Array(combo.servicios_combo_id.cantidad).fill(element);

        promotionHelpers = promotionHelpers.concat(packageServices);
      });

    }
    return promotionHelpers;
  }

  public addToCart(servicePackage: ICupon, sourceSection: EGtmSection, locationInit : string): void
  {
    let promotionHelpers: AddServicePackage[] = [];
    if (servicePackage)
    {
      const couponId = servicePackage.cupon ?? "";

      servicePackage.ids_servicios_combo?.map((combo: any) =>
      {
        const element = {
          serviceData: combo.servicios_combo_id.valor,
          coverageId: combo.servicios_combo_id.cobertura_meses,
          type: combo.servicios_combo_id.valor.includes(".") ? EServiceName.Domain : EServiceName.Service
        };

        const packageServices: any[] = Array(combo.servicios_combo_id?.cantidad).fill(element);

        promotionHelpers = promotionHelpers.concat(packageServices);
      });
      this.addSeveralServices(promotionHelpers, couponId);

      // esta funcion ademas de enviar el evento select_promotion se envia el evento add_to_cart, para la medicion de GA de parte de MKT 
      GtmTrackingService.selectPromotionEventProcess('', sourceSection, servicePackage.id.toString(), servicePackage.nombre, promotionHelpers, locationInit);
    }
  }

  public addSeveralServices(services: AddServicePackage[], coupon: string = ""): void
  {
    if (!Tools.isNullOrEmpty(services))
    {
      this.shoppingCartDataService.addPromotion(services, coupon).subscribe({
        next: (response: any) =>
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
          this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
        },
        error: () =>
        {
          this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
        }
      });
    }
  }
}
