import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetsService
{
  private _blobStorageUrl: string = "";
  private _directusURL: string = "";
  private blobURLChanges$: BehaviorSubject<string> = new BehaviorSubject<string>(this._blobStorageUrl);
  private directusURLChanges$: BehaviorSubject<string> = new BehaviorSubject<string>(this._directusURL);

  constructor() { }

  public onBlobURLChanges(): Observable<string>
  {
    return this.blobURLChanges$.asObservable();
  }

  public set setBlobUrl(value: string)
  {
    this._blobStorageUrl = value ?? "";
    this.blobURLChanges$.next(this._blobStorageUrl);
  }

  public getAssetFromBlob(name: string = ""): string
  {
    return this._blobStorageUrl + name;
  }

  public onDirectusChanges(): Observable<string>
  {
    return this.directusURLChanges$.asObservable();
  }

  public set setDirectusUrl(value: string)
  {
    this._directusURL = value ?? "";
    this.directusURLChanges$.next(this._directusURL);
  }

  public getAssetFromDirectus(name: string = ""): string
  {
    return `${this._directusURL}assets/${name}`;
  }

  public getAssetFromDirectuObservable(name: string = ""): any
  {
    return of(this._directusURL + name);
  }
}
