import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { HelpParams } from '@core-constants/url-param.const';
import { HelpCategoriesDataService } from '@core-data-services/help.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { Help, IHelpMenuItem, IMenuCategory } from '@core-models/help.model';
import { ISEOComponent } from '@core-models/seo.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-help-topics',
  templateUrl: './help-topics.component.html',
  styleUrls: ['./help-topics.component.css']
})
export class HelpTopicsComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public pageTitle: string = "Temas de ayuda Akky";
  public clicked: boolean = false;
  public menuOptions: IHelpMenuItem[] = [];
  public selectedItemInfo: any = {};
  public htmlId: string = "";

  private pageSEOTitle: string = "Conoce nuestros temas de ayuda | Akky";
  private pageSEODescription: string = "Conoce los servicios adicionales que ofrecemos para responder a todas tus necesidades. Contáctanos.";

  @ViewChild('menuCollapsed', { static: true }) public menuCollapsed: any;

  public languageSubscription: Subscription | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private helpDataService: HelpCategoriesDataService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private destroyRef$: DestroyRef
  )
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/temas-de-ayuda'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });

    this.activatedRoute.queryParams.subscribe({
      next: (params) =>
      {
        const htmlIdParam = String(params[HelpParams.Topic]);
        this.htmlId = htmlIdParam !== "undefined" ? htmlIdParam : "";

        if (Object.keys(params).length == 0)
        {
          this.setDefaultItemHtmlId();
        }
      }
    });
  }

  @HostListener('document:click', ['event'])
  public clickedOutside(): void
  {
    if (this.clicked)
    {
      this.clicked = false;
      this.menuCollapsed.nativeElement.classList.toggle('show');
    }
  }

  public ngOnInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);

    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.languageSubscription = this.translateService.onTranslateChange().subscribe({
      next: () =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
        this.getMenuOptions();
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }


  public buttonCollapseClicked(event: any): void
  {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = !this.clicked;
  }

  public setDefaultItemHtmlId(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      if (!this.htmlId && this.menuOptions[0] != undefined)
      {
        const category = this.menuOptions[0].categories[0];
        const id = category.subcategories != undefined ? category.subcategories[0].htmlId : category.htmlId;

        this.selectedItemInfo =
        {
          optionId: this.menuOptions[0].id,
          htmlId: this.menuOptions[0].categories[0].htmlId,
          categoryId: this.menuOptions[0].categories[0].id,
          subcategoryId: category.subcategories != undefined ? category.subcategories[0].id : undefined
        };

        this.router.navigate(['/ayuda/temas-de-ayuda'], {
          queryParams: {
            tema: id
          },
          replaceUrl: true
        });
      }
      else
      {
        this.menuOptions.forEach(o =>
        {
          const category = o.categories?.find(c => c.htmlId == this.htmlId);

          if (!category)
          {
            o.categories.forEach(c =>
            {
              const subcategory = c.subcategories?.find(s => s.htmlId == this.htmlId);

              if (subcategory)
              {
                this.selectedItemInfo = {
                  optionId: o.id,
                  htmlId: c.htmlId,
                  categoryId: c.id,
                  subcategoryId: subcategory.id
                };
                return;
              }
            });
          }
          else
          {
            this.selectedItemInfo = {
              optionId: o.id,
              htmlId: category.htmlId,
              categoryId: category.id,
              subcategoryId: undefined
            };
            return;
          }
        });
      }
    }
  }

  public getMenuOptions(): void
  {
    const menu = this.localStorageService.getItem(LocalStorageConst.HelpMenu + this.translateService.languageCode);

    if (!menu)
    {
      this.helpDataService.getMap(this.translateService.languageCode).subscribe({
        next: (result: Help.Categories) =>
        {
          if (result && !Tools.isNullOrEmpty(result.response))
          {
            this.menuOptions = result.response.map((helpCategory: Help.IHelpCategory) =>
            {
              const menuItem: IHelpMenuItem =
              {
                id: helpCategory.id,
                title: helpCategory.traducciones[0]?.titulo,
                categories: []
              };

              const mainCategories: IMenuCategory[] = helpCategory.ayuda_items?.length > 0 ? helpCategory.ayuda_items
                .map((helpItem: Help.IItem) =>
                {
                  return {
                    id: helpItem.id,
                    title: helpItem.traducciones[0]?.titulo,
                    htmlId: helpItem.html_id,
                    subcategories: undefined
                  };
                }) : [];

              const secondaryCategories: IMenuCategory[] = helpCategory.subcategorias?.length > 0 ? helpCategory.subcategorias
                .map((subcategory: Help.ISubcategory) =>
                {
                  const subcategories: IMenuCategory[] = subcategory.ayuda_Items?.length > 0 ? subcategory.ayuda_Items
                    .map((helpItem: Help.IItem) =>
                    {
                      return {
                        id: helpItem.id,
                        title: helpItem.traducciones[0]?.titulo,
                        htmlId: helpItem.html_id,
                        subcategories: undefined
                      };
                    }) : undefined;

                  return {
                    id: subcategory.id,
                    title: subcategory.traducciones[0]?.titulo,
                    htmlId: undefined,
                    subcategories: subcategories
                  };

                }) : [];


              menuItem.categories = mainCategories.concat(secondaryCategories);

              return menuItem;
            });

            this.localStorageService.setItem(LocalStorageConst.HelpMenu + this.translateService.languageCode, JSON.stringify(this.menuOptions));
            this.setDefaultItemHtmlId();
          }
        }
      });
    }
    else
    {
      this.menuOptions = JSON.parse(menu);
      this.setDefaultItemHtmlId();
    }
  }
}
