<div id="aviso-legal-content" class="container py-3 px-2 px-sm-3 px-md-5">
  <div class="pt-5 mt-5">
    <h1>{{'Aviso Legal'| translate}}</h1>
    <div class="gray fs-l font-weight-bold my-5 mx-5">
      <p>{{'Todos los materiales contenidos en este sitio (incluyendo, pero no limitado a, texto, logotipos, contenido, imágenes [animadas y estáticas], íconos y fotografías, entre otros) están protegidos por las leyes de Derechos de Autor y Propiedad Industrial, tanto nacionales como internacionales.'| translate}}</p>
      <p>{{'En relación con todo lo contenido en el sitio, se prohíbe la reproducción, uso, copia, impresión, distribución, publicación, traducción, adaptación, reordenación y cualquier otro uso o modificación total o parcial de los datos y obras contenidos en esta página, por cualquier medio y de cualquier forma.'| translate}}</p>
      <p>
        {{'Para cualquier asunto relacionado con este Aviso, contacte a'| translate}}
        <a href="mailto:legal@akky.mx" class="green">{{'legal@akky.mx'| translate}}</a>
      </p>
    </div>

    <h1>{{'Aviso de Privacidad'| translate}}</h1>
    <div class="gray fs-l font-weight-bold my-5 mx-5">
      <p>
        {{'La privacidad de sus datos personales es de gran importancia para el Akky Online Solutions, S.A. de C.V., por lo que hacemos de su conocimiento nuestro'| translate}}
        <a [routerLink]="['/acerca/politicas-de-servicios']" [queryParams]="{id: 'aviso-de-privacidad-clientes'}" class="green"> {{'Aviso de Privacidad'| translate}}</a>.
      </p>
    </div>
  </div>
</div>
