<ng-container *ngIf="isIndividualSuggestion">
    <app-individual-suggestion-item [parentId]="parentId"
                                    [suggestion]="suggestion"
                                    [itemProduct]="itemProduct"></app-individual-suggestion-item>
</ng-container>

<ng-container *ngIf="isUpgradeSuggestion">
    <app-upgrade-suggestion-item [parentId]="parentId"
                                 [suggestion]="suggestion"
                                 [itemProduct]="itemProduct"
                                 (onSuggestionUpgradeItem)="onSuggestionUpgradeItemEvent($event)"
                                 ></app-upgrade-suggestion-item>
</ng-container>