import { Injectable } from "@angular/core";
import { IAdditionalPurchaseSuggestionSelected, IShoppingCartGeneralStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { BehaviorSubject, Observable } from "rxjs";
import { TokenManager } from "./token.manager";

//TODO: re-evaluar todo el manager.Puede que no sea necesario si se soluciona con un emitter de purchase suggestion item hacia purchase suggestion
@Injectable({ providedIn: 'root' })
export class PurchaseSuggestionManager
{
  constructor(private tokenManager: TokenManager)
  { }

  private _purchaseSuggestionsSelected: IShoppingCartGeneralStoredSuggestionPlus[] = [];
  private suggestionSelected$: BehaviorSubject<IShoppingCartGeneralStoredSuggestionPlus[]> = new BehaviorSubject<IShoppingCartGeneralStoredSuggestionPlus[]>(this._purchaseSuggestionsSelected);

  public onSuggestionSelected(): Observable<IShoppingCartGeneralStoredSuggestionPlus[]>
  {
    return this.suggestionSelected$.asObservable();
  }

  public addPurchaseSuggestion(value: IShoppingCartGeneralStoredSuggestionPlus, isSelected: boolean): void
  {
    this._purchaseSuggestionsSelected = this.tokenManager.getPurchaseSuggestionSelected() !== null ? this.tokenManager.getPurchaseSuggestionSelected().suggestions : [];

    if (isSelected)
    {
      let isAdded: boolean = false;
      if (this._purchaseSuggestionsSelected.length > 0)
      {
        isAdded = this._purchaseSuggestionsSelected.some(item => item.suggestionId === value.suggestionId);
      }

      if (!isAdded)
      {
        this._purchaseSuggestionsSelected.push(value);
      }
    }
    else
    {
      if (this._purchaseSuggestionsSelected.length > 0)
      {
        this._purchaseSuggestionsSelected = this._purchaseSuggestionsSelected.filter((item) => item.suggestionId != value.suggestionId);
      }
    }

    if (this._purchaseSuggestionsSelected.length > 0)
    {
      const suggestions: IAdditionalPurchaseSuggestionSelected = {
        cartId: this.tokenManager.getCartCookie(),
        suggestions: this._purchaseSuggestionsSelected
      };
      this.tokenManager.savePurchaseSuggestionSelected(suggestions);
    }
    else
    {
      this.tokenManager.removePurchaseSuggestionSelected();
    }
    this.suggestionSelected$.next(this._purchaseSuggestionsSelected);
  }
}
