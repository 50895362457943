<div class="tab-slider--nav">
  <ul [ngClass]="{'tab-slider-two-tabs': data.length === 2,
  'tab-slider-three-tabs': data.length === 3,
  'slide': selectedIndex === 1,
  'slideC': selectedIndex === 2}">
    <li *ngFor="let d of data; let i = index" class="tab-slider--trigger" [ngClass]="{'active': selectedIndex === i}"
      (click)="onSelectTab(i)" rel="tab1">
      <div>
        <span><b>{{ d.title | translate }}</b></span>
      </div>
    </li>
  </ul>
</div>