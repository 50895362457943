import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HomeDataService } from '@core-data-services/home.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-home-services',
  templateUrl: './services-home.component.html',
  styleUrls: ['./services-home.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HomeServicesComponent implements OnInit, ILanguageComponent
{
  public services: any[];

  constructor(private homeService: HomeDataService,
    private ref: ChangeDetectorRef,
    public translateService: TranslateService,
    private destroyRef$: DestroyRef
  ) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServicesInfo();
      }
    });
  }

  public getServicesInfo(): void
  {
    this.homeService.getServices().subscribe({
      next: (result: any) =>
      {
        if (result)
        {
          this.services = result.response;
          this.ref.detectChanges();
        }
      }
    });
  }
}
