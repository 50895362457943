import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEOConst } from '@core-constants/seo.const';

@Injectable({ providedIn: 'root' })
export class SEOService
{
  constructor(@Inject(PLATFORM_ID) private platformId,
    private metaTagService: Meta,
    private title: Title) { }

  public updateSeoElements(title: string = "", description: string = ""): void
  {
    if (title && title != "")
    {
      this.title.setTitle(title);
    }

    if (description && description != "")
    {
      this.metaTagService.updateTag({ name: SEOConst.MetaTag.Description, content: description });
    }

    this.updateCanonicalUrl();
  }

  private updateCanonicalUrl(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const link: Element = document.querySelector(`#canonical`);
      const url = document.URL.split('?')[0];

      link.setAttribute('href', url);
    }
  }
}
