<div class="p-3 p-md-4">
  <h1>{{ title }}</h1>
  <ng-container *ngIf="faqs && faqs.length > 0">
    <div class="accordion" *ngFor="let faq of faqs; trackBy:identify">
      <a class="collapsed faq-name accordion-button accordion-header" data-bs-toggle="collapse" href="{{'#faq-' + faq.id}}" role="button" aria-expanded="false" aria-controls="faq-collapse">
        <div [innerHTML]="faq.traducciones[0]?.titulo | safe: 'html'"></div>
      </a>
      <div id="{{'faq-' + faq.id}}" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <div class="faq-text" [innerHTML]="faq.traducciones[0]?.texto | safe: 'html'"></div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

