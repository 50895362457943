import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ECartStep } from '@core-constants/cart-steps.const';
import { EDomainType } from '@core-constants/domain.const';
import { EServiceType } from '@core-constants/product-service.const';
import { TokenManager } from '@core-managers/token.manager';
import { AddCartDomainsDto, AddCartServiceDto, AddCartServicesDto, AddCartSeveralServiceDto, AddIndividualSuggestionDto, AddServicePackage, UpdateSuggestionUpgradeCoverageDto, UpdateSuggestionUpgradeQuantityDto } from '@core-models/add-to-cart.model';
import { AdditionalServiceToCart } from '@core-models/aditional-service.model';
import { IDomainCoverage } from '@core-models/domain.model';
import { EPurchaseSuggestionLocation } from '@core-models/purchase-suggestion.model';
import { RemoveDomainRenewalDto, RemoveIndividualSuggestionDto, RemoveItemDto } from '@core-models/remove-from-cart.model';
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShoppingCartDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    private tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'cart');
  }

  private getCartId(): string
  {
    let cartIdResult = '';
    const cartId = this.tokenManager.getCartCookie();

    if (cartId !== null)
    {
      cartIdResult = cartId;
    }

    return cartIdResult;
  }

  public get getShoppingCartId(): string
  {
    return this.getCartId();
  }

  // ********************************************************************************
  // #region Get
  // ********************************************************************************

  public getPopup(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    if (cartId)
    {
      const token = this.tokenManager.getToken();
      const language = this.translateService.languageCode;

      const dto = {
        token,
        cartId,
        language,
        cartType: 1
      };

      const subject = this.http.post(this.createMicroserviceURL("plus"), dto, queryDef.getRequestConfigWithAuthorizationToken(token));

      return subject;

    }
    return of();
  }

  public getInitialContent(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    if (cartId)
    {
      const subject = this.http.get(this.createMicroserviceURL("content", cartId), queryDef);

      return subject;
    }

    return of();
  }

  public getPurchaseSuggestions(location: EPurchaseSuggestionLocation): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();
    const token = this.tokenManager.getToken();

    const body =
    {
      cartId: cartId,
      location: location
    };

    const subject = this.http.post(this.createMicroserviceURL("purchase-suggestions"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  // #endregion

  // ********************************************************************************
  // #region Add
  // ********************************************************************************

  public addDomains(domains: IDomainCoverage[]): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    const addCartDomainsDto: AddCartDomainsDto = {
      domainList: domains,
      cartId,
      language
    };

    const subject = this.http.post(this.createMicroserviceURL("domains"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public addIndividualSuggestion(cartItemId: number, suggestionId: string, quantity: number = undefined, coverageMonths: number = undefined): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    const addCartDomainsDto: AddIndividualSuggestionDto = {
      cartId,
      cartItemId,
      suggestionId,
      quantity,
      coverageMonths
    };

    const subject = this.http.post(this.createMicroserviceURL("add/individual-suggestion"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(this.translateService.languageCode));

    return subject;
  }

  public addService(serviceData: any, quantity: number = 1, serviceCoverage?: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    quantity == 0 ? quantity = 1 : '';

    const addServiceDto: AddCartServiceDto = {
      serviceData,
      cartId,
      quantity,
      language,
      serviceCoverage
    };

    const subject = this.http.post(this.createMicroserviceURL("services/presence"), addServiceDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public addSeveralService(additionalServices: AdditionalServiceToCart[]): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    const addServiceDto: AddCartSeveralServiceDto = {
      cartId,
      services: additionalServices.map(item =>
      {
        return { serviceData: item.serviceData, quantity: item.quantity, serviceCoverage: item.serviceCoverage };
      }),
      language
    };

    const subject = this.http.post(this.createMicroserviceURL("add/several-presence-services"), addServiceDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public updateServiceByQuantityGroup(quantityGroup: number, coverageId: number, quantity: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();

    const body =
    {
      token: token,
      cartId: cartId,
      coverageId: coverageId,
      quantity: quantity,
      quantityGroup: quantityGroup,
      stepCalledFrom: ECartStep.Ecommerce
    };

    const subject = this.http.put(this.createMicroserviceURL("update/quantity-group"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public removeServiceByQuantityGroup(quantityGroup: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();

    const body =
    {
      token: token,
      cartId: cartId,
      quantityGroup: quantityGroup,
      stepCalledFrom: ECartStep.Ecommerce
    };

    const subject = this.http.put(this.createMicroserviceURL("remove/quantity-group"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public removeIndividualSuggestion(cartItemId: number, suggestionId: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    const addCartDomainsDto: RemoveIndividualSuggestionDto = {
      cartId,
      cartItemId,
      suggestionId
    };

    const subject = this.http.post(this.createMicroserviceURL("remove/individual-suggestion"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(this.translateService.languageCode));

    return subject;
  }

  public addPromotion(comboServices: AddServicePackage[], couponId: string = ""): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    const addServicesDto: AddCartServicesDto = {
      comboServices,
      couponId,
      cartId,
      language
    };

    const subject = this.http.post(this.createMicroserviceURL("services/presence/combo"), addServicesDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  // #endregion

  // ********************************************************************************
  // #region Remove
  // ********************************************************************************

  public removeDomainRenewal(name: string, domainType: EDomainType): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: RemoveDomainRenewalDto = {
      name,
      domainType,
      cartId: this.tokenManager.getCartCookie(),
      stepCalledFrom: ECartStep.Ecommerce
    };

    const subject = this.http.post(this.createMicroserviceURL("remove/domain-renewal"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public removeItem(id: number, serviceType: EServiceType): Observable<any>
  {
    const dto: RemoveItemDto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      id,
      serviceType,
      stepCalledFrom: ECartStep.Ecommerce
    };

    const subject = this.http.post(this.createMicroserviceURL("remove/item"), dto);

    return subject;
  }

  // #endregion

  // ********************************************************************************
  // #region Update
  // ********************************************************************************

  public updateDomainServices(domainId: number, domainServices: any[]): Observable<any>
  {
    const dto =
    {
      language: this.translateService.languageCode,
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie(),
      domainId,
      domainServices
    };

    const subject = this.http.post(this.createMicroserviceURL("update/domain-services"), dto);

    return subject;
  }

  public updateCoverage(id: number, serviceType: EServiceType, coverageId: number): Observable<any>
  {
    const dto =
    {
      language: this.translateService.languageCode,
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      id,
      coverageId,
      serviceType,
      stepCalledFrom: ECartStep.Ecommerce
    };

    const subject = this.http.put(this.createMicroserviceURL("update/item-coverage"), dto);

    return subject;
  }

  public updateAddonQuantity(id: number, serviceType: EServiceType, quantity: number): Observable<any>
  {
    const dto =
    {
      language: this.translateService.languageCode,
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      id,
      quantity,
      serviceType
    };

    const subject = this.http.put(this.createMicroserviceURL("update/addon-quantity"), dto);

    return subject;
  }

  public updateSuggestionUpgradeCoverage(shoppingCartItemId: number, suggestionId: string, coverageId: number): Observable<boolean>
  {
    const dto: UpdateSuggestionUpgradeCoverageDto = {
      shoppingCartId: this.getCartId(),
      shoppingCartItemId: shoppingCartItemId,
      suggestionId: suggestionId,
      coverageId: coverageId
    };

    const subject = this.http.put<boolean>(this.createMicroserviceURL("update/suggestion-coverage"), dto);

    return subject;
  }

  public updateSuggestionUpgradeQuantity(shoppingCartItemId: number, suggestionId: string, quantity: number): Observable<boolean>
  {
    const dto: UpdateSuggestionUpgradeQuantityDto = {
      shoppingCartId: this.getCartId(),
      shoppingCartItemId: shoppingCartItemId,
      suggestionId: suggestionId,
      quantity: quantity
    };

    const subject = this.http.put<boolean>(this.createMicroserviceURL("update/suggestion-quantity"), dto);

    return subject;
  }

  // #endregion
}
