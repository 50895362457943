import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  template: `<router-outlet></router-outlet>`
})

export class HelpComponent
{
  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router)
  {

    if (this.router.url == "/ayuda")
    {
      this.router.navigate(['/ayuda/ayuda']);
    }
  }
}
