import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountdownHelper
{
    public getCountdownToEndOfDay(): Observable<string>
    {
        return interval(1000).pipe(
            map(() => this.calculateTimeRemaining())
        );
    }

    private calculateTimeRemaining(): string
    {
        const now = new Date();
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
        const diff = endOfDay.getTime() - now.getTime();

        const hours = this.formatTimeUnit(Math.floor((diff / (1000 * 60 * 60)) % 24));
        const minutes = this.formatTimeUnit(Math.floor((diff / (1000 * 60)) % 60));
        const seconds = this.formatTimeUnit(Math.floor((diff / 1000) % 60));

        return `${hours}:${minutes}:${seconds}`;
    }

    private formatTimeUnit(unit: number): string
    {
        return unit.toString().padStart(2, '0');
    }
}