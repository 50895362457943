import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HomeDataService } from "@core-data-services/home.data-service";

@Component({
  selector: 'app-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeAboutComponent implements OnInit
{
  public cards: any[] = undefined;
  public _title: string = "";
  public _description: string = "";
  public _title_cards: string = "";
  public _subtitle: string = "";

  constructor(private homeDataService: HomeDataService,
    private ref: ChangeDetectorRef) { }

  @Input() public set title(value: any)
  {
    if (value)
    {
      this._title = value;
    }
  }

  @Input() public set description(value: any)
  {
    if (value)
    {
      this._description = value;
    }
  }

  @Input() public set title_cards(value: any)
  {
    if (value)
    {
      this._title_cards = value;
    }
  }

  @Input() public set subtitle(value: any)
  {
    if (value)
    {
      this._subtitle = value;
    }
  }

  public ngOnInit(): void
  {
    this.homeDataService.getCards().subscribe({
      next: ({ response }: any) =>
      {
        this.cards = response;
        this.ref.detectChanges();
      }
    });
  }
}
