import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchDomainCommonComponent } from '@core-components/search-domains/search-domains-common-component';
import { EAppEventName } from '@core-constants/app-events.const';
import { EventLocationSite } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { DomainsManager } from '@core-managers/domains.manager';
import { IDomainCoverage } from '@core-models/domain.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-domain-suggestions',
  templateUrl: './domain-suggestions.component.html',
  styleUrls: ['../search-result-container.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainSuggestionsComponent extends SearchDomainCommonComponent implements OnInit, ILanguageComponent
{
  public suggestions$: Observable<any[]>;
  public suggestionsAI$: Observable<any[]>;
  public suggestionsVisible$: Observable<boolean>;
  public searchSuggestionsVisible$: Observable<boolean>;
  public addAllDomainsEnabled$: Observable<boolean>;
  public isSuggestionsLoading$: Observable<boolean>;
  public errorSearchVisible$: Observable<boolean>;
  public isToggleActive$: Observable<boolean>;

  public isToggleActive: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public domainsManager: DomainsManager,
    public shoppingCartDataService: ShoppingCartDataService,
    public translateService: TranslateService,
    private ref: ChangeDetectorRef,
    public toast: ToastService,
    protected interactiveToast: InteractiveToasterService,
    private destroyRef$: DestroyRef)
  {
    super(toast);

    this.suggestions$ = domainsManager.getSuggestions();
    this.suggestionsAI$ = domainsManager.getSuggestionsAI();
    this.suggestionsVisible$ = domainsManager.getSuggestionsVisible();
    this.searchSuggestionsVisible$ = domainsManager.getSearchSuggestionsVisible();
    this.addAllDomainsEnabled$ = domainsManager.getAllDomainsEnabled();
    this.isSuggestionsLoading$ = domainsManager.getSuggestionsLoadingVisible();
    this.errorSearchVisible$ = domainsManager.getErrorSearchVisible();
    this.isToggleActive$ = domainsManager.getIsToggleActive();
    this.suggestionToggleClick(true);
  }

  public get hasSuggestions(): boolean
  {
    return this.domainsManager.hasSuggestions();
  }

  public get hasSuggestionsAI(): boolean
  {
    return this.domainsManager.hasSuggestionsAI();
  }

  public get isLoadingSuggestionAI(): boolean
  {
    return this.domainsManager.getIsLoadingSuggestionAI();
  }

  public get isFeatureSuggestionsVisible(): boolean
  {
    return this.domainsManager.getFeatureSuggestionsVisible();
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.ref.detectChanges();
      }
    });

    this.isToggleActive$
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: boolean) =>
      {
        this.changeTexts(value);
        this.isToggleActive = value;
      }
    });
  }

  public onSearchAlternativesClicked(): void
  {
    this.domainsManager.searchAlternatives();
  }

  public onAddAllDomainsToCartClicked(): void
  {
    this.domainsManager.addAllDomainsToCart(this.isToggleActive, (!this.isToggleActive ? EventLocationSite.SearchDomainIASuggestion : EventLocationSite.SearchDomainSuggestion));
  }

  public onAddToCart(domain: any): void
  {
    this.disableAddDomainItemButton(domain);

    const domains: IDomainCoverage[] = [];

    domains.push({ domain: domain.domainName, coverage: null });

    this.shoppingCartDataService.addDomains(domains).subscribe({
      next: (response: any) =>
      {
        if (response.error != undefined && response.error != null)
        {
          this.notifyDomainErrors(response.cartListResult);
        }
        else
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
          this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
        }

        domain.addedByAI = !this.isToggleActive;
        GtmTrackingService.addToCartDomainEvent(domain, false, (domain.addedByAI ? EventLocationSite.SearchDomainIASuggestion : EventLocationSite.SearchDomainSuggestion));
      },
      error: ({ error: { domains } }: any) =>
      {
        this.notifyDomainErrors(domains);
      }
    });
  }

  public goToPromotions(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const element = document.querySelector(`#most-searched`);

      if (element)
      {
        element.scrollIntoView();
      }
    }
  }

  public disableAddDomainItemButton(domain: any): void
  {
    domain.isEnabled = false;
  }

  public isSuggestedAI(domain: any): Observable<boolean>
  {
    return this.suggestionsAI$.pipe(
      map(elementos => elementos.some(item => item == domain))
    );
  }

  public changeTexts(value: boolean): void
  {
    this.errorSuggestionMessage = (value) ? "¡No te preocupes! Tenemos estas alternativas disponibles." : "¡No te preocupes! Tenemos estas alternativas generadas con Inteligencia Artificial disponibles.";
    this.successSuggestionMessage = (value) ? "También tenemos estos dominios disponibles para ti." : "Sugerencias con Inteligencia Artificial";
  }

  public suggestionToggleClick(value: boolean): void
  {
    this.changeTexts(value);
    this.domainsManager.setIsToggleActive(value);
    this.domainsManager.setAllDomainsEnabled(value ? this.hasSuggestions : this.hasSuggestionsAI);
    if (!value)
    {
      this.domainsManager.searchOnlySuggestionAI();
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
