import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFilterList, IFilterSelected } from '@core-models/filter-list.model';
import { FilterListService } from '@shared-services/filter-list.service';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent implements OnInit
{
  public filterList: IFilterList[] = [];
  public strings: string[] = [];

  constructor(private filterListService: FilterListService,
    private ref: ChangeDetectorRef,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.subscribeFilterList();
  }

  private subscribeFilterList(): void
  {
    this.filterListService.onFilterListChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (filterList: IFilterList[]) =>
        {
          this.filterList = filterList;
          this.ref.detectChanges();
        }
      });

    this.filterListService.onFilterChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: ({ value }: IFilterSelected) =>
        {
          this.filterList = this.filterList.map((filter: IFilterList) => ({
            ...filter,
            selected: filter.value === value
          }));
        }
      });
  }

  public onFilterChange({ value }: IFilterList): void
  {
    this.filterListService.filterSelected = { value: value };
  }
}
