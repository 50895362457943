import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { SettingsManager } from '@core-managers/settings.manager';
import { ToastService } from '@shared-services/toast.service';

declare function clickChat(): void;

@Component({
  selector: 'app-deal-subscribe',
  templateUrl: './deal-subscribe.component.html',
  styleUrls: ['./deal-subscribe.component.css']
})
export class SubscribeComponent 
{
  public name: string = '';
  public email: string = '';
  public urlWhatsApp = ReferenceConst.WhatsAppUrl;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public settingsManager: SettingsManager,
    public toast: ToastService) { }

  public get isChatIconEnabled(): boolean
  {
    return this.settingsManager.isChatIconEnabled;
  }

  public openChat(): void
  {
    if (!this.isChatIconEnabled)
    {
      this.toast.setErrorToast(ErrorMessageConst.ChatUnavailable);
    }
    else
    {
      if (isPlatformBrowser(this.platformId))
      {
        clickChat();
      }
    }
  }
}
