<app-chat></app-chat>

<app-promo-banner></app-promo-banner>

<app-navbar></app-navbar>

<div class="principal-container margin-liebre">
  <router-outlet></router-outlet>
</div>
<app-interactive-toaster></app-interactive-toaster>

<app-footer *ngIf="isShowFooter"></app-footer>

