<section class="slider-container">
  <div #carousel id="carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div *ngFor="let s of slides; let i = index" class="carousel-item" [ngClass]="{'active': i === 0}">
        <div class="item">
           <figure *ngIf="s?.degradado_primario_desk"
            [ngStyle]="{'--degradado-primario-desk': s.degradado_primario_desk, '--degradado-primario-movil': s.degradado_primario_movil, '--degradado-secundario-desk': s.degradado_secundario_desk, '--degradado-secundario-movil': s.degradado_secundario_movil}">
            <div class="slider-home" *ngIf="s?.imagen_desk">
                <img class="slide-img-desk" [alt]="s.imagen_desk?.title" [ngSrc]="s.imagen_desk?.id | directus"
                data-src="{{s.imagen_desk?.id | directus}}" priority width="800" height="700" placeholder>
              <img class="slide-img-movil" [alt]="s.imagen_movil?.title" [ngSrc]="s.imagen_movil?.id | directus"
                data-src="{{s.imagen_movil?.id | directus}}" priority width="200" height="150" placeholder>
            </div>
            <div class="slider-home-img-text" *ngIf="s.traducciones[0]?.img_text">
              <img class="slide-img-desk" [alt]="s.traducciones[0].img_text?.title"
                    [ngSrc]="s.traducciones[0].img_text?.id | directus" data-src="{{s.traducciones[0].img_text?.id}}" priority width="800" height="700" placeholder>
              <img class="slide-img-movil" [alt]="s.traducciones[0].img_text_movil?.title"
                   [ngSrc]="s.traducciones[0].img_text_movil?.id | directus"
                   data-src="{{s.traducciones[0].img_text_movil?.id}}"
                   srcset="{{s.traducciones[0].img_text_movil_1_5x?.id | directus}} 1.5x" priority width="200" height="150" placeholder>
            </div>
          </figure>
          <div *ngIf="s?.traducciones" class="slider-discount" [innerHTML]="s.traducciones[0].descuento | safe: 'html'">
          </div>
          <h2 class="slider-title" *ngIf="s?.traducciones" [innerHTML]="s.traducciones[0].titulo | safe: 'html'">
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-data">
    <div class="carousel-buttons" *ngIf="slides?.length > 1">
      <button type="button" class="btn-back" data-bs-target="#carousel" data-bs-slide="prev"
        [attr.aria-label]="ariaLabelForBackButton">
      </button>
      <button class="btn-next" type="button" data-bs-target="#carousel" data-bs-slide="next"
        [attr.aria-label]="ariaLabelForNextButton">
      </button>
    </div>
    <div class="slider-domain">
      <app-search-domains-input class="search-domains-input" [multipleSearchWhite]="false"
        (onSearchChangeMode)="onSearchChangeMode()" (onSingleSearch)="onSingleSearch()"></app-search-domains-input>
    </div>
    <div class="slider-offerts">
      <div class="offert" *ngFor="let o of offers; let i = index"
        [innerHTML]="o.subFijo + ' ' + o.precio + o.traducciones[0].frecuencia"
        [ngStyle]="{'border-right': i === (offers.length - 1) ? '0' : '2px solid black'}">
      </div>
    </div>
    <app-home-services class="slider-services"></app-home-services>
    <div *ngIf="conditions" class="slider-expiration" [innerHTML]="conditions | safe: 'html'">
    </div>
  </div>
</section>

<div class="column-social footer-social row mt-2 justify-content-start justify-content-md-center align-items-center">
  <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0" href="https://www.facebook.com/AkkyMX"
    target="_blank" rel="noreferrer" aria-label="Facebook">
    <span class="fas d-inline-block i-facebook size-social"></span>
  </a>
  <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0" href="https://www.instagram.com/akkymx/"
    target="_blank" rel="noreferrer" aria-label="Instagram">
    <span class="fas d-inline-block i-instagram size-social"></span>
  </a>
  <a class="text-decoration-none twitter-icon-container text-center col-1 col-md-2 col-lg-2 p-0 m-0"
    href="https://twitter.com/akkymx" target="_blank" rel="noreferrer" aria-label="Twitter">
    <span class="fas d-inline-block i-x-twitter size-social"></span>
  </a>
  <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 mx-1"
    href="https://www.youtube.com/c/Akkytienda" target="_blank" rel="noreferrer" aria-label="YouTube">
    <span class="fas d-inline-block i-youtube size-social"></span>
  </a>
  <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0"
    href="https://mx.linkedin.com/company/akky" target="_blank" rel="noreferrer" aria-label="LinkedIn">
    <span class="fas d-inline-block i-linkedin size-social"></span>
  </a>
</div>

<app-home-about *ngIf="home" [title]="home.titulo_nosotros" [description]="home.descripcion_nosotros"
  [title_cards]="home.titulo_cartas" [subtitle]="home.subtitulo_cartas"></app-home-about>

<app-solutions *ngIf="home" [title]="home.titulo_soluciones" [description]="home.descripcion_soluciones"
  class="container-services"></app-solutions>

<app-family *ngIf="home" [testimonials]="testimonials" [title_1]="home.titulo_clientes"
  [title_2]="home.titulo_testimonios" [subtitle]="home.subtitulo_testimonios"></app-family>

<div *ngIf="home">
  <h2 class="home-title" [innerHTML]="home.titulo_acreditaciones | safe: 'html'"></h2>
  <div class="with-akky">
    <div class="row acreditation-space">
      <div [innerHTML]="home.descripcion_acreditaciones | safe: 'html'"
        class="with-akky-text col-12 col-md-12 col-lg-4"></div>
      <div class="box-shadow-home acreditaciones col-12 col-md-12 col-lg-8">
        <img alt="MX Logo" class="img-acreditacion" [src]="'/logos/MX.svg' | blobstorage"
          data-src="{{'/logos/MX.svg' | blobstorage}}">
        <img alt="Verisign Logo" class="img-acreditacion" [src]="'/logos/verisign.svg' | blobstorage"
          data-src="{{/logos/verisign.svg' | blobstorage}}">
        <img alt="ICann Logo" loading="lazy" (click)="onOpenHelpPage()" class="img-acreditacion clickable"
          [src]="'/logos/icann.svg' | blobstorage" data-src="{{/logos/icann.svg' | blobstorage}}">
        <img alt="Secure Trust Logo" loading="lazy" (click)="onOpenAcreditationPage()"
          class="img-acreditacion clickable" [src]="'/images/secure_trust.svg' | blobstorage"
          data-src="{{/images/secure_trust.svg' | blobstorage}}">
      </div>
    </div>
  </div>
</div>
<div *ngIf="serviceRateInfo">
  <app-structured-data [sourceScript]="homeDEScript" [servicesDE]="servicesDE"
    [serviceRateInfo]="serviceRateInfo"></app-structured-data>
</div>
