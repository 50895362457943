import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { TransferStateSubroutine } from '@core-models/generic.model';
import { Environment } from '@environments';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TransferStateInterceptor implements HttpInterceptor
{
  constructor(@Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (request.method !== 'GET' && request.method !== 'POST')
    {
      return next.handle(request);
    }

    const requestData = request.method == 'GET' ? this.getSubRoutine(request) : this.postSubRoutine(request);

    // For any http requests made on the server, store the response in State Transfer.
    if (isPlatformServer(this.platformId))
    {
      return next.handle(requestData.apiReq).pipe(
        tap((event: HttpResponse<any>) =>
        {
          this.transferState.set(requestData.stateKey, event.body);
        })
      );
    }

    // For any http requests made in the browser, first check State Transfer for a 
    // response corresponding to the request url.
    if (isPlatformBrowser(this.platformId))
    {
      const transferStateResponse = this.transferState.get<any>(requestData.stateKey, null);

      if (transferStateResponse)
      {
        const response = new HttpResponse({ body: transferStateResponse, status: 200 });

        // Remove the response from state transfer, so any future requests to 
        // the same url go to the network TODO: Mario => Not sure about this
        this.transferState.remove(requestData.stateKey);
        return of(response);
      }
      else
      {
        return next.handle(request);
      }
    }
  }

  private getSubRoutine(request: HttpRequest<any>): TransferStateSubroutine
  {
    const result = new TransferStateSubroutine();

    //Clone the request
    if (request.url.includes(Environment.MicroserviceURL)) 
    {
      result.apiReq = request.clone({ url: `${request.url}` });
    }
    else  
    {
      result.apiReq = request.clone({ url: `${Environment.Webapi}${request.urlWithParams}` });
    }

    let requestKey = request.urlWithParams;
    requestKey = requestKey.replace(/\b&offset=\b[0-9\(\)]+/, '');

    // Use the request url as the key.
    result.stateKey = makeStateKey<string>(requestKey);

    return result;
  }

  private postSubRoutine(request: HttpRequest<any>): TransferStateSubroutine
  {
    const result = new TransferStateSubroutine();

    //Clone the request 
    if (request.url.includes(Environment.MicroserviceURL)) 
    {
      result.apiReq = request.clone({ url: `${request.url}` });
    }
    else  
    {
      result.apiReq = request.clone({ url: `${Environment.Webapi}${request.urlWithParams}` });
    }

    let requestKey: string = request.url + JSON.stringify(request.body);
    requestKey = requestKey.replace(/\b&offset=\b[0-9\(\)]+/, '');
    requestKey = requestKey.replace(/"/g, '');

    // Use the request url as the key.
    result.stateKey = makeStateKey<string>(requestKey);

    return result;
  }
}
