import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { ClickActions } from '@core-constants/action-type.const';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { IDealItem, IServicioCombo } from '@core-models/deals.model';
import { IFilterList, IFilterListHelper } from '@core-models/filter-list.model';
import { ICupon } from '@core-models/service-package.model';
import { FilterListService } from '@shared-services/filter-list.service';

@Component({
  selector: 'app-all-deals',
  templateUrl: './all-deals.component.html',
  styleUrls: ['./all-deals.component.css']
})
export class AllDealsComponent implements IFilterListHelper, OnInit, OnChanges
{
  @Input() public title: string = "";
  @Input() public description: string = "";
  @Input() public dealItems: IDealItem[] | undefined = undefined;

  public dealItemsFiltered: IDealItem[] = [];
  public displayedDeals: IDealItem[] = [];
  public currentPage: number = 1;
  public itemsPerPage: number = 6;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private filterListService: FilterListService,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper) { }

  public ngOnInit(): void
  {
    this.initFilterList();
  }

  private initFilterList(): void
  {
    this.createFilterList();
    this.filterListService.onFilterChange().subscribe({
      next: (filterSelected) =>
      {
        this.filterDealItems(filterSelected.value);
      }
    });

    if (this.filterListService.filterSelected?.value)
    {
      this.filterDealItems(this.filterListService.filterSelected.value);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.dealItems)
    {
      this.createFilterList();
    }
  }

  private getCategories(data: IDealItem[]): IFilterList[]
  {
    if (!data)
    {
      return [];
    }

    const categories = data.reduce((FilteredComboCategories: IFilterList[], item) =>
    {
      if (item?.pakageToAdd?.ids_Servicios_Combo)
      {
        const comboCategories = item.pakageToAdd.ids_Servicios_Combo.map((service: IServicioCombo) => ({
          order: this.filterListService.getOrderService(service.servicios_Combo_Id?.category ?? ""),
          selected: false,
          displayText: service.servicios_Combo_Id?.category ?? "",
          value: service.servicios_Combo_Id?.category ?? ""
        }))
          .filter(category => category.value);

        return FilteredComboCategories.concat(comboCategories);
      }
      return FilteredComboCategories;
    }, []);

    return categories;
  }

  public createFilterList(): void
  {
    this.filterListService.filterList = this.getCategories(this.dealItems);
    this.dealItemsFiltered = this.dealItems ?? [];
    this.updateDisplayedDeals();
  }

  private filterDealItems(filterValue: string): void
  {
    if (filterValue === '')
    {
      this.dealItemsFiltered = this.dealItems ?? [];
    }
    else
    {
      this.dealItemsFiltered = (this.dealItems ?? []).filter(item =>
        item?.pakageToAdd?.ids_Servicios_Combo?.some(service =>
          service.servicios_Combo_Id?.category === filterValue
        ) ?? false
      );
    }
    this.currentPage = 1;
    this.updateDisplayedDeals();
  }

  public onDealClick(deal: IDealItem): void
  {
    deal.buttonAction === ClickActions.Types.AddToCart ? this.getServicesPackage(deal.pakageToAdd.id) : this.openUrl(deal.url);
  }

  private openUrl(url: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(url, '_blank');
    }
  }

  private getServicesPackage(pakageId: number): void
  {
    this.servicesDataService.getPackageById(pakageId).subscribe({
      next: (response: ICupon) =>
      {
        if (response)
        {
          this.promotionHelper.addToCart(response, EGtmSection.Deals, EventLocationSite.FinderDeal);
        }
      }
    });
  }

  public updateDisplayedDeals(): void
  {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedDeals = this.dealItemsFiltered.slice(startIndex, endIndex);
  }

  public goToPage(page: number): void
  {
    this.currentPage = page;
    this.updateDisplayedDeals();
  }

  public getTotalPages(): number
  {
    return Math.ceil(this.dealItemsFiltered.length / this.itemsPerPage);
  }

  public get pages(): number[]
  {
    return Array.from({ length: this.getTotalPages() }, (_, i) => i + 1);
  }

  public nextPage(): void
  {
    if (this.currentPage < this.getTotalPages()) 
    {
      this.currentPage++;
      this.updateDisplayedDeals();
    }
  }

  public onPreviousClicked(): void
  {
    if (this.currentPage > 1) 
    {
      this.currentPage--;
      this.updateDisplayedDeals();
    }
  }
}
