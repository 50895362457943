import { IFilterList } from "@core-models/filter-list.model";

export const enum EDealsDocument
{
  TermsConditions = "/pdf/Terminos_y_Condiciones_Promociones.pdf"
}

export const enum EBannerAction
{
  None,
  Scroll,
  OpenUrl
}

export namespace DealConst
{
  export const ServiceOrderMapping: { [key: string]: number } =
  {
    'Dominios': 1,
    'Hosting': 2,
    'Sitios y Tiendas': 3,
    'Email y Seguridad': 4,
    'Marketing Digital': 5,
    'Microsoft': 6
  };

  export const AllDeals: IFilterList =
  {
    order: 0,
    selected: true,
    displayText: 'Todos',
    value: ''
  };
}