import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionComponent } from "@core-components/accordion/accordion.component";
import { DialogComponent } from "@core-components/dialog/dialog.component";
import { DropdownComponent } from "@core-components/dropdown/dropdown.component";
import { HubspotFormContainerComponent } from "@core-components/hubspot-form-container/hubspot-form-container.component";
import { InputNumberComponent } from "@core-components/input-number/input-number.component";
import { InputTogglerComponent } from "@core-components/input-toggler/input-toggler.component";
import { LoadingComponent } from "@core-components/loading/loading.component";
import { QuantitySelectorComponent } from "@core-components/quantity-selector/quantity-selector.component";
import { SearchDomainsInputComponent } from "@core-components/search-domains-input/search-domains-input.component";
import { ToasterComponent } from "@core-components/toaster/toaster.component";
import { AppShellNoRenderDirective } from "@core-directives/app-shell-no-render.directive";
import { AppShellRenderDirective } from "@core-directives/app-shell-render.directive";
import { DebounceClickDirective } from "@core-directives/debounce-click.directive";
import { ItemQuantityDirective } from "@core-directives/item-quantity.directive";
import { BlobStoragePipe } from "@core-pipes/blobstorage.pipe";
import { CoveragePipe } from "@core-pipes/coverage.pipe";
import { DirectusPipe } from "@core-pipes/directus.pipe";
import { SafePipe } from "@core-pipes/safe.pipe ";
import { TextColorPipe } from "@core-pipes/textcolor.pipe";
import { TranslatePipe } from "@core-pipes/translate.pipe";
import { TranslateService } from '@shared-services/translate.service';
import { RecaptchaComponent, RecaptchaModule } from "ng-recaptcha";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import './utils/array.util';


export function setupTranslateFactory(service: TranslateService): any
{
  return () =>
  {
    service.initService();
  };
}

@NgModule({
  declarations: [
    DialogComponent,
    ToasterComponent,
    TranslatePipe,
    BlobStoragePipe,
    TextColorPipe,
    SafePipe,
    DirectusPipe,
    HubspotFormContainerComponent,
    LoadingComponent,
    SearchDomainsInputComponent,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    DropdownComponent,
    CoveragePipe,
    AccordionComponent,
    InputNumberComponent,
    InputTogglerComponent,
    ItemQuantityDirective,
    QuantitySelectorComponent,
    DebounceClickDirective
  ],
  exports: [
    TranslatePipe,
    BlobStoragePipe,
    TextColorPipe,
    DirectusPipe,
    SafePipe,
    DialogComponent,
    ToasterComponent,
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    HubspotFormContainerComponent,
    RecaptchaComponent,
    LoadingComponent,
    SearchDomainsInputComponent,
    DropdownComponent,
    CoveragePipe,
    AccordionComponent,
    InputNumberComponent,
    InputTogglerComponent,
    QuantitySelectorComponent,
    ItemQuantityDirective,
    DebounceClickDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    RecaptchaModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})

export class ShareModule { }
