export namespace SEOConst
{
  export class MetaTag
  {
    public static Description: string = "description";
    public static Keywords: string = "keywords";
  }

  export class DefaultData
  {
    public static Title: string = "Akky";
    public static MaintenanceTitle: string = "Akky | Estamos mejorando nuestros servicios";
    public static Description: string = "Compra tus dominios, crea tu sitio web, blíndalo con certificados SSL y hospédalo en Akky + emails personalizados. Asesoría: +52 (81)8864-2626";
  }

}