import { isPlatformBrowser } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { LanguageConst } from "@core-constants/language.const";
import { EAdditionalService, EAdditionalServicePlan, EServiceName } from "@core-constants/product-service.const";
import { ReferenceConst } from "@core-constants/reference.const";
import { HomeDataService } from "@core-data-services/home.data-service";
import { ServiceDataService } from "@core-data-services/service.data-service";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { PromotionHelper } from "@core-helpers/promotion.helper";
import { DomainsManager } from "@core-managers/domains.manager";
import { IDomainCoverage } from "@core-models/domain.model";
import { ILanguageComponent } from "@core-models/generic.model";
import { HomeResponse } from "@core-models/home.model";
import { ServiceRequest, ServiceResponse } from "@core-models/services.model";
import { LocalStorageService } from "@shared-services/local-storage.service";
import { SEOService } from "@shared-services/seo.service";
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from "@shared-utils/tools.util";
import { fromEvent } from "rxjs";
import { HomeDEScript } from "../structured-data/scripts/HomeDEScript";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit, ILanguageComponent
{
  @ViewChild('carousel', { static: false }) public carousel: ElementRef;

  public slides: HomeResponse.ISlide[] = [];
  public offers: HomeResponse.IOffer[] = [];

  public home: HomeResponse.IHomeTranslation;
  public testimonials: boolean = false;
  public whatsAppUrl: string = ReferenceConst.WhatsAppUrl;
  public comboToAdd: string = "";

  public servicesDE: HomeResponse.IServiceED[];
  public serviceRateInfo: ServiceResponse.IRateInfo[];
  public homeDEScript = HomeDEScript;

  private ariaLabelNextButton: string = "Siguiente";
  private ariaLabelBackButton: string = "Anterior";
  public conditions: string = "";

  constructor(@Inject(PLATFORM_ID) private platformId,
    private homeDataService: HomeDataService,
    protected translateService: TranslateService,
    private domainsManager: DomainsManager,
    private localStorageService: LocalStorageService,
    private promotionHelper: PromotionHelper,
    public shoppingCartDataService: ShoppingCartDataService,
    public serviceDataService: ServiceDataService,
    public seoService: SEOService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private destroyRef$: DestroyRef
  ) { }

  public get ariaLabelForNextButton(): string
  {
    return this.translateService.getElement(this.ariaLabelNextButton);
  }

  public get ariaLabelForBackButton(): string
  {
    return this.translateService.getElement(this.ariaLabelBackButton);
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.getLandingSourceServices();
  }

  public ngAfterViewInit(): void
  {
    if (this.carousel)
    {
      fromEvent(this.carousel.nativeElement, 'slide.bs.carousel')
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: ({ to }) =>
        {
          if (!Tools.isNullOrEmpty(this.slides))
          {
            this.conditions = this.slides[to]?.traducciones[0]?.vigencia;
            this.ref.detectChanges();
          }
        }
      });

      if (!Tools.isNullOrEmpty(this.slides))
      {
        this.conditions = this.slides[0]?.traducciones[0]?.vigencia;
      }
    }
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getHomeBundle();
      }
    });
  }

  public getHomeBundle(): void
  {
    this.homeDataService.getBundle().subscribe({
      next: (response: HomeResponse.IGetBundle) =>
      {
        if (response)
        {
          this.testimonials = response?.home?.testimoniales;
          this.home = response?.home?.traducciones[0];
          this.servicesDE = response.servicios_de;

          this.slides = response.slides;

          this.offers = response.offers;

          this.seoService.updateSeoElements(this.home?.seo_titulo, this.home?.seo_descripcion);

          if (isPlatformBrowser(this.platformId))
          {
            this.processStructuredData();
          }
        }

        this.ref.detectChanges();
      }
    });
  }

  public onSingleSearch(): void
  {
    const isToggleActive = false;
    this.domainsManager.setIsToggleActive(isToggleActive);
    this.domainsManager.searchSimpleDomain(LanguageConst.ES , isToggleActive);
    this.domainsManager.searchOnlySuggestionAI();
    this.navigateToSearchDomain();
  }

  public onSearchChangeMode(): void
  {
    this.navigateToSearchDomain();
  }

  public navigateToSearchDomain(): void
  {
    this.router.navigate(['servicios', 'dominios']);
  }

  public onOpenAcreditationPage(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(ReferenceConst.AccreditationPage, '_blank');
    }
  }

  public onOpenHelpPage(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/ayuda/faq`], { queryParams: { categoria: "Acreditaciones" } })
      );

      window.open(url, '_blank');
    }
  }

  public getLandingSourceServices(): void
  {
    this.comboToAdd = this.localStorageService.getItem('personalBrandServices');
    if (this.comboToAdd)
    {
      const domainList: IDomainCoverage[] = [];
      domainList.push({ domain: '.mx', coverage: null });

      const serviceDataWix = "ASP_ID=".concat(EAdditionalService.WebsiteBuilderEPages.toString()).concat(",APL_ID=")
        .concat(EAdditionalServicePlan.WixDoItYourself.toString());

      const serviceDataOX = "ASP_ID=".concat(EAdditionalService.EmailOpenxchange.toString()).concat(",APL_ID=")
        .concat(EAdditionalServicePlan.EmailOX5G.toString());

      const elementMx = {
        serviceData: ".mx",
        coverageId: 12,
        type: EServiceName.Domain
      };

      const elementOX = {
        serviceData: serviceDataOX,
        coverageId: 3,
        type: EServiceName.Service
      };

      const elementWix = {
        serviceData: serviceDataWix,
        coverageId: 3,
        type: EServiceName.Service
      };

      const packageServices: any[] = [];
      packageServices.push(elementMx, elementWix, elementOX);
      this.promotionHelper.addSeveralServices(packageServices);
      this.localStorageService.removeItem('personalBrandServices');
    }
  }

  public processStructuredData(): void
  {
    if (!Tools.isNullOrEmpty(this.servicesDE))
    {
      const dto: ServiceRequest.IGetRates = {
        servicesId: this.servicesDE.map(x => x.id_servicio)
      };

      this.serviceDataService.getRates(dto).subscribe({
        next: (response: ServiceResponse.IRateInfo[]) =>
        {
          this.serviceRateInfo = response;
          this.ref.detectChanges();
        }
      });
    }
  }
}
