import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { EDomainStatus } from '@core-constants/domain.const';
import { EGtmEvent } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { LanguageConst } from '@core-constants/language.const';
import { RegexConst } from '@core-constants/regex.const';
import { DomainDataService } from '@core-data-services/domains.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { TokenManager } from '@core-managers/token.manager';
import { IDomainCoverage, SearchRequest, SearchResponse } from '@core-models/domain.model';
import { OnAddResponse } from '@core-models/shopping-cart-items.model';
import { TransferDomainResponse } from '@core-models/transfer-domain.model';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { ToastService } from '@shared-services/toast.service';
import { Tools } from '@shared-utils/tools.util';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DomainsManager
{
  //#region state
  private addAllDomainsEnabled = new BehaviorSubject(false);
  private errorSearchVisible = new BehaviorSubject<boolean>(false);
  private searchSuggestionsVisible = new BehaviorSubject<boolean>(false);
  private isSearchLoading = new BehaviorSubject<boolean>(false);
  private isSuggestionsLoading = new BehaviorSubject<boolean>(false);
  private domainFinded = new BehaviorSubject<SearchResponse.IDomain>(null);
  private singeSearchText = new BehaviorSubject<string>('');
  private multipleSearchText = new BehaviorSubject<string>('');
  private singleSearch = new BehaviorSubject<boolean>(true);
  private domainsFinded = new BehaviorSubject<SearchResponse.IDomain[]>([]);
  private suggestions = new BehaviorSubject<any>([]);
  private suggestionsAI = new BehaviorSubject<any>([]);
  private searchResultVisible = new BehaviorSubject<boolean>(false);
  private suggestionsVisible = new BehaviorSubject<boolean>(true);
  private errorSearchEmptyVisible = new BehaviorSubject<boolean>(false);
  private errorSearchLengthVisible = new BehaviorSubject <boolean>(false);
  private showDomainsSearchList = new BehaviorSubject<boolean[]>([]);
  private isValidForTransfer = new BehaviorSubject<boolean>(false);
  private msgErrorForTransfer = new BehaviorSubject<string>('');
  private domainsTransferText = new BehaviorSubject<string>('');
  private isToggleActiveDomain = new BehaviorSubject<boolean>(false);
  private isToggleActive = new BehaviorSubject<boolean>(true);
  private firstSearchSuggestionAI = new BehaviorSubject<boolean>(true);
  private isLoadingSuggestionAI = new BehaviorSubject<boolean>(false);
  //#endregion

  constructor(protected domainDataService: DomainDataService,
    private shoppingCartDataService: ShoppingCartDataService,
    private toast: ToastService,
    protected interactiveToast: InteractiveToasterService,
    private tokenManager: TokenManager) { }

  //#region state getters
  public getAllDomainsEnabled(): Observable<any>
  {
    return this.addAllDomainsEnabled.asObservable();
  }

  public setAllDomainsEnabled(value: boolean): void
  {
    this.addAllDomainsEnabled.next(value);
  }

  public restartDomainsState(): void
  {
    this.addAllDomainsEnabled.next(false);
    this.errorSearchVisible.next(false);
    this.searchSuggestionsVisible.next(false);
    this.isSearchLoading.next(false);
    this.isSuggestionsLoading.next(false);
    this.domainFinded.next(null);
    this.singeSearchText.next('');
    this.multipleSearchText.next('');
    this.singleSearch.next(true);
    this.domainsFinded.next([]);
    this.suggestions.next([]);
    this.suggestionsAI.next([]);
    this.searchResultVisible.next(false);
    this.suggestionsVisible.next(true);
    this.errorSearchEmptyVisible.next(false);
    this.errorSearchLengthVisible.next(false);
    this.showDomainsSearchList.next([]);
    this.isValidForTransfer.next(false);
    this.msgErrorForTransfer.next('');
    this.domainsTransferText.next('');
    this.firstSearchSuggestionAI.next(true);
  }

  public getIsValidForTransfer(): Observable<boolean>
  {
    return this.isValidForTransfer.asObservable();
  }

  public getDomainsTransferText(): Observable<string>
  {
    return this.domainsTransferText.asObservable();
  }

  public getMsgErrorForTransfer(): Observable<string>
  {
    return this.msgErrorForTransfer.asObservable();
  }

  public getSearchSuggestionsVisible(): Observable<boolean>
  {
    return this.searchSuggestionsVisible.asObservable();
  }

  public getSuggestionsLoadingVisible(): Observable<boolean>
  {
    return this.isSuggestionsLoading.asObservable();
  }

  public getErrorSearchVisible(): Observable<boolean>
  {
    return this.errorSearchVisible.asObservable();
  }

  public getDomainFinded(): Observable<any>
  {
    return this.domainFinded.asObservable();
  }

  public getSearchLoading(): Observable<boolean>
  {
    return this.isSearchLoading.asObservable();
  }

  public getSuggestions(): Observable<any>
  {
    return this.suggestions.asObservable();
  }

  public getSuggestionsAI(): Observable<any>
  {
    return this.suggestionsAI.asObservable();
  }

  public setMultipleDomainSearchText(value: string): void
  {
    this.multipleSearchText.next(value);
  }

  public setSingleDomainSearchText(value: string): void
  {
    this.singeSearchText.next(value);
  }

  public getSigleSearchText(): Observable<string>
  {
    return this.singeSearchText.asObservable();
  }

  public getMultipleSearchText(): Observable<string>
  {
    return this.multipleSearchText.asObservable();
  }

  public getDomainsFinded(): Observable<any>
  {
    return this.domainsFinded.asObservable();
  }

  public getSearchResultVisible(): Observable<boolean>
  {
    return this.searchResultVisible.asObservable();
  }

  public getSuggestionsVisible(): Observable<boolean>
  {
    return this.suggestionsVisible.asObservable();
  }

  public getErrorSearchEmptyVisible(): Observable<any>
  {
    return this.errorSearchEmptyVisible.asObservable();
  }

  public getErrorSearchLengthVisible(): Observable<any>
  {
    return this.errorSearchLengthVisible.asObservable();
  }

  public getShowDomainsSearchList(): Observable<any>
  {
    return this.showDomainsSearchList.asObservable();
  }

  public getIsToggleActive(): Observable<boolean>
  {
    return this.isToggleActive.asObservable();
  }

  public setIsToggleActive(value: boolean): void
  {
    this.isToggleActive.next(value);
  }

  public getIsToggleActiveDomain(): Observable<boolean>
  {
    return this.isToggleActiveDomain.asObservable();
  }

  public setIsToggleActiveDomain(value: boolean): void
  {
    this.isToggleActiveDomain.next(value);
  }

  public setShowDomainsSearchList(value: boolean[]): void
  {
    this.showDomainsSearchList.next(value);
  }

  public isSingleSearch(): Observable<boolean>
  {
    return this.singleSearch.asObservable();
  }

  public isAtLeastOneDomainAvailable(): boolean
  {
    return this.domainsFinded.getValue()?.find(d => d.available === true) != undefined;
  }

  public hasSuggestions(): boolean
  {
    const suggestions = this.suggestions.getValue();
    return !Tools.isNullOrEmpty(suggestions);
  }

  public hasSuggestionsAI(): boolean
  {
    const suggestionsAI = this.suggestionsAI.getValue();
    return !Tools.isNullOrEmpty(suggestionsAI);
  }

  public getIsLoadingSuggestionAI(): boolean
  {
    return this.isLoadingSuggestionAI.getValue();
  }

  public isValidDomainsForTransfer(): boolean
  {
    return this.isValidForTransfer.getValue();
  }

  //#endregion

  public disableSuggestions(): void
  {
    this.suggestions.next([...this.suggestions.getValue().map(a => ({ ...a, isEnabled: false }))]);
  }

  public setSingleSearch(): void
  {
    if (!this.singleSearch.getValue())
    {
      this.searchToggle();
    }
  }


  public searchSimpleDomain(lang: string = LanguageConst.ES, isToggleActive: boolean = true): void
  {
    this.initializeSimpleSearch(isToggleActive);

    const domainToSearch = this.singeSearchText.getValue();

    if (domainToSearch && domainToSearch.replace(/\s/g, '') != '')
    {
      if ((domainToSearch.includes('.') ? domainToSearch.split('.')[0] : domainToSearch).length < 3)
      {
          this.errorSearchLengthVisible.next(true);
          return;
      }

      this.showSearchLoading();
      this.showSearchSuggestionsButton();
      const searchText = this.singeSearchText.getValue();

      this.domainDataService.searchMultipleDomainsFromServer([searchText])
        .pipe(
          finalize(() =>
          {
            const searchTextClean: string = searchText.replace(RegexConst.SpecialChars, "");
            this.showSuggestionsLoading();

            if (searchTextClean.length === 0)
            {
              this.searchSuggestions();
              return;
            }
            this.fillSuggestionsAI(searchTextClean, lang);
          }
          )).subscribe({
            next: (response: SearchResponse.IDomain[]) =>
            {
              this.errorSearchEmptyVisible.next(false);
              this.errorSearchVisible.next(false);
              this.suggestionsVisible.next(true);
              this.domainFinded.next({ ...response[0], isEnabled: true });
              this.showSearchSuggestionsButton();
              this.suggestions.next([]);
              this.suggestionsAI.next([]);
              this.enableAddAllDomainsButton();
              this.hideSearchLoading();
            },
            error: (response: HttpErrorResponse) =>
            {
              this.errorSearchVisible.next(true);
              this.domainFinded.next(response.status != 403 ? response.error[0] : null);
              this.hideSearchLoading();
            }
          });
    }
    else
    {
      this.errorSearchEmptyVisible.next(true);
    }
  }

  private readLines(input: string): string[]
  {
    return input.split('\n');
  }


  public searchMultipleDomains(lang: string = LanguageConst.ES): void
  {
    this.initializeSimpleSearch();

    const domainsToSearch = this.multipleSearchText.getValue();

    if (domainsToSearch && domainsToSearch.replace(/\s/g, '') != '')
    {

      const linesDomain = this.readLines(domainsToSearch);

      for (const domain of linesDomain)
      {

        if ((domain.includes('.') ? domain.split('.')[0] : domain).length < 3)
        {
          this.errorSearchLengthVisible.next(true);
          return;
        }
      }

      this.showSearchLoading();
      this.showMultipleSearchLoading();
      this.showSearchSuggestionsButton();

      this.domainDataService.searchMultipleDomainsFromServer(this.multipleSearchText.getValue().trim().split('\n'))
      .pipe(
        finalize(() =>
        {
          const searchTextClean: string = domainsToSearch.replace(RegexConst.SpecialChars, "");
          this.showSuggestionsLoading();

          if (searchTextClean.length === 0)
          {
            this.searchSuggestions();
            return;
          }

          const singleSearchText = this.multipleSearchText.getValue().split('\n')[0];
          if (!singleSearchText) { return; }
          this.fillSuggestionsAI(singleSearchText, lang);
        })
      )
      .subscribe({
        next: (result: any) =>
        {

          this.enableAddAllDomainsButton();
          this.domainsFinded.next([...result.map(a => ({ ...a, isEnabled: true }))]);
          this.searchResultVisible.next(true);
          this.suggestionsVisible.next(true);
          this.suggestions.next([]);
          this.suggestionsAI.next([]);
          this.hideSearchLoading();
          this.showSuggestionsLoading();
        },
        error: (response: HttpErrorResponse) =>
        {
          if (typeof (response.error) !== 'string')
          {
            this.domainsFinded.next(response.error);
          }
          else
          {
            this.domainsFinded.next([]);
          }
          this.hideSearchLoading();
          this.searchSuggestions();
        }
      });
    }
    else
    {
      this.errorSearchEmptyVisible.next(true);
    }
  }

  public searchOnlySuggestionAI(): void
  {
    if (this.firstSearchSuggestionAI.getValue())
    {
      this.isLoadingSuggestionAI.next(true);
      this.showSuggestionsLoading();
      this.suggestionsVisible.next(false);
      this.searchSuggestions();
      this.firstSearchSuggestionAI.next(false);
    }
  }

  public initializeSimpleSearch(isToggleActive: boolean = true): void
  {
    this.domainFinded.next(null);
    this.suggestions.next([]);
    this.suggestionsAI.next([]);
    this.searchResultVisible.next(false);
    this.errorSearchVisible.next(false);
    this.suggestionsVisible.next(false);
    this.errorSearchEmptyVisible.next(false);
    this.errorSearchLengthVisible.next(false);
    this.isValidForTransfer.next(false);
    this.msgErrorForTransfer.next('');
    this.domainsTransferText.next('');
    this.isToggleActive.next(isToggleActive);
    this.firstSearchSuggestionAI.next(true);
  }

  public showSearchLoading(): void
  {
    this.isSearchLoading.next(true);
    this.searchResultVisible.next(false);
  }

  public showMultipleSearchLoading(): void
  {
    this.isLoadingSuggestionAI.next(true);
    this.firstSearchSuggestionAI.next(true);
  }

  public showSearchSuggestionsButton(): void
  {
    this.searchSuggestionsVisible.next(true);
  }

  public hideSearchLoading(): void
  {
    this.isSearchLoading.next(false);
    this.searchResultVisible.next(true);
  }

  public getDomainStatus(domain: any): EDomainStatus
  {
    let result = EDomainStatus.Available;

    if (domain?.error)
    {
      result = domain?.error?.code;
    }
    return result;
  }

  public searchSuggestions(): void
  {
    let suggestions: SearchRequest.IDomainSuggestions[];
    let suggestionsAI: SearchRequest.IDomainSuggestions[] = [];
    const domainToSearch = this.singeSearchText.getValue(); //TODO: modificar cuando se implemente para busqueda multiple

    if (this.singleSearch.getValue())
    {
      suggestions = [
        {
          domain: this.domainFinded.getValue()?.domainName,
          status: this.getDomainStatus(this.domainFinded?.getValue()),
          userId: this.tokenManager.getUser()?.id
        }
      ];
    }
    else
    {
      suggestions = this.domainsFinded.getValue().map((element: any) =>
      {
        return {
          domain: element.domainName,
          status: this.getDomainStatus(element),
          userId: this.tokenManager.getUser()?.id
        };
      });
    }

    suggestionsAI = suggestionsAI.concat(this.suggestionsAI.getValue().map((domain: any) =>
                                  {
                                    return {
                                      domain,
                                      status: this.getDomainStatus(domain),
                                      userId: this.tokenManager.getUser()?.id
                                    };
                                  }));

    this.domainDataService.searchSuggestionsFromServer(suggestions).pipe(
      finalize(() =>
      {
        if (!Tools.isNullOrEmpty(suggestionsAI))
        {
          this.domainDataService.searchSuggestionsFromServer(suggestionsAI.slice(0, 4)).subscribe({
            next: (result: any) =>
            {
              if (!Tools.isNullOrEmpty(result))
              {
                this.enableAddAllDomainsButton();
              }

              this.suggestionsAI.next([...result.map(a => ({ ...a, isEnabled: true }))]);
              this.hideSuggestionsLoading();
              this.isLoadingSuggestionAI.next(false);

              if (this.singleSearch.getValue())
              {
                const totalResults: number = suggestionsAI.length + result.length || 0;
                GtmTrackingService.viewSearchResultsEvent(EGtmEvent.ViewSearchResults, domainToSearch, totalResults);
              }
            },
            error: () =>
            {
              this.hideSuggestionsLoading();
              this.isLoadingSuggestionAI.next(false);
            }
          });
        }
        else
        {
          this.hideSuggestionsLoading();
          this.isLoadingSuggestionAI.next(false);
        }
      }
      )).subscribe({
        next: (result: any[]) =>
        {
          if (this.suggestions.getValue.length > 0)
          {
            return;
          }

          if (!Tools.isNullOrEmpty(result))
          {
            this.enableAddAllDomainsButton();
          }
          else
          {
            this.isToggleActive.next(false);
          }

          this.suggestions.next([...result.map(a => ({ ...a, isEnabled: true }))]);

          if (this.singleSearch.getValue())
          {
            const totalResults: number = suggestions.length + result.length || 0;
            GtmTrackingService.viewSearchResultsEvent(EGtmEvent.ViewSearchResults, domainToSearch, totalResults);
          }
        }
      });
  }

  public showSuggestionsLoading(): void
  {
    this.isSuggestionsLoading.next(true);
    this.suggestionsVisible.next(false);
  }

  public fillSuggestionsAI(searchText: string, lang: string): void
  {
    this.domainDataService.getSuggestionsAIRequest(searchText, lang).subscribe({
      next: (result: any) =>
      {
        this.suggestionsAI.next([...result.map((suggestion: any) => suggestion.domain)]);
        this.searchSuggestions();
      },
      error: () =>
      {
        this.searchSuggestions();
      }
    });
  }

  public searchAlternatives(): void
  {
    let alternatives: SearchRequest.IDomainAlternatives[];

    if (this.singleSearch.getValue())
    {
      alternatives = [
        {
          domain: this.domainFinded.getValue().domainName,
          status: this.getDomainStatus(this.domainFinded),
          userId: this.tokenManager.getUser()?.id
        }
      ];
    }
    else
    {
      alternatives = this.domainsFinded.getValue().map(element =>
      {
        return {
          domain: element.domainName,
          status: this.getDomainStatus(element),
          userId: this.tokenManager.getUser()?.id
        };
      });
    }

    this.showSuggestionsLoading();

    this.domainDataService.searchAlternativesFromServer(alternatives).subscribe({
      next: (result: any) =>
      {
        if (!Tools.isNullOrEmpty(result))
        {
          result.forEach((suggestion: any) => suggestion.isEnabled = true);
          this.enableAddAllDomainsButton();
        }

        const modified = [...this.suggestions.getValue(), ...result];
        this.suggestions.next([...new Map(modified.map(item => [item['domainName'], item])).values()]);

        this.hideSuggestionsLoading();
        this.hideSearchSuggestionsButton();
      },
      error: () =>
      {
        this.hideSuggestionsLoading();
        this.hideSearchSuggestionsButton();
      }
    });
  }

  public hideSuggestionsLoading(): void
  {
    this.isSuggestionsLoading.next(false);
    this.suggestionsVisible.next(true);
  }

  public hideSearchSuggestionsButton(): void
  {
    this.searchSuggestionsVisible.next(false);
  }

  public enableAddAllDomainsButton(): void
  {
    this.addAllDomainsEnabled.next(true);
  }

  public searchToggle(): void
  {
    if (this.singleSearch.getValue())
    {
      this.multipleSearchText.next(this.singeSearchText.getValue());
    }
    else
    {
      this.singeSearchText.next(this.multipleSearchText.getValue().split('\n')[0]);
    }

    this.domainsFinded.next([]);
    this.errorSearchEmptyVisible.next(false);
    this.errorSearchLengthVisible.next(false);
    this.singleSearch.next(!this.singleSearch.getValue());
    this.searchResultVisible.next(false);
    this.suggestionsVisible.next(false);
    this.hideSearchSuggestionsButton();
    this.suggestions.next([]);
    this.suggestionsAI.next([]);
    this.enableAddAllDomainsButton();
    this.isToggleActive.next(true);
  }

  public getFeatureSuggestionsVisible(): boolean
  {
    let isAvailable = !this.domainFinded.getValue()?.available;

    if (!this.singleSearch.getValue())
    {
      isAvailable = !this.isAtLeastOneDomainAvailable;
    }

    return isAvailable;
  }

  public addAllDomainsToCart(isSuggestionAI: boolean = false , addLocation : string): void
  {
    this.addAllDomainsEnabled.next(false);
    const suggestions = isSuggestionAI ? this.suggestions.getValue() : this.suggestionsAI.getValue();

    const domains: IDomainCoverage[] = suggestions.filter(suggestion => suggestion.isEnabled && !suggestion.isAvailableOffline)
      .map((suggestion: any) => <IDomainCoverage>{ domain: suggestion.domainName, coverage: null });
    this.disableSuggestions();

    this.shoppingCartDataService.addDomains(domains).subscribe({
      next: (response: OnAddResponse.IDomainAdded) =>
      {
        if (response?.error != undefined && response?.error != null)
        {
          this.notifyDomainErrors(response.cartListResult);
        }
        else
        {
          const cartId = response.cartId;
          BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, cartId);
          this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
          suggestions.map((obj) =>
          {
            obj.addedByAI = !isSuggestionAI;
            return obj;
          });
          GtmTrackingService.addToCartDomainEvent(suggestions, true, addLocation);
        }
      },
      error: (response: HttpErrorResponse) =>
      {
        this.toast.setErrorToast(response?.message);
      }
    });

    this.shoppingCartDataService.addDomains(domains).subscribe({
      next: (response: OnAddResponse.IDomainAdded) =>
      {
        if (response?.error != undefined && response?.error != null)
        {
          this.notifyDomainErrors(response.cartListResult);
        }
        else
        {
          const cartId = response.cartId;

          BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, cartId);
        }
      },
      error: (response: HttpErrorResponse) =>
      {
        this.toast.setErrorToast(response?.message);
      }
    });
  }

  public getErrorDomainsText(errorDomains: OnAddResponse.IDomainItem[]): string
  {
    let result = "";

    if (!Tools.isNullOrEmpty(errorDomains))
    {
      errorDomains.filter(x => x.error).forEach(domain =>
      {
        result += `${domain.domainName}: ${domain.error?.message} \r`;
      });
    }
    return result;
  }

  public notifyDomainErrors(response: any[]): void
  {
    const message = this.getErrorDomainsText(response);
    this.toast.setErrorToast(message);
  }

  public isValidForTransferSingleDomain(): void
  {
    this.initializeSimpleSearch();
    const domainToSearch = this.singeSearchText.getValue();

    if (domainToSearch && domainToSearch.replace(/\s/g, '') != '')
    {
      this.showSearchLoading();

      this.domainDataService.domainsAreValidForTransferFromServer([this.singeSearchText.getValue()], false).subscribe({
        next: (response: TransferDomainResponse.ITransferRequestIn[]) =>
        {
          this.errorSearchEmptyVisible.next(false);
          this.errorSearchVisible.next(false);
          this.hideSearchLoading();
          this.domainsTransferText.next(this.singeSearchText.getValue());

          let isValid = true;
          let error = "";

          if (!Tools.isNullOrEmpty(response))
          {
            isValid = response[0].isValid;
            error = response[0].shortReason.message;
          }

          this.isValidForTransfer.next(isValid);
          this.msgErrorForTransfer.next(error);
        },
        error: (response: HttpErrorResponse) =>
        {
          if (typeof (response?.error) !== 'string')
          {
            this.isValidForTransfer.next(false);
            this.msgErrorForTransfer.next(response?.error?.message);
          }
          else
          {
            this.domainsFinded.next([]);
          }

          this.hideSearchLoading();
        }
      });
    }
    else
    {
      this.errorSearchEmptyVisible.next(true);
    }
  }

  public isValidForTransferMultipleDomains(): void
  {
    this.initializeSimpleSearch();
    const domainsToSearch = this.multipleSearchText.getValue();

    if (domainsToSearch && domainsToSearch.replace(/\s/g, '') != '')
    {
      this.showSearchLoading();

      this.domainDataService.domainsAreValidForTransferFromServer(this.multipleSearchText.getValue().trim().split('\n'), true).subscribe({
        next: (response: TransferDomainResponse.ITransferRequestIn[]) =>
        {
          this.errorSearchEmptyVisible.next(false);
          this.errorSearchVisible.next(false);
          this.hideSearchLoading();
          this.domainsTransferText.next(this.multipleSearchText.getValue().trim().split('\n').join(','));

          let isValid = true;
          let error = "";

          if (!Tools.isNullOrEmpty(response))
          {
            const domainsInvalid = response.filter(x => x.isValid === false);

            if (!Tools.isNullOrEmpty(domainsInvalid))
            {
              isValid = false;

              domainsInvalid.forEach(invalidItem =>
              {
                const domainName = invalidItem.domain.fullName != null ? invalidItem.domain.fullName : invalidItem.domain.proposedName;

                const spanName = domainName != null ? '<span class="searchResult-error-text-domain">' + domainName + "</span> - " : "";

                error += spanName + invalidItem.shortReason?.message + '<br/>';
              });
            }
          }

          this.isValidForTransfer.next(isValid);
          this.msgErrorForTransfer.next(error);

        },
        error: (response: HttpErrorResponse) =>
        {
          if (typeof (response?.error) !== 'string')
          {
            this.isValidForTransfer.next(false);
            this.msgErrorForTransfer.next(response?.error?.message);
          }
          else
          {
            this.domainsFinded.next([]);
          }

          this.hideSearchLoading();
        }
      });
    }
    else
    {
      this.errorSearchEmptyVisible.next(true);
    }
  }

  //#endregion
}
