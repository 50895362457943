import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { EGtmEvent } from '@core-constants/gtm-const';
import { LanguageConst } from '@core-constants/language.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { SessionParams } from '@core-constants/url-param.const';
import { HomeDataService } from '@core-data-services/home.data-service';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { ILanguageModel } from '@core-models/language.model';
import { INavBarMenuItem } from '@core-models/menu-items.model';
import { INavBarBlog, INavBarBlogModel } from '@core-models/navbar-blog.model';
import { Environment } from '@environments';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs/operators';
import { NavbarMenuItems } from './navbar-menu-items';

/** @title Responsive sidenav */
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('150ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(500%)' }),
        animate('350ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ])
  ]
})

export class NavBarResponsiveComponent implements OnInit, AfterViewInit
{
  @ViewChild('togglerMobile', { static: true }) public togglerMobile: ElementRef;
  @ViewChild('akkyNavbarMobile', { static: true }) public mobileMenu: ElementRef;
  @ViewChild('togglerControlPanel', { static: true }) public togglerControlPanel: ElementRef;
  @ViewChild('navBar') private navbarElement: ElementRef;

  public blogs: INavBarBlogModel = undefined;

  public whiteBackground = false;
  public params: any = undefined;

  public selectedOptionIndex: number;
  public selectedControlPanel: number = 0;

  public isLogedIn: boolean = false;
  public isNavBarWhite: boolean = false;

  public showMenuPanel: boolean = false;
  public showUserMenu: boolean = false;

  public selectedLanguage: ILanguageModel;
  public dropLanguages: boolean = false;
  public dropUserMenu: boolean = false;

  public showShoppingCart: boolean = false;
  public showUserAccessModal: boolean = false;

  public isMobile = false;
  public userNameInitials = "";

  public selectedBlog: INavBarBlog;

  private ariaLabelPhone: string = "Teléfono";

  //Constants
  public languages = LanguageConst.Languages;
  public menuOptions: INavBarMenuItem[] = NavbarMenuItems;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    protected localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private tokenManager: TokenManager,
    private routeHelper: RouteHelper,
    protected checkoutManager: CheckoutManager,
    private userAccessDataService: UserAccessDataService,
    private homeService: HomeDataService,
    private destroyRef$: DestroyRef)
  {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.whiteBackground = false;
      }
    });
  }

  public get showNavBarWhite(): boolean
  {
    return (this.showMenuPanel === true || this.isNavBarWhite) || this.whiteBackground;
  }

  public get cartItemsCount(): number
  {
    return this.checkoutManager.cartItemsCount ?? 0;
  }

  public get ariaLabelForPhoneAnchor(): string
  {
    return this.translateService.getElement(this.ariaLabelPhone);
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(): void
  {
    this.setNavBarClass();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void
  {
    this.dropUserMenu = false;
    this.showMenuPanel = false;
    this.setSidenavProperties();
    if (isPlatformBrowser(this.platformId))
    {
      this.updateHeight();
    }
  }

  @HostListener('document:click', ['$event'])
  public clickout(e: Event): void
  {
    const element = (e.target as HTMLInputElement)?.title.toLocaleLowerCase();

    if (element != 'idioma' && element != 'language')
    {
      this.dropLanguages = false;
    }

    if (element != 'user')
    {
      this.dropUserMenu = false;
    }
  }

  public ngOnInit(): void
  {
    this.initLanguage();
    this.registerLanguageEventListeners();
    this.setSidenavProperties();
    this.registerEventListeners();
    this.setCurrentUserInformation();
  }

  public ngAfterViewInit(): void
  {
    this.setSidenavProperties();
    this.registerRouteEventListeners();
    this.setNavBarClass();
    if (isPlatformBrowser(this.platformId))
    {
      this.updateHeight();
    }
  }

  public registerRouteEventListeners(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.activatedRoute.queryParams.subscribe({
        next: (params) =>
        {
          this.params = params;

          const externalRequest = params[SessionParams.Login];

          if (externalRequest && (externalRequest === "true" || externalRequest === true))
          {

            const user = this.tokenManager.getUser();

            this.showUserAccessModal = user === undefined;

            this.router.navigate(['./'], {
              relativeTo: this.activatedRoute,
              queryParams: {},
              queryParamsHandling: ''
            });
          }
        }
      });
    }
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnNavColorChanges)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.whiteBackground = true;
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnLoginSuccess)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.clearFlags();
        this.setCurrentUserInformation();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnUserLogout)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.resetUserDisplayedInfo();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenLogin)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.showUserAccessModal = true;
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnTransferDomains)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.params = {
          'uri': `${this.routeHelper.ecommerceURL}/servicios/transferencias`
        };
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenRemoteCartPopUp)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.goToPopUpCart();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnCloseRemotePopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.closePopupCart();
      }
    });

  }

  private registerLanguageEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () => this.getBlogs()
    });
  }

  private updateHeight(): void
  {
    if (this.navbarElement)
    {
      const height = `${this.navbarElement.nativeElement.offsetHeight}px`;
      document.documentElement.style.setProperty('--height-navbar', height);
    }
  }

  public registerClickMenuEvent(itemClicked: string): void
  {
    if (itemClicked)
    {
      let title: string = itemClicked;
      const submenu: string = itemClicked;

      const menuOption = this.menuOptions.find((x: any) => x.title == itemClicked);

      if (menuOption == undefined)
      {
        title = this.menuOptions.find(m => m.submenus?.find(s => s.options.find(o => o.name == itemClicked))).title;
      }

      GtmTrackingService.menuEvent(EGtmEvent.Menu, title, submenu);
    }
  }

  public setSidenavProperties(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.isMobile = window.innerWidth <= 1200;
    }

    this.setNavBarClass();
  }

  private initLanguage(): void
  {
    let lang = this.localStorageService.getItem(LocalStorageConst.SelectedLang);
    if (!lang)
    {
      lang = LanguageConst.ES;
    }

    this.selectedLanguage = this.languages.find(l => l.code == lang);
    this.translateService.use(lang);
  }

  public setNavBarClass(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.isNavBarWhite = window.pageYOffset > 30 || this.showMenuPanel;
    }
  }

  public setCurrentUserInformation(): void
  {
    const user = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();

    if (user && token)
    {
      this.isLogedIn = true;
      this.userNameInitials = user.initials;
    }
    else
    {
      this.resetUserDisplayedInfo();
    }
  }

  public resetUserDisplayedInfo(): void
  {
    this.clearFlags();
    this.userNameInitials = "";
  }

  public onSelectLanguage(lang: ILanguageModel): void
  {
    this.selectedLanguage = lang;
    this.translateService.use(this.selectedLanguage.code);
  }

  public onLogout(): void
  {
    GtmTrackingService.controlPanelEvent(EGtmEvent.ControlPanel, this.translateService.getElement('Cerrar sesión'));
    this.userAccessDataService.logout().subscribe({
      next: () =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, false);
      },
      error: () =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, true);
      }
    });
  }

  public clearFlags(): void
  {
    this.isLogedIn = false;
    this.showUserAccessModal = false;
    this.showMenuPanel = false;
    this.showUserMenu = false;
    this.dropUserMenu = false;
    this.dropLanguages = false;
  }

  public onSelectOptionDesktop(index: number): void
  {
    this.showMenuPanel = true;
    this.selectedOptionIndex = index;
    this.selectBlog();
    this.dropUserMenu = false;
    this.dropLanguages = false;
  }

  public selectBlog(): void
  {
    const category = this.menuOptions[this.selectedOptionIndex]?.category;

    if (category)
    {
      const filterBlogs: INavBarBlog[] = this.blogs?.response?.filter(blog => blog.category == category);
      const random = Math.floor(Math.random() * filterBlogs?.length);
      this.selectedBlog = filterBlogs[random];
    }
  }

  public onSelectOptionMobile(index: number): void
  {
    if (this.selectedOptionIndex == index)
    {
      this.selectedOptionIndex = -1;
      this.showMenuPanel = false;
    }
    else
    {
      this.showMenuPanel = true;
      this.selectedOptionIndex = index;
      this.dropUserMenu = false;
    }
  }

  public onMenuOptionClicked(name: string): void
  {
    this.registerClickMenuEvent(name);
    this.hideMenu();
  }

  public hideMenu(): void
  {
    this.selectedOptionIndex = -1;
    this.showMenuPanel = false;

    const shown = this.mobileMenu.nativeElement.classList.contains('show');

    if (shown)
    {
      this.togglerMobile.nativeElement.click();
    }

    this.setNavBarClass();
  }

  public hideMenuFromTitle(): void
  {
    if (this.menuOptions[this.selectedOptionIndex].submenus === undefined)
    {
      this.hideMenu();
    }
  }

  public toggleMobileMenu(): void
  {
    this.togglerMobile.nativeElement.click();

    this.showMenuPanel = !this.showMenuPanel;

    if (this.selectedOptionIndex != -1)
    {
      this.selectedOptionIndex = -1;
    }

    this.setNavBarClass();
  }

  public onControlPanelClicked(): void
  {
    if (this.isLogedIn)
    {
      GtmTrackingService.controlPanelEvent(EGtmEvent.ControlPanel, this.translateService.getElement('Panel de control'));
      this.goToExternalControlPanel();
    }
  }

  public goToPopUpCart(): void
  {
    this.showShoppingCart = true;
    BroadcastService.Instance.broadcast(EAppEventName.OnOpenCartPopup, 0);
  }

  public closePopupCart(): void
  {
    this.showShoppingCart = false;
    BroadcastService.Instance.broadcast(EAppEventName.OnCloseCartPopup, 0);
  }

  public goToExternalControlPanel(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}jsf/control_panel/start.jsf`);
  }

  public onMyProfileClicked(): void
  {
    GtmTrackingService.controlPanelEvent(EGtmEvent.ControlPanel, this.translateService.getElement('Mi Perfil'));
    this.changeLocationHref(`${Environment.PortalAkkyURL}jsf/user_abc/account/update.jsf`);
  }

  public redirectToMessages(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}jsf/email/show_emails.jsf`);
  }

  public changeLocationHref(url: string): void
  {
    if (isPlatformBrowser(this.platformId) && url)
    {
      window.location.href = url;
    }
  }

  public openBlog(parameter: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(ReferenceConst.BlogAkkyUrl + parameter, '_blank');
    }
  }

  public goToHome(): void
  {
    this.router.navigate(['/']);
  }

  public identify(_index: unknown, item: any): any
  {
    return item;
  }

  private getBlogs(): void
  {
    this.homeService.getBlogs().subscribe({
      next: (result: INavBarBlogModel) => this.blogs = result
    });
  }
}
