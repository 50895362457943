<div class="container-nosotros">
  <h1 class="home-title" [innerHTML]="_title | safe: 'html'"></h1>
  <div class="nosotros-cards">
    <div class="card-text">
      <div class="home-subtitle" [innerHTML]="_title_cards | safe: 'html'"></div>
      <br>
      <div class="cards-subtitle" [innerHTML]="_subtitle  | safe: 'html'"></div>
    </div>
    <app-about-card class="card-data" *ngFor="let c of cards; let i = index" [data]="c" [varid]="'span_' + i">
    </app-about-card>
  </div>
  <div class="home-description" [innerHTML]="_description | safe: 'html'"></div>
</div>
