import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent
{
  public _title: string = "";
  public _padding: boolean = false;

  @Input() public set title(value: any)
  {
    if (value)
    {
      this._title = value;
    }
  }

  @Input() public set padding(value: any)
  {
    this._padding = value;
  }
}
