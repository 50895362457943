import { isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from "@angular/router";
import { EventLocationSite } from "@core-constants/gtm-const";
import { NavigationConst } from "@core-constants/navigation.const";
import { EAdditionalService, EServiceCode } from "@core-constants/product-service.const";
import { ServicesDisplayDataService } from "@core-data-services/services-display.data-service";
import { ServiceHelper } from "@core-helpers/service-tools.helper";
import { ToggleHelper } from "@core-helpers/toggle.helper";
import { AditionalServiceInfo, IServiceHelper } from "@core-models/aditional-service.model";
import { IBaseServiceBundleComponent, ILanguageComponent, IToggleData } from "@core-models/generic.model";
import { IPlan } from "@core-models/plans.model";
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from "@shared-utils/tools.util";
import { filter } from "rxjs/operators";

@Component({
  selector: 'app-wordpress-service',
  templateUrl: './wordpress-service.component.html',
  styleUrls: ['./wordpress-service.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WordPressServiceComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.wordPressServices;
  public _serviceID: EAdditionalService = EAdditionalService.WebsiteBuilderWordPress;
  public _serivceData: AditionalServiceInfo = undefined;

  public services: IServiceHelper[] = [];
  public plans: IPlan[] = [];

  public locationServicePage: string = "";

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef
  )
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: any) => this.router.url.startsWith(x.path));

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
      next: () =>
      {
        if (this.router.url == "/servicios/wordpress")
        {
          this.router.navigate([NavigationConst.SiteRoutes.WordPressSites]);
        }
      }
    });
  }


  public get selectedService(): any
  {
    const translation = this._serivceData?.translation;
    let obj: any = undefined;

    if (translation)
    {
      obj =
      {
        title: translation.titulo_seccion1,
        desciption: translation.titulo_seccion1,
        bannerImg: translation.imagen_banner?.id,
        bannerText: translation.texto_banner
      };
    }
    return obj;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });

    this.toggleHelper.onToggleChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (index) =>
      {
        this.onSelectTab(index);
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    this.services = [];
    const servicesNames = this.dataToggle.map(item => item.service);

    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: ({ response }: any) =>
      {
        this.services = response.map(item => ({ serviceData: item.service, plans: item.plans }));
        this.orderServices();
        this.onSelectTab(this.toggleHelper.changeToggle);
        this.addViewItemListEvent(response.plans);
      }
    });
  }

  public orderServices(): void
  {
    const newArray: any[] = [];

    this.dataToggle.forEach((x: any) =>
    {
      const service = this.services.find(s => s.serviceData.clave_interna == x.service);
      newArray.push(service);
    });

    this.services = newArray;
  }

  public onSelectTab(index: number): void
  {
    if (!Tools.isNullOrEmpty(this.services))
    {
      const serviceCode: string = this.dataToggle[index].service;

      let selectedService = this.services.find((x: any) => x.serviceData?.clave_interna === serviceCode);

      if (!selectedService)
      {
        selectedService = this.services[0];
      }

      if (serviceCode === EServiceCode.WordPressStore)
      {
        this.locationServicePage = EventLocationSite.WordpressStorePage;
      }
      else if (serviceCode === EServiceCode.WordPressSites)
      {
        this.locationServicePage = EventLocationSite.WordpressPage;
      }

      this._serivceData = selectedService?.serviceData;
      this.plans = selectedService?.plans;
    }

    this.ref.detectChanges(); // forces change detection to run
  }

  public addViewItemListEvent(items: IPlan[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
