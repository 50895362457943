
<div class="tld-container heigth-container-tld-movil p-3 pt-3 card card-panel-tld"
     [ngStyle]="{ 'border-color' : panelColorBorder === '' ? 'black' : panelColorBorder , 'background-color' : panelColorBackground === '' ? 'white' : panelColorBackground }">

    <!-- Offer icon -->
    <span *ngIf="tldDiscount" class="fas d-inline-block i-discount discount-icon"></span>

    <!-- Title & Description Tld -->
    <div class="w-100" [innerHTML]="tldName | safe: 'html'"></div>

    <!-- Line Decoration -->
    <div [ngClass]="{ 'panel-row' : tldRow , 'empty-row' : !tldRow}"
         [ngStyle]=" {'background-color' : panelColorRow }"
         [innerHTML]="textRow | safe: 'html'"></div>

    <!-- Price Tld -->
    <div class="p-0 mt-auto">
        <div class="d-flex align-items-end w-100 p-1">
            <div class="w-100" [innerHTML]="price | safe: 'html'"></div>
        </div>
    </div>
</div>
