<ng-container *ngIf="id !== undefined">
  <div id="{{ 'service-banner-'+ id }}" class="container-fluid basic-banner p-0"
       [style.backgroundImage]="bannerInfo.background | directus:'style'">
    <div class="container basic-content-service row mx-auto">
      <div id="{{'service-title-' + id}}" class="col-12 col-sm-7 col-md-7">
        <h2 class="grid-container" *ngIf="bannerInfo.text" [innerHTML]="bannerInfo.text | safe: 'html'"></h2>
      </div>
      <div id="{{'service-img-container-' + id}}"
           class="position-relative service-img-container col-12 col-sm-5 col-md-5">
        <img *ngIf="bannerInfo.image !== null" class="mx-auto align-self-center img-position" loading="lazy"
             alt="Service banner" [src]="bannerInfo.image | directus" data-src="{{bannerInfo.image | directus}}">
      </div>
    </div>
  </div>

  <!-- Subtite and service description-->
  <div id="{{ 'service-content-'+ id }}" class="container px-4 px-sm-3 px-md-5 my-5" *ngIf="serviceInfo">
    <div [innerHTML]="serviceInfo.subtitle | safe: 'html'"></div>
    <h1 [innerHTML]="serviceInfo.title | safe: 'html'"></h1>
    <div class="fw-medium pt-3" [innerHTML]="serviceInfo.description | safe: 'html'"></div>
  </div>

  <!-- dynamic content -->
  <div class="container pt-4 my-3 px-3 px-sm-3 px-md-5" id="{{'akky-service-plans-' + id}}">
    <ng-content />
    <ng-template [ngTemplateOutlet]="dynamicContentTemplate"></ng-template>
  </div>

  <!--  Comparative table -->
  <div class="container px-3 px-sm-3 px-md-5" *ngIf="showComparativeSection">
    <div class="text-center mb-5">
      <button class="btn btn-comparative text-uppercase" (click)="showComparativeTable = !showComparativeTable">
        {{ 'Ver tabla comparativa' | translate }}
      </button>
    </div>

    <div class="table-comparative-container mb-5" *ngIf="showComparativeTable">
      <table class="table-comparative">
        <thead>
          <tr>
            <!-- Headers Desktop / Mobile-->
            <th class="hidden-header"></th>
            <th class="text-center comparative-plan-header px-2" *ngFor="let header of dataComparative.headers">{{ header.title | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of dataComparative.rows">
            <tr>
              <!-- Spec Header version Mobile-->
              <th [attr.colspan]="comparativeTableColumnsNumber" class="row-header-col px-4 pt-2 table-header-color">
                <p class="comparative-title font-weight-bold">{{ row.cells[0].title | translate }}</p>
                <p class="comparative-description fw-medium">{{ row.cells[0].description | translate }}</p>
              </th>
            </tr>
            <tr [ngClass]="{'table-row-color' : row.hasBackground }">
              <!-- Rows header only version Desktop and cells version Desktop/ Mobile-->
              <th class="hidden-header-tb px-4 pt-2">
                <p class="comparative-title font-weight-bold">{{ row.cells[0].title | translate  }}</p>
                <p class="comparative-description fw-medium">{{ row.cells[0].description | translate }}</p>
              </th>
              <td class="comparative-title font-weight-bold text-center h-cells" *ngFor="let header of dataComparative.headers; index as i">{{ row.cells[i + 1].title | translate  }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

 <!-- Service Panel -->
<div *ngIf="comparativeTable" class="container py-4 px-2 px-sm-3 px-md-5 my-5 my-md-2 d-flex justify-content-center">
  <div class="d-flex flex-column flex-md-row rounded-4 border border-dark-subtle service-features">
    <div class="border-dark-subtle d-flex align-items-center panel-title">
      <span class="text-start font-weight-extra-bold">{{comparativeTable.title | translate}}</span>
    </div>
    <div *ngFor="let column of comparativeTable.comparativeTableItems" class="d-flex panel-item flex-column border-dark-subtle">
      <!-- TODO: Lograr que conviva el icon con el titulo -->
      <p *ngIf="column.mainText" class="panel-item-title text-start font-weight-bold" [innerHTML]="column.mainText | translate | textcolor: column.highlightedText : column.highlightedCssClasses | safe: 'html'">
      </p>
      <i *ngIf="column.icon" class="mb-3" [ngClass]="column.icon"></i>
      <span class="panel-description fw-medium text-start">{{column.description | translate }}</span>
    </div>
  </div>
</div>

  <!-- content Addon-->
  <div *ngIf="addonInfo" class="container my-3 px-2 px-sm-3 px-md-5">
    <div class="text-start text-md-center" [innerHTML]="addonInfo.title | safe: 'html'"></div>
    <div class="text-start text-md-center" [innerHTML]="addonInfo.description | safe: 'html'"></div>
  </div>

  <!-- complementary services -->
  <div *ngIf="complementaryServices && complementaryServices.length > 0"
       class="container py-2 px-2 px-sm-3 px-md-5 my-2 my-md-2">
    <div class="text-start text-md-center" [innerHTML]="titleComplementaryServices | safe: 'html'"></div>
    <div id="{{'service-complementary-services-' + id}}"
         class="row my-5 justify-content-center">
      <app-complementary-services class="col-auto" *ngFor="let cs of complementaryServices" [complementaryServices]="cs"></app-complementary-services>
    </div>
  </div>

  <!-- specs -->
  <div *ngIf="section1" id="{{'service-specs-'+ id}}"
       class="services-specs-container container px-2 px-sm-3 px-md-5 d-block pb-5" [ngClass]="{'d-none' : !isFeatures}">
    <div class="row" *ngIf="showSpecsTitle">
      {{section1.img_section}}
      <div class="col-12" [col-6]="section1.img_section1" [innerHTML]="section1.title | safe: 'html'"></div>
      <div class="col-6" *ngIf="section1.img_section1">
        <img alt="image section 1" loading="lazy" data-src="{{section1.img_section1 | directus}}"
             [src]="section1.img_section | directus">
      </div>
    </div>
    <ng-container *ngFor="let d of section1.data; let i = index">
      <ng-container *ngTemplateOutlet="specTemplate; context: {title: d.title, img: d.image, description: d.description, reverse: getReverse(i)}"></ng-container>
    </ng-container>
  </div>

<!-- spect item template-->
<ng-template #specTemplate let-img="img" let-description="description" let-title="title" let-reverse="reverse">
    <div class="services-specs-row row d-flex my-4" *ngIf="showSpecs && title">

        <div [ngClass]="!reverse ? 'col-12 col-md-10 col-lg-5 mx-auto order-1 order-md-1 d-flex cont-spect-item' : 'col-12 col-md-10 col-lg-5 mx-auto order-2 order-md-1 d-flex'">
            <div class="mx-auto my-auto d-flex align-items-center">
                <img *ngIf="!reverse" class="bounded-img" alt="spec image" loading="lazy" [src]="img | directus"
                     data-src="{{ img | directus }}" [ngClass]="{'show': showBorder}">
                <div *ngIf="reverse" class="mx-auto">
                    <div [innerHTML]="title | safe: 'html'"></div>
                    <div [innerHTML]="description | safe: 'html'"></div>
                </div>
            </div>
        </div>

        <div [ngClass]="!reverse ? 'col-12 col-md-10 col-lg-5 mx-auto order-2  order-md-1 d-flex' : 'col-12 col-md-10 col-lg-5 mx-auto order-1 order-md-1 d-flex cont-spect-item'">
            <div class="mx-auto my-auto">
                <img *ngIf="reverse" class="bounded-img" loading="lazy" alt="spec image" [src]="img | directus"
                     data-src="{{ img | directus }}">
                <div *ngIf="!reverse" [innerHTML]="title | safe: 'html'"></div>
                <div *ngIf="!reverse" [innerHTML]="description | safe: 'html'"></div>
            </div>
        </div>
    </div>
</ng-template>
