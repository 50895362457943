import { Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '@shared-services/assets.service';

@Pipe({
  name: 'blobstorage',
  pure: false
})
export class BlobStoragePipe implements PipeTransform
{
  constructor(private assetsService: AssetsService) { }

  public transform(value: string, type: string): string
  {
    let res = "";

    if (value)
    {
      res = this.assetsService.getAssetFromBlob(value);

      if (type == 'style')
      {
        res = 'url(' + res + ')';
      }
    }

    return res;
  }
}
