import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReferenceConst } from '@core-constants/reference.const';
import { SettingsManager } from '@core-managers/settings.manager';
import { Environment } from '@environments';
import { TranslateService } from '@shared-services/translate.service';

declare function goToChatBot(): void;
declare function loadChatScripts(): void;
declare const hideModal: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent
{
  public chatBtnId: string = ReferenceConst.ChatButtonId;
  public whatsappImage: string = "/icons/Whatsapp_home.svg";
  public whatsappurl = ReferenceConst.WhatsAppUrl;
  public showPrechat: boolean = true;
  private ariaLabelChatButton: string = "Iniciar chat";

  @ViewChild(ReferenceConst.ChatButtonId) public buttonChat: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    public settingsManager: SettingsManager) { }

  public get isChatEnabled(): boolean
  {
    return this.settingsManager.isChatIconEnabled === true;
  }

  public get isWhatsAppEnabled(): boolean
  {
    return this.settingsManager.isWhatsAppIconEnabled === true;
  }

  public get environmentChat(): boolean
  {
    return Environment.ChatBot;
  }

  public get urlSalesForce(): string
  {
    return Environment.UrlSalesForce;
  }

  public get urlChatBot(): string
  {
    return Environment.UrlChatBot;
  }

  public get urlAvatarChatBot(): string
  {
    return Environment.UrlAvatarChatBot;
  }

  public get baseLiveAgentContentURL(): string
  {
    return Environment.BaseLiveAgentContentURL;
  }

  public get salesForceId(): string
  {
    return Environment.SalesForceId;
  }

  public get deploymentId(): string
  {
    return Environment.DeploymentId;
  }

  public get buttonId(): string
  {
    return Environment.ButtonId;
  }

  public get baseLiveAgentURL(): string
  {
    return Environment.BaseLiveAgentURL;
  }

  public get eswLiveAgentDevName(): string
  {
    return Environment.EswLiveAgentDevName;
  }

  public get ariaLabelForChatButton(): string
  {
    return this.translateService.getElement(this.ariaLabelChatButton);
  }

  public openChat(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      if (this.environmentChat)
      {
        goToChatBot();
      }

      else
      {
        loadChatScripts();
      }
    }
  }

  public onClosePrechat(): void
  {
    hideModal();
    this.buttonChat.nativeElement.style.display = "block";
  }
}
