<ng-container *ngIf="isBasePage; then baseAboutTemplate else aboutOthersPagesTemplate"></ng-container>

<!-- about -->
<ng-template #baseAboutTemplate>
  <div id="aboutUs-banner" *ngIf="backgroundImage !== ''" class="container-fluid px-0 background-bg"
    [style.backgroundImage]="backgroundImage | blobstorage:'style' ">
    <div class="container row mx-auto basic-content">
      <div id="aboutUs-title-container" class="d-flex col-12 col-md-6">
        <h2 class="msb white my-auto fs-xxl">{{'¡Complementa tu negocio y dale un nuevo valor a tus ideas!' |
          translate}}</h2>
      </div>
      <div id="aboutUs-img-container" class="service-img-container col-12 col-md-6 d-none d-sm-block">
        <div>
          <img loading="lazy" data-src="{{ '/images/about.svg' | blobstorage }}" alt="About Title"
            src="{{ '/images/about.svg' | blobstorage }}">
        </div>
      </div>
    </div>
  </div>

  <div class="container my-3 mt-5 pt-5 px-2 px-sm-3 px-md-5">
    <div id="about-container" class="tab-view d-flex justify-content-center border-bottom">
      <div class="text-center p-2 px-3 clickable" (click)="onSelectTab(item, i)"
        *ngFor="let item of tabs; let i = index; trackBy:identify" [class.selected-option]="selectedIndex === i">
        <span class="text-uppercase">{{ item.title | translate }}</span>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</ng-template>

<!-- others -->
<ng-template #aboutOthersPagesTemplate>
  <router-outlet></router-outlet>
</ng-template>
