import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISalesForceEmail, IStudenProject, IStudentEmail } from '@core-models/personal-brand.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PersonalBrandDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'salesforce');
  }

  public sendMail(studentEmail: IStudentEmail): Observable<any>
  {
    const subject = this.http.post(this.createUrl("email"), studentEmail).pipe(share());
    this._handleObservableResponse(subject);
    return subject;
  }

  public createPersonalBrand(studenProject: IStudenProject): Observable<any>
  {
    const subject = this.http.post(this.createMicroserviceURL("create"), studenProject).pipe(share());
    this._handleObservableResponse(subject);
    return subject;
  }

  public sendMailWithCoupon(studentEmail: ISalesForceEmail): Observable<any>
  {
    const subject = this.http.post(this.createUrl("coupon"), studentEmail).pipe(share());
    this._handleObservableResponse(subject);
    return subject;
  }
}
