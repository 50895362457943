<div class="card-icon">
  <img loading="lazy" [alt]="cardData.icon.title" [src]="cardData.icon.id | directus" data-src="{{cardData.icon.id | directus}}">
</div>
<span class="card-data-type" [innerHTML]="_translations.titulo | safe: 'html'"></span>
<br>
<span #spanreference id="spanreference" class="card-font" [innerHTML]="cardData.prefijo | safe: 'html'"></span>
<span [id]="_varid"
      class="card-font">0</span>
<span class="card-data-text" [innerHTML]="_translations.texto | safe: 'html'"></span>
<br>
<span class="card-data-description">{{_translations.descripcion}}</span>
