import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { IToggleData } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-toggle-service',
  templateUrl: './tab-toggle.component.html',
  styleUrls: ['./tab-toggle.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TabToggleComponent implements OnInit
{
  @Input() public data: IToggleData[] = [];
  public selectedIndex: number = 0;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private ref: ChangeDetectorRef,
    protected translateService: TranslateService,
    private route: ActivatedRoute,
    private toggleHelper: ToggleHelper)
  {
    this.selectedIndex = this.toggleHelper.changeToggle;
    this.registerRouteEventListeners();
  }

  public registerRouteEventListeners(): void
  {
    this.route.params.subscribe({
      next: (val: any) =>
      {
        const index = this.data.findIndex(data => data.path.endsWith(val.type));
        if (index != -1)
        {
          this.onSelectTab(index);
        }
      }
    });
  }

  public ngOnInit(): void
  {
    //Remplaza otros parametros puestos en la URL
    if (isPlatformBrowser(this.platformId))
    {
      window.history.replaceState({}, '', this.data[this.toggleHelper.changeToggle].path);
    }
  }

  public onSelectTab(index: number): void
  {
    this.selectedIndex = index;
    this.toggleHelper.changeToggle = index;

    if (isPlatformBrowser(this.platformId))
    {
      window.history.pushState({}, '', this.data[index].path);
    }
    this.ref.detectChanges();
  }
}
