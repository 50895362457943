
export namespace GtmConst
{
  export const TrackingMethodEmail: string = "email";
  export const TrackingBrand: string = "Akky";
}

export const enum EGtmSection
{
  Home = "akky_home",
  Market = "akky_market",
  Plans = "akky_plans",
  Domains = "akky_domains",
  RecommendedTLD = "akky_recommended_domains",
  Deals = "akky_deals"
}

export const enum EGtmEvent
{
  ViewSearchResults = 'ev_view_search_results',
  Signup = 'ev_signup',
  ViewItemList = "view_item_list",
  RemoveFromCart = 'remove_from_cart',
  ViewCart = "view_cart",
  Login = 'ev_login',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  Menu = 'ev_menu',
  ControlPanel = 'ev_control_panel',
  SelectPromotion = 'select_promotion',
  ErrorMessage = 'error_message'
}

export const enum EventLocationSite
{
  GeneralSuggestion = "sugerencias_generales",
  IndividualSuggestion = "sugerencias_individuales",
  RecommendationSuggestion = "sugerencias_pantalla_intermedia",
  HostingPage = "pagina_producto_hosting",
  WebsitePage = "pagina_producto_sitios_web",
  OnlineStorePage = "pagina_producto_tienda_linea",
  WordpressPage = "pagina_producto_wordpress",
  WordpressStorePage = "pagina_producto_tienda_wordpress",
  EmailOxPage = "pagina_producto_ox",
  EmailPage = "pagina_producto_rackspace",
  CertificateSslPage = "pagina_producto_ssl",
  MarketingDigitalPage = "pagina_producto_marketing_digital",
  MicrosoftPage = "pagina_producto_microsoft",
  HomeCard = "tarjetas_home",
  SearchDomain = "resultado_busqueda_dominios",
  SearchDomainSuggestion = "alternativa_busqueda_dominios",
  SearchDomainIASuggestion = "alternativa_busqueda_dominios_IA",
  FeaturedDeal = "promociones_ofertas_destacadas",
  FlashDeal = "promociones_ofertas_flash",
  FinderDeal = "promociones_buscador_ofertas",
  AddedByUrl = "paquetes_desde_url"
}
