import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { INavBarBlogModel } from "@core-models/navbar-blog.model";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PromoBannerDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'promobanner');
  }

  public getBanner(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode
    };

    const subject = this.http.get<INavBarBlogModel>(this.createUrl(""), queryDef.getRequestConfig());

    return subject;
  }
}