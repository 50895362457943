<div class="card h-100 border-0">
  <div class="row card-body">
    <div class="service-image">
      <img [src]="img | directus" width="49" height="48.88" data-src="{{ img | directus}}" loading="lazy"
        class="img-fluid" [alt]="title">
    </div>
    <div class="service-content">
      <div class="row">
        <div *ngIf="title" class="col-12 text-uppercase" [innerHTML]="title | safe: 'html'"></div>
        <div *ngIf="description" class="col-12" [innerHTML]="description | safe: 'html'"></div>
        <div *ngIf="price" class="col-12" [innerHTML]="price | safe: 'html'"></div>
      </div>
    </div>
  </div>
  <div class="card-footer bg-transparent border-0 d-flex justify-content-center align-items-center" *ngIf="button">
    <button class="btn" [ngClass]="action === 'cart' ? 'btn-primary' : 'btn-secondary'" [attr.data-gtm-key]="gtmKey"
      id="{{ gtmKey }}" (click)=" onButtonClick() ">{{button}}</button>
  </div>
</div>