import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class RegexValidators
{
  public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn
  {
    return (control: AbstractControl): { [key: string]: any } =>
    {
      if (!control.value)
      {
        return null;
      }

      const isValid = regex.test(control.value);

      return isValid ? null : error;
    };
  }

  public static patternsValidator(regex: RegExp[], error: ValidationErrors): ValidatorFn
  {
    return (control: AbstractControl): { [key: string]: any } =>
    {
      if (!control.value)
      {
        return null;
      }

      const isValid = regex.find(rgx => rgx.test(control.value)) != undefined;

      return isValid ? null : error;
    };
  }
}

