import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenManager } from "@core-managers/token.manager";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable , of} from "rxjs";

@Injectable({ providedIn: 'root' })
export class PurchaseSuggestionDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    private tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'purchasesuggestion');
  }

  private getCartId(): string
  {
    let cartIdResult = '';
    const cartId = this.tokenManager.getCartCookie();

    if (cartId !== null)
    {
      cartIdResult = cartId;
    }

    return cartIdResult;
  }

  public getPurchaseSuggestionsFromAdditionalServices(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const cartId = this.getCartId();
    const token = this.tokenManager.getToken();
    if (cartId)
    {
      const subject = this.http.get(this.createMicroserviceURL("additional-services", cartId), queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));
      return subject;
    }
    return of();
  }

}
