<div class="my-3">
  <div class="d-none d-md-block">
    <div class="row justify-content-evenly d-flex align-items-stretch">
      <div *ngFor="let plan of plans; let l = last" class="col-12 col-sm-6" [class.plan-item-size]="plans.length === 2"
        [class.col-lg-4]="plans.length > 2" [class.col-sm-12]="l && plans.length > 2">
        <app-plan-item [plan]="plan" [imgOnBottom]="imgOnBottom" [location]="locationInit"></app-plan-item>
      </div>
    </div>
  </div>

  <div class="d-block d-md-none pb-5">
    <div id="carousel-plan-service" class="carousel generic-carousel slide" data-bs-ride="carousel">

      <ol class="carousel-indicators">
        <li data-bs-target="#carousel-plan-service" *ngFor="let slide of plans; let i = index; trackBy:identify"
          [attr.data-bs-slide-to]="i" [attr.aria-label]="i + 1" [ngClass]="{'active' : i === 0}"></li>
      </ol>

      <div class="carousel-inner">
        <div *ngFor="let plan of plans; index as i; trackBy:identify" class="carousel-item"
          [ngClass]="{'active' : i === 0}" data-bs-interval="10000">
          <div class="row justify-content-center">
            <div class="col-11">
              <button class="carousel-control-prev darkGray left" type="button" data-bs-target="#carousel-plan-service"
                data-bs-slide="prev">
                <img loading="lazy" class="fs-xl" data-src="{{ '/icons/Black/chevron-left.svg' | blobstorage }}"
                  alt="Arrow right" src="{{ '/icons/Black/chevron-left.svg' | blobstorage }}">
                <span class="visually-hidden">Previous</span>
              </button>

              <button class="carousel-control-next darkGray right" type="button" data-bs-target="#carousel-plan-service"
                data-bs-slide="next">
                <img loading="lazy" class="fs-xl" data-src="{{ '/icons/Black/chevron-right.svg' | blobstorage }}"
                  alt="Arrow right" src="{{ '/icons/Black/chevron-right.svg' | blobstorage }}">
                <span class="visually-hidden">Next</span>
              </button>

              <app-plan-item [plan]="plan" [imgOnBottom]="imgOnBottom"></app-plan-item>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
