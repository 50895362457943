import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RegexConst } from '@core-constants/regex.const';
import { UTMParamConst } from '@core-constants/url-param.const';
import { UserAccessRequest } from '@core-models/user-access.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class UTMOperationHelper
{
  constructor(protected cookieService: CookieService,
    private sanitizer: DomSanitizer) { }

  /**
   * Función que se encarga de la revisión y llamada para el guardado de cookies correspondientes a UTMs
   * @param params
   */
  public buildUTMObject(params: any): void
  {
    if (params[UTMParamConst.Source] && params[UTMParamConst.Medium] && params[UTMParamConst.Campaing])
    {
      const storedParams = this.cookieService.get(UTMParamConst.Params);

      if (storedParams)
      {
        const storedParamsObj = JSON.parse(storedParams);

        if (storedParamsObj.utmSource === params[UTMParamConst.Source] &&
          storedParamsObj.utmMedium === params[UTMParamConst.Campaing] &&
          storedParamsObj.utmCampaign === params[UTMParamConst.Campaing])
        {
          return;
        }
      }

      if (this.sanitizeParams(params[UTMParamConst.Source])) 
      {
        return;
      }

      if (this.sanitizeParams(params[UTMParamConst.Medium]))
      {
        return;
      }

      if (this.sanitizeParams(params[UTMParamConst.Campaing]))
      {
        return;
      }

      this.saveUTMObject(params);
    }
  }

  public updateUTMData<T extends UserAccessRequest.IUTMUserProperties>(dto: T): T
  {
    const data: T = { ...dto };

    const utmParams = this.cookieService.get(UTMParamConst.Params);

    if (utmParams)
    {
      const getParamsObj = JSON.parse(utmParams);

      if (getParamsObj.utmSource && getParamsObj.utmMedium && getParamsObj.utmCampaign)
      {
        data.utmSource = getParamsObj.utmSource;
        data.utmMedium = getParamsObj.utmMedium;
        data.utmCampaign = getParamsObj.utmCampaign;
      }
    }

    return data;
  }

  /**
   * Función que almacena el objeto construido en las cookies del navegador y le asigna una fecha de expiración
   */
  public saveUTMObject(params: any): void
  {
    const expireDate = new Date();

    expireDate.setMonth(expireDate.getMonth() + 6);

    const utmParams = { utmSource: params[UTMParamConst.Source], utmMedium: params[UTMParamConst.Campaing], utmCampaign: params[UTMParamConst.Campaing] };

    this.cookieService.set(UTMParamConst.Params, JSON.stringify(utmParams), expireDate);
  }

  public sanitizeParams(utm: string): boolean
  {
    if (RegexConst.SpecialChars.test(utm))
    {
      return true;
    }
    if (utm !== this.sanitizer.sanitize(SecurityContext.HTML, utm)) 
    {
      return true;
    }
    if (utm !== this.sanitizer.sanitize(SecurityContext.STYLE, utm))
    {
      return true;
    }
    if (utm !== this.sanitizer.sanitize(SecurityContext.URL, utm))
    {
      return true;
    }
  }
}
