import { Component, Input } from '@angular/core';
import { IIterations } from '@core-models/generic.model';
import { IPlan } from '@core-models/plans.model';

@Component({
  selector: 'app-service-plans',
  templateUrl: './app-service-plans-list.component.html',
  styleUrls: ['./app-service-plans-list.component.less']
})
export class ServicePlansListComponent implements IIterations
{
  @Input() public plans: IPlan[] = [];
  @Input() public imgOnBottom: boolean = true;
  @Input() public locationInit: string = "";

  public identify(index: any, item: any): any
  {
    return item;
  }
}
