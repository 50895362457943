import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EAppEventName } from '@core-constants/app-events.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { DomainDataService } from '@core-data-services/domains.data-service';
import { FaqCategoriesDataService } from '@core-data-services/faq.data-service';
import { DomainsManager } from '@core-managers/domains.manager';
import { TokenManager } from '@core-managers/token.manager';
import { AccordionModel } from '@core-models/accordion.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { Environment } from '@environments';
import { BroadcastService } from '@shared-services/broadcast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from "rxjs";

@Component({
  selector: 'app-transfer-in-service',
  templateUrl: './transfer-in-service.component.html',
  styleUrls: ['./transfer-in-service.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TransferInServiceComponent implements OnInit, ILanguageComponent, OnDestroy, AfterViewInit
{
  public translation: any = undefined;

  public titleTransfer: string = "";
  public backgroundImage: string = "";
  public titleImage: string = "";
  public placeholder: string = "Ingresa el dominio a transferir";
  public textButton: string = "Transferir";
  public textMultipleSearch: string = "Transferencia múltiple";
  public textSimpleSearch: string = "Transferencia simple";

  public titleSection1: string = "";
  public descriptionSeccion1: string = "";

  public titleSection2: string = "";
  public descriptionSection2: string = "";

  public transfersTitleMult: string = "";
  public faqTitle: string = "";
  public tldrecommendeds: any[] = [];
  public otherTlds: any[] = [];

  public simpleDomainSearchText: string = "";
  public multipleDomainSearchText: string = "";

  public pageTitle: string = "Akky | Transfiere un dominio hacia Akky";

  public faqs: any[];
  public prefix: string = "faq-page-";
  public itemsFaqs: AccordionModel[] = [];

  public isValidForTransfer: boolean = false;
  public msgErrorForTransfer: string = '';
  public domainsTransferText: string = '';
  public searchDomainInputText: string = '';
  public isSingleSearch: boolean = true;

  public isSearchLoading$: Observable<boolean>;
  public errorSearchVisible$: Observable<boolean>;
  public errorSearchEmptyVisible$: Observable<boolean>;
  public isValidForTransfer$: Observable<boolean>;
  public msgErrorForTransfer$: Observable<string>;
  public domainsTransferText$: Observable<string>;

  public searchResultVisible$: Observable<boolean> | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private domainsManager: DomainsManager,
    private domainDataService: DomainDataService,
    public seoService: SEOService,
    public localStorageService: LocalStorageService,
    private tokenManager: TokenManager,
    private faqCategoriesDataService: FaqCategoriesDataService,
    private destroyRef$: DestroyRef
  ) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public ngAfterViewInit(): void
  {
    const searchText = this.localStorageService.getItem(LocalStorageConst.TransferDomains);
    const isMultipleSearch = Boolean(this.localStorageService.getItem(LocalStorageConst.IsMultipleSearch));

    this.domainsManager.setSingleDomainSearchText(searchText);

    if (isMultipleSearch)
    {
      this.domainsManager.searchToggle();
    }
  }

  public registerEventListeners(): void
  {
    this.searchResultVisible$ = this.domainsManager.getSearchResultVisible();

    this.isSearchLoading$ = this.domainsManager.getSearchLoading();
    this.errorSearchEmptyVisible$ = this.domainsManager.getErrorSearchEmptyVisible();
    this.errorSearchVisible$ = this.domainsManager.getErrorSearchVisible();
    this.isValidForTransfer$ = this.domainsManager.getIsValidForTransfer();
    this.msgErrorForTransfer$ = this.domainsManager.getMsgErrorForTransfer();

    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (lang) =>
      {
        this.seoService.updateSeoElements(this.translateService.getElement(this.pageTitle));
        this.getAll(lang);
      }
    });

    this.domainsManager.getIsValidForTransfer()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: boolean) =>
      {
        this.isValidForTransfer = value;
        this.openTransferInExtranetPage();
      }
    });

    this.domainsManager.getMsgErrorForTransfer()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: string) =>
      {
        this.msgErrorForTransfer = value;
      }
    });

    this.domainsManager.getDomainsTransferText()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: string) =>
      {
        this.domainsTransferText = value;
      }
    });

    this.domainsManager.getSigleSearchText()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: string) =>
      {
        this.searchDomainInputText = value;
      }
    });

    this.domainsManager.getMultipleSearchText()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: string) =>
      {
        this.searchDomainInputText = value;
      }
    });

    this.domainsManager.isSingleSearch()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: boolean) =>
      {
        this.isSingleSearch = value;
      }
    });
  }

  public getAll(lang: string = this.translateService.languageCode): void
  {
    this.getTransferDomains(lang);
    this.getFaqsPage(lang);
  }

  public getTransferDomains(lang: string = this.translateService.languageCode): void
  {
    this.domainDataService.getTransferDomains(lang).subscribe({
      next: ({ response }) =>
      {
        if (response && response.traducciones)
        {
          this.backgroundImage = response?.imagen_fondo?.id;
          this.translation = response.traducciones[0];

          this.titleSection1 = this.translation.titulo_seccion1;
          this.descriptionSeccion1 = this.translation.descripcion_seccion1;
          this.titleSection2 = this.translation.titulo_seccion2;
          this.descriptionSection2 = this.translation.descripcion_seccion2;
          this.titleTransfer = this.translation.titulo_transferencia;
          this.transfersTitleMult = this.translation.titulo_transferencia_multiple;
          this.faqTitle = this.translation.titulo_faq;
          this.seoService.updateSeoElements(this.translation?.seo_titulo, this.translation?.seo_descripcion);
        }
      }
    });
  }

  public getFaqsPage(lang: string = this.translateService.languageCode): void
  {
    this.faqCategoriesDataService.getFaqsPage(ReferenceConst.FAQCategoryIdTranfers, lang).subscribe({
      next: (result) =>
      {
        if (result)
        {
          this.faqs = result.response;

          this.itemsFaqs = this.faqs.map(item =>
          {
            return {
              id: item?.id,
              text: item?.traducciones[0]?.texto,
              title: item.traducciones[0]?.titulo
            } as AccordionModel;

          });
        }
      }
    });
  }

  public onMultipleSearch(): void
  {
    const user = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();

    BroadcastService.Instance.broadcast(EAppEventName.OnTransferDomains);

    if (user && token)
    {
      this.domainsManager.isValidForTransferMultipleDomains();
    }
    else
    {
      this.localStorageService.setItem(LocalStorageConst.TransferDomains, this.searchDomainInputText);
      BroadcastService.Instance.broadcast(EAppEventName.OnOpenLogin);
    }
  }

  public onSingleSearch(): void
  {
    const user = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();
    BroadcastService.Instance.broadcast(EAppEventName.OnTransferDomains);


    if (user && token)
    {
      this.domainsManager.isValidForTransferSingleDomain();
    }
    else
    {
      this.localStorageService.setItem(LocalStorageConst.TransferDomains, this.searchDomainInputText);
      BroadcastService.Instance.broadcast(EAppEventName.OnOpenLogin);
    }

  }

  public onSearchChangeMode(): void
  {
    if (this.isSingleSearch)
    {
      this.localStorageService.removeItem(LocalStorageConst.IsMultipleSearch);
    }
    else
    {
      this.localStorageService.setItem(LocalStorageConst.IsMultipleSearch, !this.isSingleSearch);
    }
  }

  public openTransferInExtranetPage(): void
  {
    if (this.isValidForTransfer)
    {
      this.localStorageService.removeItem(LocalStorageConst.TransferDomains);
      this.localStorageService.removeItem(LocalStorageConst.IsMultipleSearch);

      if (isPlatformBrowser(this.platformId))
      {
        window.location.href = `${Environment.PortalAkkyURL}jsf/transfers/transfers_request/transfer_request.jsf?domainsTransfer=` + encodeURIComponent(this.domainsTransferText);
      }
    }
  }

  public ngOnDestroy(): void
  {
    this.isSearchLoading$ = undefined;
    this.errorSearchVisible$ = undefined;
    this.errorSearchEmptyVisible$ = undefined;
    this.isValidForTransfer$ = undefined;
    this.msgErrorForTransfer$ = undefined;
    this.domainsTransferText$ = undefined;

    this.domainsManager.restartDomainsState();
  }
}
