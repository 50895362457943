import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClickActions } from '@core-constants/action-type.const';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { CountdownHelper } from '@core-helpers/countdown.helper';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { IDealItem } from '@core-models/deals.model';
import { ICupon, IServicesPackage } from '@core-models/service-package.model';

@Component({
  selector: 'app-flash-deals',
  templateUrl: './flash-deals.component.html',
  styleUrls: ['./flash-deals.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashDealsComponent implements OnInit, OnChanges
{
  @Input() public title: string = "";
  @Input() public description: string = "";
  @Input() public dealItem: IDealItem | undefined = undefined;

  public comboServices: IServicesPackage;
  public countdown: string = '';
  public showCoupon: boolean = false; 

  constructor(@Inject(PLATFORM_ID) private platformId,
    private countdownHelper: CountdownHelper,
    private ref: ChangeDetectorRef,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    private destroyRef$: DestroyRef) { }

  public get buttonText(): string
  {
    return this.dealItem.buttonAction === ClickActions.Types.AddToCart ? 'Agregar al Carrito' : 'Conoce más';
  }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.countdownHelper.getCountdownToEndOfDay()
        .pipe(takeUntilDestroyed(this.destroyRef$))
        .subscribe({
          next: (time: string) =>
          {
            this.countdown = time;
            this.ref.detectChanges();
          }
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.dealItemn)
    {
      this.showCoupon = this.dealItem.pakageToAdd?.cupon?.length > 0;
    }
  }

  public onDealClick(): void
  {
    this.dealItem.buttonAction === ClickActions.Types.AddToCart ? this.getServicesPackage() : this.openUrl();
  }

  private openUrl(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(this.dealItem.url, '_blank');
    }
  }

  private getServicesPackage(): void
  {
    this.servicesDataService.getPackageById(this.dealItem.pakageToAdd.id).subscribe({
      next: (response: ICupon) =>
      {
        if (response)
        {
          this.promotionHelper.addToCart(response, EGtmSection.Deals, EventLocationSite.FlashDeal);
        }
      }
    });
  }
}
