import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EBannerAction, EDealsDocument } from '@core-constants/deal.const';
import { DealsDataService } from '@core-data-services/deals.data-service';
import { ICarousel, IDeal, IDealItem, IDealsResponse, IDealTranslation } from '@core-models/deals.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealsComponent implements OnInit, ILanguageComponent
{
  public flashDeal: IDealItem | undefined = undefined;

  public backgroundColor: string = "";
  public bannerImageDesktop: string = "";
  public bannerImageMobile: string = "";
  public bannerAction: number = EBannerAction.None;

  public carouselItems: ICarousel[];
  public dealDetail: IDeal;
  public detalItems: IDealItem[];
  public dealTranslation: IDealTranslation;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    protected dealsDataService: DealsDataService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private destroyRef$: DestroyRef
  ) { }

  public get isDealClickable(): boolean
  {
    return this.bannerAction !== EBannerAction.None;
  }

  public get termsConditionsPDF(): string
  {
    return EDealsDocument.TermsConditions;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.getBundle();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getBundle();
      }
    });
  }

  private getBundle(): void
  {
    this.dealsDataService.getBundle().subscribe({
      next: (response: IDealsResponse) =>
      {
        this.setDealInfo(response);
        this.ref.detectChanges();
      }
    });
  }

  private setDealInfo(data: IDealsResponse): void
  {
    if (data)
    {
      this.carouselItems = data.carouselItems;

      this.detalItems = data.items;
      this.flashDeal = this.detalItems?.find((item: IDealItem) => item.flash);

      if (data.deal)
      {
        this.backgroundColor = data.deal.backgroundColor;
        this.dealDetail = data.deal;
        this.dealTranslation = data.deal.translations[0];
        this.bannerAction = data.deal.bannerAction;

        this.bannerImageDesktop = this.dealTranslation?.banner.id;
        this.bannerImageMobile = this.dealTranslation?.bannerMovil.id;
      }
    }
  }

  public onDealClicked(): void
  {
    switch (this.bannerAction)
    {
      case EBannerAction.None:
        return;
      case EBannerAction.Scroll:
        this.scrollToElement(this.dealDetail.scrollItem);
        break;
      case EBannerAction.OpenUrl:
        this.openUrlInNewTab(this.dealDetail.url);
        break;
    }
  }

  private scrollToElement(selector: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const element = this.renderer.selectRootElement(selector, true);
      if (element)
      {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  private openUrlInNewTab(url: string): void
  {
    if (isPlatformBrowser(this.platformId) && url)
    {
      window.open(url, '_blank');
    }
  }
}
