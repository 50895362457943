<div class="suggestion-card--upgrade row p-0 m-0"
     [class.active]="!isUpgradeSelected">
    <div class="col-12 d-flex flex-row my-2 align-items-center justify-content-between p-0">
        <div class="d-flex align-items-center gap-2 mb-2 mb-md-0 p-0">
            <span class="clickable icon pe-4"
                  [ngClass]="!isAdded ? 'i-radio-on' : 'i-radio-off'"
                  (click)="onUpgradeItem(false)"></span>
            <span *ngIf="productTitle"
                  class="fw-medium clickable"
                  [ngClass]="getTitleClass(productTitle)"
                  (click)="onUpgradeItem(false)">{{productTitle}}</span>
        </div>
        <div class="d-flex justify-content-end gap-2 p-0"
             [ngClass]="getContainerClass(productTitle,productLabel, productFinalAmount | currency)">
            <span *ngIf="productLabel"
                  class="offer-radius bg-aqua white fs-xs px-2 py-1 ms-0 ms-md-3"> {{productLabel}} </span>
            <span *ngIf="isAdded"
                  class="price fw-bold fs-m">{{ productFinalAmount | currency }} MXN</span>
        </div>
    </div>
</div>

<div class="suggestion-card--upgrade row p-0 m-0"
     [class.active]="isUpgradeSelected">
    <div class="col-12 d-flex flex-row my-2 align-items-center justify-content-between p-0">
        <div class="d-flex align-items-center gap-2 mb-2 mb-md-0 p-0">
            <span class="clickable icon pe-4"
                  [ngClass]="isAdded ? 'i-radio-on' : 'i-radio-off'"
                  (click)="onUpgradeItem(true)"></span>
            <span *ngIf="title"
                  class="fw-medium clickable"
                  [ngClass]="getTitleClass(title)"
                  (click)="onUpgradeItem(true)">{{title}}</span>
        </div>
        <div class="d-flex justify-content-end gap-2 p-0"
             [ngClass]="getContainerClass(title,label, productFinalAmount | currency)">
            <span *ngIf="label"
                  class="offer-radius bg-aqua white fs-xs px-2 py-1 ms-0 ms-md-3 d-flex">{{label}}</span>
            <span *ngIf="!isAdded"
                  class="price fw-bold fs-m"> + {{ upgradeDifferenceFinalAmount | currency }} MXN</span>
        </div>
    </div>
</div>