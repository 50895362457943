<ng-container *ngIf="isSingleSearch$ | async as isSingleSearch; else multipleSearchTemplate">
    <form novalidate (ngSubmit)="searchSimpleDomain()"
        class="home-bg-searchbox d-flex justify-content-between py-1-search-domain">
        <input id="searchDomainsInput" #searchDomainsInput [ngModel]="simpleDomainSearchText$ | async"
            (ngModelChange)="setSingleDomainSearchText()" name="singleDomainInput" type="text"
            class="ms-3 me-1 domain-area-to-search" placeholder="{{placeHolderInput | translate}}" role="textbox"
            aria-disabled="false" aria-readonly="false">
        <button class="btn btn-primary pt-3 msb px-2 px-md-4 mx-1 search-button" type="submit"
            [attr.aria-label]="ariaLabelForSearchButton">
            <span class="fas i-search d-inline-block d-md-none fs-l" aria-hidden="true"></span>
            <span class="d-none d-md-block">{{textButton | translate}}</span>
        </button>
    </form>

    <div class="mt-2 fw-medium marginSearchDomain fontSearchDomain row">
        <div class="col-12 col-lg-8 text-center font-weight-bold text-lg-start">
            {{'¿Ya cuentas con un dominio?' | translate}}
            <a class="text-underline text-black" href="{{ transferDomain }}">{{'Transfiérelo' | translate}}</a>
        </div>
        <div class="float-end col-12 col-lg-4 text-center text-lg-end">
            <span class="clickable text-underline home-bg-search-multiple font-weight-bold" (click)="searchToggle()">
                {{textMultipleSearch | translate}}
            </span>
        </div>
    </div>
</ng-container>

<ng-template #multipleSearchTemplate>

    <div class="home-bg-searchbox-multiple d-flex justify-content-between py-1">
        <textarea [ngModel]="multipleDomainSearchText$ | async " (ngModelChange)="setMultipleDomainSearchText($event)"
            name="singleDomainTextArea" class="ms-3 me-1 domain-area-to-search-multiple" rows="10" cols="100"
            placeholder="{{placeHolderInput | translate}}"></textarea>
    </div>
    <div class="row mt-2 multiple-options-container">
        <div class="col-8 fw-bold text-start home-bg-search-multiple margin-transfer">
            <div>
                {{'¿Ya cuentas con un dominio?' | translate}}
                <a class="text-underline text-black" href="{{ transferDomain }}">{{'Transfiérelo' | translate}}</a>
            </div>
            <div class="clickable text-underline" (click)="searchToggle()">
                {{textSimpleSearch | translate}}
            </div>
        </div>

        <div class="col-4 text-end">
            <button class="btn btn-primary msb px-4 mx-1" (click)="searchMultipleDomains()">
                {{textButton | translate}}
            </button>
        </div>
    </div>
</ng-template>
