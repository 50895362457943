import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SettingsManager } from '@core-managers/settings.manager';
import { GroupedShoppingCart } from '@core-models/shopping-cart-items.model';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantitySelectorComponent implements OnInit, OnDestroy
{
  private _defaultDebounceTime = 1000;
  public _quantity: number = 1;

  @Input('selectedValue') public selectedValue: any;
  @Input('items') public _items: any[];

  @Input() public show: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public currentItem: GroupedShoppingCart;
  @Input() public currentTotalItems: number = 0;

  @Input() public set quantity(qnt: number)
  {
    this._quantity = qnt;
  }

  public get quantity(): number
  {
    return this._quantity;
  }

  @Output() public onQuantityChanges = new EventEmitter<number>();

  public clicker = new Subject();
  public clicker$ = this.clicker.asObservable();

  public quantityReachLimit = new Subject();
  public quantityReachLimit$ = this.quantityReachLimit.asObservable();

  constructor(public settingsManager: SettingsManager) { }

  public ngOnInit(): void
  {
    this.clicker$.pipe(
      debounceTime(this._defaultDebounceTime)).
      subscribe(() =>
      {
        this.onQuantityChanges.emit(this.quantity);
      });

    this.quantityReachLimit$.pipe(
      debounceTime(this._defaultDebounceTime)).
      subscribe(() =>
      {
        this.updateMaxCurrentItemOnReachingLimit();
      }
    );
  }

  public onValueChanges(qnty: number): void
  {
    this._quantity = qnty;
    this.clicker.next(true);
  }

  public onDecrementQuantityClicked(): void
  {
    if (!this.disabled)
    {
      this._quantity === 1 ? '' : this._quantity--;
      this.clicker.next(true);
    }
  }

  public onIncrementQuantityClicked(): void
  {
    if (!this.disabled)
    {
      if (this.currentTotalItems > 0 && this.currentTotalItems === this.settingsManager.itemsLimit)
      {
        this.clicker.next(true);
        return;
      }

      if (this._quantity >= this.settingsManager.itemsLimit)
      {
        this.quantityReachLimit.next(true);
        return;
      }


      this._quantity < this.settingsManager.itemsLimit ? this._quantity++ : '';
      this.clicker.next(true);
    }
  }

  private get currentCartMaxQuantity(): number
  {
    return (this.settingsManager.itemsLimit - this.currentTotalItems);
  }

  private updateMaxCurrentItemOnReachingLimit(): void
  {
    const isItemUpgrade = this.currentItem.product.suggestions?.some(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);
    const currentCounterItem = isItemUpgrade ?
      this.currentItem.product.suggestions.find(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade).individualFeatures.quantity :
      this.currentItem.counter;
    const currentMaxItems = this.currentCartMaxQuantity + currentCounterItem;
    this._quantity = currentMaxItems;
    this.onQuantityChanges.emit(this.quantity);
  }


  public ngOnDestroy(): void
  {
    this.clicker.complete();
    this.quantityReachLimit.complete();
  }
}