import { Injectable } from '@angular/core';
import { TranslateService } from '@shared-services/translate.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToastService
{
  public toastRef: any;

  private readonly _baseConfig: IndividualConfig = {
    disableTimeOut: false,
    timeOut: 5000,
    tapToDismiss: false,
    toastClass: "success",
    closeButton: true,
    positionClass: "bottom-right",
    extendedTimeOut: 1000,
    progressBar: false,
    progressAnimation: "decreasing",
    enableHtml: false,
    titleClass: "toast-title",
    messageClass: "toast-message",
    easing: "ease-in",
    easeTime: 300,
    onActivateTick: false,
    newestOnTop: true
  };

  constructor(protected toastr: ToastrService,
    protected translateService: TranslateService)
  {
    toastr.toastrConfig.maxOpened = 3;
  }

  public showToast(message: string, type: string, closeButton: boolean = true): void
  {
    if (message)
    {
      message = this.translateService.getElement(message);

      const config = { ...this._baseConfig };

      config.toastClass = type;
      config.closeButton = closeButton;

      this.toastRef = this.toastr.show(message, undefined, config);
    }
  }

  public setSuccessToast(message: string): void
  {
    this.showToast(message, "success");
  }

  public setErrorToast(message: string): void
  {
    this.showToast(message, "error");
    // GtmTrackingService.errorMessageEvent(message);
  }

  public setWarningToast(message: string, closeButton: boolean = true): void
  {
    this.showToast(message, "warning", closeButton);
  }
}
