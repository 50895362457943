<!-- Main content -->
<div class="domain-search-result-container p-3 px-md-5 mb-3">
  <div class="px-0 px-xl-4">
    <ng-container
      *ngTemplateOutlet="!domain || !(domain.available) ? unavailableDomainTemplate : availableDomainSelectTemplate"></ng-container>
  </div>
</div>

<!--  Available Domain Select Template -->
<ng-template #availableDomainSelectTemplate>
  <ng-container
    *ngIf="domain.isAvailableOffline; then availableOfflineDomainTemplate else availableDomainTemplate"></ng-container>
</ng-template>

<!--  Available Domain Template -->
<ng-template #availableDomainTemplate>

  <div class="basic-domain-result-text">
    <b>{{'¡Excelente!'| translate }}</b> {{'el dominio está disponible.'| translate }} <b>
      {{'Regístralo'| translate }}
    </b> {{'antes de que te lo ganen'| translate }}.
  </div>

  <hr *ngIf="!hasDomainPackages">

  <div class="row w-100 justify-content-between align-items-center p-0 mx-0 my-3">

    <!-- Domain name -->
    <div class="domain-name-container col-12 col-lg-5 d-block d-md-inline text-center text-lg-start m-0 p-0">
      <span class="fs-xl black">
        {{ domain.domainName }}
      </span>
      <!-- Offer icon -->
      <span *ngIf="domain.beforePrice" title="{{ offerMessage | translate }}" (click)="goToPromotions()"
        class="clickable fas i-offer domain d-inline-block"></span>
    </div>

    <!-- Price, requirements and add to cart -->
    <div
      class="col-12 col-lg-7 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end align-items-center p-0 m-0">

      <div class="row col-6 m-0 p-0 my-3 my-lg-0 me-0 me-lg-3 domain-price-container">
        <!-- beforePrice -->
        <div class="col-6 d-flex align-items-center justify-content-end m-0 p-0"
          [ngClass]="{ 'd-none d-lg-block': !domain.beforePrice }">
          <span *ngIf="domain.beforePrice" class="gray price searchResult-domainPriceMxn price pe-2">
            <del>
              {{ domain.beforePrice | currency }} <span> MXN</span>
            </del>
          </span>
        </div>
        <!-- price -->
        <ng-container *ngIf="domain.isFreeDomain === false; else requerimentsTemplate">
          <div class="col-6 d-flex align-items-center m-0 p-0 price justify-content-start"
            [ngClass]="{'col-12 col-lg-6 justify-content-center justify-content-lg-start': !domain.beforePrice }">
            <span class="me-2 fs-xl black">$ {{ domain.price }}</span>
            <span class="searchResult-domainPriceMxn"> MXN </span>
          </div>
        </ng-container>
        <!-- Online payment -->
        <div class="col-6 m-0 p-0" [ngClass]="{ 'd-none d-lg-block': !domain.beforePrice }"></div>
        <div *ngIf="domain?.onlinePayment" class="col-12 col-lg-6 m-0 p-0 text-center text-lg-start">
          <div class="gray-payment-message">{{'(Pago en línea)' | translate}}</div>
        </div>
      </div>

      <div class="mt-3 mt-md-0 pe-0 pe-xl-5">
        <!-- Add to cart button -->
        <button id="searchDomainForm_domainResults_0" (click)="onAddToCart()" [disabled]="!(domain.isEnabled)"
          class="btn mx-auto ms-md-0 text-nowrap" [ngClass]="hasDomainPackages ? 'btn-primary-outline' : 'btn-primary'">
          {{'Agregar al carrito' | translate}}
        </button>
      </div>
    </div>

  </div>

  <!-- Promotion -->
  <ng-container *ngFor="let pack of domain.packages">
    <ng-container *ngTemplateOutlet="promotionTemplate; context: { domainPackage: pack }"></ng-container>
  </ng-container>

  <div class="black fs-md my-3 w-100">
    {{ 'Ya que hayas agregado el dominio al carrito podrás configurarlo y agregarle servicios para tener un producto más
    completo.' | translate }}
    <br>
    <span class="text-uppercase">{{'precios incluyen iva' | translate}}</span>
  </div>

</ng-template>

<!--  Unavailable Domain Ttemplate -->
<ng-template #unavailableDomainTemplate>
  <div *ngIf="!(domain?.available)" class="d-flex align-items-center">
    <div *ngIf="domain && domain?.domainName && domain?.domainName !== ''">
      <span class="searchResult-error-description">{{'El dominio' | translate}}</span>
      <span class="basic-domain-result-text domain-name-error-container mx-1"><b>{{ domain?.domainName }}</b></span>
      <span class="searchResult-error-description">{{'no está disponible' | translate}}.</span>
    </div>
    <div *ngIf="!domain || !domain?.domainName || domain?.domainName === ''">
      <span class="searchResult-error-description">{{'El dominio no está disponible' | translate}}.</span>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="domainErrorTemplate; context: { domain: domain }"></ng-container>
</ng-template>

<!--  AvailableOffline Domain Ttemplate -->
<ng-template #availableOfflineDomainTemplate>
  <div *ngIf="(domain?.isAvailableOffline)" class="d-flex align-items-center">
    <div *ngIf="domain && domain?.domainName && domain?.domainName !== ''">
      <span class="searchResult-error-description">{{'El dominio' | translate}}</span>
      <span class="basic-domain-result-text domain-name-error-container mx-1"><b>{{ domain?.domainName }}</b></span>
      <span class="searchResult-error-description">
        {{'está disponible fuera de línea' | translate}}.
        <span class="col-12 col-sm-6 help-icon-container">
          <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}"
            alt="Question mark" src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
          <span class="tooltipTextSearchDomainOffline"><small> {{'Favor de contactar a Servicio al Cliente' |
              translate}} </small></span>
        </span>
      </span>

    </div>
    <div *ngIf="!domain || !domain?.domainName || domain?.domainName === ''">
      <span class="searchResult-error-description">{{'El dominio está disponible fuera de línea' | translate}}.
        <span class="col-12 col-sm-6 help-icon-container">
          <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}"
            alt="Question mark" src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
          <span class="tooltipTextSearchDomainOffline"> {{'Favor de contactar a Servicio al Cliente' |
            translate}}</span>
        </span>
      </span>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="domainErrorTemplate; context: { domain: domain }"></ng-container>
</ng-template>

<!-- Domain error template -->
<ng-template #domainErrorTemplate let-domain="domain">
  <p class="searchResult-error-description p-0 m-0" *ngIf="hasError(domain)">
    <span *ngIf="!isMaintenanceError()">
      {{ 'Verifica su escritura.' | translate }}
      <span class="col-12 col-sm-6 help-icon-container">
        <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}"
          alt="Question Mark" src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
        <span class="tooltipTextSearchDomain"><small> {{ getDomainErrorMessage() }} </small></span>
      </span>
    </span>
    <span class="maintenance-error" *ngIf="isMaintenanceError()">
      {{getDomainErrorMessage() }}
      <span class="text-underline clickable black" (click)="navigateToPortalAkky()">
        {{ 'Más Información' | translate }}.
      </span>
    </span>
  </p>
</ng-template>

<!-- Doamain Requirements Template -->
<ng-template #requerimentsTemplate>
  <div class="fs-l green clickable text-center text-lg-end" (click)="onRequirementsClicked()">
    {{ requirementsMessage | translate }}
  </div>
</ng-template>


<!-- Domain Promotion Template -->
<ng-template #promotionTemplate let-domainPackage="domainPackage">
  <hr>
  <div class="d-block d-lg-flex w-100 align-items-center price-button-container pe-0 pe-xl-5">
    <div class="flex-grow-1 me-0 me-md-2 me-lg-5" [innerHTML]="domainPackageHTML(domainPackage) | safe: 'html'"></div>
    <div class="d-flex align-self-end mt-3 mt-md-0">
      <button (click)="onaddToCart(domainPackage)" [disabled]="!(domain.isEnabled)"
        class="btn btn-primary text-nowrap mx-auto ms-md-0">
        {{'Agregar al carrito' | translate}}
      </button>
    </div>
  </div>
</ng-template>