<div class="padding-top-body" [ngStyle]="{'background-color': backgroundColor}">
    <div class="container d-flex justify-content-center">
        <img alt="Baner Promociones" class="d-none d-md-block banner-size" (click)="onDealClicked()"
            [class.clickable]="isDealClickable" [src]="bannerImageDesktop | directus" />

        <img alt="Baner Promociones" class="d-block d-md-none w-100" (click)="onDealClicked()"
            [class.clickable]="isDealClickable" [src]="bannerImageMobile | directus" />
    </div>
</div>
<app-featured-deals *ngIf="carouselItems" id="featuredDeals" class="container d-flex flex-column my-3 my-sm-5"
    [dealItems]="carouselItems" [title]="dealTranslation?.titleFeatured" [description]="descriptionFeatured">
</app-featured-deals>

<div *ngIf="flashDeal" id="flashDeals" class="container d-flex flex-column my-3 my-sm-5">
    <app-flash-deals [title]="dealTranslation?.titleFlash" [description]="dealTranslation?.descriptionFlash"
        [dealItem]="flashDeal">
    </app-flash-deals>
</div>
<div id="allDeals" class="container my-3 my-sm-5">
    <app-all-deals [title]="dealTranslation?.titleAllDeals" [description]="dealTranslation?.descriptionAllDeals"
        [dealItems]="detalItems">
    </app-all-deals>
</div>

<div class="container my-3 my-sm-5 d-flex justify-content-center">
    <a target="_blank" class="terms-conditions text-decoration-underline text-dark"
    href="{{ termsConditionsPDF | blobstorage}}">
        {{'Consulta Términos y Condiciones' | translate}}
    </a>
</div>

<app-deal-subscribe *ngIf="false"> </app-deal-subscribe>
