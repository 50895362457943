<div [innerHTML]="title | safe: 'html'"></div>
<div class="d-flex flex-wrap space-flex" [ngClass]="{ 'justify-content-between': bigItem }">
    <div class="d-flex max-w-big-item space-flex"
        [ngClass]="bigItem ? 'flex-column-big-item justify-content-between' : 'w-100 justify-content-around'">
        <img *ngFor="let item of exclusiveItems" (click)="onClickImg(item.url)" class="img-exclusive" [src]="item.translations[0]?.img?.id | directus"
            [alt]="item.translations[0]?.img?.title"/>
    </div>
    <div *ngIf="bigItem">
        <img class="img-big-item" (click)="onClickImg(bigItem.url)" [src]="bigItem.translations[0]?.img?.id | directus"
        [alt]="bigItem.translations[0]?.img?.title">
    </div>
</div>
