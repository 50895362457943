import { IPlanTable } from "@core-models/microsoft-service-table";

export namespace MicrosoftConst
{
  export const BenefitsTable: IPlanTable[] =
    [
      {
        isColumnAsTable: true,
        columns: [
          {
            title: "Microsoft 365 Empresa Básico", show: true,
            data: [
              {
                title: "Aplicaciones versión web",
                apps: [{ logo: EMicrosoftLogo.Word, label: "Word" }, { logo: EMicrosoftLogo.Excel, label: "Excel" },
                { logo: EMicrosoftLogo.PowerPoint, label: "PowerPoint" }, { logo: EMicrosoftLogo.OneNote, label: "OneNote" },
                { logo: EMicrosoftLogo.Access, label: "Access (PC)" }, { logo: EMicrosoftLogo.Publisher, label: "Publisher (PC)" },
                { logo: EMicrosoftLogo.Outlook, label: "Outlook" }, { logo: EMicrosoftLogo.Teams, label: "Teams" },
                { logo: EMicrosoftLogo.Sharepoint, label: "SharePoint" }, { logo: EMicrosoftLogo.OneDrive, label: "OneDrive" }]
              }
            ]
          },
          {
            title: "Microsoft 365 Empresa Estándar", show: true,
            data: [
              {
                title: "Aplicaciones versión web, móvil y escritorio",
                apps: [{ logo: EMicrosoftLogo.Word, label: "Word" }, { logo: EMicrosoftLogo.Excel, label: "Excel" },
                { logo: EMicrosoftLogo.PowerPoint, label: "PowerPoint" }, { logo: EMicrosoftLogo.OneNote, label: "OneNote" },
                { logo: EMicrosoftLogo.Access, label: "Access (PC)" }, { logo: EMicrosoftLogo.Publisher, label: "Publisher (PC)" },
                { logo: EMicrosoftLogo.Outlook, label: "Outlook" }, { logo: EMicrosoftLogo.Teams, label: "Teams" },
                { logo: EMicrosoftLogo.Sharepoint, label: "SharePoint" }, { logo: EMicrosoftLogo.OneDrive, label: "OneDrive" },
                { logo: EMicrosoftLogo.Loop, label: "Loop" }, { logo: EMicrosoftLogo.ClipChamp, label: "ClipChamp" }]
              }
            ]
          }
        ]
      },
      {
        isColumnAsTable: false,
        columns: [
          {
            title: "Office 365 E3", show: true,
            data: [
              {
                title: "Aplicaciones de Microsoft",
                apps: [{ logo: EMicrosoftLogo.PowerPoint, label: "PowerPoint" }, { logo: EMicrosoftLogo.Word, label: "Word" },
                { logo: EMicrosoftLogo.Excel, label: "Excel" }, { logo: EMicrosoftLogo.OneNote, label: "OneNote" },
                { logo: EMicrosoftLogo.Access, label: "Access (PC)" }, { logo: EMicrosoftLogo.Publisher, label: "Publisher (PC)" }]
              },
              {
                title: "Correo y Calendario",
                apps: [{ logo: EMicrosoftLogo.Outlook, label: "Outlook" }, { logo: EMicrosoftLogo.Exchange, label: "Exchange" },
                { logo: EMicrosoftLogo.Bookings, label: "Bookings" }]
              },
              {
                title: "Reuniones, Llamadas y Chat",
                apps: [{ logo: EMicrosoftLogo.Teams, label: "Teams" }]
              },
              {
                title: "Windows",
                apps: [{ logo: EMicrosoftLogo.Windows11, label: "Windows 11" }]
              },
              {
                title: "Redes Sociales, Intranet y almacenamiento",
                apps: [{ logo: EMicrosoftLogo.Sharepoint, label: "SharePoint" }, { logo: EMicrosoftLogo.VivaConnections, label: "Viva Connections" },
                { logo: EMicrosoftLogo.VivaEngage, label: "Viva Engage" }]
              },
              {
                title: "Archivos y contenido",
                apps: [{ logo: EMicrosoftLogo.OneDrive, label: "OneDrive" }, { logo: EMicrosoftLogo.Stream, label: "Stream" },
                { logo: EMicrosoftLogo.Sway, label: "Sway" }, { logo: EMicrosoftLogo.Forms, label: "Forms" }]
              },
              {
                title: "Inteligencia empresarial y análisis",
                apps: [{ logo: EMicrosoftLogo.VivaConnections, label: "Viva Insights" }]
              },
              {
                title: "Administración de proyectos y tareas",
                apps: [{ logo: EMicrosoftLogo.Planner, label: "Planner" }, { logo: EMicrosoftLogo.ToDo, label: "To Do" }]
              },
              {
                title: "Automatización, creación de aplicaciones y bots de chat",
                apps: [{ logo: EMicrosoftLogo.PowerApps, label: "Power Apps" }, { logo: EMicrosoftLogo.PowerAutomate, label: "Power Automate" },
                { logo: EMicrosoftLogo.PowerVirtualAgents, label: "Power Virtual Agents" }]
              }
            ]
          },
          {
            title: "Office LTSC Professional Plus 2021", show: true,
            data: [
              {
                title: "Aplicaciones de Microsoft",
                apps: [{ logo: EMicrosoftLogo.PowerPoint, label: "PowerPoint" }, { logo: EMicrosoftLogo.Word, label: "Word" },
                { logo: EMicrosoftLogo.Excel, label: "Excel" }, { logo: EMicrosoftLogo.OneNote, label: "OneNote" },
                { logo: EMicrosoftLogo.Access, label: "Access (PC)" }, { logo: EMicrosoftLogo.Publisher, label: "Publisher (PC)" }]
              },
              {
                title: "Correo y Calendario",
                apps: [{ logo: EMicrosoftLogo.Outlook, label: "Outlook" }]
              },
              {
                title: "Reuniones, Llamadas y Chat",
                apps: [{ logo: EMicrosoftLogo.Teams, label: "Teams" }]
              },
              {
                title: "Windows",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              },
              {
                title: "Redes Sociales, Intranet y almacenamiento",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              },
              {
                title: "Archivos y contenido",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              },
              {
                title: "Inteligencia empresarial y análisis",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              },
              {
                title: "Administración de proyectos y tareas",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              },
              {
                title: "Automatización, creación de aplicaciones y bots de chat",
                apps: [{ logo: EMicrosoftLogo.WithOutApps, label: "No aplica" }]
              }
            ]
          }
        ]
      }
    ];
}

export const enum EMicrosoftLogo
{
  Access = "/logos/msft365/logo_access.png",
  Bookings = "/logos/msft365/logo_bookings.png",
  ClipChamp = "/logos/msft365/logo_clipchamp.png",
  Excel = "/logos/msft365/logo_excel.png",
  Exchange = "/logos/msft365/logo_exchange.png",
  Forms = "/logos/msft365/logo_forms.png",
  Lists = "/logos/msft365/logo_lists.png",
  Loop = "/logos/msft365/logo_loop.png",
  OneDrive = "/logos/msft365/logo_onedrive.png",
  OneNote = "/logos/msft365/logo_onenote.png",
  Outlook = "/logos/msft365/logo_outlook.png",
  Planner = "/logos/msft365/logo_planner.png",
  PowerAutomate = "/logos/msft365/logo_power_automate.png",
  PowerVirtualAgents = "/logos/msft365/logo_power_virtual_agents.png",
  PowerApps = "/logos/msft365/logo_powerapps.png",
  PowerPoint = "/logos/msft365/logo_powerpoint.png",
  Publisher = "/logos/msft365/logo_publisher.png",
  Sharepoint = "/logos/msft365/logo_sharepoint.png",
  Stream = "/logos/msft365/logo_stream.png",
  Sway = "/logos/msft365/logo_sway.png",
  Teams = "/logos/msft365/logo_teams.png",
  ToDo = "/logos/msft365/logo_to_do.png",
  Visio = "/logos/msft365/logo_visio.png",
  VivaConnections = "/logos/msft365/logo_viva_connections.png",
  VivaEngage = "/logos/msft365/logo_viva_engage.png",
  Windows11 = "/logos/msft365/logo_windows11_enterprise.png",
  Word = "/logos/msft365/logo_word.png",
  Yammer = "/logos/msft365/logo_yammer.png",
  WithOutApps = "/logos/msft365/close-icon.png"
}


