<div id="privacy-notice" class="d-none">
  <label for="41:870;a" class="slds-form-element__label aviso privacy-label-header">
    {{'Al momento de dar clic al botón'| translate}} "{{'ENVIAR' | translate}}"
    {{ 'declaras que conoces y aceptas el' | translate }}&#xA0;
    <a href="/acerca/politicas-de-servicios?id=aviso-de-privacidad-clientes" target="_blank" class="privacy-link">
      {{ 'Aviso de Privacidad' | translate }}
    </a>{{ 'y otorgas tu consentimiento para que tus datos personales sean tratados de conformidad con dicho Aviso.' |
    translate }}
  </label>
  <div class="slds-grid">
    <div class="slds-col slds-size_1-of-1 slds-form_horizontal" data-aura-rendered-by="40:870;a">
      <input class="CheckboxClass d-inline-block uiInput uiInputCheckbox uiInput--default uiInput--checkbox"
        type="checkbox" id="41:870;a" />&#xA0;
      <label for="41:870;a" class="slds-form-element__label check privacy-check " required="required">
        {{'He leído y acepto el Aviso de Privacidad.' | translate }}
      </label>
    </div>
  </div>
</div>

<!-- WHATSAPP BUTTON -->
<a class="whats-btn" href="{{whatsappurl}}" target="_blank" [class.d-none]="!isWhatsAppEnabled">
  <img class="whats-btn-dimensions" [src]="'/logos/whatsapp_sticky.webp' | blobstorage" alt="WhatsApp button"
    data-src="{{'/logos/whatsapp_sticky.webp' | blobstorage}}">
</a>

<!-- CHAT BUTTON -->
<button [id]="chatBtnId" class="chat-button" #liveagent_button_online_5738F000000GmdD (click)="openChat()" #buttonChat
  [attr.aria-label]="ariaLabelForChatButton" [class.d-none]="!isChatEnabled ">
  <img class="chat-btn-dimensions" alt="Customer service button" [src]="'/logos/chat_sticky.webp' | blobstorage">
</button>

<div id="preChat">
  <div id="preChatHeader" class="w-100 align-items-center d-flex p-3">
    <img class="d-xl-flex justify-content-xl-start align-items-xl-start" loading="lazy" alt="akky logo"
      data-src="{{ '/logo-akky-white.svg' | blobstorage }}" width="100" height="auto"
      src="{{ '/logo-akky-white.svg' | blobstorage }}">
    <button id="preChatCloseButton" type="button" class="close ms-auto" (click)="onClosePrechat()">
      <img loading="lazy" data-src="{{ '/icons/White/x.svg' | blobstorage }}" alt="About Title"
        src="{{ '/icons/White/x.svg' | blobstorage }}">
    </button>
  </div>
  <div id="preChatBody" class="p-2 p-3">
    <div id="preChatWelcome" class="px-3">
      <h4 class="welcome-text">{{'¡Hola!' | translate }}</h4>
      <h4 class="welcome-text">{{'¿Cómo podemos ayudarte?' | translate}}</h4>
    </div>
    <div class="row justify-content-center w-100 p-0 m-0 pt-2">
      <div class="col-12 col-sm-6 p-0 d-flex justify-content-center">
        <button id="liveagent_button_online_5735w000000QAy6" type="button" class="btn btn-info prechatOption">
          {{'Servicio a clientes' | translate}}
        </button>
      </div>
      <div class="col-12 col-sm-6 p-0 d-flex justify-content-center mt-2 mt-md-0">
        <button id="liveagent_button_online_5735w000000LynZ" type="button" class="btn btn-info prechatOption">
          {{'Información y ventas' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div id="helperData" class="d-none">
  <div id="chatLanguageCode">{{'es-MX' | translate}}</div>
  <div id="chatDefaultMinimizedText">{{'Chat en línea' | translate}}</div>
  <div id="chatDisabledMinimizedText">{{'Contáctanos' | translate}}</div>
  <div id="chatLoadingText">{{'Cargando...' | translate}}</div>
  <div id="isSalesChatActive"></div>
  <div id="urlSalesForce">{{ urlSalesForce }}</div>
  <div id="urlChatBot">{{ urlChatBot }}</div>
  <div id="urlAvatarChatBot">{{ urlAvatarChatBot }}</div>
  <div id="salesForceId">{{ salesForceId }}</div>
  <div id="baseLiveAgentContentURL">{{ baseLiveAgentContentURL }}</div>
  <div id="deploymentId">{{ deploymentId }}</div>
  <div id="buttonId">{{ buttonId }}</div>
  <div id="baseLiveAgentURL">{{ baseLiveAgentURL }}</div>
  <div id="eswLiveAgentDevName">{{ eswLiveAgentDevName }}</div>
</div>
