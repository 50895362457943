<div class="accordion" *ngIf="menuOptions">
  <!--Menu options-->
  <div *ngFor="let option of menuOptions; index as i; trackBy:identify">
    <h2 class="option-item-container" id="option">
      <span class="option">
        <a class="accordion-button option-item" [class.collapsed]="selectedItemInfo.optionId !== option.id"
          data-bs-toggle="collapse" href="{{'#panelOption-collapse-' + option.id}}" role="button" aria-expanded="false">
          {{option.title}}
        </a>
      </span>
    </h2>
    <!-- Categories-->
    <div id="{{'panelOption-collapse-' + option.id }}" class="accordion-collapse collapse"
      [class.show]="selectedItemInfo.optionId === option.id" aria-labelledby="option">
      <div class="accordion-body p-0 m-0">
        <div *ngFor="let category of option.categories; trackBy:identify">
          <ng-container
            *ngTemplateOutlet="category?.subcategories !== undefined ? subcategoryTemplate : singleCategoryTemplate; context: { category : category }"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Single categories template -->
<ng-template #singleCategoryTemplate let-category="category">
  <a class="category-item ps-md-3"
    [class.active]="selectedItemInfo.subcategoryId === undefined && selectedItemInfo.categoryId === category.id"
    href="/ayuda/temas-de-ayuda?tema={{ category.htmlId}}">
    {{ category.title }}
  </a>
</ng-template>

<!-- Subcategories template -->
<ng-template #subcategoryTemplate let-category="category">
  <div class="accordion">
    <h2 class="accordion-header" id="{{ 'panelCategory' + category.id}}">
      <a class="accordion-button category-item ps-md-3 " [class.collapsed]="selectedItemInfo.categoryId !== category.id"
        data-bs-toggle="collapse" role="button" aria-expanded="false" href="{{ '#'+ 'subcategoryPanel' + category.id}}">
        {{ category.title }}
      </a>
    </h2>
    <div id="{{ 'subcategoryPanel' + category.id}}" class="accordion-collapse collapse"
      [class.show]="selectedItemInfo.categoryId === category.id"
      aria-labelledby="{{ 'panelCategory' + category.htmlId}}">
      <div class="accordion-body p-0 py-md-2 ps-md-3 pe-md-0">
        <div *ngFor="let subcategory of category.subcategories; trackBy:identify" class="text-left">
          <a class="subcategory-item fs-m d-block py-2 ps-md-3"
            [class.active]="selectedItemInfo.subcategoryId === subcategory.id"
            href="/ayuda/temas-de-ayuda?tema={{ subcategory.htmlId }}">
            {{subcategory.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>