<!-- Main item display component -->
<div class="my-3 item-card py-3 px-2 w-100">
  <!-- Item header -->
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

  <!-- Main Service detail -->
  <ng-container *ngTemplateOutlet="serviceItemTemplate"></ng-container>

  <!-- Service features -->
  <ng-container *ngTemplateOutlet="featuresTemplate"></ng-container>

  <!-- Service Suggestions -->
  <ng-container *ngTemplateOutlet="suggestionsTemplate"></ng-container>

  <!-- Trash icon -->
  <div *ngIf="isRemovable" class="pt-3 d-flex w-100 alig-items-center justify-content-end">
    <span title="{{ 'Eliminar' | translate }}" class="trash-icon" role="button" (click)="onDeleteIconClick()"></span>
  </div>
</div>

<!----------------------------------- TEMPLATES  ----------------------------------->
<ng-template #headerTemplate>
  <div class="d-flex w-100 align-items-start justify-content-between">
    <div class="text-start flex-grow-1 ">
      <div class="font-weight-bold fs-l">
        <!-- Operation type only for domain item-->
        <span *ngIf="isDomain" class="d-block word-break">
          {{ getOperationTypeName() | translate }}
          {{ isEmtpyDomainName ? ('de dominio ' | translate) + itemProduct.fullName : '' }}
        </span>
        <!-- Item name for ANY service-->
        <span class="d-block word-break">
          {{getProductName()}}
        </span>
      </div>
      <!-- Object Id when required-->
      <span class="d-block fs-m">{{ getObjectId() }}</span>
      <span class="d-block fs-m">{{ getAddonObjectId() }}</span>
    </div>

    <!-- Offer message -->
    <div *ngIf="hasOffer(itemProduct.rate)" class="offer-radius bg-aqua white fs-xs px-2 py-1 ms-0 ms-md-3">
      {{ itemProduct.rate.message | translate }}
    </div>

  </div>
</ng-template>

<!-- Coverage Template -->
<ng-template #coverageTemplate>
  <div class="w-100">
    <app-dropdown *appShellNoRender [label]="getCoverageLabel()"
      [items]="itemProduct.coverageOptions | coverage:'array'" [isFullWidth]="true" [coverage]="true"
      [disabled]="disableCoverageDropdown" [parentId]="itemProduct.id" [selectedValue]="itemProduct.coverageId"
      (onItemSelectedChange)="onItemCoverageChange($event)">
    </app-dropdown>
  </div>
</ng-template>

<!-- Price -->
<ng-template #priceTemplate let-rate="rate" let-quantity="quantity" let-counter="counter">
  <div *ngIf="rate && rate?.finalAmount !== 0" class="d-flex p-0 flex-column">
    <!-- Final price -->
    <span class="text-end price fs-m fw-bold p-0">
      {{ rate.finalAmount * quantity * counter | currency }}
      <span> MXN</span>
    </span>
    <!-- Offer -->
    <div *ngIf="hasOffer(rate)" class="d-flex flex-column alig-items-center justify-content-end">
      <!-- Offer amount -->
      <span class="gray fs-xs price fw-bold p-0 text-end">
        <del>{{ rate.offerAmount * quantity * counter | currency }}</del>
        <span> MXN</span>
      </span>
      <!-- Discount amount-->
      <span class="green fw-bold text-uppercase text-end fs-s">
        {{ 'Ahorra' | translate }}
        {{ ((rate.finalAmount * quantity * counter) - (rate.offerAmount * quantity * counter) ) | currency}} MXN
      </span>
    </div>
  </div>
  <span *ngIf="rate.finalAmount === 0 && !isRenewal" class="fs-m fw-bold text-end">
    {{ getDisplayMessageRate0() | translate }}
  </span>
</ng-template>

<!-- Addon quantity selector Template-->
<ng-template #addonQuantityTemplate>
  <div class="row w-100 justify-content-between alig-items-center p-0 m-0 pt-3">
    <!-- Quantity selector -->
    <div class="col-7 p-0 m-0">
      <app-dropdown *appShellNoRender [label]="'CANTIDAD'" [items]="emailOXCapacityOptions" [isFullWidth]="true"
        [parentId]="itemProduct.id" [selectedValue]="selectedQuantity" [disabled]="disabledCapacityButtons"
        (onItemSelectedChange)="onAddonCapacitySelectedChanges($event)">
      </app-dropdown>
    </div>
    <!-- GB input -->
    <div *ngIf="isPersonalizedQuantity === true" class="col-5 pe-0 me-0">
      <div class="w-100 d-flex justify-content-end">
        <app-input-number *appShellNoRender [label]="'GB'" [value]="itemProduct.quantity"
          [disabled]="disabledCapacityButtons" [parentId]="itemProduct.id" [min]="1"
          (onValueChanges)="onAddonItemValueChanges($event)">
        </app-input-number>
      </div>
    </div>
  </div>
</ng-template>

<!-- All services template-->
<ng-template #serviceItemTemplate>
  <div *ngIf="isValidCoverage()" class="row w-100 p-0 m-0 align-items-center justify-content-between">

    <!-- Addon quantity selectors -->
    <ng-container *ngIf="isAddonCumulative === true; then addonQuantityTemplate"></ng-container>

    <!-- Coverage dropdown -->
    <div *ngIf="!isNotChargableDomain && showCoverageDropdown() === true;" class="col-12 col-md-7 p-0 m-0 order-1"
      [ngClass]="isAddonCumulative === true ? 'pt-0' : 'pt-3'">
      <ng-container *ngTemplateOutlet="coverageTemplate"></ng-container>
    </div>

    <!-- Price -->
    <div *ngIf="!isNotChargableDomain" class="col-5 order-3 pe-0 order-md-2">
      <div class="d-flex justify-content-end pt-3">
        <ng-container
          *ngTemplateOutlet="priceTemplate; context: { rate: itemProduct.rate, quantity: itemProduct.quantity, counter: item.counter }"></ng-container>
      </div>
    </div>

    <!-- Service quantity selector -->
    <div class="col-7 col-md-4 p-0 m-0 order-2"
      [ngClass]="showCoverageDropdown() === true ? 'order-md-3' : 'order-md-1'">
      <div *ngIf="isUserServiceRegistration" class="pt-2 qnty-selector-container">
        <quantity-selector [currentItem]="item" [show]="true" [disabled]="disableQuantityGroupButtons" [currentTotalItems]="totalItemsShoppingCart"
          [quantity]="item.counter" (onQuantityChanges)="onUpdateServiceByQuantityGroup($event)">
        </quantity-selector>
      </div>
    </div>
  </div>

  <!-- Domain Services -->
  <ng-container *ngTemplateOutlet="domainServicesTemplate"></ng-container>

  <!-- Various Services -->
  <ng-container *ngTemplateOutlet="variousServicesTemplate"></ng-container>

</ng-template>

<!-- Various services Template -->
<ng-template #variousServicesTemplate>
  <ng-container *ngIf="hasVariousServices">
    <div *ngFor="let service of itemProduct.variousServices" class="row p-0 m-0 pt-3 align-items-center">
      <!-- Service name -->
      <div class="col-12 text-break font-weight-bold fs-m p-0">
        {{service.description}}
      </div>
      <!-- Price -->
      <div class="col-12 p-0 pt-3 m-0">
        <ng-container
          *ngTemplateOutlet="priceTemplate; context: { rate: service.coverage.rate, quantity: itemProduct.quantity, counter: 1 }">
        </ng-container>
      </div>
      <div *ngIf="isAddonCumulative === false" class="col-12 p-0 pt-3 m-0 text-end price fs-m fw-bold p-0">
        {{ getTotalByProduct() | currency }} MXN
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Domain services Template -->
<ng-template #domainServicesTemplate>
  <div *ngIf="isDomain && domainServices()" class="p-0 m-0 pt-3">
    <div class="domain-service-container px-3 py-2">
      <!-- Card title -->
      <div class="fs-m text-uppercase green font-weight-bold w-100 py-2">
        {{ 'Servicios de dominio' | translate }}
      </div>
      <!-- Item detail -->
      <ng-container *ngFor="let domainService of domainServices(); let last = last">
        <section class="row p-0 m-0 my-3">
          <!-- Domain service name -->
          <div class="col-7 p-0 m-0">
            <span class="font-weight-bold fs-m">
              {{ domainService.name | translate }}
            </span>
          </div>
          <!-- Domain service price -->
          <div class="col-5 p-0 m-0">
            <ng-container
              *ngTemplateOutlet="priceTemplate; context: { rate: domainService.rate, quantity: 1, counter: 1 }">
            </ng-container>
          </div>
        </section>

        <section *ngIf="domainService.variousService" class="row p-0 m-0 my-3">
          <!-- Service name -->
          <div class="col-7 text-break font-weight-bold fs-m p-0">
            {{domainService.variousService.name}}
          </div>
          <!-- Price -->
          <div class="col-5 p-0 m-0">
            <ng-container
              *ngTemplateOutlet="priceTemplate; context: { rate: domainService.variousService.coverage.rate, quantity: itemProduct.quantity, counter: 1 }"></ng-container>
          </div>
        </section>

        <hr *ngIf="!last" class="m-0">
      </ng-container>
    </div>

    <div class="text-end p-0 m-0 pt-3">
      <span class="price font-weight-bold fs-m">
        {{ getTotalByProduct() | currency }} MXN
      </span>
    </div>
  </div>
</ng-template>

<!-- Service suggestions Template -->
<ng-template #suggestionsTemplate>
  <ng-container *ngIf="hasSuggestions">
    <div *ngFor="let suggestion of suggestions; trackBy: trackById" class="pt-3">
      <app-shopping-cart-suggestion-item
        [parentId]="itemProduct.id"
        [suggestion]="suggestion"
        [itemProduct]="itemProductSuggestionLite"
        (onSuggestionUpgradeItem)="onSuggestionUpgradeItem($event)"
      ></app-shopping-cart-suggestion-item>
    </div>
  </ng-container>
</ng-template>

<!-- Service features Template -->
<ng-template #featuresTemplate>
  <ng-container *ngIf="hasValidFeatures()">
    <div class="d-flex mt-3 me-3" *ngFor="let feature of features">
      <div class="d-flex align-items-center me-2">
        <span class="i-green-check"></span>
      </div>
      <div>
        <span class="text-break fs-m">{{feature}}</span>
      </div>
    </div>
  </ng-container>
</ng-template>

<!----------------------------------- Delete modal  ----------------------------------->
<app-dialog [enableCloseDialogButton]="false" [show]="showDeleteItemModal" [preventCloseFromBackDrop]="true">
  <div class="p-4 bg-white delete-item-modal">
    <!-- Back icon -->
    <div class="clickable" (click)="onCloseConfirmationModalClick()">
      <img loading="lazy" data-src="{{ '/icons/Colores/arrow-left-light-gray.svg' | blobstorage }}" alt="Arrow left"
        src="{{ '/icons/Colores/arrow-left-light-gray.svg' | blobstorage }}">
    </div>
    <!-- Delete message confirmation -->
    <div class="text-center my-3 my-md-4">
      <h4 class="font-weight-bold black my-2 p-0">
        {{ '¡Ya encontraste tu dominio ideal!' | translate }}

      </h4>
      <p class="fs-l black p-0 my-2">
        {{ '¿Seguro que quieres eliminarlo del carrito?' | translate }}
      </p>
    </div>
    <!-- Buttons -->
    <div class="row p-0 m-0">
      <div class="col-12 col-md-5 order-2 order-md-1 mt-3 mt-md-0">
        <button class="btn btn-primary me-0 me-md-3 w-100 text-uppercase" type="button"
          (click)="onCloseConfirmationModalClick()">
          {{ 'Cancelar' | translate }}
        </button>
      </div>
      <div class="col-12 col-md-7 order-1 order-md-2">
        <button class="btn btn-info w-100" (click)="onRemoveCartItemClick()">
          {{'Sí, estoy seguro' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-dialog>
