import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FaqCategoriesDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'faq-categories');
  }

  public getFaqCategories(language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language
    };

    const subject = this.http.get(this.createUrl(), queryDef.getRequestConfig());

    return subject;
  }

  public getFaqsByCategory(categoryHtmlId: string, language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      categoryHtmlId,
      language
    };

    const subject = this.http.get(this.createUrl(`${categoryHtmlId}/faqs`), queryDef.getRequestConfig());

    return subject;
  }

  public getFaqsPage(categoryId: number, language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      categoryId,
      language
    };

    const subject = this.http.get(this.createUrl(`${categoryId}/faqsPage`), queryDef.getRequestConfig());

    return subject;
  }
}

