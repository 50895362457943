<ng-container *appShellNoRender>
  <div *ngIf="showSuggestions" class="gradient-top-container basic-content">
    <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">

      <div class="row">
        <div class="col-md-12">
          <div class="fs-3 fw-bold">
            {{"¡Complementa tu compra!" | translate}}
          </div>
        </div>
        <div class="col-md-12 mt-3 mb-3 fs-6">
          {{"Descubre los artículos ideales para complementar tus productos del carrito y aprovecha nuestros precios de promoción exclusivos para ti." | translate }}
        </div>
      </div>

      <div class="row items-container">
        <div *ngFor="let suggestion of purchaseSuggestionsAdditionalServicesList; let i = index;let l = last"
          class="col-12 col-lg-4 col-md-6 pb-3" [class.col-md-12]="l">
          <app-additional-purchase-suggestion-item [purchaseSuggestion]="suggestion" [index]="i">
          </app-additional-purchase-suggestion-item>
        </div>
      </div>

      <div class="d-flex" [class.advise-container-none]="isItemSelected">
        <div class="advise-container fs-xs ms-auto d-flex" [class.d-none]="isItemSelected">
          <span class="d-inline-block i-alert mt-3 ms-2"></span>
          <span class="p-2">
            {{"Esta oferta no volverá a estar disponible." | translate}}<br />
            {{"¿Estás seguro de continuar sin agregarlos?" | translate}}
          </span>
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-lg-4 col-md-6">
          <button (click)="goToHome()" type="button"
            class="btn w-100 w-btn-secondary-md btn-secondary btn-secondary-add-serv d-flex justify-content-center align-items-center">
            <span class="d-inline-block i-arrow-left-short me-2"></span>
            <span class="text-black clickable fw-bold fs-sm pt-1">
              {{"Seguir comprando" | translate}}
            </span>
          </button>
        </div>

        <div class="col-lg-4 d-none d-lg-block"></div>

        <div class="col-lg-4 col-md-6 pt-3 pt-md-0" [class.d-none]="!isItemSelected">
          <div class="d-md-flex justify-content-end">
            <button (click)="addToCart()" type="button" class="btn btn-primary w-100 w-btn-primary-md">
              <span class="text-uppercase">
                {{'Continuar al carrito' | translate}}</span>
            </button>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 pt-3 pt-md-0" [class.d-none]="isItemSelected">
          <div class="d-md-flex justify-content-end">
            <button (click)="goToCart()" type="button"
              class="btn btn-secondary w-100 w-btn-secondary-md btn-secondary-add-serv d-flex justify-content-center align-items-center">
              <span class="text-black clickable fw-bold fs-sm pt-1">
                {{"Continuar sin agregar" | translate}}
              </span>
              <span class="d-inline-block i-arrow-right-short ms-2"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>