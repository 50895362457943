
export namespace UTMParamConst
{
  export const Params: string = 'utmParams';
  export const Campaing: string = 'utm_campaign';
  export const Medium: string = 'utm_medium';
  export const Source: string = 'utm_source';
}

export namespace SessionParams
{
  export const Login: string = 'isLogin';
  export const Error: string = 'isError';
  export const PostBackUrl: string = 'uri';
}

export namespace NewsLetterParams
{
  export const Edition: string = 'edicion';
  export const Notice: string = 'aviso';
}

export namespace HelpParams
{
  export const Category: string = 'categoria';
  export const Topic: string = 'tema';
}

export namespace AddToCartParams
{
  export const Coupon: string = 'couponToAdd';
  export const Package: string = 'packageToAdd';
}

export namespace QueryParams
{
  export const Lang: string = 'lang';
}
