import { EAppEventName } from "@core-constants/app-events.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { IResultDomain } from "@core-models/domain.model";
import { BroadcastService } from "@shared-services/broadcast.service";
import { ToastService } from "@shared-services/toast.service";

export class SearchDomainCommonComponent 
{
  public requirementsMessage = "Ver requisitos";
  public offerMessage = "Hay una oferta o promoción disponible para este dominio";
  public searchingSuggestionsMessage: string = "Buscando alternativas de dominios para ti";
  public errorSuggestionMessage: string = "¡No te preocupes! Tenemos estas alternativas disponibles.";
  public successSuggestionMessage: string = "También tenemos estos dominios disponibles para ti.";
  public onlinePaymentMessage: string = "(Pago en línea)";
  public vatMessage: string = "precios incluyen iva";

  constructor(protected toast: ToastService) { }

  public notifySuccessDomainsAdded(cartId: string, result: IResultDomain[]): void
  {
    this.onSuccessDomainsAdded(true, cartId, result);
  }

  public notifySuccessDomainAdded(cartId: string, result: IResultDomain[]): void
  {
    this.onSuccessDomainsAdded(false, cartId, result);
  }

  private onSuccessDomainsAdded(isMultipleSearch: boolean, cartId: string, result: IResultDomain[]): void
  {
    const message = isMultipleSearch === true ? SuccessMessageConst.MultipleDomainAddedSuccessfully : SuccessMessageConst.DomainAddedSuccessfully;

    BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, cartId);


    this.toast.setSuccessToast(message);
  }

  public notifyDomainErrors(response: any[]): void
  {
    const message = this.getErrorDomainsText(response);
    this.toast.setErrorToast(message);
  }

  private getErrorDomainsText(response: any[]): string
  {
    let result = '';

    if (response)
    {
      response.forEach(item =>
      {
        result += item.error != null ? `${item.domainName}: ${item.error?.message} \r` : '';
      });
    }
    return result;
  }
}
