<ng-container *ngIf="id !== undefined">
  <div id="{{ 'service-banner-'+ id }}" class="container-fluid basic-banner p-0"
    [style.backgroundImage]="bannerBackground | directus:'style'">
    <div class="container basic-content-service row mx-auto">
      <div id="{{'service-title-' + id}}" class="col-12 col-sm-7 col-md-7">
        <h2 class="grid-container" *ngIf="bannerText" [innerHTML]="bannerText | safe: 'html'"></h2>
      </div>
      <div id="{{'service-img-container-' + id}}"
        class="position-relative service-img-container col-12 col-sm-5 col-md-5">
        <img *ngIf="bannerImage !== null" class="mx-auto align-self-center img-position" loading="lazy"
          alt="Service banner" [src]="bannerImage | directus" data-src="{{bannerImage| directus}}">
      </div>
    </div>
  </div>

  <!-- Subtite and service description-->
  <div id="{{ 'service-content-'+ id }}" class="container px-2 px-sm-3 px-md-5 my-5" *ngIf="serviceInfo">
    <div [innerHTML]="serviceInfo.subtitle | safe: 'html'"></div>
    <h1 [innerHTML]="serviceInfo.title | safe: 'html'"></h1>
    <div [innerHTML]="serviceInfo.description | safe: 'html'"></div>
  </div>

  <!-- dynamic content -->
  <div class="container my-3 px-2 px-sm-3 px-md-5" id="{{'akky-service-plans-' + id}}">
    <ng-content />
    <ng-template [ngTemplateOutlet]="dynamicContentTemplate"></ng-template>
  </div>

  <!-- content Addon-->
  <div *ngIf="infoAddon" class="container my-3 px-2 px-sm-3 px-md-5">
    <div class="text-start text-md-center" [innerHTML]="infoAddon.title | safe: 'html'"></div>
    <div class="text-start text-md-center" [innerHTML]="infoAddon.description | safe: 'html'"></div>
  </div>

  <!-- specs -->
  <div *ngIf="section1" id="{{'service-specs-'+ id}}"
    class="services-specs-container container px-2 px-sm-3 px-md-5 my-5 d-block" [ngClass]="{'d-none' : !showFeatures}">
    <div class="row" *ngIf="showSpecsTitle">
      {{section1.img_section1}}
      <div class="col-12" [col-6]="section1.img_section1" [innerHTML]="section1.title | safe: 'html'"></div>
      <div class="col-6" *ngIf="section1.img_section1">
        <img alt="image section 1" loading="lazy" data-src="{{section1.img_section1 | directus}}"
          [src]="section1.img_section1 | directus">
      </div>
    </div>
    <ng-container
      *ngTemplateOutlet="specTemplate; context: {title: section1.specs1?.title, img: section1.specs1?.image, description: section1.specs1?.description, reverse: section1.specs_reverse}"></ng-container>
    <ng-container
      *ngTemplateOutlet="specTemplate; context: {title: section1.specs2?.title, img: section1.specs2?.image, description: section1.specs2?.description, reverse: !section1.specs_reverse}"></ng-container>
    <ng-container
      *ngTemplateOutlet="specTemplate; context: {title: section1.specs3?.title, img: section1.specs3?.image, description: section1.specs3?.description, reverse: section1.specs_reverse}"></ng-container>
  </div>

  <!-- examples -->
  <div *ngIf="examples && examples.title && examples.images?.length > 0" class="container my-3 px-2 px-sm-3 px-md-5">

    <div id="{{'web-service-examples-' + id}}" class="service-examples-container my-5">

      <div class="service-examples-imgs d-none d-md-flex flex-row justify-content-around container px-0">
        <img *ngFor="let image of examples.images; let i = index; trackBy:identify" loading="lazy"
          [alt]="'Sitio Ejemplo'+ i" [src]="image | directus" data-src="{{image | directus}}">
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center d-block d-md-none">
        <div id="{{'carousel-service-examples-' + id}}" class="carousel serviceExamplesCarousel slide"
          data-bs-ride="carousel">
          <ol class="carousel-indicators ">
            <li data-bs-target="#carousel-service-examples"
              *ngFor="let image of examples.images; let i = index; trackBy:identify" [attr.data-bs-slide-to]="i"
              [attr.aria-label]="i + 1" [ngClass]="{'active' : i === 0}"></li>
          </ol>
          <div class="carousel-inner">
            <div *ngFor="let image of examples.images; let i = index; trackBy:identify"
              [ngClass]="{'carousel-item custom-carousel-item': true, 'active' : i === 0}" data-bs-interval="1000">
              <img loading="lazy" [alt]="'Sitio Ejemplo'+ i" [src]="image | directus" data-src="{{image | directus}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- complementary services -->
  <div *ngIf="complementaryServices && complementaryServices.length > 0"
    class="container py-4 px-2 px-sm-3 px-md-5 my-5 my-md-2">
    <div class="text-start text-md-center" [innerHTML]="titleComplementaryServices | safe: 'html'"></div>
    <div id="{{'service-complementary-services-' + id}}"
      class="container complementary-services-container my-5 mx-auto px-sm-3 px-md-5 px-lg-5">
      <app-complementary-service-list [complementaryServices]="complementaryServices"></app-complementary-service-list>
    </div>
  </div>
</ng-container>

<!-- spect item template-->
<ng-template #specTemplate let-img="img" let-description="description" let-title="title" let-reverse="reverse">
  <div class="services-specs-row row d-flex my-4" *ngIf="showSpecs">

    <div
      [ngClass]="!reverse ? 'col-12 col-md-6 col-lg-6 mx-auto order-1 order-md-1 d-flex' : 'col-12 col-md-6 col-lg-6 mx-auto order-2 order-md-1 d-flex'">
      <div class="mx-auto my-auto">
        <img *ngIf="!reverse" class="limited-size-img" alt="spec image" loading="lazy" [src]="img | directus"
          data-src="{{ img | directus }}">
        <div *ngIf="reverse" [innerHTML]="title | safe: 'html'"></div>
        <div *ngIf="reverse" [innerHTML]="description | safe: 'html'"></div>
      </div>
    </div>

    <div
      [ngClass]="!reverse ? 'col-12 col-md-6 col-lg-6 mx-auto order-2  order-md-1 d-flex' : 'col-12 col-md-6 col-lg-6 mx-auto order-1 order-md-1 d-flex'">
      <div class="mx-auto my-auto">
        <img *ngIf="reverse" class="limited-size-img" loading="lazy" alt="spec image" [src]="img | directus"
          data-src="{{ img | directus }}">
        <div *ngIf="!reverse" [innerHTML]="title | safe: 'html'"></div>
        <div *ngIf="!reverse" [innerHTML]="description | safe: 'html'"></div>
      </div>
    </div>
  </div>
</ng-template>