import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBenefitsResponse } from "@core-models/benefits.model";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class BenefitsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'benefits');
  }

  public getBundle(): Observable<IBenefitsResponse>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const params = new HttpParams({ fromObject: queryDef.params });

    const options = {
      params: params,
      observe: 'body' as const
    };
    return this.http.get<IBenefitsResponse>(this.createUrl(""), options);
  }
}
