import { Component, Input } from '@angular/core';
import { IIterations } from '@core-models/generic.model';
import { EventLocationSite } from '@core-constants/gtm-const';

@Component({
  selector: 'app-mkt-service-plans',
  templateUrl: './app-mkt-service-plans-list.component.html'
})

export class MktServicePlansListComponent implements IIterations
{
  @Input() public plans: any[] = [];
  @Input() public verticalStyle: boolean = false;

  public locationServicePage: string = EventLocationSite.MarketingDigitalPage;

  public identify(index: any, item: any): any
  {
    return item;
  }
}
