import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.css']
})
export class UserAccessComponent
{
  @Input() public params = undefined;
  @Input() public enabled: boolean;

  @Output() public onCloseForm = new EventEmitter<boolean>();

  public handleEvent(data: boolean): void
  {
    this.onCloseForm.emit(data);
  }
}
