<div [innerHTML]="title | safe: 'html'"></div>
<div class="d-none d-md-block pb-5">
    <div *ngIf="monthItems" class="d-flex flex-wrap space-flex justify-content-between">
        <img class="img-month" (click)="onClickImg(item.url)" *ngFor="let item of monthItems" [src]="item.translations[0]?.img?.id | directus"
             [alt]="item.translations[0]?.img?.title">
    </div>
</div>

<div class="d-block d-md-none pb-4">
    <div id="carousel-month-movil" class="carousel generic-carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
            <li data-bs-target="#carousel-month-movil" *ngFor="let image of monthItems; let i=index; trackBy:identify"
                [attr.data-bs-slide-to]="i" [attr.aria-label]="i + 1" [ngClass]="{'active' : i === 0}"></li>
        </ol>
        <div class="carousel-inner">
            <div *ngFor=" let item of monthItems; let i=index; trackBy:identify" class="carousel-item"
                 [ngClass]="{'active' : i === 0}" data-bs-interval="10000">
                <div class="row justify-content-center">
                    <button class="carousel-control-prev darkGray left" type="button" data-bs-target="#carousel-month-movil"
                        data-bs-slide="prev">
                        <img loading="lazy" class="fs-xl" data-src="{{ '/icons/Black/chevron-left.svg' | blobstorage }}"
                        alt="Arrow right" src="{{ '/icons/Black/chevron-left.svg' | blobstorage }}">
                        <span class="visually-hidden">Previous</span>
                    </button>
        
                    <button class="carousel-control-next darkGray right" type="button" data-bs-target="#carousel-month-movil"
                        data-bs-slide="next">
                        <img loading="lazy" class="fs-xl" data-src="{{ '/icons/Black/chevron-right.svg' | blobstorage }}"
                        alt="Arrow right" src="{{ '/icons/Black/chevron-right.svg' | blobstorage }}">
                        <span class="visually-hidden">Next</span>
                    </button>
                    <img class="img-month" (click)="onClickImg(item.url)" [src]="item.translations[0]?.img?.id | directus"
                             [alt]="item.translations[0]?.img?.title">
                </div>
            </div>
        </div>
    </div>
</div>
