<div id="comitee-subheader" class="container my-5">
  <div class="text-uppercase fs-l font-weight-bold silver">{{'ponte en contacto' | translate}}</div>
  <h1 class="msb fs-xxl green">{{'Nuestro Equipo' | translate}}</h1>
</div>

<div id="comitee-contacts" class="container my-3">
  <div class="row d-flex my-4">
    <div class="col-12 col-md-6 d-flex mx-auto mb-4">
      <div class="my-auto padding-img">
        <div class="card-directive mx-auto my-auto">
          <img class="padding-img mx-auto d-block" height="180" loading="lazy" alt="Director General"
               [src]="'/images/AlejandroCastro.png' | blobstorage"
               data-src="{{'/images/AlejandroCastro.png' | blobstorage}}">
          <div class="p-2">
            <div class="msb black my-auto fs-xxl">
              Alejandro Castro
            </div>
            <div class="font-weight-bold gray my-auto fs-xl">
              {{'Director General' | translate}}
            </div>
            <div class="row mt-2 justify-content-start justify-content-center align-items-center">
              <a [href]="linkedinChiefExecutive" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-linkedin size-social"></i></a>
              <a href="mailto:alejandro.castro@mosaicholdings.mx" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-mail size-social"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex mx-auto mb-4">
      <div class="my-auto padding-img">
        <div class="card-directive mx-auto my-auto">
          <img class="padding-img mx-auto d-block" height="180" loading="lazy" alt="Gerente de Servicio al Cliente"
               [src]="'/images/MarthaGrimaldo.png' | blobstorage"
               data-src="{{'/images/MarthaGrimaldo.png' | blobstorage}}">
          <div class="p-2">
            <div class="msb black my-auto fs-xxl">
              Martha Grimaldo
            </div>
            <div class="font-weight-bold gray my-auto fs-xl">
              {{'Gerente de Servicio al Cliente' | translate}}
            </div>
            <div class="row mt-2 justify-content-start justify-content-center align-items-center">
              <a [href]="linkedinCustomerServiceManager" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-linkedin size-social"></i></a>
              <a href="mailto:mgrimaldo@akky.mx" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-mail size-social"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex mx-auto mb-4">
      <div class="my-auto padding-img">
        <div class="card-directive mx-auto my-auto">
          <img class="padding-img mx-auto d-block" height="180" loading="lazy" alt="Gerente de TI"
               [src]="'/images/FabricioRuales.png' | blobstorage"
               data-src="{{'/images/FabricioRuales.png' | blobstorage}}">
          <div class="p-2">
            <div class="msb black my-auto fs-xxl">
              Fabricio Ruales
            </div>
            <div class="font-weight-bold gray my-auto fs-xl">
              {{'Gerente de TI' | translate}}
            </div>
            <div class="row mt-2 justify-content-start justify-content-center align-items-center">
              <a [href]="linkedinITManager" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-linkedin size-social"></i></a>
              <a href="mailto:fruales@akky.mx" target="_blank" class="text-decoration-none text-center col-2 col-md-2 col-lg-2 p-0 m-0"><i class="fas d-inline-block i-mail size-social"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
