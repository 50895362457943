import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenManager } from '@core-managers/token.manager';

@Component({
  selector: 'app-user-esu-access',
  templateUrl: './user-esu-access.component.html'
})
export class UserEsuAccessComponent implements OnInit
{
  public params = undefined;
  public isModalDisplay: boolean = false;
  public isMainLogin: boolean = false;
  public enabled: boolean = true;

  constructor(private router: Router,
    private tokenManager: TokenManager) { }

  public ngOnInit(): void
  {
    this.validateUserIsLoggedIn();
  }

  public validateUserIsLoggedIn(): void
  {
    const user = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();

    if (user && token)
    {
      this.goToHome();
    }
  }

  public goToHome(): void
  {
    this.router.navigate(['/']);
  }
}
