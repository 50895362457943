<div class="deal-subscribe-color container-fluid  columna w-min">
    <div class="py-sm-5 justify-content-center d-flex subscribe">
        <div class="container-subscribe d-none">
            <h3 class="libre-text">
                {{'Suscríbete' | translate}}
            </h3>
            <p class="silver font-body fw-bold">{{'¡Obtén las ofertas más exclusivas y entérate antes que nadie!' |
                translate}}</p>
            <form>
                <input class="input-subscribe text-uppercase" placeholder="{{'Nombre(s)' | translate}}" type="text"
                    minlength="1" [(ngModel)]="name" name="name" maxlength="30" required>
                <input class="input-subscribe text-uppercase" placeholder="{{'correo electrónico' | translate}}"
                    type="email" minlength="1" [(ngModel)]="email" name="email" maxlength="50" required>
                <button class="button-send" type="submit">Enviar</button>
            </form>
        </div>
        <div class="container-subscribe d-none d-md-block pb-5">
            <img class="img-subscribe" loading="lazy" data-src="{{ '/images/message-sent.svg' | blobstorage }}"
                alt="Experiencia probada" src="{{ '/images/message-sent.svg' | blobstorage }}">
        </div>
        <div class="contact-section d-none d-md-block pb-5">
            <h3>{{'Contáctanos' | translate}}</h3>
            <div class="d-flex d-flow">
                <div>
                    <span class="fas d-inline-block i-mail icon-size"></span>
                    <span class="text-uppercase fw-bolder">{{'mándanos un correo' | translate}}</span>
                    <br />
                    <span class="font-body">{{'Información adicional:' | translate}}</span>
                    <p class="font-body"><a class="black" href="mailto:ventas@akky.mx">{{'ventas@akky.mx' |
                            translate}}</a></p>
                    <span class="font-body">{{'Servicio a cliente y soporte:' | translate}} </span>
                    <p class="font-body"><a class="black" href="mailto:ayuda@akky.mx">{{'ayuda@akky.mx' |
                            translate}}</a></p>
                </div>
                <div class="ms-3">
                    <span class="fas d-inline-block i-chat icon-size"></span>
                    <span class="text-uppercase fw-bolder">
                        {{'Platícanos' | translate}}
                    </span>
                    <div class="clickable" (click)="openChat()">
                        <span class="font-body">
                            {{'Chat en vivo' | translate}}
                        </span>
                    </div>
                    <ng-container>
                        <a href="{{urlWhatsApp}}" target="_blank">
                            <span class="font-body text-black">Whatsapp</span>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="contact-section d-block d-md-none pb-2">
            <h3>{{'Contáctanos' | translate}}</h3>
            <div class="d-flex d-flow">
                <div>
                    <span class="fas d-inline-block i-mail icon-size"></span>
                    <span class="text-uppercase fw-bolder">{{'mándanos un correo' | translate}}</span>
                    <br />
                    <span class="font-body">{{'Información adicional:' | translate}}</span>
                    <p class="font-body"><a class="black" href="mailto:ventas@akky.mx">{{'ventas@akky.mx' |
                            translate}}</a></p>
                    <span class="font-body">{{'Servicio a cliente y soporte:' | translate}} </span>
                    <p class="font-body"><a class="black" href="mailto:ayuda@akky.mx">{{'ayuda@akky.mx' |
                            translate}}</a></p>
                    <div>
                        <div>
                            <span class="fas d-inline-block i-chat icon-size"></span>
                            <span class="text-uppercase fw-bolder">
                                {{'Platícanos' | translate}}
                            </span>
                            <div class="clickable" (click)="openChat()">
                                <span class="font-body">
                                    {{'Chat en vivo' | translate}}
                                </span>
                            </div>
                            <ng-container>
                                <a href="{{urlWhatsApp}}" target="_blank">
                                    <span class="font-body text-black">Whatsapp</span>
                                </a>
                            </ng-container>
                        </div>
                        <div class="img-m-subscribe">
                            <img class="img-subscribe" loading="lazy"
                                data-src="{{ '/images/message-sent.svg' | blobstorage }}" alt="Experiencia probada"
                                src="{{ '/images/message-sent.svg' | blobstorage }}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>