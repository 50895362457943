import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDealsResponse } from "@core-models/deals.model";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DealsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'deals');
  }

  public getBundle(): Observable<IDealsResponse>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const params = new HttpParams({ fromObject: queryDef.params });

    const options = {
      params: params,
      observe: 'body' as const
    };

    //TODO diana: corregir esto para que utilice getRequestConfig()
    return this.http.get<IDealsResponse>(this.createUrl(""), options);
  }
}