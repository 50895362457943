import { HttpHeaders, HttpParams } from "@angular/common/http";

export class UrlQueryDef
{
  // ********************************************************************
  //#region Properties / Variables
  // ********************************************************************

  public includes: string = "";
  public domainSpecificContextId?: number;
  public params: any;

  // #endregion

  // ********************************************************************
  //#region Methods
  // ********************************************************************

  /** Method to add HttpParams into our request. Accepts a <name> <value> pair */
  public addParam(name: string, value: any): void
  {
    if (this.params == null) { this.params = {}; }

    this.params[name] = value;
  }

  public getRequestConfig(): any
  {
    const requestConfig = {
      params: this._getParamsMap(),
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Vary': 'Origin, Cache-Control' })
    };

    return requestConfig;
  }

  public getRequestConfigWithAuthorizationToken(authorizationToken, languageCode = null): any
  {
    let requestConfig = this.getRequestConfig();

    if (authorizationToken != null)
    {
      requestConfig = {
        params: this._getParamsMap(),
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': authorizationToken })
      };
    }

    if (languageCode != null && !requestConfig.headers.has('Accept-Language'))
    {
      requestConfig.headers = requestConfig.headers.append('Accept-Language', languageCode);
    }

    return requestConfig;
  }

  public getRequestConfigForRedirect(authorizationToken): any
  {
    let requestConfig = this.getRequestConfig();

    if (authorizationToken != null)
    {
      requestConfig = {
        responseType: 'text',
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*", 'Authorization': authorizationToken })
      };
    }

    return requestConfig;
  }

  // #endregion

  // ********************************************************************
  //#region Support Methods
  // ********************************************************************

  private _getParamsMap(): HttpParams
  {
    let paramsMap = new HttpParams();

    if (this.params != null)
    {
      // Add properties from params to params map
      for (const paramName in this.params)
      {
        paramsMap = paramsMap.append(paramName, this.params[paramName]);
      }
    }

    if (this.includes != "")
    {
      paramsMap = paramsMap.append("includes", this.includes);
    }

    if (this.domainSpecificContextId)
    {
      paramsMap = paramsMap.append("domainSpecificContextIds", this.domainSpecificContextId.toString());
    }

    return paramsMap;
  }

  //#endregion
}
