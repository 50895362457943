import { HttpClient } from '@angular/common/http';
import { Environment } from '@environments';
import { Observable } from 'rxjs';

export class ApiServiceBase
{
  private _apiUrl: string = "";

  constructor(protected http: HttpClient) { }

  // ***********************************************************
  // Support Methods
  // ***********************************************************

  public get apiURL(): string
  {
    return `api/${this._apiUrl}`;
  }

  public set apiURL(value: string)
  {
    if (value && value.trim() != "")
    {
      this._apiUrl = value;
    }
    else
    {
      throw new Error("API URL required");
    }
  }

  protected createUrl(method: string = "", ...identifiers: any[]): string
  {
    let returnValue = `${this.apiURL}`;

    if (method)
    {
      returnValue = `${returnValue}/${method}`;
    }

    if (identifiers)
    {
      identifiers.forEach(identifier => 
      {
        if (identifier != null)
        {
          returnValue += `/${identifier}`;
        }
      });
    }

    return returnValue;
  }

  protected createMicroserviceURL(method: string = "", ...identifiers: any[] | undefined): string
  {
    let returnValue = `${Environment.MicroserviceURL}${this.apiURL}`;

    if (method && method != "")
    {
      returnValue = `${returnValue}/${method}`;
    }

    if (identifiers)
    {
      identifiers.forEach(identifier => 
      {
        if (identifier != null)
        {
          returnValue += `/${identifier}`;
        }
      });
    }

    return returnValue;
  }

  /** 
  * Takes an Observable - and handles the success and fail conditions - including logging the results.
  */
  protected _handleObservableResponse(subject: Observable<any>): void
  {
    subject.subscribe({
      next: (errorData: any) =>
      {
        try
        {
          // Not Found
          if (errorData && errorData.status == 404)
          {
            return;
          }

          // Unauthorized
          if (errorData && errorData.status == 401)
          {
            if (errorData.statusText != "Unauthorized")
            {
              window.location.href = "/";
            }

            return;
          }

          if (errorData && errorData.status == 500)
          {
            // Not in cache
            if (errorData.error && errorData.error.message == "User not in Cache... possibly timed out.")
            {
              window.location.href = "/";
            }

            return;
          }

          // Outage
          if (errorData && errorData.status == 503)
          {
            return;
          }
        }
        catch
        {
          return;
        }
      }
    });
  }
}
