<div class="slider-service box-shadow-home">
  <div class="service-icon-container">
    <img [src]="_service.icon?.id | directus" 
    [alt]="_service.icon?.title" 
    data-src="{{_service.icon?.id | directus}}" 
    class="icon-service" 
    srcset="{{_service.icon_1_5x?.id | directus}} 1.5x">
  </div>
  <div class="slider-service-data" *ngIf="_translations !== undefined">
    <div class="slider-service-title">
      <div class="font-14b" [innerHTML]="_translations.title | safe: 'html'"></div>
    </div>
    <div class="font-10b text-muted" [innerHTML]="_translations.cupon | safe: 'html'"></div>

    <div class="slider-service-subtitle">
      <div class="slider-service-price">
        <b *ngIf="_service.price">${{_service.price}}</b> <b
          *ngIf="_service.price === null || _service.price === ''">{{_translations.price_text}}</b><span
          [innerHTML]="_translations.recurrencia | safe: 'html'"></span>
        <del class="text-muted" *ngIf="_service.old_price">${{_service.old_price}}</del>
      </div>
      <div class="d-flex text-uppercase btns-links" [ngStyle]="{'justify-content': packageId ? 'space-between': 'end'}">
        <a class="text-muted" href="{{_service.url}}" [innerHTML]="_translations.link | safe: 'html'"></a>
        <div *ngIf="packageId">
          <button class="btn-sm btn-primary-outline" (click)="onComboButtonClicked()">
            {{ 'Agregar' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
