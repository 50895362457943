import { DateConst } from "@core-constants/date.const";
import { EGtmEvent } from "@core-constants/gtm-const";
import { EAdditionalService, ServiceConst } from "@core-constants/product-service.const";
import { IPlan } from "@core-models/plans.model";
import { IProduct } from "@core-models/product.model";
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { Tools } from "@shared-utils/tools.util";

export class ServiceHelper
{
  public static getPriceFromHtml(stringHtml: string): number
  {
    const stringText = new DOMParser().parseFromString(stringHtml, 'text/html');
    return Number(stringText.getElementsByClassName('priceNumber')[0]?.textContent.replace(/,/g, ''));
  }

  public static splitAddServPkgAndPlan(cartServiceData: string): any[]
  {
    const splitarr = cartServiceData.split(',');

    return splitarr.map(e =>
    {
      const keyVal = e.split('=');

      if (keyVal[0] == 'ASP_ID')
      {
        return { ASP_ID: keyVal[0], value: keyVal[1] };
      }
      else
      {
        return { APL_ID: keyVal[0], value: keyVal[1] };
      }
    });
  }

  public static getCoverageFromHtml(stringHtml: string | undefined): string
  {
    const dom = new DOMParser().parseFromString(stringHtml, 'text/html');

    let text = dom.getElementsByClassName('priceNumber')[0]?.nextElementSibling?.children[0]?.textContent || "";

    if (text)   
    {
      text = text.toLocaleLowerCase();

      if (text.includes(DateConst.ESMonthLablel) || text.includes(DateConst.ENMonthLablel))
      {
        return "1m";
      }

      if (text.includes(DateConst.ESYearLablel) || text.includes(DateConst.ENYearLablel))
      {
        return "1y";
      }
    }

    return "";
  }

  public static addViewItemListEvent(items: IPlan[], aditionalServiceId: EAdditionalService): void
  {
    const productList: IProduct[] = [];

    const serviceName: string = ServiceConst.AdditionalServicePkg.get(aditionalServiceId);

    if (!Tools.isNullOrEmpty(items))
    {
      items.map(item =>
      {
        const addSerPkgPlan = ServiceHelper.splitAddServPkgAndPlan(item?.id_servicio_carrito?.valor);

        productList.push({
          id: addSerPkgPlan.find((x: any) => x.APL_ID).value,
          name: item.nombre,
          listId: addSerPkgPlan.find((x: any) => x.ASP_ID).value,
          listName: serviceName,
          coverage: ServiceHelper.getCoverageFromHtml(item?.traducciones[0]?.precio_actual),
          price: ServiceHelper.getPriceFromHtml(item?.traducciones[0]?.precio_actual),
          category: serviceName,
          variant: item.nombre,
          isPublic: true,
          quantity: 1
        });
      });
    }

    GtmTrackingService.viewItemListEvent(EGtmEvent.ViewItemList, GtmTrackingService.mapProducts(productList), serviceName, aditionalServiceId);
  }
}
