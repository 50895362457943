import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from "@angular/core";
import { EKeyCode } from '@core-constants/input-key.const';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit
{
  private _currentValue: number;
  private _timeoutId = undefined;
  public inputId: string;

  @Output() public onValueChanges = new EventEmitter<number>();

  @Input() public label: string = "";
  @Input() public parentId: string = "";
  @Input() public placeholder: string = "";
  @Input() public isFullWidth: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public min: number = 0;
  @Input() public max: number;
  @Input() public maxLength: number = 4;

  @Input() public set value(newValue)
  {
    this._currentValue = newValue;
  }

  public get value(): number
  {
    return this._currentValue;
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnInit(): void
  {
    this.inputId = "input_" + this.parentId;
  }

  public onChange(): void
  {
    this.emitEvent();
  }

  public onKeydown(event: KeyboardEvent): boolean
  {
    const numberKeyCode: number = +(event.key);
    const keyCode: string = event.key;

    if ((numberKeyCode >= 0 && numberKeyCode <= 9) ||
      keyCode == EKeyCode.Backspace
      || keyCode == EKeyCode.Delete
      || keyCode == EKeyCode.ArrowLeft
      || keyCode == EKeyCode.ArrowRight)
    {
      this.emitEvent();
    }
    else
    {
      return false;
    }
  }

  public emitEvent(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.changeDetectorRef.detectChanges();

      if (this._timeoutId != undefined)
      {
        clearTimeout(this._timeoutId);
        this._timeoutId = undefined;
      }

      //setear nuevo valor
      this._currentValue = this.value;
      this._timeoutId = setTimeout(() =>
      {
        this.onValueChanges.emit(this.value);
      }, 500);
    }
  }
}
