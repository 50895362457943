import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomainConst, EDomainType } from "@core-constants/domain.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EGtmEvent } from "@core-constants/gtm-const";
import { EServiceName, EServiceType, ServiceConst } from "@core-constants/product-service.const";
import { RegexConst } from "@core-constants/regex.const";
import { ECartItemOperation, ECartItemStatus, ShoppingCartConst } from "@core-constants/shopping-cart.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { IndividualSuggestionManager } from "@core-managers/individual-suggestion.manager";
import { DropDownItem, UpdateDomainServiceDto } from "@core-models/generic.model";
import { IPurchaseSuggestionRate, IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { DomainServiceItem, GroupedShoppingCart, ICoverageOption, IItemProductLite, ShoppingCarBaseContent, ShoppingCartItem, ShoppingCartUpdatedItemResponse, VariousServiceItem } from "@core-models/shopping-cart-items.model";
import { GtmTrackingService } from "@shared-base/gtm-tracking.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";
import { finalize } from "rxjs";

@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ["./shopping-cart-item.component.css"]
})
export class ShoppingCartItemComponent
{
  private _itemInMemory: GroupedShoppingCart;
  public readonly emailOXCapacityOptions: DropDownItem[] = ServiceConst.CapacityEmailOXOptionsGB;

  public disableQuantityGroupButtons: boolean = false; //this is not for Addons
  public disabledCapacityButtons: boolean = false;//this is only for Addons
  public disableCoverageDropdown: boolean = false;

  public isPersonalizedQuantity: boolean = false;
  public showDeleteItemModal: boolean = false;
  private _item: GroupedShoppingCart;

  @Output() public onItemChanges = new EventEmitter<boolean>();

  constructor(protected shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    protected toast: ToastService,
    protected checkoutManager: CheckoutManager,
    protected individualSuggestionsManager: IndividualSuggestionManager
  ) { }

  // ********************************************************************
  //#region Props
  // ********************************************************************

  @Input() public set item(value: GroupedShoppingCart)
  {
    const hasUpgradeSuggestions = value.product.suggestions.some(x => x.individualFeatures.isValidUpgrade);

    if(hasUpgradeSuggestions)
    {
      const areAllSuggestionAdded = value.product.suggestions.filter(x => !x.individualFeatures.isValidUpgrade).every(x => x.individualFeatures.isAdded);
      value.product.suggestions = !areAllSuggestionAdded
      ? value.product.suggestions.filter(x => x.individualFeatures.isValidUpgrade)
      : value.product.suggestions;
    }

    this._item = value;
    this._itemInMemory = this.deepCopy(value);
    const isUpgrade = value.product.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);

    if( isUpgrade )
    {
      const suggestion = value.product.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
      this.updateVisualItem(suggestion);
    }
    this.setEmailOXSelectedCapacity();
  }

  public get item(): GroupedShoppingCart
  {
    return this._item;
  }

  public get itemProduct(): ShoppingCartItem
  {
    return this._item.product;
  }

  public get itemProductInMemory(): ShoppingCartItem
  {
    return this._itemInMemory.product;
  }

  public get itemProductSuggestionLite() : IItemProductLite
  {
    return {
      id: this.itemProductInMemory.id,
      fullName: this.itemProductInMemory.fullName,
      rate: this.itemProductInMemory.rate,
      coverageOptions: this.itemProductInMemory.coverageOptions,
      finalAmount: this.itemProductInMemory.rate.finalAmount,
      coverageId: this.itemProductInMemory.coverageId,
      quantity: this.itemProductInMemory.quantity,
      itemCounter: this.item.counter,
      containUpgradeSuggestion: this.itemContainsUpgradeSuggestion
    };
  }

  public get itemContainsUpgradeSuggestion(): boolean
  {
    return this.itemProduct.suggestions.some(x => x.individualFeatures.isValidUpgrade);
  }

  public get isDomain(): boolean
  {
    return this.itemProduct.type === EServiceName.Domain;
  }

  public get isRemovable(): boolean
  {
    return this.itemProduct.isRemovable === true;
  }

  public get isRenewal(): boolean
  {
    return this.itemProduct.operationId == ECartItemOperation.Renewal;
  }

  public get features(): string[]
  {
    return this.itemProduct.featureDetail.features;
  }

  public get suggestions(): IShoppingCartIndividualStoredSuggestionPlus[]
  {
    return this.itemProduct.suggestions;
  }

  public get hasVariousServices(): boolean
  {
    return this.itemProduct.variousServices && this.itemProduct.variousServices.length > 0;
  }

  public get isUserServiceRegistration(): boolean
  {
    return this.itemProduct.type == EServiceName.Service && this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  public get isNotChargableDomain(): boolean
  {
    return this.isDomain && this.itemProduct.isNotChargeableDomain && !this._isGobEduCloseDomain;
  }

  public get isEmtpyDomainName(): boolean
  {
    return this.isDomain && this.itemProduct.name === '';
  }

  public get selectedQuantity(): number
  {
    return this.isPersonalizedQuantity ? -1 : this.itemProduct.quantity;
  }

  public get isAddonCumulative(): boolean
  {
    return this._isAddon && this.itemProduct.isAccumulable && (this.itemProduct.operationId == ECartItemOperation.Registration || this.itemProduct.operationId == ECartItemOperation.Renewal);
  }

  private get _isAddon(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Addon;
  }

  private get _isCloseDomain(): boolean
  {
    return this.isDomain && DomainConst.CloseDomains.includes(this.itemProduct.domainType) === true;
  }

  private get _isSynchronization(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Service && this.itemProduct.synchronization === true;
  }

  private get _isGobEduCloseDomain(): boolean
  {
    return this.itemProduct.domainType == EDomainType.gob_mx || this.itemProduct.domainType == EDomainType.edu_mx;
  }

  private get _isCoverageExtension(): boolean
  {
    return this.itemProduct.synchronization || this.itemProduct.operationId === ECartItemOperation.Transfer;
  }

  // #endregion

  // ********************************************************************
  //#region Display validations and helpers
  // ********************************************************************

  public get totalItemsShoppingCart(): number
  {
    return this.checkoutManager.cartItemsCount;
  }

  public hasValidFeatures(): boolean
  {
    return !Tools.isNullOrEmpty(this.itemProduct.featureDetail?.features);
  }

  public get hasSuggestions(): boolean
  {
    return !Tools.isNullOrEmpty(this.itemProduct.suggestions);
  }

  public hasOffer(rate: IPurchaseSuggestionRate): boolean
  {
    return rate && rate.offerAmount > 0;
  }

  public getProductName(): string
  {
    if ((this.isDomain && !this.isEmtpyDomainName) || (!this.isDomain && this.itemProduct.name))
    {
      return this.itemProduct.fullName;
    }

    if (this.isDomainDiverseService())
    {
      return this.itemProduct.name;
    }

    return '';
  }

  public domainServices(): DomainServiceItem[] | undefined
  {
    const domServices = this.itemProduct.domainServices?.filter((x: DomainServiceItem) => x.isAdded === true);

    return domServices?.length > 0 ? domServices : undefined;
  }

  public getVariousServiceAmount(service: VariousServiceItem): number | string
  {
    if (service.coverage.rate.finalAmount === 0)
    {
      return '';
    }

    if (this.isAddonCumulative && this._isSynchronization)
    {
      return service.coverage.rate.finalAmount;
    }

    return service.coverage.rate.finalAmount * this.itemProduct.quantity;
  }

  public getDisplayMessageRate0(): string
  {
    return this._isCloseDomain ? "Pendiente documentación" : this.isAddonCumulative == false ? "Sin costo" : "";
  }

  public isValidCoverage(): boolean
  {
    return this.itemProduct.coverageOptions.find(x => x.id != 0) != undefined;
  }

  public isDomainDiverseService(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.DiverseService && this.itemProduct.serviceData?.domainId != null && this.itemProduct.serviceData?.domainId > 0;
  }

  public showCoverageDropdown(): boolean
  {
    if (this.itemProduct.coverageOptions?.length > 0 && !this._isGobEduCloseDomain)
    {
      const coverageId = this.itemProduct.coverageId;
      const selectedCoverage: number = this.itemProduct.coverageOptions.find((x: ICoverageOption) => x.id == coverageId)?.value;

      return this._isSynchronization ? true : selectedCoverage > 0 ? true : selectedCoverage == -1;
    }

    return false;
  }

  public getOperationTypeName(): string
  {
    const operationId: ECartItemOperation = this.itemProduct.operationId;
    return ShoppingCartConst.OperationLabels.get(operationId) + " ";
  }

  public getCoverageLabel(): string
  {
    return this._isCoverageExtension ? 'EXT. DE COBERTURA' : 'COBERTURA';
  }

  public getObjectId(): string
  {
    if (this.isRenewal && (this._isAddon || this._isSynchronization))
    {
      return "ID: " + this.itemProduct.objectId;
    }

    return "";
  }


  public getAddonObjectId(): string
  {
    if (this._isAddon)
    {
      if (ServiceConst.OxAddOn.includes(this.itemProduct.serviceData.addonType))
      {
        return this.translateService.getElement("Buzón") + ": " + this.itemProduct.serviceData.serviceId;
      }

      return this.translateService.getElement("Servicio") + " Id: " + this.itemProduct.serviceData.serviceId;
    }

    return "";
  }

  public getTotalByProduct(): number
  {
    return this.checkoutManager.calculateTotalByProduct(this.itemProduct, 1);
  }

  // #endregion

  // ********************************************************************
  //#region Addons
  // ********************************************************************


  private setEmailOXSelectedCapacity(): void
  {
    const capacity = this.emailOXCapacityOptions.find(x => x.value === this.itemProduct.quantity);

    this.isPersonalizedQuantity = capacity == undefined;
  }

  public onAddonCapacitySelectedChanges($event: DropDownItem): void
  {
    const quantitySelected = $event?.value;

    this.isPersonalizedQuantity = quantitySelected == -1;

    if (this.isPersonalizedQuantity == false)
    {
      this.updateAddonQuantity(quantitySelected);
    }
  }

  public onAddonItemValueChanges($event: any): void
  {
    this.isPersonalizedQuantity = true;

    const quantitySelected = $event;
    const isMatch: boolean = quantitySelected.match(RegexConst.Numeric) !== null;

    if (quantitySelected > 0 && quantitySelected < 9999 && isMatch)
    {
      this.updateAddonQuantity(quantitySelected);
    }
  }

  private updateAddonQuantity(quantitySelected: number): void
  {
    this.disabledCapacityButtons = true;
    this.onItemChanges.emit(true);

    this.shoppingCartDataService.updateAddonQuantity(this.itemProduct.id, this.itemProduct.serviceType, quantitySelected)
      .subscribe({
        next: () =>
        {
          this.checkoutManager.setCartItemAddonQuantity(this.itemProduct.id, quantitySelected);
        },
        complete: () =>
        {
          this.disabledCapacityButtons = false;
          this.onItemChanges.emit(false);
        }
      });
  }

  // #endregion

  // ********************************************************************
  //#region EVENTS
  // ********************************************************************

  private get isUpgradeItemSelected(): boolean
  {
    return this.itemProduct.suggestions.length > 0
            ? this.itemProduct.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)
            : false;
  }

  public onItemCoverageChange($event: DropDownItem): void
  {
    const coverage: number = $event?.value;
    if(typeof coverage !== "number") { return; }

    this.disableCoverageDropdown = true;

    this.isUpgradeItemSelected
      ? this.handleCoverageUpgradeItem(coverage)
      : this.handleCoverageMainItem(coverage);
  }

  private handleCoverageMainItem(coverageId: number): void
  {
    this.onItemChanges.emit(this._isAddon);

    this.shoppingCartDataService.updateCoverage(this.itemProduct.id, this.itemProduct.serviceType, coverageId)
    .pipe(finalize(() =>
          {
            this.disableCoverageDropdown = false;
            this.onItemChanges.emit(false);
          }))
    .subscribe({
      next: (response: ShoppingCartUpdatedItemResponse) =>
      {
        if (this._isAddon)
        {
          this.checkoutManager.setItem(response.item);
        }
        else
        {
          this.checkoutManager.setCartItemCoverage(this.itemProduct.id, coverageId);
        }
      }
    });
  }

  private handleCoverageUpgradeItem(coverageId: number): void
  {
    const suggestionId = this.itemProduct.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)?.suggestionId;

    this.shoppingCartDataService.updateSuggestionUpgradeCoverage(this.itemProduct.id, suggestionId, coverageId)
    .pipe(finalize(() => this.disableCoverageDropdown = false))
    .subscribe({
      next: (value: boolean) =>
      {
        if(!value) { return; }

        this.checkoutManager.setCartItemUpgradeCoverage(this.itemProduct.id, suggestionId, coverageId);
      },
      error: () => this.toast.setErrorToast(ErrorMessageConst.OperationFailed)
    });
  }

  public onDeleteIconClick(): void
  {
    this.showDeleteItemModal = false;

    if (this.isRemovable)
    {
      if (this.itemProduct.serviceType == EServiceType.Domain)
      {
        if (this.itemProduct.status == ECartItemStatus.Renewal && this.isValidCoverage() && !this.itemProduct.isNotChargeableDomain)
        {
          this.removeDomainRenewaCartItem();
        }
        else
        {
          this.showDeleteItemModal = true;
        }
      }
      else if ((this.itemProduct.type == EServiceName.Service) && (this.itemProduct.operationId == ECartItemOperation.Registration))
      {
        this.onRemoveItemQuantityGroupClick(this.itemProduct.quantityGroup);
      }
      else
      {
        this.onRemoveCartItemClick();
      }
    }
  }

  public onUpdateServiceByQuantityGroup(quantity: number): void
  {
    this.disableQuantityGroupButtons = true;
    const lastCounterValue = this.item.counter;
    const quantityGroup = this.item.quantityGroup;

    this.onItemChanges.emit(true);

    this.isUpgradeItemSelected
      ? this.handleUpdateServiceByQuantityGroupUpgrade(quantity, lastCounterValue)
      : this.handleUpdateServiceByQuantityGroup(quantity, lastCounterValue, quantityGroup);

  }

  private handleUpdateServiceByQuantityGroup(quantity: number, lastCounterValue: number, quantityGroup: number): void
  {
     this.shoppingCartDataService.updateServiceByQuantityGroup(quantityGroup, this.itemProduct.coverageId, quantity)
     .pipe(finalize(() =>
      {
        this.onItemChanges.emit(false);
        this.disableQuantityGroupButtons = false;
      }))
      .subscribe({
        next: (response: ShoppingCartUpdatedItemResponse) =>
        {
          this.checkoutManager.initializeCartBaseData(response.baseCart);
          this.checkoutManager.setQuantityGroupCounter(quantityGroup, quantity);
        },
        error: () =>
        {
          this.checkoutManager.setQuantityGroupCounter(quantityGroup, lastCounterValue);
          this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
        }
      });
  }

  private handleUpdateServiceByQuantityGroupUpgrade(quantity: number, lastCounterValue: number): void
  {
    const suggestionId = this.itemProduct.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)?.suggestionId;

    this.shoppingCartDataService.updateSuggestionUpgradeQuantity(this.itemProduct.id, suggestionId, quantity)
    .pipe(finalize(() =>
      {
        this.disableQuantityGroupButtons = false;
        this.onItemChanges.emit(false);
      }))
      .subscribe({
        next: (value: boolean) =>
        {
          if(!value)
          {
            this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, lastCounterValue);
            this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
          }

          this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, quantity);
        },
        error: () =>
        {
          this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, lastCounterValue);
          this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
        }
      });
  }

  public onCloseConfirmationModalClick(): void
  {
    this.showDeleteItemModal = false;
  }

  // #endregion

  // ********************************************************************
  //#region Domain services
  // ********************************************************************

  private resetDomainServicesCoverages(): void
  {
    const domainServices: UpdateDomainServiceDto[] = this.itemProduct.domainServices
      .map((x: DomainServiceItem) =>
      {
        const dtoItem = {
          id: x.id,
          coverageId: x.coverageId = x.coverageOptions[0].id
        };

        return dtoItem;
      });

    this.updateDomainServicesCoverages(this.itemProduct.id, domainServices);
  }

  private updateDomainServicesCoverages(domainId: number, domainServices: UpdateDomainServiceDto[]): void
  {
    this.shoppingCartDataService.updateDomainServices(domainId, domainServices).subscribe({
      next: () =>
      {
        this.checkoutManager.setDomainServicesCoverage(domainId, domainServices);
      }
    });
  }
  // #endregion

  // ********************************************************************
  //#region Remove Items
  // ********************************************************************

  public onRemoveItemQuantityGroupClick(quantityGroup: number): void
  {
    this.shoppingCartDataService.removeServiceByQuantityGroup(quantityGroup).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        this.checkoutManager.removeCartItemQuantityGroup(quantityGroup);
        this.checkoutManager.initializeCartBaseData(response);

        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [this.item], this.itemProduct.rate.finalAmount);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  public onRemoveCartItemClick(): void
  {
    this.shoppingCartDataService.removeItem(this.itemProduct.id, this.itemProduct.serviceType).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        this.checkoutManager.removeCartItem(this.itemProduct.id);
        this.checkoutManager.initializeCartBaseData(response);

        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [this.item], this.itemProduct.rate.finalAmount);

        this.showDeleteItemModal = false;
        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  private removeDomainRenewaCartItem(): void
  {
    this.shoppingCartDataService.removeDomainRenewal(this.itemProduct.name, this.itemProduct.domainType).subscribe({
      next: () =>
      {
        this.checkoutManager.removeDomainRenewalCartItem(this.itemProduct.id);

        this.resetDomainServicesCoverages();

        const renewalServices: number[] = this.itemProduct.domainServices
          .filter((x: DomainServiceItem) => x.operationId == ECartItemOperation.Renewal || x.maxCoverage == 0)
          .map((x: DomainServiceItem) => { return x.id; });

        if (!Tools.isNullOrEmpty(renewalServices))
        {
          this.checkoutManager.removeDomainServices(this.itemProduct.id, renewalServices);
        }

        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [this.item], this.itemProduct.rate.finalAmount);

        this.showDeleteItemModal = false;
        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }
  // #endregion

  public onSuggestionUpgradeItem(event: IShoppingCartIndividualStoredSuggestionPlus | undefined) : void
  {
    this.updateVisualItem(event);
  }

  private updateVisualItem(suggestion: IShoppingCartIndividualStoredSuggestionPlus | undefined): void
  {
    if(!suggestion)
    {
      this._item = this.deepCopy(this._itemInMemory);
      return;
    }

    const { coverage, coverageId } = suggestion;
    const { displayName, features, quantity } = suggestion.individualFeatures;
    const rate = suggestion.coverage.find(x => x.id === coverageId)?.rate;
    const newProduct: ShoppingCartItem = {
        ...this.item.product,
        name: displayName,
        fullName: displayName,
        featureDetail: { imgRoute: '', iconRoute: '', features },
        coverageOptions: [...coverage],
        coverageId,
        rate
    };

    this._item.counter = quantity;
    this._item = { ...this.item, product: newProduct };
  }

  private deepCopy(obj: GroupedShoppingCart): GroupedShoppingCart
  {
    return structuredClone(obj);
  }

  public trackById(_: number, suggestion: IShoppingCartIndividualStoredSuggestionPlus): number
  {
    return suggestion.id;
  }

}
