import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { ServicePoliciesDataService } from '@core-data-services/service-policies.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { ISEOComponent } from '@core-models/seo.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { QueryParams } from '@core-constants/url-param.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-services-policies',
  templateUrl: './services-policies.component.html',
  styleUrls: ['./services-policies.component.css']
})

export class ServicesPoliciesComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public pagetitle: string = "Políticas de servicios Akky";
  private pageSEOTitle: string = "Conoce nuestra política de servicios | Akky";
  private pageSEODescription: string = "Conoce nuestras políticas de servicios, con la finalidad de que estés informado de los lineamientos del servicio que te brindamos.";

  public servicePoliceCategories: any[] = [];
  public policiesListVisible: boolean = true;
  public policiesContentVisible: boolean = false;
  public showErrorVisible: boolean = false;
  public policiesContent: string = "";
  public curPdfUrl: string = "";

  constructor(@Inject(PLATFORM_ID) private platformId,
    private servicePoliciesDataService: ServicePoliciesDataService,
    public translateService: TranslateService,
    public seoService: SEOService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private destroyRef$: DestroyRef
  )
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/politicas-de-servicios'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });
  }

  public get isPdfValid(): boolean
  {
    const pdfUrlSplit = this.curPdfUrl.split('/');
    return pdfUrlSplit[pdfUrlSplit.length - 1].length > 6;
  }

  public ngOnInit(): void
  {
    this.route.queryParamMap.pipe(
      filter((params: ParamMap) => params.has(QueryParams.Lang))
    ).subscribe({
      next: (param: ParamMap) =>
      {
        const lang = param.get(QueryParams.Lang);
        this.translateService.use(lang);
      }
    });
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (lang) =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
        this.getServicePolicies(lang);
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }

  public getServicePolicies(lang: string = this.translateService.languageCode): void
  {
    this.servicePoliciesDataService.getServicePolicies(lang).subscribe({
      next: ({ response }: any) =>
      {
        this.servicePoliceCategories = response;
        this.activatedRoute.queryParams.subscribe({
          next: (route: any) =>
          {
            if (!(Object.keys(route).length === 0))
            {
              this.openPolicyByName(route['id']);
            }
          }
        });
      }
    });
  }

  public openPolicyByName(htmlId: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.searchPolicy(htmlId)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (policy: any) =>
        {
          if (policy.tipo_despliegue === "ver_html")
          {
            this.openContent(policy);
          }
        }
      });
    }
  }

  public searchPolicy(htmlId: string): Observable<any>
  {
    return new Observable<any>((subscriber) =>
    {
      if (htmlId == null || htmlId === undefined)
      {
        subscriber.error(this.translateService.getElement("política no encontrada"));
      }

      let policyResult = null;

      for (let categoryIndex = 0; categoryIndex < this.servicePoliceCategories.length && policyResult == null; categoryIndex++)
      {
        const currentCategory: any = this.servicePoliceCategories[categoryIndex];

        for (let policiesIndex = 0; policiesIndex < currentCategory.politicas.length && policyResult == null; policiesIndex++)
        {
          const currentPolicie = currentCategory.politicas[policiesIndex];

          if (currentPolicie.html_id == htmlId)
          {
            policyResult = currentPolicie;
          }
        }
      }

      if (policyResult == null)
      {
        subscriber.error(this.translateService.getElement("política no encontrada"));
      }
      else
      {
        subscriber.next(policyResult);
      }
    });
  }

  public navigateToContent(servicePolicy: any): void
  {
    if (servicePolicy.tipo_despliegue === "ver_html")
    {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          id: servicePolicy.html_id
        },
        queryParamsHandling: ''
      });
    }
    else
    {
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: ''
      });

      this.openContent(servicePolicy);
    }
  }

  public openContent(servicePolicy: any): void
  {
    const translation = servicePolicy.traducciones[0];

    this.policiesListVisible = false;
    this.curPdfUrl = translation.pdf?.id || '';

    if (servicePolicy.tipo_despliegue === 'ver_pdf')
    {
      const pdfUrl = servicePolicy.traducciones[0].pdf?.id;

      const pdfUrlSplit = pdfUrl.split('/');

      if (pdfUrlSplit[pdfUrlSplit.length - 1].length === 3)
      {
        this.showErrorVisible = true;
      }
      else
      {
        this.policiesListVisible = true;
        if (isPlatformBrowser(this.platformId))
        {
          window.open(this.curPdfUrl, "_blank");
        }
      }
    }
    else
    {
      this.policiesContent = translation.version_html;

      if (this.policiesContent === null)
      {
        this.showErrorVisible = true;
      }
      else
      {
        this.policiesContentVisible = true;
      }
    }
  }

  public setServicePoliciesVisible(): void
  {
    this.policiesListVisible = true;
    this.policiesContentVisible = false;
    this.showErrorVisible = false;

    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: ''
    });
  }

  public openPdf(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(this.curPdfUrl, "_blank");
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
