import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AdditionalSettingsDataService } from '@core-data-services/additional-settings.data-service';
import { SettingsManager } from '@core-managers/settings.manager';
import { AdditionalSettings } from '@core-models/additional-settings.model';
@Injectable({
    providedIn: 'root'
})
export class AdditionalSettingResolver
{
    constructor(@Inject(PLATFORM_ID) private platformId,
        private additionalSettingsDataService: AdditionalSettingsDataService,
        public settingsManager: SettingsManager) { }

    public resolve(): void
    {
        if (isPlatformBrowser(this.platformId))
        {
            this.additionalSettingsDataService.getEcommerceConfig().subscribe({
                next: (response: AdditionalSettings.IEcommerceConfigResponse) => 
                {
                    if (response)
                    {
                        this.settingsManager.setSuggestionsLimit = response.maxSuggestionsLimit;
                    }
                }
            });
        }
    }
}