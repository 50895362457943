<!-- Banner content -->
<div class="container-fluid px-0 basic-content pb-3 pb-md-2 background-bg" [style.backgroundImage]="backgroundImage | directus:'style'">
    <div class="container row mx-auto pt-3 pt-md-5">
        <div class="d-flex col-12 col-md-12 justify-content-center">
            <h2 *ngIf="!changeTitle" class="akky-container-text my-auto mx-auto" [innerHTML]="title | safe: 'html'"></h2>
            <h2 *ngIf="changeTitle" class="akky-container-text my-auto mx-auto" [innerHTML]="titleIA | safe: 'html'"></h2>
        </div>
        <!--Toggler-->
        <div class="mx-auto col-md-8 py-md-4">
            <div class="d-flex justify-content-center">
                <div class="d-flex toggle-container">
                    <button class="btn ai-suggestion btn-search-domain msb"
                            [ngStyle]="{ 'background-color': (isToggleActiveDomain$ | async) ? 'var(--primary-blue)' : 'white' }"
                            (click)="domainToggleClick(true)">
                        <span [ngStyle]="{ 'color': (isToggleActiveDomain$ | async) ? 'white' : 'black'}">
                            {{ 'Encuentra tu dominio' | translate }}
                        </span>
                    </button>
                    <button class="btn ai-suggestions btn-search-domain-ia w-100 msb"
                            [ngStyle]="{ 'background-color': (isToggleActiveDomain$ | async) === false ? 'var(--primary-blue)' : 'white'}"
                            (click)="domainToggleClick(false)">
                        <img *ngIf="isToggleActiveDomain" loading="lazy" data-src="{{ '/icons/magic.svg' | blobstorage }}" alt="Magic wand"
                             src="{{ '/icons/magic.svg' | blobstorage }}" rendered="isToggleActiveDomain$">
                        <img *ngIf="!isToggleActiveDomain" loading="lazy" data-src="{{ '/icons/magic-white.svg' | blobstorage }}" alt="Magic wand"
                             src="{{ '/icons/magic-white.svg' | blobstorage }}" rendered="isToggleActiveDomain$">
                        <span [ngStyle]="{ 'color': (isToggleActiveDomain$ | async) === false ? 'white' : 'black'}">
                          {{ 'Genera tu dominio con Inteligencia Artificial' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Search Domain INPUT -->
        <div class="mx-auto col-12 col-md-7 mb-4 px-0 py-3 py-md-4">
            <app-search-domains-input (onSingleSearch)="onSingleSearch()" (onMultipleSearch)="onMultipleSearch()"></app-search-domains-input>
        </div>
    </div>
</div>

<!--Search results-->
<app-search-result-container></app-search-result-container>

<!-- "Los más buscados" section -->
<div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5" id="most-searched">
    <div class="row p-0 m-0">
        <div class="col-12 my-3" [innerHTML]="mainDomains | safe: 'html'"></div>

        <app-panel-tld *ngFor="let tld of panelTldsMain; trackBy:identify" [item]="tld" class="col-6 col-lg-3 col-md-6 px-3 pb-4"></app-panel-tld>

        <!--more TLDs-->
        <div class="col-12 my-3" [innerHTML]="otherDomains | safe: 'html'"></div>

        <app-panel-tld *ngFor="let tld of panelOtherTlds; trackBy:identify" [item]="tld" class="col-6 col-lg-3 col-md-6 px-3 pb-4"></app-panel-tld>

        <!--Consejos dominios-->
        <div class="col-12 my-4" [innerHTML]="domainInformation | safe: 'html'"></div>
        <div class="col-12 mt-3 mb-4" [innerHTML]="domainTips | safe: 'html'"></div>

        <!-- Boton -->
        <div class="d-flex w-100 justify-content-center font-button mt-2">
            <button class="btn btn-primary text-uppercase" routerLink="/servicios/dominios">{{ 'escoger mi dominio' | translate }}</button>
        </div>
    </div>
</div>
