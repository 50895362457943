export namespace RegexConst
{
  export const DoubleOnly = new RegExp(/^[0-9]{0,13}(\.[0-9]{0,2}){0,1}$/g);
  export const IntegerOnly = new RegExp(/^[0-9]{0,9}$/g);
  export const PhoneNumber = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
  export const SpecialChars = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
  export const EmailStudentChallenge = /^[Aa]\d{8}@(tec\.mx|itesm\.mx)$/;
  export const EmailStudentProjectChallenge = /^[\w-\.]+@(?!tec\.mx|nic\.mx|akky\.mx|raspberry\.mx)([\w-]+\.)+mx$/;
  export const EnrollmentStudentProjectChallenge = /^[Aa]\d{8}$/;
  export const LinkStudentChallenge = /^(www\.)+([\w-]+\.)+mx+([//\\]+[\w-]+)*$/;
  export const Numeric = new RegExp(/^\d+$/g);
  export const ItemQuantity = new RegExp(/[\d]+/);
  export const RegexsDgt = [/^[Aa]\d{8}@(tec\.mx|itesm\.mx)$/, /^[A-Za-z][A-Za-z]\d{8}@(tecmilenio\.mx)$/];
}
