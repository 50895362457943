<div class="header mb-1 mt-1">
  <div class="container">
    <div class="row py-2">
      <div class="col-sm-12 col-md-8">
        <div class="row align-items-center">
          <div class="col-4 col-md-4">
            <img src="{{ '/images/emprendedores-tec-header.jpg' | blobstorage }}"
              data-src="{{'/images/emprendedores-tec-header.jpg' | blobstorage}}" class="img-fluid w-75"
              alt="Tecnológico de Monterrey" />
          </div>
          <div class="col-4 col-md-4">
            <img src="{{ '/images/logo-emprendimiento-header.jpg' | blobstorage }}"
              data-src="{{'/images/logo-emprendimiento-header.jpg' | blobstorage}}" class="img-fluid w-100"
              alt="Instituto de Emprendimiento" />
          </div>
          <div class="col-4 col-md-4 d-flex justify-content-center">
            <img src="{{ '/images/emprendedores-akky-header.png' | blobstorage }}"
              data-src="{{'/images/emprendedores-akky-header.png' | blobstorage}}" class="img-fluid w-75"
              alt="En colaboración con Akky" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container container-position-challenge ps-0 pe-0 ms-0 me-0">
  <div class="row align-items-center">
    <div class="col-md-12 ps-0 pe-0">
      <div class="position-relative-challenge text-center">
        <img src="{{ '/images/banner-challenge.png' | blobstorage }}"
          data-src="{{ '/images/banner-challenge.png' | blobstorage }}" alt="Banner challenge"
          class="img-fluid w-100" />
        <div class="text-overlay-challenge ps-5 pe-5">
          <div class="layout-banner-challenge">
            <h1 class="titles-challenge white">Challenge Mi marca personal,</h1>
            <p class="description-banner">
              tiene como objetivo reconocer a la creatividad de quienes
              participen en nuestra iniciativa. Creativos, Emprendedores, todos
              pueden participar.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row align-items-center py-4">
    <div class="col-12">
      <h2 class="titles-challenge text-center">
        ¡Las páginas
        <span class="bg-color-title-prom ps-2 pe-2">más creativas</span> ganarán
        premios!
      </h2>
      <p class="text-prom">
        Elige la categoría de tu proyecto o idea.<br />
        Inspírate en los ejemplos para poder realizar tu idea.<br />
        Utiliza las herramientas digitales y empieza a crear.<br />
        Podrás utilizarlas
        <span class="txt-color-blue-challenge fw-bolder">a partir del 29 de septiembre de 2023.</span>
      </p>
      <p class="text-prom-date">
        Fecha límite de recepción de proyectos: 21 de noviembre de 2023 a las
        11:59 pm.
      </p>
    </div>
    <div class="col-12 text-center">
      <div class="container">
        <div class="row row-cols-md-4 row-cols-lg-4">
          <div class="col-sm-6 col-lg-3">
            <div class="card h-100 bg-light border-white rounded-3">
              <div class="card-body">
                <img src="{{ '/images/ilustracion-academica.png' | blobstorage }}"
                  data-src="{{'/images/ilustracion-academica.png' | blobstorage}}" class="img-fluid"
                  alt="Ilustración Académica" />
                <h5 class="card-title card-title-challenge mt-2 fw-bolder">Académica</h5>
                <p class="card-text">
                  Muestra tus proyectos estudiantiles expresado en digital.
                </p>
              </div>
              <div class="card-footer bg-light border-0 pb-4">
                <a href="https://santiagoperezr.myportfolio.com" target="_blank" class="link-page-challenge">
                  Ver
                  ejemplo
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="card h-100 bg-light border-white rounded-3">
              <div class="card-body">
                <img src="{{ '/images/ilustracion-emprendedora.png' | blobstorage }}"
                  data-src="{{'/images/ilustracion-emprendedora.png' | blobstorage }}" class="img-fluid"
                  alt="Ilustración emprendedora" />
                <h5 class="card-title card-title-challenge mt-2 fw-bolder">Emprendedora</h5>
                <p class="card-text">
                  Da a conocer tu idea, negocio o emprendimiento.
                </p>
              </div>
              <div class="card-footer bg-light border-0 pb-4">
                <a href="https://nancystylingservice.my.canva.site/rhetoricacademy" target="_blank"
                  class="link-page-challenge">Ver ejemplo</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="card h-100 bg-light border-white rounded-3">
              <div class="card-body">
                <img src="{{ '/images/ilustracion-social.png' | blobstorage }}" data-src="{{
                    '/images/ilustracion-social.png' | blobstorage
                  }}" class="img-fluid" alt="Ilustración responsabilidad social" />
                <h5 class="card-title card-title-challenge mt-2 fw-bolder">
                  Responsabilidad Social
                </h5>
                <p class="card-text">
                  Cuéntanos cómo apoyas a la sociedad y cómo tienen impacto tus
                  acciones.
                </p>
              </div>
              <div class="card-footer bg-light border-0 pb-4">
                <a href="https://proyecto-salvavidas.com" target="_blank" class="link-page-challenge">
                  Ver
                  ejemplo
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="card h-100 bg-light border-white rounded-3">
              <div class="card-body">
                <img src="{{ '/images/ilustracion-se-tu.png' | blobstorage }}"
                  data-src="{{ '/images/ilustracion-se-tu.png' | blobstorage }}" class="img-fluid"
                  alt="Ilustracion se tú" />
                <h5 class="card-title card-title-challenge mt-2 fw-bolder">Se tú</h5>
                <p class="card-text">
                  Cuenta tu historia mediante las herramientas digitales y
                  comparte tus habilidades y gustos.
                </p>
              </div>
              <div class="card-footer bg-light border-0 pb-4">
                <a href="https://www.bibicandelas.com" target="_blank" class="link-page-challenge">Ver ejemplo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row align-items-center py-5">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <img src="{{ '/images/estudiante-escribiendo.png' | blobstorage }}"
        data-src="{{ '/images/estudiante-escribiendo.png' | blobstorage }}" class="img-fluid img-student"
        alt="Estudiante escribiendo" />
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <h2 class="title-participate fw-bolder mb-4 mt-4">¿Cómo participar?</h2>

      <ul class="fa-ul list-text-participate">
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Elige tu categoría
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Activa tus beneficios
          <span class="txt-color-blue-challenge fw-bolder">
            <a class="text-decoration-none" href="https://www.akky.mx/"> aquí</a>
          </span>
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Elige tu dominio, crea tu e-mail y diseña tu página
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Empieza a crear tu proyecto
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          <span>
            Comparte tu proyecto en
            <a class="sr-only text-dark">el siguiente formulario</a>
            para poder participar antes del
            <strong>21 de noviembre de 2023 a las 11:59 pm. </strong>
            <span class="txt-color-blue-challenge fw-bolder">
              No se recibirán más proyectos después de la fecha
              indicada.
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="container container-grid py-5">
  <h2 class="title-best fw-bolder text-center">
    ¡Participa de manera individual o grupal, los mejores proyectos
    recibirán un premio!
  </h2>
  <div class="cards-flex">
    <div class="card-flex bg-light border-white rounded-3">
      <div class="card-body">
        <img src="{{ '/icons/rayo.png' | blobstorage }}" data-src="{{ '/icons/rayo.png' | blobstorage }}"
          class="img-fluid img-size" alt="icon rayo" />
        <h5 class="card-title card-title-challenge mt-2">
          <strong>Ronda eliminatoria</strong>
        </h5>
        <p>
          Un comité de maestros evaluará los proyectos, definiendo a los
          <span class="txt-color-blue-challenge fw-bolder">
            15 mejores proyectos de cada categoría.
          </span>
        </p>
      </div>
    </div>
    <div class="card-flex bg-light border-white rounded-3">
      <div class="card-body">
        <img src="{{ '/icons/hexagono.png' | blobstorage }}" data-src="{{ '/icons/hexagono.png' | blobstorage }}"
          class="img-fluid img-size" alt="icon hexágono" />
        <h5 class="card-title card-title-challenge mt-2"><strong>Ronda final</strong></h5>
        <p>
          Un jurado con habilidades de diseño, marketing y desarrollo en
          software de Akky, evaluarán tu proyecto donde se calificará tu
          creatividad, el valor de contenido, el uso de las herramientas
          digitales y el impacto de tu página.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row align-items-center py-5">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <img src="{{ '/images/estudiantes-sonriendo.png' | blobstorage }}"
        data-src="{{ '/images/estudiantes-sonriendo.png' | blobstorage }}" class="img-fluid img-student"
        alt="Estudiante sonriendo" />
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <h2 class="title-participate fw-bolder mb-4 mt-4 me-4">
        Tu proyecto tendrá que destacar en:
      </h2>
      <ul class="fa-ul list-text-your-project">
        <li class="d-flex align-items-center">
          <span class="fas d-inline-block i-check-circle"></span>
          Creatividad del nombre
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Creatividad del diseño
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Funcionalidad técnica
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Conexión con redes sociales
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Uso de herramientas mixtas
        </li>
        <li>
          <span class="fas d-inline-block i-check-circle"></span>
          Contenido de valor
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- premios -->
<div class="container mw-100">
  <div class="row awards-title text-center">
    <h2 class="awards-title"><strong>¡Premios!</strong></h2>
  </div>
  <div class="row awards-container ps-3 pe-3 pt-4 pb-4">
    <div class="col-sm-6 mb-3 mb-sm-0">
      <div class="card awards-card h-100 ps-4 pe-4 pt-4 pb-4">
        <img src="{{ '/images/categorial-individual.png' | blobstorage }}"
          data-src="{{ '/images/categorial-individual.png' | blobstorage }}" class="card-img-top"
          alt="categoría individual" />
        <div class="card-body">
          <p class="card-text">
            <img src="{{ '/icons/star.png' | blobstorage }}" data-src="{{ '/icons/star.png' | blobstorage }}"
              alt="icon estrella" />
            <strong>$10,000 pesos</strong> para el primer lugar en servicios digitales
            remidibles en: <a class="custom-link" href="https://tec.mx/es/tecstore" target="_blank">Tecstore</a>,
            <a class="custom-link" href="https://www.sorteostec.org/" target="_blank">Sorteos Tec</a> y
            <a class="custom-link" href="https://www.akky.mx/" target="_blank">akky.</a> <br><br>
            <img src="{{ '/icons/star.png' | blobstorage }}" data-src="{{ '/icons/star.png' | blobstorage }}"
              alt="icon estrella" />
            5 sesiones de 45 minutos c/u con expertos en redes sociales,
            servicios digitales o marketing.<br><br>
            <img src="{{ '/icons/hand.png' | blobstorage }}" data-src="{{ '/icons/hand.png' | blobstorage }}"
              alt="icon mano" />
            $3,000 pesos en servicios digitales remidibles en: <a class="custom-link" href="https://tec.mx/es/tecstore"
              target="_blank">Tecstore</a>,
            <a class="custom-link" href="https://www.sorteostec.org/" target="_blank">Sorteos Tec</a> y
            <a class="custom-link" href="https://www.akky.mx/" target="_blank">akky</a>, para los segundos lugares de
            cada categoría (Académico, Emprendedor, Responsabilidad Social y Sé
            tú). Más 3 sesiones de 45 minutos c/u con expertos en redes sociales, servicios digitales o marketing.
          </p>
        </div>
        <div class="card-footer bg-transparent border-0 pb-4">
          <div>
            <img src="{{ '/icons/download-file.png' | blobstorage }}"
              data-src="{{ '/icons/download-file.png' | blobstorage }}" alt="icon lineamientos" />
            <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/2601df75-49a0-47fb-b24e-3bf3e88a7df6"
              target="_blank" class="font-white">
              Lineamientos de participación
            </a><br>
          </div>
          <div class="mt-3">
            <img src="{{ '/icons/download-file.png' | blobstorage }}"
              data-src="{{ '/icons/download-file.png' | blobstorage }}" alt="icon lineamientos" />
            <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/01d1e088-f7c6-4a0f-873c-6c183569a1ec"
              target="_blank" class="font-white">
              Términos y condiciones aquí
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-3 mb-sm-0">
      <div class="card awards-card h-100 ps-4 pe-4 pt-4 pb-4">
        <img src="{{ '/images/categoria-grupal.png' | blobstorage }}"
          data-src="{{ '/images/categoria-grupal.png' | blobstorage }}" class="card-img-top" alt="Categoría grupal" />
        <div class="card-body">
          <p class="card-text">
            <img src="{{ '/icons/star.png' | blobstorage }}" data-src="{{ '/icons/star.png' | blobstorage }}"
              alt="icon estrella" />
            <strong>$35,000 pesos</strong> para el primer lugar en servicios digitales
            remidibles en: <a class="custom-link" href="https://tec.mx/es/tecstore" target="_blank">Tecstore</a>,
            <a class="custom-link" href="https://www.sorteostec.org/" target="_blank">Sorteos Tec</a> y
            <a class="custom-link" href="https://www.akky.mx/" target="_blank">akky.</a><br><br>
            <img src="{{ '/icons/star.png' | blobstorage }}" data-src="{{ '/icons/star.png' | blobstorage }}"
              alt="icon estrella" />
            5 sesiones de 45 minutos c/u con expertos en redes
            sociales, servicios digitales o marketing.<br><br>
            <img src="{{ '/icons/hand.png' | blobstorage }}" data-src="{{ '/icons/hand.png' | blobstorage }}"
              alt="icon estrella" />
            El equipo ganador deberá contar dentro de su proyecto, con
            un mínimo de 20 páginas de nueva creación para ser acredor al premio. <strong>
              ¡Las mejores ideas se trabajan
              en equipo!
            </strong>
          </p>
        </div>
        <div class="card-footer bg-transparent border-0 pb-4">
          <div>
            <img src="{{ '/icons/download-file.png' | blobstorage }}"
              data-src="{{ '/icons/download-file.png' | blobstorage }}" alt="icon lineamientos" />
            <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/be4597ce-297f-48cc-83de-81a1e8b05c17"
              target="_blank" class="text-white">
              Lineamientos de participación
            </a><br>
          </div>
          <div class="mt-3">
            <img src="{{ '/icons/download-file.png' | blobstorage }}"
              data-src="{{ '/icons/download-file.png' | blobstorage }}" alt="icon lineamientos" />
            <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/01d1e088-f7c6-4a0f-873c-6c183569a1ec"
              target="_blank" class="font-white">
              Términos y condiciones aquí
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-3 mb-3" id="form-students">
  <div class="col-12 mt-4">
    <h2 class="gray-h2 fw-bolder text-center">¡Lleva tu idea al mundo digital, empieza aquí!</h2>
  </div>
  <div class="col-12">
    <form [formGroup]="studentsForm" class="formpro" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label for="proyectName">Nombre de tu Proyecto</label>
        <input id="proyectName" type="text" class="form-control" [formControl]="nameProyect"
          placeholder="Nombre de tu Proyecto" required maxlength="255">
        <div class="input-invalid-form"
          *ngIf="nameProyect.touched && nameProyect.errors && nameProyect.errors.required">
          Te
          falta escribir el nombre de tu proyecto.
        </div>
      </div>
      <br>

      <div class="form-group">
        <label for="exampleFormControlSelect1">Selecciona tu categoría</label>
        <div class="col-12">
          <select class="form-control" [formControl]="category" required>
            <option value="" disabled>Selecciona tu categoría</option>
            <option>Académica</option>
            <option>Emprendedora</option>
            <option>Responsabilidad Social</option>
            <option>Se tú</option>
            <option>Grupal</option>
          </select>
          <div class="input-invalid-form" *ngIf="category.touched && category.errors && category.errors.required">
            Selecciona una categoría.
          </div>
        </div>
      </div>
      <br>

      <div class="col-12">
        <label for="emailProyect">E-mail del proyecto</label>
        <input type="email" id="emailProyect" class="form-control" [formControl]="emailProyect" required
          placeholder="E-mail del proyecto" title="No puede utilizar correos populares (@yahoo.mx, @gmail.com, etc..)"
          maxlength="255">
        <div class="input-invalid-form" *ngIf="emailProyect.touched && emailProyect.errors">
          El e-mail de tu proyecto no es válido, debe estar asociado al dominio de tu proyecto.
        </div>
      </div>
      <br>

      <div class="col-12">
        <label for="Link de tú página">Link de tú página</label>
        <input type="text" class="form-control" [formControl]="link" placeholder="Link de tú página" required
          maxlength="255">
        <div class="input-invalid-form" *ngIf="link.touched && link.errors">
          El link de tu página no es válido, se requiere el formato www.mipagina.mx
        </div>
      </div>
      <br>

      <div class="col-12">
        <label for="email">E-mail institucional</label>
        <input type="text" class="form-control" [formControl]="emailInst" placeholder="E-mail institucional" required
          title="Utilice el dominio @tec.mx o @itesm.mx" maxlength="20">
        <div class="input-invalid-form" *ngIf="emailInst.touched && emailInst.errors && !emailInst.errors.onlyAlpha">
          Tu e-mail no es válido, debe comenzar con la letra A seguido de 8 números y pertenecer al dominio @tec.mx o
          @itesm.mx.
        </div>
      </div>
      <br>
      <div class="col-12">
        <label for="phone">Teléfono de contacto</label>
        <input type="tel" class="form-control" [formControl]="phone" placeholder="Teléfono de contacto" required
          minlength="10" maxlength="10">
        <div class="input-invalid-form" *ngIf="phone.touched && phone.errors && phone.errors.required">
          Te falta escribir el teléfono de contacto
        </div>
        <br>
      </div>
      <div>
        <div class="form-group mb-2">
          <label for="aceptarAviso">
            <input type="checkbox" id="aceptarAviso" [formControl]="noticePrivacy">
            <span class="ms-2">
              Acepto el
              <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/8c692673-3af5-4635-a2a5-3afe5cf1f674"
                target="_blank">
                Aviso de Privacidad
              </a>
            </span>
          </label>
        </div>
        <div class="form-group">
          <label for="aceptarTerminos">
            <input type="checkbox" id="aceptarTerminos" [formControl]="termsAndConditions">
            <span class="ms-2">
              Acepto <a
                href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/01d1e088-f7c6-4a0f-873c-6c183569a1ec"
                target="_blank">Términos y Condiciones</a>
            </span>
          </label>
        </div>
      </div>
      <br>
      <div class="row text-left">
        <div class="col-12">
          <button id="btn-send-form" (click)="onSubmit()" type="button" [disabled]="studentsForm.invalid"
            class="btn btn-lg btn-see-more">
            Enviar
          </button>
        </div>
      </div>
      <br>

    </form>
  </div>
  <br>
</div>

<div class="custom-footer-challenge mt-1">
  <div class="container">
    <div class="row py-2">
      <div class="col-sm-12 col-md-12">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-4 d-flex justify-content-center my-4">
            <img src="{{ '/images/emprendedores-tec-footer.png' | blobstorage }}"
              data-src="{{'/images/emprendedores-tec-footer.png' | blobstorage}}" class="img-fluid w-50"
              alt="Tecnológico de Monterrey" />
          </div>
          <div class="col-12 col-md-4 d-flex justify-content-center my-4">
            <img src="{{ '/images/emprendedores-emprendimiento-footer.png' | blobstorage}}" class="img-fluid w-75"
              alt="Instituto de Emprendimiento" />
          </div>
          <div class="col-12 col-md-4 d-flex justify-content-center">
            <img src="{{ '/images/emprendedores-akky-footer.png' | blobstorage }}"
              data-src="{{ '/images/emprendedores-akky-footer.png' | blobstorage }}" class="w-50"
              alt="En colaboración con Akky" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalsucces" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title fs-5" id="exampleModalLabel">Enviando datos del Proyecto</h4>
        <button (click)="closeModal()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body text-center mt-2 ps-3 pe-3">
        <div>
          <h5 class="modal-title" id="exampleModalLabel">
            {{responseTitleMssg}}
          </h5>
          <p class="mt-2">
            {{responseMssg}}
          </p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <span *ngIf="hasError" class="fas d-inline-block i-check-remove"></span>
          <img *ngIf="!hasError" loading="lazy" src="{{ '/icons/check-circle.png' | blobstorage }}"
            data-src="{{ '/icons/check-circle.png' | blobstorage }}" alt="Paloma verde" class="area-40">
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>