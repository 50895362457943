import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RegexConst } from "@core-constants/regex.const";
import { SutentsChallengeConst } from "@core-constants/students-challenge.const";
import { PersonalBrandDataService } from "@core-data-services/personal-brand.data-service";
import { IStudenProject } from "@core-models/personal-brand.model";
import { Environment } from '@environments';
import { SEOService } from "@shared-services/seo.service";
import { RegexValidators } from "@shared-utils/regex-validation.util";

@Component({
  selector: 'app-personal-brand-challenge',
  templateUrl: './personal-brand-challenge.component.html',
  styleUrls: ['./personal-brand-challenge.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PersonalBrandChallengeComponent implements OnInit
{
  public favIcon: HTMLLinkElement = isPlatformBrowser(this.platformId) ? document.querySelector('#appIcon') : null;
  public responseMssg: string = "";
  public responseTitleMssg: string = "";
  public hasError: boolean = false;

  //TODO  quitar esto de aquí
  public nameProyect = new FormControl('', Validators.compose([
    Validators.required,
    Validators.maxLength(225)
  ]));

  public category = new FormControl('', Validators.compose([
    Validators.required
  ]));

  public emailProyect = new FormControl('', Validators.compose([
    Validators.required,
    Validators.maxLength(255),
    RegexValidators.patternsValidator([RegexConst.EmailStudentProjectChallenge], { onlyNumber: false })
  ]));

  public link = new FormControl('', Validators.compose([
    Validators.required,
    Validators.maxLength(225),
    RegexValidators.patternsValidator([RegexConst.LinkStudentChallenge], { onlyNumber: true })
  ]));

  public emailInst = new FormControl('', Validators.compose([
    Validators.required,
    Validators.maxLength(20),
    RegexValidators.patternsValidator([RegexConst.EmailStudentChallenge], { onlyNumber: true })
  ]));

  public phone = new FormControl('', Validators.compose([
    Validators.required,
    Validators.maxLength(10)
  ]));

  public noticePrivacy = new FormControl(false, Validators.requiredTrue);
  public termsAndConditions = new FormControl(false, Validators.requiredTrue);

  //TODO  quitar esto de aquí
  public studentsForm = new FormGroup({
    projectName: this.nameProyect,
    category: this.category,
    projectEmail: this.emailProyect,
    pageLink: this.link,
    institutionalEmail: this.emailInst,
    telephoneContact: this.phone,
    noticePrivacy: this.noticePrivacy,
    termsAndConditions: this.termsAndConditions
  });

  constructor(@Inject(PLATFORM_ID) private platformId,
    private SEOService: SEOService,
    private personalBrandService: PersonalBrandDataService,
    private el: ElementRef,
    private renderer: Renderer2) { }

  public ngOnInit(): void
  {
    this.SEOService.updateSeoElements("Mi marca personal | Challenge", "");
  }

  public onSubmit(): void
  {
    if (this.studentsForm.valid)
    {
      const temporalStudentValue: IStudenProject =
      {
        projectName: this.studentsForm.controls['projectName'].value,
        category: this.studentsForm.controls['category'].value,
        projectEmail: this.studentsForm.controls['projectEmail'].value,
        pageLink: this.studentsForm.controls['pageLink'].value,
        institutionalEmail: this.studentsForm.controls['institutionalEmail'].value,
        telephoneContact: this.studentsForm.controls['telephoneContact'].value,
        noticePrivacy: +this.studentsForm.controls['noticePrivacy'].value,
        termsAndConditions: +this.studentsForm.controls['termsAndConditions'].value
      };

      this.setCheckTermsAndPrivacy();

      this.personalBrandService.createPersonalBrand(temporalStudentValue).subscribe({
        next: (response: any) =>
        {
          this.handleSuccessResponse(response);
        },
        error: (response: HttpErrorResponse) =>
        {
          this.handleErrorResponse(response);
        }
      });
    }
  }

  private setCheckTermsAndPrivacy(): void
  {
    this.studentsForm.patchValue({
      noticePrivacy: true,
      termsAndConditions: true
    });
  }

  private resetCheckTermsAndPrivacy(): void
  {
    this.studentsForm.get('noticePrivacy').reset();
    this.studentsForm.get('termsAndConditions').reset();
  }

  private handleSuccessResponse(response: any): void
  {
    if (response?.message === "OK")
    {
      this.hasError = false;
      this.responseMssg = "¡Hemos recibido tu proyecto correctamente!";
      this.responseTitleMssg = "Revisa tu e-mail para más información.";
      this.sendEmailStudents();
      this.studentsForm.reset();
    }
    this.openModal();
  }

  private sendEmailStudents(): void
  {
    const emailTo = this.studentsForm.get('institutionalEmail').value;
    const emailId = Environment.emailId;
    this.personalBrandService.sendMail(
      {
        emailId: emailId,
        message: { to: emailTo }
      }
    );
  }

  private handleErrorResponse(error: any): void
  {
    this.hasError = true;

    const errorDescription = error?.error.description;
    let responseTitleMssg = "¡Ups! Algo va mal, puedes volver a intentarlo en unos minutos";
    let responseMssg = "";

    if (SutentsChallengeConst.FormStudentsChallengeCodeErrors.has(errorDescription))
    {
      responseTitleMssg = "Hemos validado los datos de tu proyecto";
      responseMssg = SutentsChallengeConst.FormStudentsChallengeCodeErrors.get(errorDescription);
    }

    this.responseTitleMssg = responseTitleMssg;
    this.responseMssg = responseMssg;

    this.openModal();
    this.resetCheckTermsAndPrivacy();
  }

  public openModal(): void
  {
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
    }
  }

  public closeModal(): void
  {
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'hide');
      this.renderer.setStyle(modal, 'display', 'none');
    }
  }
}
