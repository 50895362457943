import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { UpdateSuggestionResponse } from '@core-models/add-to-cart.model';
import { IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { ICoverageOption, IItemProductLite } from '@core-models/shopping-cart-items.model';
import { ToastService } from '@shared-services/toast.service';
import { finalize } from 'rxjs';
import { ShoppingCartSuggestionItemBase } from '../base/shopping-cart-suggestion-item';

@Component({
  selector: 'app-upgrade-suggestion-item',
  templateUrl: './upgrade-suggestion-item.component.html',
  styleUrls: ['./upgrade-suggestion-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeSuggestionItemComponent extends ShoppingCartSuggestionItemBase
{
  @Output() public onSuggestionUpgradeItem = new EventEmitter<IShoppingCartIndividualStoredSuggestionPlus>();

  @Input('parentId') public set setParentId(value: number)
  {
    super.parentId = value;
  }

  @Input('suggestion') public set setSuggestion(value: IShoppingCartIndividualStoredSuggestionPlus)
  {
     super.suggestion = value;
  }

  @Input('itemProduct') public set setItemProduct(value: IItemProductLite)
  {
    super.itemProduct = value;
  }

  constructor(
    private shoppingCartDataService: ShoppingCartDataService,
    private checkoutManager: CheckoutManager,
    private toast: ToastService
  ) { super();  }

  public get isUpgradeSelected(): boolean
  {
    return this.suggestion.individualFeatures.isAdded;
  }

  public override get buttonId(): string
  {
    const itemData = `${this.suggestion.id}-${this.parentId}`;
    return this.isUpgradeSuggestion  && `upgrade-suggestion-${itemData}`;
  }

  public get productTitle(): string
  {
    return this.itemProduct.fullName;
  }

  public get productLabel(): string
  {
    return this.itemProduct.rate.message;
  }

  public get productFinalAmount(): number
  {
    const coverage = this.itemProduct.coverageOptions.find(x => x.id === this.itemProduct.coverageId).rate;
    return coverage.finalAmount * this.itemProduct.itemCounter;
  }

  public get upgradeFinalAmount(): number
  {
    const coverageItemProductValue: number = this.itemProduct.coverageOptions.find(x => x.id === this.itemProduct.coverageId).value;
    const coverageSuggestion: ICoverageOption = this.suggestion.coverage.find(x => x.value === coverageItemProductValue);
    const { finalAmount } = coverageSuggestion.rate;
    return finalAmount * this.itemProduct.itemCounter;
  }

  public get upgradeDifferenceFinalAmount(): number
  {
    return this.upgradeFinalAmount - this.productFinalAmount;
  }

  public onUpgradeItem(value: boolean): void
  {
    this.isAdded = value;
    if(this.suggestion.individualFeatures.isAdded === this.isAdded) { return; }
    if(!this.isUpgradeSuggestion) { return; }

    this.suggestion.individualFeatures.isAdded = this.isAdded;

    this.suggestion.individualFeatures.isAdded === true
      ? this.addUpgradeSuggestionToCart()
      : this.removeUpgradeSuggestionFromCart();
  }

  private addUpgradeSuggestionToCart(): void
  {

    if (this.addedIsChanging) { return; }

    this.addedIsChanging = true;

    const parentId = this.parentId;
    const suggestionId = this.suggestion.suggestionId;
    const quantity = this.itemProduct.itemCounter;
    const coverageMonths = this.itemProduct.coverageOptions.find(x => x.id === this.itemProduct.coverageId).value;

    this.shoppingCartDataService.addIndividualSuggestion(parentId, suggestionId, quantity, coverageMonths)
      .pipe(finalize(() => this.addedIsChanging = false))
      .subscribe({
        next: (response: UpdateSuggestionResponse) =>
        {
          const suggestionResponse = this.suggestion;
          suggestionResponse.individualFeatures.isAdded = response.operationResult;
          suggestionResponse.coverageId = response.newCoverageId;
          suggestionResponse.coverageMonths = response.newCoverageMonths;

          this.checkoutManager.addIndividualSuggestionUpgrade(parentId, suggestionId, quantity, response.newCoverageId, response.newCoverageMonths);

          this.onSuggestionUpgradeItem.emit(suggestionResponse);
        },
        error: () => this.toast.setErrorToast(ErrorMessageConst.OperationFailed)
      });
  }

  private removeUpgradeSuggestionFromCart(): void
  {
    if (this.addedIsChanging) { return; }

    this.addedIsChanging = true;

    const parentId = this.parentId;
    const suggestionId = this.suggestion.suggestionId;

    this.shoppingCartDataService.removeIndividualSuggestion(parentId, suggestionId)
      .pipe(finalize(() => this.addedIsChanging = false))
      .subscribe({
        next: () =>
        {
          this.checkoutManager.removeIndividualSuggestion(parentId, suggestionId);
          this.onSuggestionUpgradeItem.emit(undefined);
        },
        error: () => this.toast.setErrorToast(ErrorMessageConst.OperationFailed)
      });
  }

}
