<!-- Sub menu - DESKTOP -->
<div @myInsertRemoveTrigger class="margin-banner fixed-top bg-white d-none d-lg-block menu-container"
  *ngIf="showMenuPanel && selectedOptionIndex !== -1" (mouseleave)="hideMenu();">
  <div class="container d-flex justify-content-center">
    <div class="p-5 col-12 col-md-6 col-xl-6" *ngIf="menuOptions[selectedOptionIndex]?.submenus !== undefined">
      <div class="col" [class.col-8]="menuOptions[selectedOptionIndex]?.submenus.length === 1" @slideInOut
        *ngFor="let submenu of menuOptions[selectedOptionIndex]?.submenus; trackBy:identify">
        <ng-container
          *ngTemplateOutlet="navHTemplate; context: {subtitle: submenu.subtitle, link: submenu.link, options: submenu.options}"></ng-container>
      </div>
    </div>
    <div class="p-5 flex-column col-12 col-md-6 col-xl-6"
      *ngIf="menuOptions[selectedOptionIndex]?.submenus !== undefined">
      <img alt="blog image" *ngIf="selectedBlog !== undefined && selectedBlog.image !== undefined" class="blog-image"
        (click)="openBlog(selectedBlog.path_parameter)" loading="lazy" [src]="selectedBlog.image.id | directus">
      <div *ngIf="selectedBlog !== undefined && selectedBlog.traducciones[0] !== undefined" class="text-blog"
        (click)="openBlog(selectedBlog.path_parameter)" [innerHTML]="selectedBlog.traducciones[0].title | safe: 'html'">
      </div>
    </div>
  </div>
</div>

<!-- Sub menu template (mobile and desktop)-->
<ng-template #navHTemplate let-subtitle="subtitle" let-link="link" let-options="options">
  <a class="nav-h-title" [class.clickable]="link" [class.nav-h-title-clickable]="link"
    (click)="onMenuOptionClicked(subtitle)">{{ subtitle | translate}}</a>
  <div class="my-3" *ngFor="let option of options; trackBy:identify">
    <a href="{{option.link}}" class="nav-h-subtitle clickable py-2 py-sm-0"
      (click)="onMenuOptionClicked(option.name)">{{ option.name | translate}}</a>
    <div class="nav-h-description d-none">{{ option.description | translate}}</div>
  </div>
</ng-template>

<div #navBar class="margin-banner fixed-top">
  <nav id="index-navbar" class="akky-navbar  navbar-expand-lg d-flex align-items-center w-100 p-0 m-0 pt-2 py-md-4"
    [ngClass]="showNavBarWhite  ? 'navbar-white' : 'transparent-navbar'">

    <!-- Togglers hidden-->
    <a class="d-none" #togglerMobile data-bs-toggle="collapse" href="#akkyNavbarMobile" role="button"
      aria-expanded="false" aria-controls="akkyNavbarMobile"> </a>

    <!--Hamburguer menu - MOBILE -->
    <button (click)="toggleMobileMenu(); dropUserMenu = false;" class="p-2 navbar-toggler" type="button"
      aria-label="Toggle navigation">
      <span class="fas d-inline-block i-menu"></span>
    </button>

    <!-- AKKY logo-->
    <span id="akky-logo" class="navbar-brand ms-0 p-0 ms-md-2 ms-xl-4 ms-xxl-5 clickable" (click)="goToHome()">
      <span class="fas d-inline-block brand-image akky-logo"></span>
    </span>

    <!-- Menu options DESKTOP-->
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav navbar-expand-titles m-0">
        <li class="nav-item mx-sm" *ngFor="let option of menuOptions; let i = index; trackBy:identify">
          <a *ngIf="option.link; else noLinkItemTemplate" [href]="option.link" class="nav-link p-2 clickable"
            [class.mt-1]="i===menuOptions.length-1" [class.selected-option]="i === selectedOptionIndex"
            (mouseenter)="onSelectOptionDesktop(i)" (mouseleave)="hideMenuFromTitle();"
            (click)="registerClickMenuEvent(option.title)">
            <div class="text-uppercase d-flex align-items-center">
              {{option.title | translate}}
              <span *ngIf="option?.submenus !== undefined"
                class="d-inline-block {{ i === selectedOptionIndex ? 'i-arrow-down-hover rotate-180' : 'i-arrow-down'}}"></span>
            </div>
          </a>
          <ng-template #noLinkItemTemplate>
            <div class="nav-link p-2 clickable" [class.mt-1]="i===menuOptions.length-1"
              [class.selected-option]="i === selectedOptionIndex" (mouseenter)="onSelectOptionDesktop(i)"
              (mouseleave)="hideMenuFromTitle();">
              <div class="text-uppercase d-flex align-items-center">
                {{option.title | translate }}
                <span *ngIf="option?.submenus !== undefined"
                  class="{{ i === selectedOptionIndex ? 'i-arrow-down-hover rotate-180' : 'i-arrow-down'}}"></span>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>

    <!-- Another menu items -->
    <div class="d-flex ms-auto menu-items-container">

      <!-- PHONE -->
        <a href="tel:+528188642640" class="nav-item d-sm-flex d-md-none align-self-center mx-2 black d-inline-flex "
           (click)="hideMenu();" [attr.aria-label]="ariaLabelForPhoneAnchor">
            <span class="i-phone-circle"></span>
        </a>

      <a href="/ayuda/contacto" class="nav-item d-none d-md-flex mx-2 align-self-center black" (click)="hideMenu();"
        [attr.aria-label]="ariaLabelForPhoneAnchor">
        <span class="i-phone-circle"></span>
      </a>

      <!-- USER NOT LOGUED-->
      <div *ngIf="!isLogedIn" class="nav-item align-self-center mx-2 mx-xl-3" id="navBarLogin"
        (click)="hideMenu(); showUserAccessModal = true;">

        <div class="d-flex align-items-center" [ngClass]="!showMenuPanel ? 'btn-secondary': 'btn-info'">
          <span class="fas d-inline-block i-user-circle"></span>
        </div>
      </div>

      <!-- LOGED USER -->
      <div *ngIf="isLogedIn" #userItem id="userItem" class="nav-item align-self-center mx-0 mx-sm-2 mx-xl-3 d-sm-block d-flex"
        [class.d-none]="!isLogedIn" (click)="hideMenu(); dropUserMenu = !dropUserMenu; dropLanguages = false;">
        <span title="User" class="dropdown-toggle navbarUserInitialsDiv">
          {{userNameInitials}}
        </span>
        <span class="ms-1 i-arrow-down rotate-180"
          (click)="hideMenu(); $event.stopPropagation(); dropUserMenu = !dropUserMenu; dropLanguages = false;"></span>

        <ul class="dropdown-menu  px-3 py-2" [class.show]="dropUserMenu">
          <li class="py-3 nav-dropdown-option" (click)="onControlPanelClicked()">{{'Panel de control' | translate}}</li>
          <li class="py-3 nav-dropdown-option" (click)="onMyProfileClicked()">{{'Mi perfil' | translate}}</li>
          <li class="py-3 nav-dropdown-option last" (click)="onLogout()">
            {{'Cerrar sesión' | translate}}
            <span class="fas d-inline-block i-sign-out"></span>
          </li>
        </ul>
      </div>

      <!-- CART -->
      <div id="navBarCart" class="nav-item mx-2" (click)="hideMenu();">
        <div title="{{'Carrito' | translate}}" class="pt-2" (click)="goToPopUpCart()">
          <span class="i-cart-circle"></span>
          <span class="count-badge badge">
            {{ cartItemsCount }}
          </span>
        </div>
      </div>

      <!-- LANG -->
      <div class="nav-item align-self-center mx-2 mx-sm-2 mx-xl-3 language-options" #langItem id="langItem"
        (click)="hideMenu(); dropLanguages = !dropLanguages; dropUserMenu = false;">
        <span title="{{'Idioma' | translate}}" class="language-code-text text-uppercase">
          {{selectedLanguage.code}}</span>
        <span
          class="{{dropLanguages ? 'i-arrow-down-hover rotate-180' : 'i-arrow-down'}} lang-arrow-align position-relative"></span>
        <ul class="dropdown-menu px-3 py-2" [class.show]="dropLanguages">
          <li *ngFor="let lang of languages; let last = last; trackBy:identify" class="py-3 nav-dropdown-option"
            [class.last]="last" (click)="onSelectLanguage(lang)">{{lang.name}}</li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Menu and submenu options IN MOBILE-->
  <div class="collapse navbar-white bg-white m-0 p-0 pt-3" #akkyNavbarMobile id="akkyNavbarMobile">
    <ul class="navbar-nav p-0 m-0">
      <li class="nav-item nav-item-titles mx-3" [class.pb-3]="l"
        *ngFor="let option of menuOptions; let i = index; let l = last; trackBy:identify">
        <a href="{{ option.link ? option.link : '#' }}"
          class="nav-link clickable d-flex align-items-center text-uppercase" id="nav-i-about"
          [class.selected-option]="i === selectedOptionIndex" (click)="onSelectOptionMobile(i);">
          {{ option.title | translate }} <span *ngIf="option?.submenus !== undefined"
            class="ms-auto pe-2 d-inline-block {{ i === selectedOptionIndex ? 'i-chevron-up-green' : 'i-chevron-down'}}"></span>
        </a>
        <div class="mobile-submenu p-3 px-4"
          *ngIf="i === selectedOptionIndex && menuOptions[selectedOptionIndex]?.submenus !== undefined">
          <div *ngFor="let submenu of menuOptions[selectedOptionIndex]?.submenus; trackBy:identify">
            <ng-container
              *ngTemplateOutlet="navHTemplate; context: {subtitle: submenu.subtitle, link: submenu.link, options: submenu.options}"></ng-container>
          </div>
        </div>
        <hr *ngIf="!l" />
      </li>
    </ul>
  </div>
</div>

<!-- Login and register modal -->
<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1" [class.show]="showUserAccessModal">
  <div class="modal-dialog modal-dialog-centered user-access-modal p-0 d-flex justify-content-center m-0 m-sm-auto">
    <div class="modal-content user-access-modal-content p-0">
      <div class="modal-body p-0 h-100">
        <app-user-access *appShellNoRender [isEnabled]="showUserAccessModal" [params]="params"
          (onCloseForm)="showUserAccessModal= false"></app-user-access>
      </div>
    </div>
  </div>
</div>

<app-popup-shopping-cart *appShellNoRender (onHide)="showShoppingCart = false"
  [show]="showShoppingCart"></app-popup-shopping-cart>
