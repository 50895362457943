import { Component, OnInit } from '@angular/core';
import { LanguageConst } from '@core-constants/language.const';
import { SEOConst } from '@core-constants/seo.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { AdditionalSettingsDataService } from '@core-data-services/additional-settings.data-service';
import { SettingsManager } from '@core-managers/settings.manager';
import { AdditionalSettings } from '@core-models/additional-settings.model';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit
{
  public pageTitle: string = SEOConst.DefaultData.MaintenanceTitle;
  public langCode: string = LanguageConst.ES;
  public enabled: boolean = false;
  public backgroundImage: string = "/maintenance/back.svg";

  constructor(public additionalSettingsDataService: AdditionalSettingsDataService,
    protected translateService: TranslateService,
    public seoService: SEOService,
    public settingsManager: SettingsManager,
    protected localStorageService: LocalStorageService) { }

  public get data(): AdditionalSettings.IMaintenanceData
  {
    return this.settingsManager.maintenanceData;
  }

  public ngOnInit(): void
  {
    this.initLanguage();

    this.seoService.updateSeoElements(this.translateService.getElement(this.pageTitle), "");
  }

  public initLanguage(): void
  {
    let lang = this.localStorageService.getItem(LocalStorageConst.SelectedLang);

    if (!lang)
    {
      lang = LanguageConst.ES;
    }

    this.translateService.use(lang);
  }

  public ngOnLanguageChange(code: string = this.langCode): void
  {
    const _code = code.toLowerCase();
    this.langCode = _code;
    this.setLanguage();
  }

  private setLanguage(): void
  {
    this.translateService.use(this.langCode);
  }
}
