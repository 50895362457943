import { Injectable } from "@angular/core";
import { DealConst } from "@core-constants/deal.const";
import { IFilterList, IFilterSelected } from "@core-models/filter-list.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class FilterListService
{
    private _filterSelected: IFilterSelected = { value: '' };
    private _filterList: IFilterList[] = [];

    private filterChange$: BehaviorSubject<IFilterSelected> = new BehaviorSubject<IFilterSelected>(this._filterSelected);
    private filterListChange$: BehaviorSubject<IFilterList[]> = new BehaviorSubject<IFilterList[]>(this._filterList);

    public onFilterChange(): Observable<IFilterSelected>
    {
        return this.filterChange$.asObservable();
    }

    public onFilterListChange(): Observable<IFilterList[]>
    {
        return this.filterListChange$.asObservable();
    }

    public set filterSelected(value: IFilterSelected)
    {
        this._filterSelected = value;
        this.filterChange$.next(this._filterSelected);
    }

    public set filterList(value: IFilterList[])
    {
        this._filterList = this.purgeRedundantElements(value)
            .sort((a, b) => a.order - b.order);
        this._filterList.unshift(DealConst.AllDeals);

        this.filterListChange$.next(this._filterList);
    }

    private purgeRedundantElements(array: IFilterList[]): IFilterList[]
    {
        const uniqueCategories = Array.from(new Set(array.map(cat => cat.value)))
            .map(value => array.find(cat => cat.value === value)!);
        return uniqueCategories;
    }

    public getOrderService(text: string): number | undefined
    {
        return DealConst.ServiceOrderMapping[text];
    }
}
