import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageConst } from '@core-constants/storage.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { DomainsManager } from '@core-managers/domains.manager';
import { ILanguageComponent } from '@core-models/generic.model';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-search-result-container',
  templateUrl: './search-result-container.component.html',
  styleUrls: ['./search-result-container.component.css']
})
export class SearchResultContainerComponent implements OnInit, ILanguageComponent
{
  public allPackages: any[] = [];
  public simpleSearch$: Observable<boolean>;
  public domainsFinded$: Observable<any[]>;
  public domainFinded$: Observable<any>;
  public searchResultVisible$: Observable<boolean>;
  public isSearchLoading$: Observable<boolean>;
  public errorSearchEmptyVisible$: Observable<boolean>;
  public errorSearchLengthVisible$: Observable<boolean>;

  public searchinDomainMessage: string = "Buscando el nombre de dominio";
  public domainSearchErrorMessage: string = "Por favor intenta de nuevo, es necesario que indiques un nombre de dominio.";
  public domainSearchLengthErrorMessage: string = "Por favor intenta de nuevo, es necesario que el nombre de dominio tenga al menos 3 caracteres y utilice sólo números, letras y guiones.";

  constructor(private domainsManager: DomainsManager,
    public servicesDataService: ServicesDisplayDataService,
    public localStorageService: LocalStorageService,
    public translateService: TranslateService,
    private ref: ChangeDetectorRef,
    public toast: ToastService,
    private destroyRef$: DestroyRef)
  {
    this.isSearchLoading$ = this.domainsManager.getSearchLoading();
    this.errorSearchEmptyVisible$ = this.domainsManager.getErrorSearchEmptyVisible();
    this.errorSearchLengthVisible$ = this.domainsManager.getErrorSearchLengthVisible();
    this.searchResultVisible$ = this.domainsManager.getSearchResultVisible();
    this.simpleSearch$ = this.domainsManager.isSingleSearch();
    this.domainFinded$ = this.domainsManager.getDomainFinded();
    this.domainsFinded$ = this.domainsManager.getDomainsFinded();
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.ref.detectChanges();
        this.getPackages();
      }
    });
  }

  public getPackages(): void
  {
    this.servicesDataService.getAllPackages().subscribe({
      next: (result: any) =>
      {
        const allPackages = result?.response;
        const allPackagesProccesed = allPackages.map(item => item.traducciones = []);
        if (allPackagesProccesed)
        {
          this.localStorageService.setItem(LocalStorageConst.DomainPackages, JSON.stringify(allPackagesProccesed));
        }
        else
        {
          this.localStorageService.removeItem(LocalStorageConst.DomainPackages);
        }
      }
    });
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
