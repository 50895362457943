import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EAppEventName } from '@core-constants/app-events.const';
import { EInteractiveToasterOperation } from '@core-constants/interactive-toaster.const';
import { LanguageConst } from '@core-constants/language.const';
import { TokenManager } from '@core-managers/token.manager';
import { IToasterData } from '@core-models/interactive-toaster.model';
import { Subject } from 'rxjs';
import { BroadcastService } from './broadcast.service';
import { TranslateService } from './translate.service';

@Injectable({ providedIn: 'root' })
export class InteractiveToasterService
{
  public data: IToasterData;
  public keepClosed: boolean = false;
  public open = new Subject<IToasterData>();

  constructor(protected translateService: TranslateService,
    protected tokenManager: TokenManager,
    private destroyRef$: DestroyRef)
  {
    this.registerEventListeners();
  }

  public showMessage(data: IToasterData): void
  {
    this.data = { ...data };
    this.translateMessages();
    this.doInitialOperation();
  }

  private registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnOpenCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.keepClosed = true;
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnCloseCartPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.keepClosed = false;
      }
    });
  }

  public hide(): void
  {
    this.data = { ...this.data, show: false };
  }

  private translateMessages(): void
  {
    if (this.data.message && this.data.buttonMessage)
    {
      this.data.message = this.translateService.getElement(this.data.message);
      this.data.buttonMessage = this.translateService.getElement(this.data.buttonMessage);
    }
  }

  private waitForCartUpdate(): void
  {
    this.tokenManager.changeDetection
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: number) =>
      {
        if (this.translateService.languageCode == LanguageConst.ES)
        {
          this.data.message = this.data.message.replace(new RegExp(`(@ artículos)`, 'gi'), '<b>$1</b>');
        }
        else
        {
          this.data.message = this.data.message.replace(new RegExp(`(have @)`, 'gi'), '<b>$1</b>');
        }
        this.data.message = this.data.message.replace("@", value.toString());
        this.data.show = !this.keepClosed;
        this.open.next(this.data);
      }
    });
  }

  private doInitialOperation(): void
  {
    if (this.data.operation == EInteractiveToasterOperation.OpenCart)
    {
      this.waitForCartUpdate();
    }
  }

}
