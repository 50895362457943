<h2 class="deal-title">
    {{title | translate | titlecase}}
</h2>
<span class="deal-subtitle mb-3">
    {{description | translate}}
</span>
<div *ngIf="dealItem" class="d-flex justify-content-center">
    <div class="d-flex card-flash-deal rounded-3 p-4 shadow my-4">
        <img class="flash-img me-4" [src]="dealItem.translations[0]?.card?.id | directus"
            [alt]="dealItem.translations[0]?.card?.title">
        <div class="d-flex flex-column justify-content-between">
            <div class="d-flex flex-column-reverse flex-sm-row mb-3 justify-content-between">
                <h3 class="flash-title">{{dealItem?.translations[0]?.title}}</h3>
                <div class="rounded-pill pill-time">{{'Quedan' | translate}} {{countdown}}</div>
            </div>
            <div [innerHTML]="dealItem.translations[0]?.longDescription | safe: 'html'"></div>
            <span>
                <strong *ngIf="showCoupon">{{'Cupón:' | translate}}</strong> {{dealItem?.pakageToAdd?.cupon}}
            </span>
            <div [innerHTML]="dealItem.translations[0]?.termsConditions | safe: 'html'"></div>
            <div class="d-flex justify-content-sm-end mt-3">
                <button (click)="onDealClick()" class="btn btn-primary w-sm-100">
                    {{buttonText | translate}}
                </button>
            </div>
        </div>
    </div>
</div>