import { DestroyRef, Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit
{
  @Input() public debounceTime = 500;
  @Output() public debounceClick = new EventEmitter();

  constructor(private destroyRef$: DestroyRef) { }

  private clicks = new Subject();

  public ngOnInit(): void
  {
    this.clicks.pipe(
      debounceTime(this.debounceTime),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: () =>
      {
        this.debounceClick.emit();
      }
    });
  }

  @HostListener('click', ['$event'])
  public clickEvent(event): void
  {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
