<!-- TODO: Corregir todo el HTML, estilos y logica  -->
<div class="d-flex px-2 flex-wrap justify-content-between">
  <div class="d-flex flex-wrap col-12 col-md-12 col-lg-6 justify-content-around align-items-center">
    <img class="img-navbar-brand" src="{{ '/images/emprendedores-tec-header.jpg' | blobstorage }}"
      data-src="{{'/images/emprendedores-tec-header.jpg' | blobstorage}}" alt="Tecnológico de Monterrey" />
    <img class="img-navbar-brand" src="{{ '/images/logo-emprendimiento-header.jpg' | blobstorage }}"
      data-src="{{'/images/logo-emprendimiento-header.jpg' | blobstorage}}" alt="Instituto de Emprendimiento" />
    <img class="img-navbar-brand" src="{{ '/images/emprendedores-akky-header.png' | blobstorage }}"
      data-src="{{'/images/emprendedores-akky-header.png' | blobstorage}}" alt="En colaboración con Akky" />
  </div>
  <div class="d-flex flex-wrap col-12 col-md-12 col-lg-6 space-flex-20 justify-content-end align-items-center">
    <a href="https://es.wix.com/blog" class=".d-sm-none .d-md-block" target="_blank">
      <img src="{{ '/images/emprendedores-blog-header.png' | blobstorage }}"
        data-src="{{'/images/emprendedores-blog-header.png' | blobstorage}}" alt="En colaboración con Akky" />
    </a>
    <div #dropdownlinks class="landing-dropdown" tabindex="0">
      <button class="dropbtn" (click)="toggleDropdown()">Descarga las guías de usuario
        <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="[!isOpen ? 'dgt-arrow-rotate' : 'dgt-arrow-rotate-rev']"
          width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M4.07992 15.05L10.5999 8.53C11.3699 7.76 12.6299 7.76 13.3999 8.53L19.9199 15.05" stroke="#5643F2"
            stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <div class="landing-dropdown-content" [class.show]="isOpen">
        <a target="_blank"
          href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/bcb699fd-27a5-4381-b442-2bc4c9f96f7b">
          Alta de cuenta en Akky
        </a>
        <a target="_blank"
          href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/736686eb-950e-42b1-a1f8-d6fc6604b9e6">
          Alta de dominio
        </a>
        <a target="_blank"
          href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/fea490a7-b88e-4350-92aa-1ade278dc113">
          Configuración de Correo
        </a>
        <a target="_blank"
          href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/0f722d36-53e1-4c2a-9bc6-b7983cd1b936">
          Guía de WIX
        </a>
      </div>
    </div>
  </div>
</div>

<body>
  <div class="body">
    <div class="d-flex justify-content-center mt-3 mt-sm-0 bg-main-image">
      <img class="bg-main-image-responsive" src="{{ '/images/emprendedores-tec-main-image.png' | blobstorage }}"
        data-src="{{'/images/emprendedores-tec-main-image.png | blobstorage}}" alt="Mi marca personal">
    </div>
    <div class="container">
      <div class="d-flex flex-wrap justify-content-center align-items-center py-5">
        <div class="col-12 col-md-6 col-lg-6 text-center">
          <img src="{{ '/images/emprendedores-foto-1.png' | blobstorage }}"
            data-src="{{ '/images/emprendedores-foto-1.png' | blobstorage }}" class="img-fluid" alt="Foto 1">
        </div>
        <div class="col-12 col-md-6 d-flex flex-column space-flex-20  px-2 px-lg-5 col-lg-6 text-center text-md-start">
          <span class="gray-h2"><b>Mi marca personal...</b></span>
          <div class="txt-hr">
            <span class="gray-h3"><b>Tu ruta hacia el éxito profesional</b></span>
            <hr class="hr-personal-brand">
          </div>
          <p class="white-section-p">
            Es una iniciativa que busca <b>fomentar tu identidad digital</b> para
            <span class="bg-primary-blue text-white">
              potencializar tus oportunidades estudiantiles y
              profesionales.
            </span>
          </p>
          <a (click)="getPersonalBrandServices()" class="btn-personal-brand">
            Activa tus beneficios <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
              <text y="25" font-family="Arial" font-size="40" fill="white">&#8594;</text>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="div-purple-bg">
      <div class="container py-5 d-flex flex-wrap justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-6 px-2 px-lg-5 text-center text-lg-start">
          <h2 class="white-h2 fw-bolder">¡Todos necesitan una identidad digital!</h2>
          <p class="white-p pt-4">
            Préparate para iniciar <b>un viaje que transformará tu presencia en línea </b> y te ayudará a construir
            una marca personal sólida y profesional.
            <br>
            <br>
            Nuestro objetivo es equiparte con las herramientas necesarias para que te destaques en línea y <b>
              alcances
              tus metas profesionales con confianza
            </b>.
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-6 text-center">
          <img src="{{ '/images/emprendedores-foto-2.png' | blobstorage }}"
            data-src="{{ '/images/emprendedores-foto-2.png' | blobstorage }}" class="img-fluid" alt="Foto 2">
        </div>
      </div>
    </div>
    <div class="container div-gray-cards">
      <div class="row align-items-center py-5">
        <div class="col-12">
          <h2 class="gray-h2 fw-bolder text-center">¡Desarrolla tus habilidades digitales!</h2>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 text-center text-sm-start">
          <div class="card">
            <div class="card-body">
              <img src="{{ '/images/emprendedores-habilidad-1.png' | blobstorage }}"
                data-src="{{ '/images/emprendedores-habilidad-1.png' | blobstorage }}" class="img-fluid w-75"
                alt="Habilidad">
              <h5 class="font-title">¡Muestra lo que sabes hacer! Crea tu marca personal.</h5>
              <p class="font-body">
                Cuenta tu historia y presenta tu trabajo con imágenes y videos de alta calidad en una hermosa galería.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 text-center text-sm-start">
          <div class="card">
            <div class="card-body">
              <img src="{{ '/images/emprendedores-habilidad-2.png' | blobstorage }}"
                data-src="{{ '/images/emprendedores-habilidad-2.png' | blobstorage }}" class="img-fluid w-75"
                alt="Habilidad">
              <h5 class="font-title">Comparte y conecta.</h5>
              <p class="font-body">
                Agrega los enlaces de tus redes sociales y tus datos de contacto, para que todos puedan encontrarte
                enseguida.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 text-center text-sm-start">
          <div class="card">
            <div class="card-body">
              <img src="{{ '/images/emprendedores-habilidad-3.png' | blobstorage }}"
                data-src="{{ '/images/emprendedores-habilidad-3.png' | blobstorage }}" class="img-fluid w-75"
                alt="Habilidad">
              <h5 class="font-title">Haz tu portafolio único.</h5>
              <p class="font-body">
                Elige entre cientos de increíbles plantillas en wix.com y crea desde cero tu portafolio profesional
                en tan solo unos minutos.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 text-center text-sm-start">
          <div class="card">
            <div class="card-body">
              <img src="{{ '/images/emprendedores-habilidad-4.png' | blobstorage }}"
                data-src="{{ '/images/emprendedores-habilidad-4.png' | blobstorage }}" class="img-fluid w-75"
                alt="Habilidad">
              <h5 class="font-title">Luce 100% profesional online.</h5>
              <p class="font-body">
                Obtén el nombre de tu dominio .mx, el cual te da una identidad digital única. Con él, conecta una
                dirección de
                email personalizada.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container div-benefits mb-5">
      <div class="row align-items-center py-4">
        <div class="col-12 col-md-6 col-lg-6 text-center">
          <img src="{{ '/images/emprendedores-foto-9.png' | blobstorage }}"
            data-src="{{ '/images/emprendedores-foto-9.png' | blobstorage }}" class="img-fluid rounded mb-5"
            alt="Foto 3">
        </div>
        <div class="col-12 col-md-6 col-lg-6 text-left text-md-start">
          <h2 class="gray-h2 fw-bolder">Beneficios</h2>
          <div class="txt-hr">
            <span class="gray-h3"><b>Iniciativa Identidad Digital Estudiantes</b></span>
            <hr class="hr-personal-brand">
          </div>
          <br>
          <div class="list-text-participate">
            <span class="fas d-inline-block i-check-circle"></span>&nbsp;
            <span class="gray-h3">Dominio</span>
            <img src="{{ '/images/emprendedores-logo-mx.png' | blobstorage }}"
              data-src="{{ '/images/emprendedores-logo-mx.png' | blobstorage }}" class="w-40" alt="Dominio MX">
            <span class="gray-h3 font-purple"> primer año sin costo</span>
            <br>
            <span class="fas d-inline-block i-check-circle"></span>&nbsp;
            <span class="gray-h3">3 meses sin costo de </span><span class="font-purple gray-h3">e-mail
              personalizado</span>
            <br>
            <span class="fas d-inline-block i-check-circle"></span>&nbsp;
            <span class="gray-h3">Creador de sitios web </span>
            <img src="{{ '/images/emprendedores-logo-wix.PNG' | blobstorage }}"
              data-src="{{ '/images/emprendedores-logo-wix.PNG' | blobstorage }}" class="w-40" alt="Wix">
            <br>
            <span class="fas d-inline-block i-check-circle"></span>&nbsp;
            <span class="gray-h3"> Uso y aprovechamiento de herramientas digitales</span>
          </div>
          <div class="text-left mt-3 d-flex justify-space-beetwen align-items-center">
            <a (click)="getPersonalBrandServices()" class="btn-personal-brand">
              Activa tus beneficios <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
                <text y="25" font-family="Arial" font-size="40" fill="white">&#8594;</text>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="div-light-purple-bg py-5">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
            <h2 class="gray-h2 fw-bolder text-center mt-4 mb-5">¡Activa todos los beneficios en 3 sencillos pasos!</h2>
          </div>
        </div>
        <div class="d-flex flex-wrap spacing-row align-items-center justify-content-around">
          <div class="card-personal-brand-container">
            <div class="circle">1</div>
            <div class="card-personal-brand">
              <h5 class="font-title">Genera tu cupón</h5>
              <p class="font-body">
                Solo necesitas tu matrícula y correo institucional
              </p>
              <a data-bs-toggle="modal" data-bs-target="#formModal" class="btn-personal-brand"
                (click)="formModalInitialize()">
                Solicita tu cupón <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
                  <text y="25" font-family="Arial" font-size="40" fill="white">&#8594;</text>
                </svg>
              </a>
            </div>
          </div>
          <div class="card-personal-brand-container">
            <div class="circle">2</div>
            <div class="card-personal-brand">
              <h5 class="font-title">Crea tu cuenta de usuario:</h5>
              <p class="font-body">
                En akky.mx
              </p>
              <a (click)="getPersonalBrandServices()" class="btn-personal-brand">
                Activa tus beneficios <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
                  <text y="25" font-family="Arial" font-size="40" fill="white">&#8594;</text>
                </svg>
              </a>
            </div>
          </div>
          <div class="card-personal-brand-container">
            <div class="circle">3</div>
            <div class="card-personal-brand">
              <h5 class="font-title">¡Listo!</h5>
              <p class="font-body">
                Descarga las guías para empezar a utilizar tus beneficios
              </p>
              <a (click)="goToDropDown()" class="btn-personal-brand">
                Descarga ahora <svg width="20" height="30" xmlns="http://www.w3.org/2000/svg">
                  <text y="25" font-family="Arial" font-size="40" fill="white">&#8594;</text>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-footer-personal-brand">
    <div class="container">
      <div class="row py-2">
        <div class="col-sm-12 col-md-12">
          <div class="row align-items-center justify-content-center">
            <div class="col-6 col-md-3 d-flex justify-content-center my-4">
              <img src="{{ '/images/emprendedores-tec-footer.png' | blobstorage }}" data-src="{{
                '/images/emprendedores-tec-footer.png' | blobstorage
              }}" class="img-fluid w-75" alt="Tecnológico de Monterrey" />
            </div>
            <div class="col-6 col-md-3 d-flex justify-content-center my-4">
              <img src="{{
                '/images/emprendedores-emprendimiento-footer.png' | blobstorage
              }}" class="img-fluid w-100" alt="Instituto de Emprendimiento" />
            </div>
            <div class="col-6 col-md-3 d-flex justify-content-center">
              <img src="{{ '/images/emprendedores-akky-footer.png' | blobstorage }}" data-src="{{
                '/images/emprendedores-akky-footer.png' | blobstorage
              }}" class="w-75" alt="En colaboración con Akky" />
            </div>
            <div class="col-6 col-md-2 d-flex justify-content-center">
              <a href="https://wa-akkyrarprod-cmsdirectus.azurewebsites.net/assets/846d817c-fd63-4522-b44b-a7a931cf945f"
                target="_blank" class="text-terms">
                Términos y Condiciones de la promoción
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button (click)="cleanPersonalBrandVideoPopup()" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="ratio ratio-16x9">
            <iframe *appShellNoRender id="videoId" [src]="personalBrandVideoPopup" allow="autoplay;" allowfullscreen
              enablejsapi="true"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <div class="modal fade" id="modalsucces" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title fs-5" id="exampleModalLabel"></h4>
          <button (click)="closeModal()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body text-center mt-2 ps-3 pe-3">
          <div>
            <h5 class="modal-title" id="exampleModalLabel">
              {{responseTitleMssg}}
            </h5>
            <p class="mt-2">
              {{responseMssg}}
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span *ngIf="hasError" class="fas d-inline-block i-check-remove"></span>
            <img *ngIf="!hasError" loading="lazy" src="{{ '/icons/check-circle.png' | blobstorage }}"
              data-src="{{ '/icons/check-circle.png' | blobstorage }}" alt="Paloma verde" class="area-40">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content size-modal-content">
        <div class="modal-header">
          <button id="closeModalForm" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="ratio ratio-16x9">
            <div class="col-12">
              <form [formGroup]="couponForm" class="formpro" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="enrollment">Matrícula:</label>
                  <input type="text" class="form-control" [formControl]="enrollment" placeholder="Matrícula" required
                    maxlength="255">
                  <div class="input-invalid-form" *ngIf="enrollment.touched && enrollment.errors">
                    Matrícula Inválida
                  </div>
                </div>
                <br>
                <br>
                <div class="form-group">
                  <label for="institutionalMail">Correo Insititucional:</label>
                  <input type="text" class="form-control" [formControl]="institutionalMail"
                    placeholder="E-mail institucional" required title="Utilice el dominio @tec.mx o @itesm.mx"
                    maxlength="20">
                  <div class="input-invalid-form"
                    *ngIf="institutionalMail.touched && institutionalMail.errors && !institutionalMail.errors.onlyAlpha">
                    Tu e-mail no es válido, debe comenzar con la letra A seguido de 8 números y pertenecer al dominio
                    @tec.mx o @itesm.mx.
                  </div>
                </div>
                <br>
                <div class="d-flex w-100 align-items-center justify-content-start">
                  <button id="btn-send-form" (click)="onSubmit()" type="button" [disabled]="couponForm.invalid"
                    class="btn btn-lg btn-see-more">Enviar</button>
                </div>
                <br>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>