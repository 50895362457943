import { isPlatformBrowser } from "@angular/common";
import { AfterViewChecked, Component, DestroyRef, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ILanguageComponent } from "@core-models/generic.model";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-about-card',
  templateUrl: './about-card.component.html',
  styleUrls: ['./about-card.component.css']
})

export class AboutCardComponent implements OnInit, AfterViewChecked, ILanguageComponent
{
  public cardData: any;
  public _varid: string = '';
  public _translations: any;
  public activated = false;
  public cardtxt = '';

  @Input() public set data(value: any)
  {
    this.cardData = value;
  }

  @Input() public set varid(value: any)
  {
    if (value !== undefined)
    {
      this._varid = value;
    }
  }

  @ViewChild('spanreference', { static: false }) private divData: ElementRef<HTMLSpanElement>;

  @HostListener('window:scroll', ['$event'])
  public isScrolledIntoView(): void
  {
    this.initAnimation();
  }

  public initAnimation(): void
  {
    if (this.divData && isPlatformBrowser(this.platformId))
    {
      const rect = this.divData.nativeElement.getBoundingClientRect();
      const div = document.getElementById(this._varid);
      const isTopShown = rect.top >= 0;
      const isBottomShown = rect.bottom <= window.innerHeight;

      if (isTopShown && isBottomShown && !this.activated)
      {
        this.activated = true;
        const end = this.cardData.dato !== undefined ? this.cardData.dato : 0;
        let start = 0;
        const duration = Math.floor(3000 / end);

        const counter = setInterval(function ()
        {
          start += 1;
          div.innerHTML = start.toString();

          if (start === end)
          {
            clearInterval(counter);
          }
        }, duration);
      }
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private destroyRef$: DestroyRef) { }

  public setTraduccion(lang: string = this.translateService.languageCode): void
  {
    this._translations = this.cardData.traducciones.find((x: any) => x.lenguaje == lang);
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public ngAfterViewChecked(): void
  {
    this.initAnimation();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (lang) =>
        {
          this.setTraduccion(lang);
        }
      });
  }
}
