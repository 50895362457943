<div class="card h-100 border-0" *ngIf="plan">
  <div *ngIf="plan.title" [innerHTML]="plan.title | safe: 'html'"></div>

  <div *ngIf="recommended" class="d-flex justify-content-end mx-3 recommended-container">
    <div class="recommended" [style.backgroundImage]="recommendedImage | blobstorage:'style'"></div>
  </div>

  <ng-container
    *ngIf="!verticalStyle && !isMobile; then horizontallPlanStyleTemplate else verticalPlanStyleTemplate"></ng-container>
</div>

<!-- vertical template -->
<ng-template #verticalPlanStyleTemplate>

  <div [class.recommended-bodrder]="showRecommendedBorder" class="w-100">

    <div [ngStyle]="{'background-color': plan.cardBackground}"
      class="card-body gray-rounded-div p-0 pb-4 position-relative h-100 flex-row flex-wrap justify-content-around">
      <div class="row p-0 m-0 vertical">
        <div class="col-12 row p-0 m-0 p-md-3 border-custom-contianer"
          [ngStyle]="{'background-color': plan.priceBackground}">
          <!-- Title column -->
          <div class="col-12 col-md-6 p-0 m-0">
            <div class="p-4 pb-0 pb-md-4 mt-0">
              <div class="d-flex justify-content-start d-none d-md-block" *ngIf="plan.img">
                <img class="limited-size-img" loading="lazy" data-src="{{plan.img | directus}}" alt="mkt plan image"
                  [src]="plan.img | directus">
              </div>
              <div *ngIf="plan.description" [innerHTML]="plan.description | safe: 'html'"></div>
            </div>
          </div>

          <!-- Price column -->
          <div class="col-12 col-md-6 p-0 m-0">
            <div
              class="p-0 p-md-4 service-price-section d-flex flex-column justify-content-start justify-content-md-center align-items-center py-2">
              <div *ngIf="plan.currentPrice" [innerHTML]="plan.currentPrice | safe: 'html'"></div>
              <div *ngIf="plan.lastPrice" [innerHTML]="plan.lastPrice | safe: 'html'"></div>
            </div>
          </div>
        </div>

        <!-- button -->
        <div class="ms-auto col-12">
          <div class="ms-auto col-12 col-md-6 p-0 m-0" *ngIf="plan.textButton">
            <div class="d-flex justify-content-center position-relative mt-2 z-index-1 item-button-area action-button">
              <button appDebounceClick (debounceClick)="onButtonClick()" [debounceTime]="500"
                class="btn my-3 mx-1 mkt-button" [ngClass]="plan.action === 'cart' ? 'btn-primary' : 'btn-secondary'"
                id="{{ plan.gtmKey }}" [attr.data-gtm-key]="plan.gtmKey">
                {{ plan.textButton }}
              </button>
            </div>
          </div>
        </div>

        <!-- button combo -->
        <div class="ms-auto col-12 mt-1" *ngIf="plan.comboId">
          <div class="ms-auto col-12 col-md-6 p-0 m-0" class="mkt-button">
            <div class="d-flex justify-content-center position-relative mt-2 z-index-1 item-button-area action-button">
              <button appDebounceClick (debounceClick)="onComboButtonClicked()" [debounceTime]="500"
                class="btn my-3 mx-1 mkt-button btn-info" id="{{ plan.gtmKey }}" [attr.data-gtm-key]="plan.gtmKey">
                {{ plan.textButtonCombo }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Specs -->
      <div class="py-2 py-md-4" [ngStyle]="{'background-color': plan.cardBackground}">
        <div *ngIf="plan.characteristics" [innerHTML]="plan.characteristics | safe: 'html'"></div>
      </div>
    </div>
  </div>
</ng-template>

<!-- horizontal template -->
<ng-template #horizontallPlanStyleTemplate>

  <div [class.recommended-bodrder]="showRecommendedBorder" class="w-100">

    <div [ngStyle]="{'background-color': plan.cardBackground}"
      class="card-body gray-rounded-div p-0 h-100 flex-row flex-wrap justify-content-around">
      <div class="row p-0 m-0 horizontal">
        <div class="col-12 col-md-6 col-lg-7 p-2 m-0">
          <div *ngIf="plan.characteristics" [innerHTML]="plan.characteristics | safe: 'html'"></div>
        </div>

        <!-- Price column -->
        <div
          class="col-12 col-md-6 col-lg-5 d-flex flex-column justify-content-center align-items-center p-0 m-0 border-custom-contianer"
          [ngStyle]="{'background-color': plan.priceBackground}">

          <!-- Price -->
          <div class="p-0 d-flex flex-column justify-content-center align-items-center py-2">
            <div *ngIf="plan.currentPrice" [innerHTML]="plan.currentPrice | safe: 'html'"></div>
            <div *ngIf="plan.lastPrice" [innerHTML]="plan.lastPrice | safe: 'html'"></div>
          </div>

          <!-- Button -->
          <div class="d-flex justify-content-center w-100" *ngIf="plan.textButton">
            <button appDebounceClick (debounceClick)="onButtonClick()" [debounceTime]="500"
              class="btn my-3 mx-1 mkt-button" [ngClass]="plan.action === 'cart' ? 'btn-primary' : 'btn-secondary'"
              id="{{ plan.gtmKey }}" [attr.data-gtm-key]="plan.gtmKey">
              {{ plan.textButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>