import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment } from '@environments';
import { AppBrowserModule } from './app/app.browser.module';

if (Environment.Production)
{
  enableProdMode();
}

function bootstrap(): void
{
  platformBrowserDynamic().bootstrapModule(AppBrowserModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete')
{
  bootstrap();
}
else
{
  document.addEventListener('DOMContentLoaded', bootstrap);
}

