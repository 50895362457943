import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownItem } from '@core-models/generic.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent 
{
  public showOptions: boolean = false;
  private _disabled: boolean = false;
  @Output() public onItemSelectedChange = new EventEmitter<any>();

  @Input() public label: string = "";
  @Input() public parentId: string = "";
  @Input() public isFullWidth: boolean = false;
  @Input() public showError: boolean = false;
  @Input() public coverage: boolean = false;


  @Input('selectedValue') public selectedValue: any;
  @Input('items') public _items: any[];

  @Input() public set disabled(value: boolean)
  {
    this._disabled = value;
    this.showOptions = value;
  }

  public get disabled(): boolean 
  {
    return this._disabled;
  }

  public get items(): DropDownItem[]
  {
    return this._items;
  }

  public get selectedOption(): DropDownItem
  {
    return this._items.find((x: any) => x.value == this.selectedValue);
  }

  constructor() { }

  public onSelectOption(item: any): void
  {
    if (!this.disabled)
    {
      this.selectedValue = item.value;
      this.onItemSelectedChange.emit(item);
    }
  }
}
