import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})

export class TestimonialsComponent
{
  constructor() { }

  public _title: string = "";
  public _subtitle: string = "";

  @Input() public set title(value: any)
  {
    if (value)
    {
      this._title = value;
    }
  }

  @Input() public set subtitle(value: any)
  {
    if (value)
    {
      this._subtitle = value;
    }
  }
}
