import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, DestroyRef, ElementRef, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { AditionalServiceInfo, IAditionalServiceInfoTranslation } from '@core-models/aditional-service.model';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { ServiceSection } from '@core-models/base-service-section.model';
import { IComplementaryService } from '@core-models/service-complementary.model';
import { ServicePanelTemplate } from '../templates/service-panel-template';
import { IComparativeTable } from '@core-models/comparative-table.model';
import { ComparativeTableConst } from '@core-constants/comparative-table.const';
import { ESection1PropertiesName } from '@core-constants/service-section.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-new-base-service',
  templateUrl: './new-base-service-component.html',
  styleUrls: ['./new-base-service-component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewBaseServiceComponent
{
  @Input() public showSpecs: boolean = true;
  @Input() public showSpecsTitle: boolean = true;
  @Input() public showExamples: boolean = true;
  @Input() public showServicePanel: boolean = true;

  public serviceInfo: ServiceSection.IServiceInfo;
  public bannerInfo: ServiceSection.IBannerInfo;

  public examples: any = undefined;
  public section1: ServiceSection.ISection1 = new ServiceSection.Section1();
  public aditionalDescription: any = undefined;
  public addonInfo: any = undefined;
  public isFeatures: boolean = true;
  public comparativeTable: IComparativeTable | undefined = undefined;

  public id: number | undefined = undefined;

  public complementaryServices: IComplementaryService[] = [];
  public titleComplementaryServices: string;

  public showComparativeSection: boolean = false;
  public dataComparative: IComparativeTable;
  public showComparativeTable: boolean = false;
  public comparativeTableColumnsNumber: number = 0;
  public servicePanelStructHTML: string = '';

  @ContentChild('dynamicContentTemplate') public dynamicContentTemplate: TemplateRef<ElementRef>;

  @Input() public set serviceID(value: number)
  {
    if(value)
    {
      this.comparativeTable = ComparativeTableConst.ComparativeTable.find((x: IComparativeTable) => x.serviceID === value);
    }

    else
    {
      this.comparativeTable = undefined;
    }
  }

  @Input() public set serviceData(value: AditionalServiceInfo)
  {
    if (value)
    {
      this.update(value);
    }
  }

  @Input() public set serviceComparative(value: IComparativeTable)
  {
    if (value)
    {
      this.setDataComparative(value);
    }
  }

  constructor(protected translateService: TranslateService,
    protected seoService: SEOService,
    private ref: ChangeDetectorRef,
    public metaTagService: Meta,
    public toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.onShowFeatures()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (value: boolean) =>
      {
        this.isFeatures = value;
      }
    });
  }

  public update(service: AditionalServiceInfo): void
  {
    if (service)
    {
      this.id = service.id;

      const data = service.traducciones[0];

      if (data)
      {
        this.seoService.updateSeoElements(data?.seo_titulo, data?.seo_descripcion);
        this.setBannerInfo(data);
        this.setServiceInfo(data);
        this.setExamplesSection(data);
        this.setInfoAddon(data);
        this.setSection1(data);
        this.setServiceDataPanel();

        this.titleComplementaryServices = data.titulo_complementos;
      }

      if (service.servicios_complementarios)
      {
        this.complementaryServices = service.servicios_complementarios.map((x: any) => x.servicios_complementarios_id);
      }
    }

    this.ref.detectChanges();
  }

  public setBannerInfo(data: IAditionalServiceInfoTranslation): void
  {
    this.bannerInfo =
    {
      background: data.imagen_banner?.id,
      image: data.imagen_titulo?.id,
      text: data.texto_banner
    };
  }

  public setServiceInfo(data: IAditionalServiceInfoTranslation): void
  {
    this.serviceInfo =
    {
      title: data.titulo,
      subtitle: data.subtitulo,
      description: data.descripcion_general
    };
  }

  public setInfoAddon(data: IAditionalServiceInfoTranslation): void
  {
    this.addonInfo = undefined;

    if (data.descripcion_addon != undefined && data.descripcion_addon != '' && data.titulo_addon != undefined && data.titulo_addon != '')
    {
      this.addonInfo = {};
      this.addonInfo.description = data.descripcion_addon;
      this.addonInfo.title = data.titulo_addon;
    }
  }

  public setExamplesSection(data: IAditionalServiceInfoTranslation): void
  {
    this.examples = undefined;

    if (this.showExamples === true)
    {
      this.examples = {};

      this.examples.title = data.titulo_ejemplos;
      this.examples.images = [];

      this.addImage(data.img_ejemplo1);
      this.addImage(data.img_ejemplo2);
      this.addImage(data.img_ejemplo3);
    }
  }

  public setDataComparative(data: IComparativeTable): void
  {
    this.dataComparative = data[0];
    this.showComparativeSection = true;
    this.showComparativeTable = false;
    this.comparativeTableColumnsNumber = data[0].headers.length;
  }

  public addImage(image: string): void
  {
    if (image != undefined && image != '')
    {
      this.examples.images.push(image);
    }
  }

  public setSection1(data: IAditionalServiceInfoTranslation): void
  {
    if (this.showSpecs)
    {
      this.section1.title = data.titulo_seccion1;
      this.section1.img_section = data.img_seccion1;
      this.section1.specs_reverse = Object.prototype.hasOwnProperty.call(data, 'specs_reverse');
      this.section1.data = this.mapSpecsArray(data);
    }
  }

  private mapSpecsArray(data: any): ServiceSection.ISectionData[]
  {
    return [1, 2, 3].map(index =>
    ({
      title: data[ESection1PropertiesName.tituloCaracteristica + index],
      image: data[ESection1PropertiesName.imagenCaracteristica + index]?.id,
      description: data[ESection1PropertiesName.descripcionCaracteristica + index]
    }));
  }

  public getReverse(i: number): boolean
  {
    return this.isOdd(i) ? !this.section1.specs_reverse : this.section1.specs_reverse;
  }

  private isOdd(numero: number): boolean
  {
      return numero % 2 !== 0;
  }

  public setServiceDataPanel(): void
  {
    const servicePanelTemplate = new ServicePanelTemplate(this.translateService);
    this.servicePanelStructHTML = servicePanelTemplate._servicePanelStructHTML;
  }
}
