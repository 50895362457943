<div class="service-policies-container basic-content">
  <div class="container my-5 px-2 px-sm-3 px-md-5">
    <div class="col-12" *ngIf="policiesListVisible">
      <div class="row">
        <div class="col-12 mt-3 mt-md-5">
          <h1>{{'Políticas de servicio' | translate}}</h1>
        </div>
      </div>
      <div class="row" *ngFor="let servicePoliceCategory of servicePoliceCategories">
        <div class="col-12">
          <p class="service-policies-category">
            {{servicePoliceCategory?.traducciones[0]?.descripcion}}
          </p>
        </div>
        <div class="col-12">
          <ul>
            <li class="green fs-l mb-3 link-item"
              *ngFor="let servicePolicy of servicePoliceCategory.politicas; trackBy:identify"
              (click)="navigateToContent(servicePolicy)">
              <span>{{servicePolicy?.traducciones[0]?.titulo}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="policiesContentVisible">
      <div class="row">
        <div class="col-12">
          <div class="row border-bottom-1">
            <div class="col-6 mt-5">
              <div class="redakky-return text-uppercase">
                <a (click)="setServicePoliciesVisible()" class="d-flex align-items-center my-2">
                  <img loading="lazy" class="ml-1 fs-l" data-src="{{ '/icons/Black/arrow-left.svg' | blobstorage }}"
                    alt="Arrow left" src="{{ '/icons/Black/arrow-left.svg' | blobstorage }}">
                  <span class="darkGray text-uppercase msb ml-3"> {{'Regresar' | translate}}</span>
                </a>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button class="btn btn-primary button-download" [hidden]="!isPdfValid" (click)="openPdf()">
                {{'Descargar pdf' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 pb-5" [innerHTML]="policiesContent | safe: 'html'"></div>
      </div>
    </div>

    <div class="mt-5 col-12 p-5" *ngIf="showErrorVisible">
      <div class="jumbotron dashed px-2 mb-0 mt-5">
        <div class="col-12 m-3">
          <span class="display-5 my-3 d-block text-break text-center">{{'Politica no encontrada' | translate}}</span>
          <Button class="btn btn-primary" (click)="setServicePoliciesVisible()">
            {{'Volver a políticas de servicio' | translate}}</Button>
        </div>
      </div>
    </div>
  </div>
</div>