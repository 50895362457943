import { Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { IBenefitItem } from '@core-models/benefits.model';

@Component({
  selector: 'app-exclusive-promotion',
  templateUrl: './exclusive-promotion.component.html',
  styleUrls: ['./exclusive-promotion.component.css']
})
export class ExclusivePromotionComponent implements OnChanges
{
  @Input() public title: string = "";
  @Input() public exclusiveItems: IBenefitItem[] | undefined = undefined;
  public bigItem: IBenefitItem | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  public onClickImg(url: string) : void
  {
    if (this.platformId === 'browser')
    {
      window.open(url, '_blank');
    }
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.exclusiveItems)
    {
      this.setBigItem();
    }
  }

  public setBigItem(): void
  {
    if (this.exclusiveItems.length === 3)
    {
      this.bigItem = this.exclusiveItems.pop();
    }
  }
}
