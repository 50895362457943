<div id="methods-banner" class="methods-bg container-fluid px-0"
  [style.backgroundImage]="backgroundImage | blobstorage:'style' ">
  <div class="container row mx-auto pt-3 pt-md-5 justify-content-around">
    <div class="d-flex col-12 col-md-6">
      <span class="mx-auto my-auto text-center text-md-left msb fs-xxl">{{'Tus proyectos crecen más al estar en
        Internet' | translate}} </span>
    </div>
    <div class="service-img-container col-12 col-md-6 col-lg-5">
      <img loading="lazy" src="{{ '/images/meeting.svg' | blobstorage }}" alt="CpanelFt">
    </div>
  </div>
</div>

<div id="methods-content" class="container py-4 px-2 px-sm-3 px-md-5">
  <div id="methods-header" class="mt-4">
    <h1>{{'Formas de Pago' | translate}}</h1>
  </div>
  <div id="methods-body" class="mt-4 mb-5 d-flex flex-column col-12 px-0">
    <div id="methods-description" class="font-weight-bold fs-l col-12 px-0">
      {{'Las formas de pago en los servicios y nombres de dominio pueden variar, en la contratación, registro,
      renovación y recuperación. Así como en los distintos estatus de' | translate}} <span
        class="text-uppercase yellow">{{'pendientes de pago' | translate}}</span> {{'y' | translate}} <span
        class="text-uppercase lightRed">{{'suspendidos' | translate}}</span>.
    </div>
    <div id="methods-header" class="col-12 d-flex flex-row pt-5 px-0">
      <div class="col-4 px-0 fs-l msb text-uppercase">
        {{'Forma de pago' | translate}}
      </div>
      <div class="col-4 px-0 fs-l msb text-uppercase">
        {{'Tiempo de acreditación' | translate}}
      </div>
    </div>

    <!-- online payment -->
    <div id="pay-online" class="col-12 d-flex flex-row pt-5 px-0">
      <div id="po-1" class="col-4 px-0 ">
        <div id="info" class="col-9 fs-m msb px-0 text-uppercase">
          {{'Pago en línea con tarjeta de crédito o débito' | translate}}
        </div>
        <div id="detail" class="fs-s pt-3">
          {{'Aplica para todos los servicios' | translate}}
        </div>
      </div>
      <div id="po-2" class="col-4 px-0 fs-m align-items-center d-flex">
        {{'La activación de los servicios con este método de pago es inmediata' | translate}}
      </div>
      <div id="po-3" class="col-4 px-0 d-flex align-items-center flex-wrap  align-content-center">

        <!-- first row-->
        <div id="po-3-f1" class="row align-items-center d-flex justify-content-center">
          <div class="col-5 col-sm-4 col-md-3 col-lg-2 px-1 py-0">
            <img loading="lazy" src="{{ '/cards/logo-visa.svg' | blobstorage }}"
              data-src="{{ '/cards/logo-visa.svg' | blobstorage }}" alt="VISA" class="w-100">
          </div>
          <div class="col-5 col-sm-4 col-md-3 col-lg-2 px-1 py-0">
            <img loading="lazy" src="{{ '/cards/logo-mastercard.svg' | blobstorage }}"
              data-src="{{ '/cards/logo-mastercard.svg' | blobstorage }}" alt="Mastercard" class="w-100">
          </div>
        </div>

        <!-- second row -->
        <div id="po-3-f2" class="row align-items-center d-flex justify-content-center">
          <div class="col-5 col-sm-4 col-md-3 col-lg-2 px-1 py-0">
            <img loading="lazy" src="{{ '/cards/logo-amex.svg' | blobstorage }}"
              data-src="{{ '/cards/logo-amex.svg' | blobstorage }}" alt="AmericanExpress" class="w-100">
          </div>
          <div class="col-5 col-sm-4 col-md-3 col-lg-2 px-1 py-0">
            <img loading="lazy" src="{{ '/cards/logo-paypal-v2' | blobstorage }}.svg"
              data-src="{{ '/cards/logo-paypal-v2' | blobstorage }}.svg" alt="Paypal" class="w-100">
          </div>
        </div>

      </div>
    </div>

    <!-- bank -->
    <div id="bank-deposit" class="col-12 d-flex flex-row pt-5 px-0">
      <div id="bd-1" class="col-4 px-0 ">
        <div id="info" class="fs-m msb px-0 text-uppercase">
          {{'Depósito bancario:' | translate}} <br> {{'Pago referenciado' | translate}} <br> {{'Cuenta de cheques' |
          translate}}
        </div>
        <div id="detail" class="fs-s col-9 px-0">
          {{'No aplica en registro para' | translate}} .com, .net, .lat, .autos, .lol, .mom, .xyz, .cc {{'y' | translate}} .tv
        </div>
      </div>
      <div id="bd-2" class="col-4 px-0 fs-m align-items-center d-flex">
        {{'La activación de los servicios con este método de pago demora de 1 día hábil si este fue realizado en efectivo, banca en línea o con cheque (BBVA), o bien, 2 a 3 días hábiles si es que fue efectuado con cheque de otro banco.' | translate}}
      </div>
      <div id="bd-3" class="col-4 px-0 fs-l msb d-flex flex-column align-items-center justify-content-center">
        <div id="bbva" class="align-items-center d-flex justify-content-center row">
          <div class="col-8 col-sm-6 col-md-4 col-lg-3 p-0">
            <img loading="lazy" src="{{ '/cards/logo-bbva.svg' | blobstorage }}"
              data-src="{{ '/cards/logo-bbva.svg' | blobstorage }}" alt="BBVA" class="w-100">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
