<!-- Banner -->
<div id="help-banner" class="banner-bg basic-content help-main-banner-bg container-fluid d-flex align-items-center"
  *ngIf="backgroundImage && backgroundImage !== ''" [style.backgroundImage]="backgroundImage | blobstorage:'style'">
  <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5 d-flex justify-content-around">
    <div id="help-title-container" class="d-flex w-100 justify-content-center align-items-center">
      <h1 class="msb fs-xxl">{{ pageTitle | translate}}</h1>
    </div>
  </div>
</div>


<div id="help-content" class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
  <!-- Help topics grid -->
  <section id="help-topics">
    <div id="help-topics-title" class="px-2">
      <div class="font-weight-bold silver text-uppercase fs-l">{{'Temas de Ayuda'| translate}}</div>
      <h2>{{'¿Con qué te ayudamos?'| translate}}</h2>
    </div>
    <div class="row p-0 m-0 px-md-4">
      <ng-container
        *ngTemplateOutlet="helpItemTemplate; context: { title: 'Procesos de Atención a Cliente', text: '¿Más dudas? No te preocupes, estamos para servirte.', link: '../../ayuda/temas-de-ayuda' }"></ng-container>
      <ng-container
        *ngTemplateOutlet="helpItemTemplate; context: { title: 'Nombre de Dominio', text: 'Aprende a dar de alta el nombre de tu negocio en Internet.', link: '../../ayuda/temas-de-ayuda' }"></ng-container>
      <ng-container
        *ngTemplateOutlet="helpItemTemplate; context: { title: 'Servicios', text: 'Te ofrecemos servicios adicionales para responder a todas tus necesidades.', link: '../../ayuda/temas-de-ayuda' }"></ng-container>
      <ng-container
        *ngTemplateOutlet="helpItemTemplate; context: { title: 'Contáctanos', text: 'Ya sea que tengas una duda o alguna inquietud, por favor comunícate con alguien de nuestro equipo.', link: '../../ayuda/contacto' }"></ng-container>
      <ng-container
        *ngTemplateOutlet="helpItemTemplate; context: { title: 'FAQ\'s', text: 'Respondemos todas tus dudas en un listado que nuestros expertos han armado.', link: '../../ayuda/faq' }"></ng-container>
    </div>
  </section>

  <!-- Exclusive help -->
  <section id="help-sections">
    <div id="help-sections-title" class="my-3 px-3">
      <div class="font-weight-bold silver text-uppercase fs-l">
        {{'Recursos' | translate}}
      </div>
      <h3>{{'Secciones exclusivas de ayuda' | translate}}</h3>
      <div class="font-weight-bold green fs-xl">
        {{'Publicaciones' | translate}}
      </div>
    </div>

    <div id="help-sections-content" class="row p-0 m-0 px-md-4">
      <div class="col-12 col-md-6 p-3">
        <div (click)="goToBlog()" class="help-section-a">
          <div class="gray-rounded-div d-flex flex-column h-100 p-4">
            <div class="font-weight-bold green fs-xl">{{'Blog' | translate}}</div>
            <div class="fs-l darkGray mt-3">
              {{'Visita nuestro blog con la información más actualizada, tendencias y tips para tu dominio y/o negocio.'
              | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Chat section -->
  <section id="help-chat" class="p-2">
    <div id="help-chat-title" class="text-center">
      <div class="fs-xxl my-3">
        {{'¿No encuentras lo que estás buscando?' | translate}}
      </div>
      <div class="w-100 silver fs-xl my-3">
        {{'¡No te preocupes! Te ayudaremos a encontrar la respuesta o conectarte con un asesor.' | translate}}</div>
    </div>
    <div id="help-chat-content" class="d-flex my-3">
      <button class="btn btn-primary mx-auto text-uppercase d-flex align-items-center" id="chatButton" #btnChat
        title="Chat" (click)="openChat()">
        {{'Iniciar Chat' | translate}}
        <span class="mx-1"></span>
        <img loading="lazy" class="position" data-src="{{ '/icons/White/comment-white.svg' | blobstorage }}" width="20"
          height="20" alt="comment" src="{{ '/icons/White/comment-white.svg' | blobstorage }}">
      </button>
    </div>
  </section>
</div>

<!-- Help item Template -->
<ng-template #helpItemTemplate let-title="title" let-text="text" let-link="link">
  <div class="col-12 col-md-6 col-lg-4 p-3">
    <div class="help-topic-item d-flex flex-column h-100 p-3">
      <div class="fs-xl text-black font-weight-bold">
        <span class="help-topic-title clickable" (click)="redirectTo(link)">{{ title | translate}}</span>
      </div>
      <div class="fs-l darkGray mt-2 mb-3">{{text | translate}}</div>
      <div class="d-flex mt-auto">
        <span (click)="redirectTo(link)" class="green clickable item-link ms-auto mr-2"> {{'Ver más' | translate}}
        </span>
      </div>
    </div>
  </div>
</ng-template>
