import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html'
})
export class FamilyComponent
{
  public _title_1: string = "";
  public _title_2: string = "";
  public _subtitle: string = "";
  public _testimonials: boolean = false;

  @Input() public set title_1(value: any)
  {
    if (value)
    {
      this._title_1 = value;
    }
  }

  @Input() public set title_2(value: any)
  {
    if (value)
    {
      this._title_2 = value;
    }
  }

  @Input() public set subtitle(value: any)
  {
    if (value)
    {
      this._subtitle = value;
    }
  }
  @Input() public set testimonials(value: boolean)
  {
    this._testimonials = value;
  }
}
