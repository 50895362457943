import { isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from "@angular/core";

@Component({
  selector: 'app-input-toggler',
  templateUrl: './input-toggler.component.html',
  styleUrls: ['./input-toggler.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTogglerComponent
{
  private _isActive: boolean = false;

  @Input() public title: string = "";
  @Input() public parentId: string = "";
  @Input() public disabled: boolean = false;

  @Output() public onValueChanges = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  @Input() public set value(value: boolean)
  {
    this._isActive = value;
  }

  public get value(): boolean
  {
    return this._isActive;
  }

  public get inputId(): string
  {
    return "input-" + this.parentId;
  }

  public onCheckChanges(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this._isActive = !this._isActive;
      this.onValueChanges.emit(this._isActive);
    }
  }
}
