<!-- Main content -->
<div class="domain-search-result-container p-3 px-md-5 mb-3" *ngIf="hasAvailableDomains">
  <div class="px-0 px-xl-4">
    <ng-container *ngTemplateOutlet="availableDomainsTemplate"></ng-container>
  </div>
</div>

<div class="domain-search-result-container p-3 px-md-5 mb-3" *ngIf="hasAvailableOfflineDomains">
  <div class="px-0 px-xl-4">
    <ng-container *ngTemplateOutlet="availableOfflineDomainsTemplate"></ng-container>
  </div>
</div>

<div class="domain-search-result-container p-3 px-md-5 mb-3" *ngIf="hasUnvailableDomains">
  <div class="px-0 px-xl-4">
    <ng-container *ngTemplateOutlet="unavailableDomainsTemplate"></ng-container>
  </div>
</div>

<!--  Available Domains Template -->
<ng-template #availableDomainsTemplate>

  <!-- Search domain result message -->
  <div class="basic-domain-result-text">
    <ng-container *ngIf="hasOneAvailableDomain; else manyAvailableDomainsMessageTemplate">
      <b>{{'¡Excelente!'| translate }}</b> {{'el dominio está disponible.'| translate }} <b>
        {{'Regístralo'| translate }}
      </b> {{'antes de que te lo ganen'| translate }}.
    </ng-container>
    <ng-template #manyAvailableDomainsMessageTemplate>
      <b>{{'¡Excelente!'| translate }}</b> {{'los dominios están disponibles.'| translate }} <b>
        {{
        '¡Regístralos'|
        translate
        }}
      </b> {{'ya!'| translate }}
    </ng-template>
  </div>

  <hr>

  <!--available domains list-->
  <div *ngFor="let domain of availableDomains; let i = index"
    class="row w-100 justify-content-between align-items-center p-0 mx-0 my-3">

    <!-- Domain name -->
    <div class="domain-name-container col-12 col-lg-5 d-block d-md-inline text-center text-lg-start m-0 p-0">
      <span class="fs-xl black">
        {{ domain.domainName }}
      </span>
      <!-- Offer icon -->
      <span *ngIf="domain.beforePrice || hasDomainPromotion(domain)" title="{{ offerMessage | translate }}"
        (click)="goToPromotions()" class="clickable fas i-offer domain d-inline-block"></span>
    </div>


    <!-- Price, requirements and add to cart -->
    <div
      class="col-12 col-lg-7 d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end align-items-center p-0 m-0">

      <div class="row col-6 m-0 p-0 my-3 my-lg-0 me-0 me-lg-3 domain-price-container">
        <!-- beforePrice -->
        <div class="col-6 d-flex align-items-center justify-content-end m-0 p-0"
          [ngClass]="{ 'd-none d-lg-block': !domain.beforePrice }">
          <span *ngIf="domain.beforePrice" class="gray price searchResult-domainPriceMxn price pe-2">
            <del>
              {{domain.beforePrice | currency}} <span> MXN</span>
            </del>
          </span>
        </div>
        <!-- price -->
        <ng-container *ngIf="domain.isFreeDomain === false; else requerimentsTemplate">
          <div class="col-6 d-flex align-items-center m-0 p-0 price justify-content-start"
            [ngClass]="{'col-12 col-lg-6 justify-content-center justify-content-lg-start': !domain.beforePrice }">
            <span class="me-2 fs-xl black">$ {{ domain.price }}</span>
            <span class="searchResult-domainPriceMxn"> MXN </span>
          </div>
        </ng-container>
        <!-- Online payment -->
        <div class="col-6 m-0 p-0" [ngClass]="{ 'd-none d-lg-block': !domain.beforePrice }"></div>
        <div *ngIf="domain?.onlinePayment" class="col-12 col-lg-6 m-0 p-0 text-center text-lg-start">
          <div class="gray-payment-message">{{'(Pago en línea)' | translate}}</div>
        </div>
      </div>

      <div class="mt-3 mt-md-0 pe-0 pe-xl-5">
        <!-- Add to cart button -->
        <button [attr.id]="'searchDomainForm_domainResults_' + (i + 1)" (click)="onAddToCart(domain)"
          [disabled]="!(domain.isEnabled)" class="btn btn-primary mx-auto ms-md-0 text-nowrap">
          {{'Agregar al carrito' | translate}}
        </button>
      </div>
    </div>

  </div>

  <div class="black fs-md my-3">
    {{
    'Ya que hayas agregado el dominio al carrito podrás configurarlo y agregarle servicios para tener un producto más
    completo.' | translate
    }}
    <br>
    <span class="text-uppercase">{{'precios incluyen iva' | translate}}</span>
  </div>

  <div *ngIf="hasOneAvailableDomain === false" class="my-2 d-flex pe-0 pe-xl-5">
    <button [disabled]="!addAllDomainsEnabled" id="searchDomainForm_addAllResults"
      class="btn btn-primary mx-auto me-md-0 ms-md-auto text-nowrap" (click)="addAllDomainsToCart()">
      {{'Agregar todos' | translate}}
    </button>
  </div>

</ng-template>

<!-- Multiple domains availableOffline template -->
<ng-template #availableOfflineDomainsTemplate>
  <span class="searchResult-error-description">
    {{'Los siguientes dominios están disponibles fuera de línea.' | translate}}
    <span class="col-12 col-sm-6 help-icon-container">
      <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}" alt="Question mark"
        src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
      <span class="tooltipTextSearchDomainOffline"><small> {{'Favor de contactar a Servicio al Cliente' | translate}}
        </small></span>
    </span>
  </span>
  <!--unavailable domains -->
  <div *ngFor="let domain of availableOfflineDomains">

    <span class="basic-domain-result-text"> {{ domain?.domainName }}</span>
    <ng-container *ngTemplateOutlet="domainErrorTemplate; context: { domain: domain }"></ng-container>
  </div>
</ng-template>

<!-- Multiple domains unavailable template -->
<ng-template #unavailableDomainsTemplate>
  <span class="searchResult-error-description">{{'Los siguientes dominios no están disponibles.' | translate}}</span>

  <!--unavailable domains -->
  <div *ngFor="let domain of unavailableDomains">
    <span class="red fs-xl me-3">
      <img loading="lazy" data-src="{{ '/icons/Colores/times-red.svg' | blobstorage }}" alt="Red Circle"
        src="{{ '/icons/Colores/times-red.svg' | blobstorage }}">
    </span>
    <span class="basic-domain-result-text"> {{ domain?.domainName }}</span>
    <ng-container *ngTemplateOutlet="domainErrorTemplate; context: { domain: domain }"></ng-container>
  </div>

</ng-template>

<!-- Domain error template -->
<ng-template #domainErrorTemplate let-domain="domain">
  <p class="searchResult-error-description p-0 m-0" *ngIf="hasError(domain)">
    <span *ngIf="!isMaintenanceError(domain)">
      {{ 'Verifica su escritura.' | translate }}
      <span class="col-12 col-sm-6 help-icon-container">
        <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}"
          alt="Question mark" src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
        <span class="tooltipTextSearchDomain"><small> {{ getDomainErrorMessage(domain) }} </small></span>
      </span>
    </span>
    <span *ngIf="isMaintenanceError(domain)" class="domain-error-multiple-search">
      {{getDomainErrorMessage(domain) }}
      <span class="text-underline clickable black" (click)="navigateToPortalAkky()">
        {{'Más Información' | translate}}.
      </span>
    </span>
  </p>
</ng-template>

<!-- Doamain Requirements Template -->
<ng-template #requerimentsTemplate>
  <div class="fs-l green clickable text-center text-lg-end" (click)="onRequirementsClicked()">
    {{ requirementsMessage | translate }}
  </div>
</ng-template>
