import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { CouponConst } from "@core-constants/coupon.const";
import { EDgtDocument } from "@core-constants/digitize.const";
import { RegexConst } from "@core-constants/regex.const";
import { SutentsChallengeConst } from "@core-constants/students-challenge.const";
import { AddToCartParams } from "@core-constants/url-param.const";
import { CouponsDataService } from "@core-data-services/coupons.data-service";
import { PersonalBrandDataService } from "@core-data-services/personal-brand.data-service";
import { RouteHelper } from "@core-helpers/route.helper";
import { TokenManager } from "@core-managers/token.manager";
import { ICouponStudent, ISalesForceEmail } from "@core-models/personal-brand.model";
import { Environment } from '@environments';
import { RegexValidators } from "@shared-utils/regex-validation.util";

@Component({
  selector: 'app-digitize',
  templateUrl: './digitize.component.html',
  styleUrls: ['./digitize.component.css']
})
export class DigitizeComponent implements OnInit
{
  public studentId: string = "";
  public responseTitleMssg: string = "";
  public hasError: boolean = false;
  public responseMssg: string = "";
  public enrollment: FormControl = null;
  public institutionalMail: FormControl = null;
  public couponForm: FormGroup = null;
  public isOpen: boolean = false;
  public isExpand: boolean = false;
  @ViewChild('formContainer', { static: false }) public formContainer: ElementRef<HTMLButtonElement>;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private couponDataService: CouponsDataService,
    private personalBrandDataService: PersonalBrandDataService,
    public routeHelper: RouteHelper,
    public tokenManager: TokenManager,
    private titleService: Title,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef
  ) { }

  public get accountRegistrationPDF(): string
  {
    return EDgtDocument.AccountRegistration;
  }

  public get domainRegistrationPDF(): string
  {
    return EDgtDocument.DomainRegistration;
  }

  public get emailConfigurationPDF(): string
  {
    return EDgtDocument.EmailConfiguration;
  }

  public get wixConfigurationPDF(): string
  {
    return EDgtDocument.WixConfiguration;
  }

  public get termsConditionsPDF(): string
  {
    return EDgtDocument.TermsConditions;
  }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.titleService.setTitle("Akky | Digitalízate");
    }
    this.initializeForm();
    this.registerRouteEventListeners();
  }

  public toggleDropdown(): void
  {
    this.isOpen = !this.isOpen;
  }

  public scrollToButton(): void
  {
    this.formContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private initializeForm(): void
  {
    this.enrollment = new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(10)
    ]));

    this.institutionalMail = new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(225),
      RegexValidators.patternsValidator(RegexConst.RegexsDgt, { onlyNumber: false })
    ]));

    this.couponForm = new FormGroup({
      institutionalMail: this.institutionalMail,
      enrollment: this.enrollment
    });
  }

  public onAnimationToggle(): void
  {
    this.isExpand = !this.isExpand;
  }

  public onCloseModal(): void
  {
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'hide');
      this.renderer.setStyle(modal, 'display', 'none');
    }
  }

  public openModal(): void
  {
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
    }
  }

  public onSubmit(): void
  {
    if (this.couponForm.valid)
    {
      const temporalStudentValue: ICouponStudent =
      {
        institutionalMail: this.couponForm.controls['institutionalMail'].value,
        enrollment: this.couponForm.controls['enrollment'].value
      };

      this.couponDataService.createStudentCoupon(temporalStudentValue).subscribe({
        next: () =>
        {
          this.handleSuccessResponse();
        },
        error: (response: HttpErrorResponse) =>
        {
          this.handleErrorResponse(response);
        }
      });
    }
  }

  private handleSuccessResponse(): void
  {
    this.hasError = false;
    this.responseMssg = "¡Hemos recibido tu petición correctamente!";
    this.responseTitleMssg = "Revisa tu e-mail para más información.";
    this.sendEmailWithCoupon();
    this.openModal();
  }

  private handleErrorResponse(error: any): void
  {
    this.hasError = true;
    const errorDescription = error?.error.description;
    let responseTitleMssg: string = "¡Ups! Algo va mal, puedes volver a intentarlo en unos minutos";
    let responseMssg: string;

    if (SutentsChallengeConst.FormStudentsCodeErrors.has(errorDescription))
    {
      responseTitleMssg = "Hubo un error en el registro";
      responseMssg = SutentsChallengeConst.FormStudentsCodeErrors.get(errorDescription);
    }
    this.responseTitleMssg = responseTitleMssg;
    this.responseMssg = responseMssg;
    this.openModal();
  }

  public sendEmailWithCoupon(): void
  {
    const emailData = this.setEmailCouponData();
    this.personalBrandDataService.sendMailWithCoupon(emailData).subscribe({
      next: () =>
      {
        this.couponForm.reset();
      },
      error: () =>
      {
        this.handleErrorResponseEmail();
        this.couponForm.reset();
      }
    });
  }

  private setEmailCouponData(): ISalesForceEmail
  {
    const studentInfo: ISalesForceEmail = {
      email: this.institutionalMail.value,
      couponId: CouponConst.PrefixEnterprisingTEC23 + this.enrollment.value.trim()
    };

    return studentInfo;
  }

  private handleErrorResponseEmail(): void
  {
    this.hasError = true;
    this.responseTitleMssg = "¡Ups! Algo va mal";
    this.responseMssg = "Tu cupón se guardó correctamente pero ocurrió un error al enviarlo a tu email. \nContáctanos para enviarte el cupón.";
    this.openModal();
  }

  public get homePackageURL(): string
  {
    let packageToAdd: number | undefined = this.tokenManager.getPackageIdToAdd();

    if (!packageToAdd)
    {
      packageToAdd = Environment.DigitizePackage;
    }

    return `${this.routeHelper.ecommerceURL}?packageToAdd=${packageToAdd}`;
  }

  public registerRouteEventListeners(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.activatedRoute.queryParams
      .subscribe({
        next: (params) =>
        {
          this.buildToAddParams(params);
        }
      });
    }
  }

  private buildToAddParams(params: object): void
  {
    if (params[AddToCartParams.Coupon] || params[AddToCartParams.Package])
    {
      const pkg = params[AddToCartParams.Package];
      const coupon = params[AddToCartParams.Coupon];

      this.tokenManager.saveCartToAddParams(pkg, coupon);
    }
    else
    {
      this.tokenManager.remoteToAdd();
    }
  }
}
