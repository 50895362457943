import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EAppEventName } from '@core-constants/app-events.const';
import { EBenefitType, EDgtDocument } from '@core-constants/benefits.const';
import { BenefitsDataService } from '@core-data-services/benefits.data-service';
import { IBenefitItem, IBenefitsResponse } from '@core-models/benefits.model';
import { ILanguageComponent } from '@core-models/generic.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitsComponent implements OnInit, ILanguageComponent, AfterViewInit
{
  public benefits: IBenefitsResponse | undefined = undefined;
  public exclusiveItems: IBenefitItem[] | undefined = undefined;
  public seasonalItems: IBenefitItem[] | undefined = undefined;
  public monthItems: IBenefitItem[] | undefined = undefined;

  constructor(protected translateService: TranslateService,
    private ref: ChangeDetectorRef,
    protected benefitsDataService: BenefitsDataService,
    private destroyRef$: DestroyRef
  ) { }

  public get termsConditionsPDF(): string
  {
    return EDgtDocument.TermsConditions;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.getBundle();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getBundle();
      }
    });
  }

  public ngAfterViewInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
    this.ref.detectChanges();
  }

  private getBundle(): void
  {
    this.benefitsDataService.getBundle().subscribe({
      next: (response: IBenefitsResponse) =>
      {
        this.setBenefits(response);
      }
    });
  }

  private setBenefits(benefits: IBenefitsResponse): void
  {
    this.benefits = benefits;
    this.exclusiveItems = this.benefits.items.filter((item: IBenefitItem) => item.section === EBenefitType.Exclusive).sort((a, b) => a.order - b.order);
    this.seasonalItems = this.benefits.items.filter((item: IBenefitItem) => item.section === EBenefitType.Seasonal).sort((a, b) => a.order - b.order);
    this.monthItems = this.benefits.items.filter((item: IBenefitItem) => item.section === EBenefitType.Month).sort((a, b) => a.order - b.order);
    this.ref.detectChanges();
  }
}
