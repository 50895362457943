<div class="p-3 p-md-4">

  <h1 *ngIf="title !== ''">{{ title }}</h1>
  <ng-container *ngIf="items && items.length > 0">
    <div class="accordion" *ngFor="let item of items; trackBy:identify">
      <a class="collapsed accordion-name accordion-button accordion-header" data-bs-toggle="collapse"
        href="#{{prefix + item.id}}" role="button" aria-expanded="false" aria-controls="faq-collapse">
        <div [innerHTML]="item.title | safe: 'html'"></div>
      </a>
      <div id="{{prefix + item.id}}" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <div class="accordion-text" [innerHTML]="item.text | safe: 'html'"></div>
        </div>
      </div>
    </div>
  </ng-container>

</div>