import { isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { EGtmSection, EventLocationSite } from '@core-constants/gtm-const';
import { AddToCartParams } from '@core-constants/url-param.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { RouteHelper } from '@core-helpers/route.helper';
import { UTMOperationHelper } from '@core-helpers/utm-operation.helper';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { SettingsManager } from '@core-managers/settings.manager';
import { TokenManager } from '@core-managers/token.manager';
import { ICupon } from '@core-models/service-package.model';
import { GroupedShoppingCart, ShoppingCarBaseContent, ShoppingCart, ShoppingCartItem } from '@core-models/shopping-cart-items.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit
{
  public isSiteEnabled: boolean = true;
  public validated: boolean = false;
  public isShowFooter = true;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected toast: ToastService,
    protected localStorageService: LocalStorageService,
    public tokenManager: TokenManager,
    public settingsManager: SettingsManager,
    private activatedRoute: ActivatedRoute,
    private utmOperationService: UTMOperationHelper,
    private servicesDataService: ServicesDisplayDataService,
    protected checkoutManager: CheckoutManager,
    protected routeHelper: RouteHelper,
    protected promotionHelper: PromotionHelper,
    protected translateService: TranslateService,
    private shoppingCartDataService: ShoppingCartDataService,
    public userAccessDataService: UserAccessDataService,
    private router: Router,
    private destroyRef$: DestroyRef
  )
  {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: NavigationEnd) =>
      {
        this.isShowFooter = !event.url.includes('/recomendaciones');
      }
    });
  }

  public get cartURL(): string
  {
    return this.routeHelper.shoppingCartWebURL;
  }

  public get tokenToValidate(): boolean
  {
    const token = this.tokenManager.getToken();
    return token && token !== "";
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.registerRouteEventListeners();
  }

  public registerRouteEventListeners(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.activatedRoute.queryParams.subscribe({
        next: (params: HttpParams) =>
        {
          this.utmOperationService.buildUTMObject(params);
          this.buildToAddParams(params);

          this.validateSession();
        }
      });
    }
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnLoginSuccess)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown) =>
      {
        const cartId = event[0][0];
        this.tokenManager.saveCartCookie(cartId);
        this.getInitialCartOnLogin();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnAddToCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown) =>
      {
        const cartId = event[0][0];
        this.tokenManager.saveCartCookie(cartId);

        if (this.hasPendingPackageToAdd() === true)
        {
          this.getAndApplyPackage();
        }
        else
        {
          this.getInitialCart();
        }
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnUserLogout)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.removeCartInfoOnLogout();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnValidateSession)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        if (this.hasPendingPackageToAdd() === true)
        {
          this.getAndApplyPackage();
        }
        else
        {
          this.getInitialCart();
        }
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnLanguageChange)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getInitialCart(); // TODO Evaluar esta línea. Genera una doble petición del carrito inicial
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnSignUpSuccess)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getInitialCart();
      }
    });

  }

  private getInitialCartOnLogin(): void
  {
    this.shoppingCartDataService.getInitialContent().subscribe({
      next: (res: ShoppingCarBaseContent) =>
      {
        if (res)
        {
          this.checkoutManager.initializeCartBaseData(res);

          if (res.cartItemsCounts == 0)
          {
            this.tokenManager.removeAditionalCartInformation();
            BroadcastService.Instance.broadcast(EAppEventName.OnUpdateCart, 0);
          }
          else if (res.cartItemsCounts > 0)
          {
            this.getPopupCart();
          }
        }
      }
    });
  }

  private getInitialCart(): void
  {
    if (this.settingsManager.isSiteEnabled && isPlatformBrowser(this.platformId))
    {
      this.shoppingCartDataService.getInitialContent().subscribe({
        next: (res: ShoppingCarBaseContent) =>
        {
          if (res)
          {
            this.checkoutManager.initializeCartBaseData(res);

            if (res.cartItemsCounts > 0)
            {
              this.getPopupCart();
            }
            else
            {
              this.tokenManager.removeAditionalCartInformation();
              BroadcastService.Instance.broadcast(EAppEventName.OnUpdateCart, 0);
            }
          }
        }
      });
    }
  }

  private getPopupCart(): void
  {
    this.shoppingCartDataService.getPopup().subscribe({
      next: (res: ShoppingCart) =>
      {
        if (res)
        {
          this.checkoutManager.initializeCartItems(this.preProcessCartItems(res.items));
        }
      }
    });
  }

  private preProcessCartItems(shoppingCartItems: ShoppingCartItem[]): GroupedShoppingCart[]
  {
    const groupedItems = Object.entries(shoppingCartItems.reduce((accumulator, currentItem) =>
    {
      const currentKey = currentItem.quantityGroup;

      (accumulator[currentKey] = accumulator[currentKey] || []).push(currentItem);

      return accumulator;
    }, {}));

    const result : GroupedShoppingCart[] = groupedItems.map((item: any) => ({ product: item[1][0], quantityGroup: item[0], counter: item[1].length }));
    return result;
  }

  private removeCartInfoOnLogout(): void
  {
    this.tokenManager.signOut();
    this.userAccessDataService.redirectToAkkyOnLogout();
  }

  private validateSession(): void
  {
    if (this.settingsManager.isSiteEnabled && isPlatformBrowser(this.platformId))
    {
      const token: string = this.tokenManager.getToken();
      const cartId: string = this.tokenManager.getCartCookie();

      if (token)
      {
        this.userAccessDataService.validateSession().subscribe({
          next: (res: any) =>
          {
            this.tokenManager.saveUser(res.userInfoModel);
            this.tokenManager.saveCartCookie(res.cartId);

            BroadcastService.Instance.broadcast(EAppEventName.OnValidateSession, true);
          },
          error: () =>
          {
            BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, true);
          }
        });
      }
      else
      {
        if (cartId)
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnValidateSession, false);
        }
        else
        {
          this.tokenManager.signOut();
          BroadcastService.Instance.broadcast(EAppEventName.OnValidateSession, false);
        }
      }
    }
  }

  private hasPendingPackageToAdd(): boolean
  {
    const packageToAdd: number = this.tokenManager.getPackageIdToAdd();

    return packageToAdd !== undefined;
  }

  private getAndApplyPackage(): void
  {
    const packageToAdd: number = this.tokenManager.getPackageIdToAdd();

    if (packageToAdd)
    {
      this.servicesDataService.getPackageById(packageToAdd).subscribe({
        next: (response: ICupon) =>
        {
          if (response)
          {
            const servicePackage: ICupon = response;
            const couponToAdd: string = this.tokenManager.getCouponToAdd();

            if (couponToAdd && couponToAdd != "")
            {
              response.cupon = couponToAdd;
            }

            this.promotionHelper.addToCart(servicePackage, EGtmSection.Home, EventLocationSite.AddedByUrl);

            this.tokenManager.remoteToAdd();
          }
        },
        error: () =>
        {
          this.getInitialCart();
        }
      });
    }
  }

  //Package to add
  private buildToAddParams(params: object): void
  {
    if (params[AddToCartParams.Coupon] || params[AddToCartParams.Package])
    {
      const pkg = params[AddToCartParams.Package];
      const coupon = params[AddToCartParams.Coupon];

      this.tokenManager.saveCartToAddParams(pkg, coupon);
    }
  }
}
