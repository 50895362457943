export namespace ReferenceConst
{
  export const FAQCategoryIdTranfers: number = 11;
  export const ChatButtonId: string = 'liveagent_button_online_5738F000000GmdD';
  export const PersonalBrandComboPromo: string = "personal.brand.combo.id";

  export const WhatsAppUrl: string = 'https://wa.me/5218129089830';
  export const BlogAkkyUrl: string = 'https://blog.akky.mx/';
  export const AccreditationPage: string = 'https://sealserver.trustwave.com/cert.php?customerId=d2781281211249f9a17a599b0cb9581b&size=105x54&style=';
  export const GoogleMapsURL = 'https://www.google.com/maps/place/Akky+Online+Solutions/@25.6539925,-100.2766807,19z/data=!4m6!3m5!1s0x8662bfe1ae1939bf:0xaf96d41d2fc65a23!8m2!3d25.6537797!4d-100.2767987!16s%2Fg%2F11nx26r6c9?entry=ttu';
}

