<div *ngIf="item" class="w-100 card suggestion-item" [class.active]="isSelected">
  <div id="select-container" (click)="onSelectItem()">
    <!-- image and label -->
    <div class="d-flex align-items-center justify-content-between m-3 p-0">
      <img class="service-img" src="{{ imgRoute | blobstorage }}" loading="lazy" [alt]="'imgRoute_' + index">
      <div *ngIf="textLabel" class="text-center py-2 px-3 text-label" [class.active]="isSelected">
        <span class="fs-m fw-semibold ">{{ textLabel }}</span>
      </div>
    </div>

    <!-- service name -->
    <div class="d-flex align-items-center justify-content-start m-3 p-0 h-title-item">
      <img id="{{controlName}}" loading="lazy" alt="Selected Checkbox Suggestion" data-src="{{ image | blobstorage }}"
        src="{{image | blobstorage }}" class="clickable ckeck-icon">
      <label for="{{controlName}}" class="me-auto">
        <span class="ms-2 fw-bold fs-5">
          {{title}}
        </span>
      </label>
    </div>

    <div class="border-bottom mx-3"></div>

    <!-- features -->
    <div *ngIf="hasFeatures" class="d-flex m-3 p-0 h-feature-item align-items-center">
      <ul class="p-0 m-0">
        <li *ngFor="let feature of featuresTranslations" class="d-flex align-items-center justify-content-start my-2">
          <span class="i-check-cian me-2 p-3"></span>
          <span class="fs-m">{{feature.text}}</span>
        </li>
      </ul>
    </div>

    <div *ngIf="hasFeatures" class="border-bottom mx-3"></div>

    <!-- price -->
    <div class="d-flex flex-column align-items-center justify-content-center mt-3 p-0 h-price-item">
      <div class="fw-bold fs-5 price">
        {{ finalAmount | currency }}<span> MXN</span>
      </div>
      <div *ngIf="hasOfferAmount" class="fw-bold d-block gray fs-m">
        <del>
          {{ offerAmount | currency }} <span> MXN</span>
        </del>
      </div>
    </div>
  </div>
  
  <!-- learn more -->
  <div class="m-3 p-0">
    <div *ngIf="subtitle" class="accordion accordion-card" id="accordion-add-suggestions-{{index}}">
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading_parentId">
          <button class="collapsed accordion-button accordion-button-card" data-bs-toggle="collapse"
            attr.data-bs-target="#collapse_{{index}}" aria-expanded="true" attr.aria-controls="collapse_{{index}}">
            <span class="text-uppercase green font-weight-bold">
              {{"Conoce más" | translate}}
            </span>
          </button>
        </h2>
        <div id="collapse_{{index}}" class="accordion-collapse collapse" data-bs-parent="#accordion-add-suggestions-{{index}}">
          <div class="accordion-body m-3 p-0">
            <div [innerHTML]="subtitle | safe: 'html'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
