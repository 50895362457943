<div class="gradient-top-container basic-content">
  <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
    <div class="row p-0 m-0">
      <div class="col-12 col-md-6 col-lg-7">
        <section id="contact-hours">
          <div class="font-login my-3">
            {{'Horarios' | translate}}
          </div>

          <section id="contact-info-sales" class="fs-l font-weight gray my-3">
              {{ 'Ventas de Lunes a Viernes de' | translate }} <strong> 08:00 </strong> {{ 'a' | translate }} <strong> 20:00 </strong> {{ 'horas y Sábados de' | translate }}
              <strong> 09:00 </strong> {{ 'a' | translate }} <strong> 15:00 </strong> {{ 'horas de acuerdo a la zona horaria' | translate }} {{ 'UTC-6.' | translate }}
              <br />
              {{ 'Teléfono: +52 (81) 8864-2640' | translate }}
            <div>
              {{'Chat: Via' | translate}}
              <span class="msb green clickable" (click)="openChat()">Web</span>
              {{'o' | translate}}
              <a href="{{urlWhatsApp}}" target="_blank" class="msb green">Whatsapp</a>
            </div>
            <div>
              {{'Correo:' | translate }}
              <a class="msb green underline fs-l" href="mailto:ventas@akky.mx"> {{'ventas@akky.mx' | translate}}</a>
            </div>
          </section>

          <section id="contact-info-support" class="fs-l font-weight gray my-3">
            {{'Atención a dudas generales, configuración y fallas técnicas 24/7.' | translate }}
            <br />
            {{ 'Teléfono: +52 (81) 8864-2626' | translate }}
            <div>
              {{'Chat Vía:' | translate}}
              <span class="msb green clickable" (click)="openChat()">Web</span>
              {{'o' | translate}}
              <a href="{{urlWhatsApp}}" target="_blank" class="msb green">Whatsapp</a>
            </div>
            <div>
              {{'Correo:' | translate }}
              <a class="msb green underline fs-l" href="mailto:ayuda@akky.mx"> {{'ayuda@akky.mx' | translate}}</a>
            </div>
          </section>

          <section id="contact-info-abuse" class="fs-l font-weight gray my-3">
            <div>
              {{'Casos de Abuso (Autoridad): +52 (81) 8864-2625' | translate}}
            </div>
            <div>
              {{'Correo:' | translate }}
              <a class="msb green underline" href="mailto:abuso@akky.mx"> {{'abuso@akky.mx' | translate}}</a>
            </div>
          </section>

          <div class="fs-l font-weight gray">
            {{'En el siguiente enlace podrás conocer a detalle nuestros:' | translate}}
            <a href="/ayuda/temas-de-ayuda?tema=Servicio-al-cliente" class="msb green d-block">
              {{'Procesos de Atención al Cliente' | translate}}
            </a>
          </div>
        </section>

        <section id="contact-phone" class="my-3">
          <div class="font-login my-3">{{'Teléfono' | translate}}</div>
          <div class="service-complements row d-md-flex p-0 m-0 justify-content-start justify-content-lg-center">
            <div class="col-12 col-lg-4 mx-0 mx-md-auto p-2 msb fs-l">
              <span class="gray d-block">{{'En México:' | translate}}</span>
              <span class="black">(81) 8864-2626</span>
            </div>
            <div class="col-12 col-lg-4 mx-0 mx-md-auto p-2 msb fs-l">
              <span class="gray d-block">{{'Del extranjero:' | translate}}</span>
              <span class="black">+52 (81) 8864-2626</span>
            </div>
            <div class="col-12 col-lg-4 mx-0 mx-md-auto p-2 msb fs-l">
              <span class="gray d-block">{{'Casos de Abuso (Autoridad):' | translate}}</span>
              <span class="black">(81) 8864-2625</span>
            </div>
          </div>
        </section>

        <section id="contact-help">
          <div class="font-login my-3">{{'Ayuda en Línea' | translate}}</div>
          <div class="fs-l font-weight gray">
            {{'También puedes ingresar a nuestra Ayuda en Línea, donde serás atendido en tiempo real.' | translate}}
          </div>
          <div class="my-3 text-center">
            <a class="btn btn-primary" id="chatButton" #btnChat title="Chat" (click)="openChat()">
              {{'INICIAR CHAT' | translate}} <span class="mx-1"></span>
              <img loading="lazy" data-src="{{ '/icons/White/comment-white.svg' | blobstorage }}" alt="comment" width="20" height="20" src="{{ '/icons/White/comment-white.svg' | blobstorage }}">
            </a>
          </div>
        </section>
      </div>
      <!--FORM CONTACT US-->
    </div>
  </div>

  <div class="container-fluid container-lg py-4 px-4 px-sm-3 px-md-4 px-lg-5">
    <div class="font-login my-3">
      {{'Correo electrónico' | translate}}
    </div>
    <div class="fs-l font-weight gray my-3">
      {{
'Nuestra atención a través de las distintas cuenta de correo electrónico tienen un tiempo máximo de respuesta
        en nuestro horario de atención de 24 horas. Al recibir tu mensaje te enviaremos de forma automática un ticket
        con el cual te daremos atención.' | translate
      }}
    </div>
    <div class="fs-l font-weight gray my-3">
      <ul class="ps-4">
        <li>
          {{'Si tienes dudas acerca de los nombres de dominio y/o servicios en general por favor escríbenos a la siguiente dirección:' | translate}}
          <a class="msb green underline fs-l" href="mailto:ayuda@akky.mx."> {{'ayuda@akky.mx' | translate}}</a>
        </li>
        <li>
          {{'Si tienes dudas acerca del proceso de pago, por favor escríbenos a:' | translate}}
          <a class="msb green underline fs-l" href="mailto:pagos@akky.mx"> {{'pagos@akky.mx' | translate}}</a>
        </li>
        <li>
          {{'Si tienes dudas sobre qué plan de Sitios Web o Tienda en Línea elegir, o bien, si ya contrataste algunos de estos servicios y requieres asistencia, por favor escríbenos a:' | translate }}
          <a class="msb green underline fs-l" href="mailto:ventas@akky.mx"> {{'ventas@akky.mx' | translate}}</a>
        </li>
        <li>
          {{ 'Si tienes comentarios o sugerencias acerca de nuestro servicio en general, por favor escríbenos a:' | translate }}:
          <a class="msb green underline fs-l" href="mailto:comentarios@akky.mx">
            {{ 'comentarios@akky.mx' | translate }}
          </a>
        </li>
        <li>
          {{'Reportar nombres de dominio utilizados en casos de abuso:' | translate}}
          <a class="msb green underline fs-l" href="mailto:abuso@akky.mx"> {{'abuso@akky.mx' | translate}}</a>
        </li>
      </ul>
    </div>
    <div class="fs-l font-weight gray my-3">
      {{'Al recibir tu mensaje te enviaremos el número de Ticket ID con el cual será atendida tu duda o comentario.' | translate}}
    </div>
  </div>

  <section>
    <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
      <div class="row w-100 m-0 p-0">
        <div class="col-12 col-md-5 col-lg-6 align-self-center">

          <!-- SOCIAL MEDIA -->
          <section>
            <span class="font-login my-3 d-block">{{'Redes Sociales' | translate}}</span>
            <div class="my-3">
              <a class="text-decoration-none" href="https://www.facebook.com/AkkyMX" target="_blank">
                <img class="limited-size-img" loading="lazy" data-src="{{ '/social-media/facebook.svg' | blobstorage }}" width="100%" height="100%" src="{{ '/social-media/facebook.svg' | blobstorage }}" alt="Página de Facebook">
              </a>
              <span class="mx-2"></span>
              <a class="text-decoration-none" href="https://twitter.com/akkymx" target="_blank">
                <img class="limited-size-img" loading="lazy" data-src="{{ '/social-media/twitter.svg' | blobstorage }}" width="100%" height="100%" src="{{ '/social-media/twitter.svg' | blobstorage }}" alt="Página de Twitter">
              </a>
              <span class="mx-2"></span>
              <a class="text-decoration-none" href="https://www.youtube.com/channel/UCRYwktxrxbg9SNlOPcHMzrA"
                 target="_blank">
                <img class="limited-size-img" loading="lazy" data-src="{{ '/social-media/youtube.svg' | blobstorage }}" width="100%" height="100%" src="{{ '/social-media/youtube.svg' | blobstorage }}" alt="Perfil de YouTube">
              </a>
            </div>
          </section>

          <!-- ADDRESS -->
          <section>
            <span class="font-login msb my-3 d-block">{{'Dirección' | translate}}</span>
            <span class="fs-l gray my-3">
              Av. Revolución 2703-1
              <br>Nuevo Sur, Torre 2, Piso 3 Col. Ladrillera
              <br>Monterrey N.L., México
              <br>C.P. 64830
            </span>
          </section>
        </div>

        <div
          class="col-12 col-md-7 col-lg-6 p-4 p-md-0 d-flex align-self-end justify-content-center justify-content-md-end">
          <img (click)="openMap()" class="address-image clickable" loading="lazy" data-src="{{ '/images/Maps-nuevo-sur.svg' | blobstorage }}"
            alt="Ubicación AKKY" src="{{ '/images/Maps-nuevo-sur.svg' | blobstorage }}">
        </div>
      </div>
    </div>
  </section>

</div>
