import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-builder',
  template: `<router-outlet></router-outlet>`
})

export class SiteBuilderComponent
{
  constructor(private router: Router)
  {
    if (this.router.url == "/servicios/creador-sitios")
    {
      this.router.navigate(['/']);
    }
  }
}
