import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { EventLocationSite } from "@core-constants/gtm-const";
import { NavigationConst } from '@core-constants/navigation.const';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { AditionalServiceInfo } from '@core-models/aditional-service.model';
import { IBaseServiceBundleComponent, ILanguageComponent, IToggleData } from '@core-models/generic.model';
import { IPlan } from '@core-models/plans.model';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-ssl-certificate-service',
  templateUrl: './ssl-certificate-service.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SslCertificateServiceComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.sslCertificateServices;
  public _serviceID: EAdditionalService = EAdditionalService.SSLCertificate;
  public _serivceData: AditionalServiceInfo = undefined;

  public plans: IPlan[] = [];
  public groupedPlans: any[] = [];
  public currentGroupCode: string = "";
  public selectedIndex: number = 0;

  public locationServicePage: string = EventLocationSite.CertificateSslPage;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: any) => this.router.url.includes(x.path));

    if (isPlatformBrowser(this.platformId))
    {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef$)
      ).subscribe({
        next: (event: any) =>
        {
          if (event.url.includes('#') && isPlatformBrowser(this.platformId))
          {
            const elementId = event.url.split('#').pop();
            const element = document.querySelector(`#${elementId}`);

            if (element)
            {
              element.scrollIntoView();
            }
          }
        }
      });
    }
  }

  public get exampleHtmlText(): string
  {
    return this._serivceData?.translation?.titulo_ejemplos;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public getServiceBundleInfo(): void
  {
    const serviceName: string[] = [EServiceCode.SSL];

    this.servicesDataService.getBundle(serviceName).subscribe({
      next: ({ response }: any) =>
      {
        if (response)
        {
          this._serivceData = response[0].service;

          const res: any[] = response[0].plans;

          this.groupedPlans = Tools.groupBy(res, "grupo");

          if (this.currentGroupCode === '')
          {
            this.currentGroupCode = this.dataToggle[0]?.service;
          }

          this.plans = this.groupedPlans[this.currentGroupCode];

          this.onSelectTab(this.selectedIndex);
          this.addViewItemListEvent(response[0].plans);
        }
      }
    });
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });

    this.toggleHelper.onToggleChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (index) =>
      {
        this.onSelectTab(index);
      }
    });
  }

  public onSelectTab(index: number): void
  {
    this.currentGroupCode = this.dataToggle[index].service;
    this.plans = this.groupedPlans[this.currentGroupCode];

    this.selectedIndex = index;
    this.ref.detectChanges(); // forces change detection to run
  }

  public addViewItemListEvent(items: any[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
