import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccordionModel } from '@core-models/accordion.model';
import { AssetsService } from '@shared-services/assets.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-accordion-page',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements OnInit, AfterViewInit
{
  public itemsAccordion: any[] = [];
  public titleAccordion: string = "";
  public prefixAccordion: string = "accordion-item-";
  public colorAccordion: string = "";

  @Input() public items: AccordionModel[] = [];
  @Input() public title: string = "";
  @Input() public prefix: string = "accordion-item-";

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    public assetsService: AssetsService,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public ngAfterViewInit(): void
  {
    this.setImages();
  }

  public registerEventListeners(): void
  {
    this.assetsService.onBlobURLChanges()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.setImages();
      }
    });
  }

  public setImages(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const backgroundImage = this.assetsService.getAssetFromBlob('/icons/down_arrow.svg');
      Tools.cssRuleSet('.accordion-button::after', 'background-image', `url(${backgroundImage})`, 'important');
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
