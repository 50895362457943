<app-new-base-service [serviceData]="_serivceData" [showSpecsTitle]="false" [serviceID]="_serviceID">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serivceData">

      <div class="d-flex w-100 justify-content-center mb-5 align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <div class="d-flex space-plans justify-content-center flex-wrap">
        <div *ngFor="let item of plans; let l = last">
          <app-service-plan [plan]="item" [location]="locationServicePage"></app-service-plan>
        </div>
      </div>

      <div class="row justify-content-center my-3" *ngIf="selectedService">
        <div class="mx-auto col-10 col-md-12" [innerHTML]="selectedService.title | safe: 'html'"></div>
      </div>

      <div class="container-fluid" *ngIf="selectedService">
        <div class="container">
          <div class="row d-md-flex"></div>
          <div *ngIf="selectedService" class="row d-md-flex text-center pb-2" [innerHTML]="selectedService.description | safe: 'html'"></div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</app-new-base-service>
