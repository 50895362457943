import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ReferenceConst } from '@core-constants/reference.const';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlogRedirectGuard 
{
  constructor(@Inject(PLATFORM_ID) private platformId) { }

  public canActivate(): Observable<boolean>
  {

    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = ReferenceConst.BlogAkkyUrl;
    }
    return of(false);
  }
}
