<div class="card border-0" [ngClass]="plan_layout ? 'h-100' : 'h-content'">
  <div *ngIf="recommended" class="row container-recommend fs-m">
    <div class="col justify-content-center block-recommend">
      <span class="fas d-inline-block i-star size-icon-recommend align-text-top"></span> {{ textRecommended }}
    </div>
  </div>
  <div [ngStyle]="{'background-color': cardBackground, 'border': '2px solid ' + priceBackground}"
       class="card-body gray-rounded-div p-0 mb-4 position-relative h-100 flex-row flex-wrap justify-content-around">

    <div class="email-item-title space-top" [class.height-ssl]="plan_layout"
         [ngStyle]="{'background-color': titleBackgroud}">
      <div *ngIf="title" [innerHTML]="title | safe: 'html'"></div>
    </div>

    <!-- Price section -->
    <div [ngStyle]="{'background-color': priceBackground}" class="mt-auto py-4">
      <div *ngIf="currentPrice" [innerHTML]="currentPrice | safe: 'html'"></div>
      <div *ngIf="lastPrice" [innerHTML]="lastPrice | safe: 'html'"></div>

      <!-- Quantity control -->
      <div *ngIf="showQuantityControl === true" class="d-flex justify-content-center align-items-center my-2 z-index-1">
        <div target="_blank"
             class="btn quantity-btn d-flex fw-bold  justify-content-center align-items-center text-uppercase d-flex">
          <div class="mx-2 fs-xxl clickable" (click)="onDecrementQuantityClicked()"> - </div>
          <span class="mx-2 text">{{ quantity === 0 ? ("cantidad" | translate) : quantity }}</span>
          <div class="mx-2 fs-xxl clickable" (click)="onIncrementQuantityClicked()"> + </div>
        </div>
      </div>
    </div>

    <!-- Add to cart or redirect button -->
    <div *ngIf="textButton" class="d-flex justify-content-center my-2 z-index-1 item-button-area action-button">
      <button appDebounceClick (debounceClick)="onButtonClick()" [debounceTime]="500" class="btn-large btn my-3 mx-1"
              [ngClass]="action === 'cart' ? 'btn-primary' : 'btn-secondary'" id="{{ gtmKey }}" [attr.data-gtm-key]="gtmKey">
        {{ textButton }}
      </button>
    </div>

    <div *ngIf="packageId" class="d-flex justify-content-center position-relative my-2 item-button-area">
      <button appDebounceClick (debounceClick)="onComboButtonClicked()" [debounceTime]="500"
              class="btn-large btn my-3 mx-1 btn-info" id="{{ gtmKey }}" [attr.data-gtm-key]="gtmKey">
        {{ textButtonCombo }}
      </button>
    </div>
    <br />
    <div class="d-flex justify-content-center position-relative mt-2 z-index-1 action-button">
      <a class=" btn btn-large my-3 mx-1 btn-whatsapp" id="{{ gtmKey }}" target="_blank" href="{{whatsappurl}}"
         [attr.data-gtm-key]="gtmKey">
        <span class="fas d-inline-block i-whatsapp s-whatsapp text-uppercase"></span><span class="text-uppercase">
          {{ textButtonGetAdvice | translate }}
        </span>
      </a>
    </div>

    <div class="m-3 px-3 fs-l pt-3 pt-sm-0 font-weight-bold black mt-3position-relative z-index-1"
         *ngIf="characteristics">
      <div *ngIf="description" [innerHTML]="description | safe: 'html'"></div>
      <div class="d-flex" *ngIf="img && !imgOnBottom">
        <img class="mx-auto limited-size-img" alt="plan characteristics image" loading="lazy"
             data-src="{{img | directus}}" [src]="img | directus">
      </div>
      <div *ngIf="characteristics" [innerHTML]="characteristics | safe: 'html'"></div>
    </div>

    <div class="d-flex" *ngIf="img && imgOnBottom">
      <img alt="plan image" class="mx-auto limited-size-img " loading="lazy" [src]="img | directus"
           data-src="{{img | directus}}">
    </div>
  </div>
</div>
