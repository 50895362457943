import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RegisterDomainsResponse } from '@core-models/register-domains.model';

@Component({
  selector: 'app-panel-tld',
  templateUrl: './panel-tld.component.html',
  styleUrls: ['./panel-tld.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelTldComponent
{

  public itemValue: RegisterDomainsResponse.IPanelTld;

  public price: string = "";
  public tldName: string = "";
  public tldMain: boolean = false;
  public tldDiscount: boolean = false;
  public tldRow: boolean = false;
  public panelColorRow: string = "";
  public textRow: string = "";
  public panelColorBackground: string = "";
  public panelColorBorder: string = "";

  @Input() public set item(value: RegisterDomainsResponse.IPanelTld)
  {
    if (value)
    {
      this.itemValue = value;

      this.tldMain = value.dominio_principal;
      this.tldDiscount = value.oferta_dominio;
      this.tldRow = value.panel_cintilla;
      this.panelColorBackground = value.panel_color_fondo;
      this.panelColorRow = value.panel_cintilla_color;
      this.panelColorBorder = value.panel_color_borde;

      const translation = value?.traducciones[0];

      if (translation)
      {
        this.tldName = translation?.contenido_tld;
        this.textRow = translation?.panel_texto_cintilla;
        this.price = translation?.precio_tld;
      }

    }
  }

}
