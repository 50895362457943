<div [class.transition]="show" class="side-cart p-0 m-0 d-flex flex-column">
  <div class="popup-main-container p-0 m-0">
    <!-- Automatic coupon  message -->
    <div *ngIf="hasCouponToApply && isValidUser"
      class="bg-green m-0 py-3 fs-m white font-weight-bold d-flex align-items-center justify-content-center">
      {{ 'El cupón se agregará automáticamente' | translate }}
    </div>

    <div class="p-3">
      <!--  Title -->
      <div class="d-flex alig-items-center justify-content-between">
        <h2 class="font-weight-bold p-0 m-0">
          {{ title | translate }}
        </h2>
        <img loading="lazy" class="clickable close-icon" data-src="{{ '/icons/close-x.svg' | blobstorage }}"
          alt="ícono cerrar" src="{{ '/icons/close-x.svg' | blobstorage }}" (click)="onHideShoppingCartClicked()" />
      </div>

      <ng-container *ngIf="isValidUser else loginOptionsTemplate">
        <!-- Cart items -->
        <ng-container *ngFor="let item of cartItems; trackBy:trackByQuantityGroup">
          <app-shopping-cart-item [item]="item"
            (onItemChanges)="onShoppingCartItemChanges($event)"></app-shopping-cart-item>
        </ng-container>

        <!-- Cart suggestions -->
        <ng-container *ngIf="showSuggestions">
          <div *ngFor="let item of purchaseSuggestions" class="m-3">
            <app-purchase-suggestion-item [item]="item"
              (onAddSuggestion)="onAddSuggestion($event)"></app-purchase-suggestion-item>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Purchase summary -->
  <ng-container *ngTemplateOutlet="purchaseSummaryTemplate"></ng-container>
</div>

<!-------------------------------- TEMPLATES -------------------------------->
<!--Login Options Template-->
<ng-template #loginOptionsTemplate>
  <div class="d-flex flex-column gap-4 m-4 p-3 rounded-3 bg-white pop-up-cart-login fade-in-animation">
    <button class="btn btn-secondary" (click)="onLogInClicked()">
      {{ 'Iniciar sesión' | translate}}
    </button>
    <button class="btn btn-secondary" (click)="onSignUpClicked()">
      {{ 'Regístrate'| translate}}
    </button>
    <button class="btn btn-secondary" (click)="onCheckoutClicked()">
      {{ 'Continuar sin iniciar sesión' | translate}}
    </button>
  </div>
</ng-template>

<!--Purchase Summary Template-->
<ng-template #purchaseSummaryTemplate>
  <div *ngIf="isValidUser" class="bg-white p-4 purchase-summary-shadow">
    <span class="green fw-bold fs-l">
      {{ servicesLabel }}
    </span>

    <div class="d-flex justify-content-between alig-items-center mb-3">
      <span class="text-dark-emphasis fw-bold fs-xs">
        {{'Total con IVA' | translate}}
      </span>
      <span class="text-black fw-bold fs-l">
        {{ total | currency }} MXN
      </span>
    </div>
    <div class="d-flex flex-column mb-3">
      <button class="btn btn-primary w-100 mb-3" [disabled]="disableCheckout" (click)="goToCart()">
        <span class="text-uppercase">{{'Continuar al carrito' | translate}}</span>
      </button>

      <button class="btn btn-secondary w-100 text-uppercase btn-shopping d-none d-md-block"
        (click)="onHideShoppingCartClicked()">
        {{'Seguir comprando' | translate}}
      </button>

      <div class="d-flex justify-content-center m-3 go-to-ecommerce-mobile lign-items-center d-block d-md-none"
        (click)="onHideShoppingCartClicked()">
        <span class="d-inline-block i-arrow-left me-2"></span>
        <span class="text-black text-decoration-underline clickable fw-bold fs-sm">
          {{'Seguir comprando' | translate}}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <span class="d-inline-block lock-icon me-2"></span>
      <span class="fw-bold fs-m text-black">
        {{ 'Compra Segura con Akky' | translate }}
      </span>
    </div>
  </div>
</ng-template>