import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { HomeDataService } from "@core-data-services/home.data-service";
import { ILanguageComponent } from "@core-models/generic.model";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})

export class SolutionsComponent implements OnInit, ILanguageComponent
{
  public solutions: any[];
  public _title: string = "";
  public _description: string = "";

  @Input() public set title(value: any)
  {
    if (value)
    {
      this._title = value;
    }
  }

  @Input() public set description(value: any)
  {
    if (value)
    {
      this._description = value;
    }
  }

  constructor(private homeService: HomeDataService,
    private ref: ChangeDetectorRef,
    protected translateService: TranslateService,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServicesInfo();
      }
    });
  }

  public getServicesInfo(): void
  {
    this.homeService.getSolutions().subscribe({
      next: ({ response }: any) =>
      {
        this.solutions = response;
        this.ref.detectChanges();
      }
    });
  }

  public getClass(empty: boolean, size: string, image: any): string
  {
    if (empty)
    {
      return 'empty-block';
    }

    if (size === 'size-2x1' && image !== null)
    {
      return 'panel-container box-shadow-home ' + size + ' hidden-movil';
    }

    return 'panel-container box-shadow-home ' + size;
  }
}
