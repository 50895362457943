import { IComparativeTable } from "@core-models/comparative-table.model";
import { EAdditionalService, EClassIcon } from "./product-service.const";

export namespace ComparativeTableConst
{
    export const ComparativeTable: IComparativeTable[] = [
        {
            title: "Todos nuestros planes de Web Hosting incluyen:",
            serviceID: EAdditionalService.Hosting,
            comparativeTableItems: [
                {
                    mainText: "Panel de control a través de cPanel",
                    highlightedText: "cPanel", 
                    highlightedCssClasses: "#E97001",
                    description: "Administra tu web hosting con el panel de control más popular e intuitivo"
                },
                {
                    mainText: "Certificado de Seguridad SSL DV",
                    description: "Protege tus datos y genera confianza en los usuarios desde tu sitio web"
                },
                {
                    mainText: "Instalación de WordPress en 1 clic",
                    highlightedText: "WordPress",
                    highlightedCssClasses: "#2054BA",
                    description: "Sin complicaciones técnicas, instalar WordPress nunca fue tan fácil"
                },
                {
                    mainText: "2 respaldos semanales",
                    description: "Trabaja tranquilo, asegura toda tu información con respaldos constantes"
                }
            ]
        },
        {
            title: "Todos nuestros planes incluyen",
            serviceID: EAdditionalService.EmailOpenxchange,
            comparativeTableItems: [
                {
                    icon: EClassIcon.BrowserHand,
                    description: "Fácil administración de tu calendario, contactos y tareas"
                },
                {
                    icon: EClassIcon.CalendarDate,
                    description: "Libre de publicidad y anuncios"
                },
                {
                    icon: EClassIcon.EmailActionWarning,
                    description: "Añade filtros de correo y evita el spam"
                },
                {
                    icon: EClassIcon.HeadphonesCustomerSupportHuman,
                    description: "Ventanas emergentes para una mejor organización"
                },
                {
                    icon: EClassIcon.MoodHappyLaptop,
                    description: "¿Alguna duda? Recuerda que contamos con asistencia 24/7"
                }
            ]
        }
    ];
}
