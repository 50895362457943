import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-about-directive-committee',
  templateUrl: './directive-committee.component.html',
  styleUrls: ['./directive-committee.component.css']
})

export class DirectiveCommitteeComponent implements OnInit
{
  public linkedinChiefExecutive: SafeResourceUrl;
  public linkedinCommercialDirector: SafeResourceUrl;
  public linkedinCustomerServiceManager: SafeResourceUrl;
  public linkedinITManager: SafeResourceUrl;

  constructor(@Inject(PLATFORM_ID) private platformId, public sanitizer: DomSanitizer) { }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.linkedinChiefExecutive = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.linkedin.com/in/alejandro-picho-castro-3a214410/");
      this.linkedinCustomerServiceManager = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.linkedin.com/in/martha-g-35a41259/");
      this.linkedinITManager = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.linkedin.com/in/fabricio-ruales-3a42026/");
    }
  }
}
