import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EHubspotFormPlace } from '@core-constants/hubspot.const';
import { ReferenceConst } from '@core-constants/reference.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { SettingsManager } from '@core-managers/settings.manager';
import { Environment } from '@environments';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';

declare function loadChatBotScripts(): void;
declare function clickChat(): void;
declare function loadEvents(): void;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ["./footer.component.css"],
  encapsulation: ViewEncapsulation.None
})

export class FooterComponent implements OnInit, AfterViewInit
{
  public formType = EHubspotFormPlace.Footer;
  public urlWhatsApp = ReferenceConst.WhatsAppUrl;
  public showCookiesAdvertisment: boolean = true;

  public currentDate: Date;

  private ariaLabelICANNButton: string = "Registrador acreditado por ICANN";
  private altWarrantyImage: string = "Garantía de satisfacción Akky";
  private altSupportImage: string = "Soporte 24/7";

  constructor(@Inject(PLATFORM_ID) private platformId,
    public router: Router,
    public translateService: TranslateService,
    public toast: ToastService,
    public settingsManager: SettingsManager,
    protected localStorageService: LocalStorageService) { }

  public get isChatIconEnabled(): boolean
  {
    return this.settingsManager.isChatIconEnabled;
  }

  public get isWhatsAppIconEnabled(): boolean
  {
    return this.settingsManager.isWhatsAppIconEnabled;
  }

  public get supportImage(): string
  {
    return "Soporte_" + this.translateService.languageCode.toLocaleLowerCase();
  }

  public get environmentChat(): boolean
  {
    return Environment.ChatBot;
  }

  public get warrantyImage(): string
  {
    return "Garantia_" + this.translateService.languageCode.toLocaleLowerCase();
  }

  public get altForWarrantyImage(): string
  {
    return this.translateService.getElement(this.altWarrantyImage);
  }

  public get altForSupportImage(): string
  {
    return this.translateService.getElement(this.altSupportImage);
  }

  public get ariaLabelForICANNButton(): string
  {
    return this.translateService.getElement(this.ariaLabelICANNButton);
  }

  public get altForICANNImage(): string
  {
    return this.translateService.getElement(this.ariaLabelICANNButton);
  }

  public ngOnInit(): void
  {
    const item: any = this.localStorageService.getItem(LocalStorageConst.CookiesAdd);
    let isAccepted: boolean = false;

    if (item)
    {
      isAccepted = JSON.parse(item);
      this.showCookiesAdvertisment = true;
    }

    if (isAccepted && isAccepted === true)
    {
      this.showCookiesAdvertisment = false;
    }

    this.currentDate = new Date();
  }

  public ngAfterViewInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      if (this.environmentChat)
      {
        loadChatBotScripts();
      }
      else
      {
        loadEvents();
      }
    }
  }

  public openChat(): void
  {
    if (!this.isChatIconEnabled)
    {
      this.toast.setErrorToast(ErrorMessageConst.ChatUnavailable);
    }
    else
    {
      if (isPlatformBrowser(this.platformId))
      {
        clickChat();
      }
    }
  }

  public onCookiesAdvertismentClick(): void
  {
    this.localStorageService.setItem(LocalStorageConst.CookiesAdd, JSON.stringify(true));
    this.showCookiesAdvertisment = false;
  }

  public onCloseAdvertismentClick(): void
  {
    this.showCookiesAdvertisment = false;
  }

  public navigateToWhois(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}herramientas/whois.jsf`);
  }

  public navigateToDnsVerify(): void
  {
    this.changeLocationHref(`${Environment.PortalAkkyURL}herramientas/dns.jsf`);
  }

  public changeLocationHref(url: string): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.location.href = url;
    }
  }

  public onOpenHelpPage(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/ayuda/faq`], { queryParams: { categoria: "Acreditaciones" } })
      );

      window.open(url, '_blank');
    }
  }

  public onOpenWarrantyPolicies(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/acerca/politicas-de-servicios`], { queryParams: { id: "Política de Garantía de Satisfacción Akky" } })
      );

      window.open(url, '_blank');
    }
  }

  public onOpenAcreditationPage(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      window.open(ReferenceConst.AccreditationPage, '_blank');
    }
  }
}
