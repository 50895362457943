import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { SesionStorageConst } from '@core-constants/storage.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { TokenManager } from '@core-managers/token.manager';
import { UserAccessRequest, UserAccessResponse } from '@core-models/user-access.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { TranslateService } from '@shared-services/translate.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor
{
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(@Inject(PLATFORM_ID) private platformId,
    private tokenService: TokenManager,
    private translateService: TranslateService,
    private userAccessDataService: UserAccessDataService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (isPlatformBrowser(this.platformId))
    {
      let authReq = req;
      const token = this.tokenService.getToken();

      if (token != null)
      {
        authReq = this.addTokenHeader(req, token);
      }

      return next.handle(authReq)
        .pipe(catchError(reponse =>
        {
          if (reponse instanceof HttpErrorResponse &&
            !(authReq.url.includes('user-access/logout') ||
              authReq.url.includes('user-access/login') ||
              authReq.url.includes('user-access/refresh') ||
              authReq.url.includes('session')) &&
            reponse.status === 401)
          {
            return this.handle401Error(authReq, next);
          }

          throw (reponse);
        }));
    }
    else
    {
      return next.handle(req);
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (!this.isRefreshing)
    {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.tokenService.getRefreshToken();

      if (refreshToken)
      {
        const dto: UserAccessRequest.IRefreshToken =
        {
          language: this.translateService.languageCode,
          refreshToken: refreshToken
        };

        return this.userAccessDataService.refreshToken(dto).pipe(
          switchMap((response: UserAccessResponse.IRefreshToken) =>
          {
            this.tokenService.saveToken(response.bearerToken);
            this.tokenService.saveRefreshToken(response.refreshToken);
            this.isRefreshing = false;

            this.refreshTokenSubject.next(response.refreshToken);
            return next.handle(this.addTokenHeader(request, response.bearerToken));
          }),
          catchError((response) =>
          {
            this.isRefreshing = false;
            BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, true);
            throw (response);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any>
  {
    return request.clone({ headers: request.headers.set(SesionStorageConst.TokenHeaderKey, token) });
  }
}
