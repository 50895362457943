import { LanguageConst } from '@core-constants/language.const';
import { TranslateService } from '@shared-services/translate.service';

export class ServicePanelTemplate
{
  constructor(protected translateService: TranslateService) {}

  private _servicePanelStructHTML_ES: string = `
      <div class="row row-cols-lg-5 rounded-v2">
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p><span class='font-size-larger d-flex first-title-panel mt-4'>Todos nuestros planes de Web Hosting incluyen:</span></p>
            </div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>Panel de control a través de <span class='text-warning font-weight-bold'>cPanel</span></p>
            </div>
            <div class="panel-description text-start">Administra tu web hosting con el panel de control más popular e intuitivo</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>Certificado de Seguridad SSL DV</p>
            </div>
            <div class="panel-description text-start">Protege tus datos y genera confianza en los usuarios desde tu sitio web</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>Instalación de <span class='text-primary-blue'>WordPress </span>en 1 clic</p>
            </div>
            <div class="panel-description text-start">Sin complicaciones técnicas, instalar WordPress nunca fue tan fácil</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>2 respaldos semanales</p>
            </div>
            <div class="panel-description text-start">Trabaja tranquilo, asegura toda tu información con respaldos constantes</div>
          </div>
        </div>
      </div>`;

  private _servicePanelStructHTML_EN: string = `
      <div class="row row-cols-lg-5 rounded-v2">
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p><span class='font-size-larger d-flex first-title-panel mt-4'>All of our Web Hosting include:</span></p>
            </div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>Control Panel through <span class='text-warning font-weight-bold'>cPanel</span></p>
            </div>
            <div class="panel-description text-start">Manage your web hosting with the most popular and intuitive control panel</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>DV SSL Security Certificate</p>
            </div>
            <div class="panel-description text-start">Protect your data and generate trust in users from your website</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>Installing <span class ='text-primary-blue'>WordPress </span>in 1 click</p>
            </div>
            <div class="panel-description text-start">No technical complications, install WordPress has never been so easy</div>
          </div>
        </div>
        <div class="panel-item-v2">
          <div class="item-content">
            <div class="panel-title text-start font-weight-bold">
              <p>2 backups weekly</p>
            </div>
            <div class="panel-description text-start">Work with peace of mind, secure all your information with constant backups</div>
          </div>
      </div>
    `;

  public get _servicePanelStructHTML(): string
  {
      if(this.translateService.languageCode === LanguageConst.ES)
      {
        return this._servicePanelStructHTML_ES;
      }
      else
      {
        return this._servicePanelStructHTML_EN;
      }
  }
}
