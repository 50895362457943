import { Injectable } from "@angular/core";
import { AdditionalSettings } from "@core-models/additional-settings.model";

@Injectable({ providedIn: 'root' })
export class SettingsManager
{
  private _maintenanceData: AdditionalSettings.IMaintenanceData;
  private _isSiteEnabled: boolean = true;
  private _isChatIconEnabled: boolean = false;
  private _purchaseSuggestionPageEnabled: boolean = false;
  private _isWhatsAppIconEnabled: boolean = false;
  private _maxItems: number = 0;
  private _maxSuggestionsLimit: number = 0;

  constructor() { }

  public set setMaintenanceData(value: AdditionalSettings.IMaintenanceData)
  {
    this._maintenanceData = value;
  }

  public get maintenanceData(): AdditionalSettings.IMaintenanceData
  {
    return this._maintenanceData;
  }

  // ********************************************************************
  // #region SiteEnabled
  // ********************************************************************

  public set setSiteEnabled(value: boolean)
  {
    this._isSiteEnabled = value;
  }

  public get isSiteEnabled(): boolean
  {
    return this._isSiteEnabled;
  }

  // ********************************************************************
  // #endregion SiteEnabled
  // ********************************************************************

  // ********************************************************************
  // #region ChatIconEnabled
  // ********************************************************************

  public set setChatIconEnabled(value: boolean)
  {
    this._isChatIconEnabled = value;
  }

  public get isChatIconEnabled(): boolean
  {
    return this._isChatIconEnabled;
  }

  // ********************************************************************
  // #endregion ChatIconEnabled
  // ********************************************************************

  // ********************************************************************
  // #region WhatsappIconEnabled
  // ********************************************************************

  public set setWhatsappIconEnabled(value: boolean)
  {
    this._isWhatsAppIconEnabled = value;
  }

  public get isWhatsAppIconEnabled(): boolean
  {
    return this._isWhatsAppIconEnabled;
  }

  // ********************************************************************
  // #endregion WhatsappIconEnabled
  // ********************************************************************

  // ********************************************************************
  // #region MaxItems
  // ********************************************************************
  public set setItemsLimit(value: number)
  {
    this._maxItems = value;
  }

  public get itemsLimit(): number
  {
    return this._maxItems;
  }

  // ********************************************************************
  // #endregion MaxItems
  // ********************************************************************

  // ********************************************************************
  // #region MaxSuggestionsLimit
  // ********************************************************************
  public set setSuggestionsLimit(value: number)
  {
    this._maxSuggestionsLimit = value;
  }

  public get suggestionsLimit(): number
  {
    return this._maxSuggestionsLimit;
  }

  // ********************************************************************
  // #endregion MaxItems
  // ********************************************************************


  // ********************************************************************
  // #region PurchaseSuggestionPageEnabled
  // ********************************************************************

  public set setPurchaseSuggestionPageEnabled(value: boolean)
  {
    this._purchaseSuggestionPageEnabled = value;
  }

  public get purchaseSuggestionPageEnabled(): boolean
  {
    return this._purchaseSuggestionPageEnabled;
  }

  // ********************************************************************
  // #endregion ChatIconEnabled
  // ********************************************************************

}
