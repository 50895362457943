import { Component, ViewEncapsulation } from '@angular/core';
import { SettingsManager } from '@core-managers/settings.manager';
import { Environment } from '@environments';

declare const showModal: any;

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent 
{
  public showScheduleModal: boolean = false;
  public schedulerURL: string = Environment.SchedulerURL;

  constructor(public settingsManager: SettingsManager) { }

  public onOpenChat(): void
  {
    showModal();
  }

  public onOpenScheduleModal(): void
  {
    this.showScheduleModal = true;
  }

  public onShedulerBackClicked(): void
  {
    this.showScheduleModal = false;
  }
}
