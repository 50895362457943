import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EDomainType } from '@core-constants/domain.const';
import { TokenManager } from '@core-managers/token.manager';
import { SearchRequest } from '@core-models/domain.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { Tools } from '@shared-utils/tools.util';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DomainDataService extends EntityDataServiceBase 
{
  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager)
  {
    super(http, 'domains');
  }

  public getDomains(language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language
    };

    const subject = this.http.get(this.createUrl(), queryDef.getRequestConfig());

    return subject;
  }

  public searchMultipleDomainsFromServer(domains: string[]): Observable<unknown>
  {
    const queryDef = new UrlQueryDef();

    const body: any = {
      domainSearchList: domains,
      userId: this.tokenManager.getUser()?.id
    };

    const subject = this.http.post(this.createMicroserviceURL("search"), body, queryDef.getRequestConfig())
      .pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getSuggestionsAIRequest(domain: string, lang: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      domain,
      lang,
      check_availability: "0",
      tld: EDomainType.mx,
      user_id: "1"
    };

    const subject = this.http.get(this.createUrl("recommendedsAI"), queryDef.getRequestConfig()).pipe(share());

    return subject;
  }

  public getTldrecommended(tldSearched: boolean, language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language,
      tldSearched,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("recommendeds"), queryDef.getRequestConfig());

    return subject;
  }

  public searchSuggestionsFromServer(domains: SearchRequest.IDomainSuggestions[]): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const body = {
      suggestions: domains
    };

    const subject = this.http.post(this.createMicroserviceURL("suggestions"), body, queryDef.getRequestConfig())
      .pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public searchAlternativesFromServer(domains: SearchRequest.IDomainAlternatives[]): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const body = {
      alternatives: domains
    };

    const subject = this.http.post(this.createMicroserviceURL("alternatives"), body, queryDef.getRequestConfig())
      .pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getTransferDomains(language: string): Observable<unknown>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language
    };

    const subject = this.http.get(this.createUrl("transfers"), queryDef.getRequestConfig());

    return subject;
  }

  public domainsAreValidForTransferFromServer(domains: string[], isMultiple: boolean): Observable<unknown>
  {
    const queryDef = new UrlQueryDef();

    const body = {
      transferDomains: {
        domainTransferList: domains,
        multiple: isMultiple
      }
    };
    const subject = this.http.post(this.createMicroserviceURL("isValidForTransfer"), body, queryDef.getRequestConfig())
      .pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getBundleDomains(language: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language
    };

    const subject = this.http.get(this.createUrl("registerDomainsBundle"), queryDef.getRequestConfig());

    return subject;
  }
  //#endregion
}
