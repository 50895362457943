<div class="gradient-top-container basic-content">
  <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
    <div class="d-flex w-100">
      <h1>{{'Preguntas frecuentes' | translate}}</h1>
    </div>
    <div class="row">
      <div class="col-md-4 col-12 options-side p-0">
        <div class="menu d-block d-md-none p-3">
          <div class="col-12 align-self-center">
            <a class="button-select select-text d-flex" (click)="buttonCollapseClicked($event)"
              data-bs-toggle="collapse" href="#collapseMenu" role="button" aria-expanded="false"
              aria-controls="collapseMenu">
              <div class="text-flex">
                {{'Selecciona un tema' | translate}}
              </div>
              <div class="icon-down">
                <img src="{{ '/icons/down_arrow.svg' | blobstorage }}" alt="Selección de tema"
                  data-src="{{ '/icons/down_arrow.svg' | blobstorage }}" loading="lazy" class="image-fluid d-flex">
              </div>
            </a>
          </div>
          <!-- mobile menu -->
          <div class="col-11 ms-1 p-0 menu-container collapse" id="collapseMenu" #menuCollapsed
            (click)="$event.stopPropagation()">
            <app-faqs-menu [menuOptions]="menuOptions" [htmlId]="htmlId"></app-faqs-menu>
          </div>
        </div>
        <!-- desktop menu -->
        <div class="col-12 d-none d-md-block">
          <app-faqs-menu [menuOptions]="menuOptions" [htmlId]="htmlId"></app-faqs-menu>
        </div>
      </div>
      <div class="col-md-8 col-12 content-side">
        <app-faqs-detail *ngIf="htmlId" [idHtml]="htmlId"> </app-faqs-detail>
      </div>
    </div>
  </div>
</div>