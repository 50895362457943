import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CouponConst } from "@core-constants/coupon.const";
import { EDomainType } from "@core-constants/domain.const";
import { EPersonalBrandVideo } from "@core-constants/personal-brand.const";
import { EAdditionalService, EAdditionalServicePlan } from "@core-constants/product-service.const";
import { RegexConst } from "@core-constants/regex.const";
import { SutentsChallengeConst } from "@core-constants/students-challenge.const";
import { CouponsDataService } from "@core-data-services/coupons.data-service";
import { PersonalBrandDataService } from "@core-data-services/personal-brand.data-service";
import { CreateCouponDto, ICouponDomainModel, ICouponPresenceServiceModel } from "@core-models/coupon.model";
import { ICouponStudent, ISalesForceEmail, IStudentEmail } from "@core-models/personal-brand.model";
import { LocalStorageService } from "@shared-services/local-storage.service";
import { TranslateService } from '@shared-services/translate.service';
import { RegexValidators } from "@shared-utils/regex-validation.util";

@Component({
  selector: 'app-personal-brand',
  templateUrl: './personal-brand.component.html',
  styleUrls: ['./personal-brand.component.css'],
  encapsulation: ViewEncapsulation.None,
  host: { ngSkipHydration: 'true' }
})
export class PersonalBrandComponent implements OnInit
{
  public personalBrandVideoPrincipal: SafeResourceUrl;
  public personalBrandVideoPopup: SafeResourceUrl;
  public personalBrandVideo1: string = "https://www.youtube.com/embed/fWmO9bHU_fg";
  public personalBrandVideo1Safe: SafeResourceUrl;
  public akkyChannel: SafeResourceUrl;
  public favIcon: HTMLLinkElement = isPlatformBrowser(this.platformId) ? document.querySelector('#appIcon') : null;
  public studentId: string = "";
  public responseTitleMssg: string = "";
  public hasError: boolean = false;
  public responseMssg: string = "";
  public enrollment: FormControl = null;
  public institutionalMail: FormControl = null;
  public couponForm: FormGroup = null;
  public isOpen: boolean = false;
  @ViewChild('dropdownlinks') private dropDown: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private localStorageService: LocalStorageService,
    protected translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private titleService: Title,
    private couponsDataService: CouponsDataService,
    private personalBrandDataService: PersonalBrandDataService,
    private renderer: Renderer2,
    private el: ElementRef) { }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.personalBrandVideoPrincipal = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/0ycCkXGpk3g?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0");
      this.personalBrandVideo1Safe = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/fWmO9bHU_fg");
      this.akkyChannel = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/@Akkytienda");
      this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.titleService.setTitle("Mi marca personal");
    }
    this.initializeForm();
  }

  public toggleDropdown(): void
  {
    this.isOpen = !this.isOpen;
  }

  public goToDropDown(): void
  {
    this.dropDown.nativeElement.focus();
    this.isOpen = true;
  }

  public initializeForm(): void
  {
    this.enrollment = new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(9),
      RegexValidators.patternsValidator([RegexConst.EnrollmentStudentProjectChallenge], { onlyNumber: false })
    ]));

    this.institutionalMail = new FormControl('', Validators.compose([
      Validators.required,
      Validators.maxLength(225),
      RegexValidators.patternsValidator([RegexConst.EmailStudentChallenge], { onlyNumber: false })

    ]));

    this.couponForm = new FormGroup({
      institutionalMail: this.institutionalMail,
      enrollment: this.enrollment
    });
  }

  public getPersonalBrandServices(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.localStorageService.setItem('personalBrandServices', 0);
      this.titleService.setTitle("Akky | Tu tienda de servicios digitales");
      this.router.navigate(['/']);
    }
  }

  public loadVideoModal(idVideo: number): void
  {
    switch (idVideo)
    {
      case EPersonalBrandVideo.ProfessionalSuccess:
        this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/CP0yBuEV78w?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0");
        break;

      case EPersonalBrandVideo.KnowMxDomain:
        this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/UiIxr_9YowM?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0");
        break;

      case EPersonalBrandVideo.FormalizeYourCommunication:
        this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/nobSz5JsAfs?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0");
        break;

      case EPersonalBrandVideo.CreateYourSpace:
        this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/k07oJJjJdxM?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0");
        break;
    }
  }

  public cleanPersonalBrandVideoPopup(): void
  {
    this.personalBrandVideoPopup = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  public createCoupon(): void
  {
    let dto = new CreateCouponDto();
    dto = this.fillCouponData(dto);
    this.couponsDataService.create(dto).subscribe({});
  }

  public fillCouponData(dto: CreateCouponDto): CreateCouponDto
  {
    const domain: ICouponDomainModel = {
      tld: EDomainType.mx,
      category: 1,
      coverage: 12
    };

    const wixService: ICouponPresenceServiceModel = {
      additionalServicePkg: EAdditionalService.WebsiteBuilderEPages,
      additionalServicePlan: EAdditionalServicePlan.WixDoItYourself,
      category: 14,
      coverage: 3
    };

    const oxService: ICouponPresenceServiceModel = {
      additionalServicePkg: EAdditionalService.EmailOpenxchange,
      additionalServicePlan: EAdditionalServicePlan.EmailOX5G,
      category: 14,
      coverage: 3
    };

    dto.prefix = CouponConst.PrefixEnterprisingTEC23;
    dto.id = this.studentId;
    dto.expirationDate = new Date(2024, 12, 31);
    dto.useLimit = 1;
    dto.userUseLimit = 1;
    dto.multipleServices = true;
    dto.comments = "Creación de cupón automático";
    dto.internalCode = 14;
    dto.domainsToApply = new Array(domain);
    dto.presenceServicesToApply = [wixService, oxService];

    return dto;
  }

  private handleSuccessResponse(response: any): void
  {
    this.closeModalForm();
    this.hasError = false;
    this.responseMssg = "¡Hemos recibido tu petición correctamente!";
    this.responseTitleMssg = "Revisa tu e-mail para más información.";
    this.sendEmailWithCoupon();
    this.openModal();
  }

  private handleErrorResponse(error: any): void
  {
    this.hasError = true;
    const errorDescription = error?.error.description;
    let responseTitleMssg = "¡Ups! Algo va mal, puedes volver a intentarlo en unos minutos";
    let responseMssg = "";

    if (SutentsChallengeConst.FormStudentsCodeErrors.has(errorDescription))
    {
      responseTitleMssg = "Hemos registrado tu información";
      responseMssg = SutentsChallengeConst.FormStudentsCodeErrors.get(errorDescription);
    }
    this.responseTitleMssg = responseTitleMssg;
    this.responseMssg = responseMssg;
    this.openModal();

  }

  public closeModal(): void
  {
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'hide');
      this.renderer.setStyle(modal, 'display', 'none');
    }
  }

  public openModal(): void
  {

    this.closeModalForm();
    const modal = this.el.nativeElement.querySelector('#modalsucces');
    if (modal)
    {
      this.renderer.addClass(modal, 'show');
      this.renderer.setStyle(modal, 'display', 'block');
    }

  }

  public closeModalForm(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      document.getElementById("closeModalForm")?.click();
    }
  }

  public formModalInitialize(): void
  {
    this.couponForm.reset();
  }

  public onSubmit(): void
  {
    if (this.couponForm.valid)
    {
      const temporalStudentValue: ICouponStudent =
      {
        institutionalMail: this.couponForm.controls['institutionalMail'].value,
        enrollment: this.couponForm.controls['enrollment'].value
      };

      this.couponsDataService.createStudentCoupon(temporalStudentValue).subscribe({
        next: (response: any) =>
        {
          this.handleSuccessResponse(response);
        },
        error: (response: HttpErrorResponse) =>
        {
          this.handleErrorResponse(response);
        }
      });
    }
  }

  public sendEmailWithCoupon(): void
  {
    const emailData = this.setEmailCouponData();
    this.personalBrandDataService.sendMailWithCoupon(emailData).subscribe({
      next: () =>
      {
        this.couponForm.reset();
      },
      error: () =>
      {
        this.handleErrorResponseEmail();
        this.couponForm.reset();
      }
    });
  }

  private setEmailCouponData(): ISalesForceEmail
  {
    const studentInfo: ISalesForceEmail = {
      email: this.institutionalMail.value,
      couponId: CouponConst.PrefixEnterprisingTEC23 + this.enrollment.value.trim()
    };

    return studentInfo;
  }

  private handleErrorResponseEmail(): void
  {
    this.hasError = true;
    this.responseTitleMssg = "¡Ups! Algo va mal";
    this.responseMssg = "Tu cupón se guardó correctamente pero ocurrió un error al enviarlo a tu email. \nContáctanos para enviarte el cupón.";
    this.openModal();
  }

}
