import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EventLocationSite } from "@core-constants/gtm-const";
import { NavigationConst } from '@core-constants/navigation.const';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { AditionalServiceInfo, IServiceHelper } from '@core-models/aditional-service.model';
import { IBaseServiceBundleComponent, ILanguageComponent, IToggleData } from '@core-models/generic.model';
import { IPlan } from '@core-models/plans.model';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-email-service',
  templateUrl: './email-service.component.html',
  styleUrls: ['./email-service.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailServiceComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.emailServices;
  public _serviceID: number;
  public _serivceData: AditionalServiceInfo = undefined;

  public plans: IPlan[] = [];
  public services: IServiceHelper[] = [];

  public locationServicePage: string = "";

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: any) => this.router.url.startsWith(x.path));
  }

  public get selectedService(): any
  {
    const translation = this._serivceData?.translation;
    let obj: any = undefined;

    if (translation)
    {
      obj =
      {
        title: translation.titulo_seccion1,
        desciption: translation.titulo_seccion1,
        bannerImg: translation.imagen_banner?.id,
        bannerText: translation.texto_banner
      };

    }
    return obj;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
   this.translateService.onTranslateChange()
   .pipe(takeUntilDestroyed(this.destroyRef$))
   .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });

   this.toggleHelper.onToggleChange()
   .pipe(takeUntilDestroyed(this.destroyRef$))
   .subscribe({
      next: (index) =>
      {
        this.onSelectTab(index);
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    this.services = [];
    const servicesNames = this.dataToggle.map(item => item.service);

    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: ({ response }: any) =>
      {
        if (response)
        {
          this.services = response.map(function (row)
          {
            return { serviceData: row.service, plans: row.plans };
          });

          this.orderServices();
          this.onSelectTab(this.toggleHelper.changeToggle);
          this.addViewItemListEvent(response.plans);
        }
      }
    });
  }

  public orderServices(): void
  {
    const newArray: any[] = [];

    this.dataToggle.forEach((x: any) =>
    {
      const service = this.services.find(s => s.serviceData.clave_interna == x.service);
      newArray.push(service);
    });

    this.services = newArray;
  }

  public onSelectTab(index: number): void
  {
    if (!Tools.isNullOrEmpty(this.services))
    {
      const serviceCode: string = this.dataToggle[index].service;

      let selectedService = this.services.find((x: any) => x.serviceData?.clave_interna === serviceCode);
      this._serviceID = selectedService.serviceData?.id === 26 ? EAdditionalService.EmailOpenxchange : EAdditionalService.PremiumEmail;
      if (!selectedService)
      {
        selectedService = this.services[0];
      }

      if (this._serviceID === EAdditionalService.EmailOpenxchange)
      {
        this.locationServicePage = EventLocationSite.EmailOxPage;
      }
      else if (this._serviceID === EAdditionalService.PremiumEmail)
      {
        this.locationServicePage = EventLocationSite.EmailPage;
      }

      this._serivceData = selectedService?.serviceData;
      this.plans = selectedService?.plans;
    }

    this.ref.detectChanges(); // forces change detection to run
  }

  public addViewItemListEvent(items: any[]): void
  {
    const service = this.dataToggle.find((x: any) => this.router.url.startsWith(x.path)).service;

    const serviceId = service == EServiceCode.EmailOX ? EAdditionalService.EmailOpenxchange : EAdditionalService.PremiumEmail;

    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, serviceId);
    }
  }
}
