import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdditionalSettingsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'settings');
  }

  public getBase(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const subject = this.http.get(this.createUrl(''), queryDef.getRequestConfig());

    return subject;
  }

  public getEcommerceConfig(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const subject = this.http.get(this.createMicroserviceURL('e-settings'), queryDef.getRequestConfig());

    return subject;
  }
}

