import { isPlatformServer } from '@angular/common';
import { Directive, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appShellNoRender]'
})

/**
 * It is use to indicate to de application that the element is only
 * rendered while the app is on the client, but not in the serve
 */

export class AppShellNoRenderDirective implements OnInit
{
  constructor(@Inject(PLATFORM_ID) private plataformId: any,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  public ngOnInit(): void
  {
    if (isPlatformServer(this.plataformId))
    {
      this.viewContainer.clear();
    }
    else
    {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
