<div class="row" #toastDiv>
  <div class="col-9">
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
      [attr.aria-label]="message" class="message-container ">
      <div *ngIf="successToast; else elseStatusIcon" class="d-flex align-items-center">
        <img loading="lazy" data-src="{{ '/icons/Colores/check-circle-aqua.svg' | blobstorage }}" alt="Check circle"
          src="{{ '/icons/Colores/check-circle-aqua.svg' | blobstorage }}">
        <div class="custom-message-success">
          {{ message }}
        </div>
      </div>
      <ng-template #elseStatusIcon>
        <div class="d-flex align-items-center">
          <img loading="lazy" data-src="{{ '/icons/Colores/times-circle-morado.svg' | blobstorage }}" alt="Check circle"
            src="{{ '/icons/Colores/times-circle-morado.svg' | blobstorage }}">
          <div class="custom-message-error">
            {{ message }}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="col-3 text-end d-flex align-items-center justify-content-center" *ngIf="options.closeButton">
    <span *ngIf="successToast; else elseCloseIcon" class="icon-close-success" (click)="remove()">
      <img loading="lazy" data-src="{{ '/icons/White/x.svg' | blobstorage }}" alt="Close icon"
        src="{{ '/icons/White/x.svg' | blobstorage }}">
    </span>
    <ng-template #elseCloseIcon>
      <span class="icon-close-error" (click)="remove()">
        <img loading="lazy" data-src="{{ '/icons/Colores/times-morado.svg' | blobstorage }}" alt="Close icon"
          src="{{ '/icons/Colores/times-morado.svg' | blobstorage }}">
      </span>
    </ng-template>
  </div>
</div>