import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationConst } from '@core-constants/navigation.const';
import { ILanguageComponent } from '@core-models/generic.model';
import { ISEOComponent } from '@core-models/seo.model';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public tabs: any[] = [{
    title: 'Acerca de Akky',
    route: NavigationConst.SiteRoutes.AboutAKKY,
    name: 'Akky descubre quiénes somos | Akky',
    description: "Akky ofrece servicios y asesorías a empresas, emprendedores y PyMES para que desarrollen y crezcan su negocio, dando el mejor servicio a cliente y soporte"
  },
  {
    title: 'Nuestro Equipo',
    route: NavigationConst.SiteRoutes.ExecutiveCommittee,
    name: 'Conoce a Nuestro Equipo | Akky',
    description: 'Nuestro equipo se encarga de darte el mejor servicio, personalizado y a la medida de tu empresa, conócenos'
  }];

  public backgroundImage: string = "/images/bg-red.svg";
  public currentRoute: string = 'acerca/quienes-somos';
  public isBasePage: boolean = false;
  public selectedIndex: number = 0;

  constructor(public translateService: TranslateService,
    public seoService: SEOService,
    public router: Router,
    private destroyRef$: DestroyRef)
  {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: () =>
      {
        this.setRouteData();
      }
    });
  }

  public get selectedTabName(): string
  {
    return this.translateService.getElement(this.tabs[this.selectedIndex].name);
  }

  public ngOnInit(): void
  {
    this.setRouteData();
    this.registerEventListeners();
  }

  public setRouteData(): void
  {
    this.selectedIndex = 0;
    this.isBasePage = false;

    if (this.router.url.includes('/acerca'))
    {
      if (this.router.url == "/acerca")
      {
        this.router.navigate(['acerca/quienes-somos']);
        this.selectedIndex = 0;
        this.isBasePage = true;
      }

      if (this.router.url.includes('/quienes-somos') || this.router.url.includes('/nuestroequipo'))
      {
        this.isBasePage = true;
      }

      if (this.router.url.includes('/acerca/nuestroequipo'))
      {
        this.selectedIndex = 1;
      }
    }
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
      }
    });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.tabs[this.selectedIndex].name);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.tabs[this.selectedIndex].description);
  }

  public onSelectTab(item: any, i: number): void
  {
    this.selectedIndex = i;
    this.seoService.updateSeoElements(this.translateService.getElement(item.name), "");
    this.router.navigate([item.route]);
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
