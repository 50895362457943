import { NgOptimizedImage } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_ID, APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideClientHydration } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { FilterListComponent } from "@core-components/filter-list/filter-list.component";
import { InteractiveToasterComponent } from "@core-components/interactive-toaster/interactive-toaster.component";
import { TabToggleComponent } from "@core-components/tab-toggle/tab-toggle.component";
import { ToasterComponent } from "@core-components/toaster/toaster.component";
import { UserAccessDataService } from "@core-data-services/security/user-access.data-service";
import { AddApikeyInterceptor } from "@shared-interceptors/api-key.interceptor";
import { AddLanguageInterceptor } from "@shared-interceptors/language.interceptor";
import { AddAuthorizationMSVCToken } from "@shared-interceptors/msvc.interceptor";
import { TransferStateInterceptor } from "@shared-interceptors/state-transfer.interceptor";
import { RecaptchaModule } from "ng-recaptcha";
import { ToastrModule } from "ngx-toastr";
import { catchError, of } from 'rxjs';
import { ShareModule } from "./_shared/share.module";
import { AboutComponent } from "./about/about.component";
import { DirectiveCommitteeComponent } from "./about/directive-committee/directive-committee.component";
import { LegalNoticeComponent } from "./about/legal-notice/legal-notice.component";
import { PaymentMethodsComponent } from "./about/payment-methods/payment-methods.component";
import { ServicesPoliciesComponent } from "./about/services-policies/services-policies.component";
import { WhoWeAreComponent } from "./about/who-we-are/who-we-are.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContactUsComponent } from "./help/contact-us/contact-us.component";
import { FaqsDetailComponent } from "./help/faqs/faqs-detail/faqs-detail.component";
import { FaqsMenuComponent } from "./help/faqs/faqs-menu/faqs-menu.component";
import { FaqsComponent } from "./help/faqs/faqs.component";
import { HelpMainComponent } from "./help/help-main/help-main.component";
import { HelpDetailComponent } from "./help/help-topics/help-detail/help-detail.component";
import { HelpMenuComponent } from "./help/help-topics/help-menu/help-menu.component";
import { HelpTopicsComponent } from "./help/help-topics/help-topics.component";
import { HelpComponent } from "./help/help.component";
import { NewsletterDetailComponent } from "./help/newsletters/newsletter-detail/newsletter-detail.component";
import { NewsletterItemComponent } from "./help/newsletters/newsletter-item/newsletter-item.component";
import { NewslettersComponent } from "./help/newsletters/newsletters.component";
import { ChatComponent } from "./home/chat/chat.component";
import { HomeComplementaryServiceItemComponent } from "./home/complementary-services/complementary-service-item/home-complementary-service-item.component";
import { HomeComplementaryServicesComponent } from "./home/complementary-services/home-complementary-services.component";
import { CustomerComponent } from "./home/family/customer/customer.component";
import { FamilyComponent } from "./home/family/family.component";
import { TestimonialsComponent } from "./home/family/testimonials/testimonials.component";
import { AboutCardComponent } from "./home/home-about/about-card/about-card.component";
import { HomeAboutComponent } from "./home/home-about/home-about.component";
import { HomeComponent } from "./home/home.component";
import { PromotionItemComponent } from "./home/promotions/promotion-item/promotion-item.component";
import { PromotionsComponent } from "./home/promotions/promotions.component";
import { HomeServiceItemComponent } from "./home/services/service-item/home-service-item.component";
import { HomeServicesComponent } from "./home/services/services-home.component";
import { HomeSliderComponent } from "./home/slider/home-slider.component";
import { HomeSlideItemComponent } from "./home/slider/slide-item/home-slide-item.component";
import { SolutionComponent } from "./home/solutions/solution/solution.component";
import { SolutionsComponent } from "./home/solutions/solutions.component";
import { BenefitsComponent } from "./landings/benefits/benefits.component";
import { ExclusivePromotionComponent } from "./landings/benefits/exclusive-promotion/exclusive-promotion.component";
import { PromotionMonthComponent } from "./landings/benefits/promotion-month/promotion-month.component";
import { SeasonalDiscountsComponent } from "./landings/benefits/seasonal-discounts/seasonal-discounts.component";
import { AllDealsComponent } from "./landings/deals/all-deals/all-deals.component";
import { DealsComponent } from "./landings/deals/deals.component";
import { FeaturedDealsComponent } from "./landings/deals/featured-deals/featured-deals.component";
import { FlashDealsComponent } from "./landings/deals/flash-deals/flash-deals.component";
import { SubscribeComponent } from "./landings/deals/subscribe/deal-subscribe.component";
import { DigitizeComponent } from "./landings/digitize/digitize.component";
import { PersonalBrandChallengeComponent } from "./landings/personal-brand/challenge/personal-brand-challenge.component";
import { PersonalBrandComponent } from "./landings/personal-brand/personal-brand.component";
import { PymesComponent } from "./landings/pymes/pymes.component";
import { FooterComponent } from "./layouts/footer/footer.component";
import { MainComponent } from "./layouts/main/main.component";
import { MaintenanceComponent } from "./layouts/maintenance/maintenance.component";
import { NavBarResponsiveComponent } from "./layouts/navbar/navbar.component";
import { PopupShoppingCartComponent } from "./layouts/popup-shopping-cart/popup-shopping-cart.component";
import { PurchaseSuggestionItemComponent } from "./layouts/popup-shopping-cart/purchase-suggestion-item/purchase-suggestion-item.component";
import { ShoppingCartItemComponent } from "./layouts/popup-shopping-cart/shopping-cart-item/shopping-cart-item.component";
import { IndividualSuggestionItemComponent } from "./layouts/popup-shopping-cart/shopping-cart-item/shopping-cart-suggestion-item/individual-suggestion-item/individual-suggestion-item.component";
import { ShoppingCartSuggestionItemComponent } from "./layouts/popup-shopping-cart/shopping-cart-item/shopping-cart-suggestion-item/shopping-cart-suggestion-item.component";
import { UpgradeSuggestionItemComponent } from "./layouts/popup-shopping-cart/shopping-cart-item/shopping-cart-suggestion-item/upgrade-suggestion-item/upgrade-suggestion-item.component";
import { PromoBannerComponent } from "./layouts/promo-banner/promo-banner.component";
import { AdditionalPurchaseSuggestionItemComponent } from "./purchase-suggestion/additional-purchase-suggestion-item/additional-purchase-suggestion-item.component";
import { AdditionalPurchaseSuggestionComponent } from "./purchase-suggestion/additional-purchase-suggestion.component";
import { BaseServiceComponent } from "./services/base-service/base-service.component";
import { ComplementaryServiceComponent } from "./services/base-service/complementary-service/complementary-services.component";
import { ComplementaryServiceItemComponent } from "./services/base-service/complementary-services/item/complementary-service-item.component";
import { ComplementaryServiceListComponent } from "./services/base-service/complementary-services/list/complementary-service-list.component";
import { MktServicePlanItemComponent } from "./services/base-service/mkt-plans/item/app-mkt-service-plan-item.component";
import { MktServicePlansListComponent } from "./services/base-service/mkt-plans/list/app-mkt-service-plans-list.component";
import { NewBaseServiceComponent } from "./services/base-service/new-base-service/new-base-service-component";
import { ServicePlanItemComponent } from "./services/base-service/plans/item/app-service-plan-item.component";
import { ServicePlansListComponent } from "./services/base-service/plans/list/app-service-plans-list.component";
import { ServicePlanComponent } from "./services/base-service/plans/plan/app-service-plan.component";
import { SchedulerComponent } from "./services/base-service/sheduler/scheduler.component";
import { DigitalMarketingComponent } from "./services/digital-marketing/digital-mkt-service.component";
import { EmailServiceComponent } from "./services/email/email-service.component";
import { HostingServiceComponent } from "./services/hosting/hosting-service.component";
import { MicrosoftServiceComponent } from "./services/microsoft/microsoft-service.component";
import { PanelTldComponent } from "./services/search-domains/panel-tld/panel-tld.component";
import { RegisterDomainsComponent } from "./services/search-domains/register-domains/register-domains.component";
import { SearchDomainsComponent } from './services/search-domains/search-domains.component';
import { DomainSuggestionsComponent } from "./services/search-domains/search-result-container/domain-suggestions/domain-suggestions.component";
import { MultipleDomainSearchResultComponent } from "./services/search-domains/search-result-container/multiple-domain-search-result/multiple-domain-search-result.component";
import { SearchResultContainerComponent } from "./services/search-domains/search-result-container/search-result-container.component";
import { SingleDomainSearchResultComponent } from "./services/search-domains/search-result-container/single-domain-search-result/single-domain-search-result.component";
import { TldRecommendedItemComponent } from "./services/search-domains/tld-recommended-item/tld-recommended-item.component";
import { ServicesComponent } from "./services/services.component";
import { OnlineStoreServiceComponent } from "./services/site-builder/online-store/online-store-service.component";
import { SiteBuilderComponent } from "./services/site-builder/site-builder.component";
import { WebsiteServiceComponent } from "./services/site-builder/website/website-service.component";
import { SslCertificateServiceComponent } from "./services/ssl-certificate/ssl-certificate-service.component";
import { TransferInServiceComponent } from "./services/transfer-in/transfer-in-service.component";
import { WordPressServiceComponent } from "./services/wordpress/wordpress-service.component";
import { StructuredDataComponent } from "./structured-data/structured-data.component";
import { UserAccessFormComponent } from "./user-access/user-access-form/user-access-form.component";
import { UserAccessComponent } from "./user-access/user-access.component";
import { UserEsuAccessComponent } from "./user-access/user-esu-access/user-esu-access.component";

export function appInitialializerFactory(userAccessDataService: UserAccessDataService)
{
  /* eslint-disable */
  return () => userAccessDataService.getMSVCInitialToken()
    .pipe(
      catchError(() => of(null))
  );
  /* eslint-disable */
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MainComponent,

    NavBarResponsiveComponent,
    MaintenanceComponent,
    PromoBannerComponent,

    PopupShoppingCartComponent,
    ShoppingCartItemComponent,

    AboutComponent,
    DirectiveCommitteeComponent,
    LegalNoticeComponent,
    PaymentMethodsComponent,
    ServicesPoliciesComponent,
    WhoWeAreComponent,

    HelpComponent,
    ContactUsComponent,
    FaqsComponent,
    FaqsMenuComponent,
    FaqsDetailComponent,
    HelpDetailComponent,
    HelpMainComponent,
    HelpMenuComponent,
    HelpTopicsComponent,

    NewslettersComponent,
    NewsletterItemComponent,
    NewsletterDetailComponent,

    HomeComponent,
    ChatComponent,
    HomeComplementaryServicesComponent,
    HomeComplementaryServiceItemComponent,
    PromotionsComponent,
    PromotionItemComponent,
    HomeServicesComponent,
    HomeServiceItemComponent,
    HomeSliderComponent,
    HomeSlideItemComponent,
    SolutionsComponent,
    SolutionComponent,
    HomeAboutComponent,
    AboutCardComponent,
    FamilyComponent,
    TestimonialsComponent,
    CustomerComponent,

    ServicesComponent,
    BaseServiceComponent,
    TabToggleComponent,
    SchedulerComponent,
    DigitalMarketingComponent,
    EmailServiceComponent,
    HostingServiceComponent,

    RegisterDomainsComponent,
    SearchResultContainerComponent,
    SearchDomainsComponent,
    SingleDomainSearchResultComponent,
    MultipleDomainSearchResultComponent,
    DomainSuggestionsComponent,
    TldRecommendedItemComponent,
    PanelTldComponent,

    SiteBuilderComponent,
    WebsiteServiceComponent,
    OnlineStoreServiceComponent,
    SslCertificateServiceComponent,
    WordPressServiceComponent,
    ServicePlansListComponent,
    ServicePlanItemComponent,
    TransferInServiceComponent,
    PersonalBrandComponent,
    PersonalBrandChallengeComponent,
    MicrosoftServiceComponent,

    MktServicePlanItemComponent,
    MktServicePlansListComponent,

    TldRecommendedItemComponent,

    ComplementaryServiceItemComponent,
    ComplementaryServiceListComponent,

    UserAccessFormComponent,
    UserAccessComponent,
    UserEsuAccessComponent,

    PymesComponent,

    DigitizeComponent,

    StructuredDataComponent,

    PurchaseSuggestionItemComponent,
    ShoppingCartSuggestionItemComponent,
    UpgradeSuggestionItemComponent,
    IndividualSuggestionItemComponent,

    InteractiveToasterComponent,
    ComplementaryServiceComponent,
    NewBaseServiceComponent,
    ServicePlanComponent,

    AdditionalPurchaseSuggestionComponent,
    AdditionalPurchaseSuggestionItemComponent,

    BenefitsComponent,
    ExclusivePromotionComponent,
    PromotionMonthComponent,
    SeasonalDiscountsComponent,

    DealsComponent,
    FeaturedDealsComponent,
    FlashDealsComponent,
    AllDealsComponent,
    SubscribeComponent,
    FilterListComponent
  ],
  imports: [
    NoopAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RecaptchaModule,
    ShareModule,
    ToastrModule.forRoot({
      toastComponent: ToasterComponent
    }),
    NgOptimizedImage
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialializerFactory,
      multi: true,
      deps: [UserAccessDataService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddLanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddApikeyInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthorizationMSVCToken,
      multi: true
    },
    { provide: APP_ID, useValue: 'serverApp' },

    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
