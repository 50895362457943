import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingCartConst } from '@core-constants/shopping-cart.const';
import { ICoverageOption } from '@core-models/shopping-cart-items.model';
import { TranslateService } from '@shared-services/translate.service';

@Pipe({ name: 'coverage' })
export class CoveragePipe implements PipeTransform
{
  private readonly _monthsModifier = 'months';
  private readonly _arrayModifier = 'array';

  constructor(public translateService: TranslateService) { }

  public transform(value: any, modifier: string): string
  {
    if (value == undefined || value == null)
    {
      return "";
    }

    if (!modifier)
    {
      return this.convertToYears(value);
    }
    if (modifier == this._arrayModifier)
    {
      return this.array(value);
    }
    if (modifier == this._monthsModifier)
    {
      return this.convertToMonths(value);
    }
    return "";
  }

  private array(value: any[]): any
  {
    const converted = value.map((item: ICoverageOption) =>
    {
      return {
        value: item.id,
        label: this.convertToYears(item.value),
        rate: item.rate
      };
    });

    return converted;
  }

  private convertToYears(monthValue: number): string
  {
    const andWord = " " + this.translateService.getElement("y") + " ";
    const result = [];
    const months = monthValue % 12;
    const years = monthValue == 0 ? 0 : Math.floor(monthValue / 12);

    if (years > 0 || (years == 0 && months == 0))
    {
      result.push(years + ' ' + this.getLabel(years, ShoppingCartConst.CoverageLabel.yearsLabels));
    }

    if (months > 0)
    {
      result.push(months + ' ' + this.getLabel(months, ShoppingCartConst.CoverageLabel.monthsLabels));
    }

    if (months == -1)
    {
      result.push(this.translateService.getElement("Sincronizar a Servicio"));
    }

    return result.join(andWord);
  }

  private convertToMonths(monthValue: number): string
  {
    if (monthValue && monthValue > 0)
    {
      return monthValue + ' ' + this.getLabel(monthValue, ShoppingCartConst.CoverageLabel.monthsLabels);
    }

    return "";
  }

  private getLabel(number: number, word: any): any
  {
    return this.translateService.getElement(number == 1 && word.one || word.many);
  }
}
