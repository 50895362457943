<div class="container-customer">
  <div class="customers">
    <div class="cards">
      <div class="card-cus">
        <div>
          <span class="card-name">Roberto Luján</span><br>
          <span class="card-type">Desarrollador</span>
        </div>
        <span class="card-description">Tengo más de 100 dominios</span>
        <div class="d-flex">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>
      </div>
      <div class="card-cus">
        <div>
          <span class="card-name">Roberto Luján</span><br>
          <span class="card-type">Desarrollador</span>
        </div>
        <span class="card-description">Tengo más de 100 dominios</span>
        <div class="d-flex">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-customers">
    <div class="home-subtitle" [innerHTML]="_title | safe: 'html'"></div>
    <br>
    <div class="text-center fs-m fw-bold" [innerHTML]="_subtitle | safe: 'html'"></div>
  </div>
</div>