<div class="container">
    <div class="row">
        <ng-container *ngIf="isSearchLoading$ | async as isSearchLoading">
            <app-loading class="search-result-container" text="{{ searchinDomainMessage | translate }}"></app-loading>
        </ng-container>

        <div class="domain-search-result-container search-result-container p-4"
             *ngIf="errorSearchEmptyVisible$ | async as errorSearchVisible">
            <span class="searchResult-error-description">{{ domainSearchErrorMessage | translate}}</span>
        </div>

        <div class="domain-search-result-container search-result-container p-4"
             *ngIf="errorSearchLengthVisible$ | async as errorSearchVisible">
            <span class="searchResult-error-description fs-l">{{ domainSearchLengthErrorMessage | translate}}</span>
        </div>

        <!--Principal search result-->
        <ng-container *ngIf="searchResultVisible$ | async as searchErrorVisible">
            <div class="col-12 search-result-container">

                <!-- Display search result -->
                <ng-container *ngIf="(simpleSearch$ | async) === true">
                    <app-single-domain-search-result [domain]="domainFinded$ | async"></app-single-domain-search-result>
                </ng-container>

                <ng-container *ngIf="(simpleSearch$ | async) === false">
                    <app-multiple-domain-search-result [domains]="domainsFinded$ | async"></app-multiple-domain-search-result>
                </ng-container>

            </div>
        </ng-container>

        <!-- Domain Suggestions and Alternatives -->
        <app-domain-suggestions></app-domain-suggestions>
    </div>
</div>
