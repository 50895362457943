import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less']
})

export class LoadingComponent 
{
  @Input() public xlSize: boolean = true;
  @Input() public text: string = "unspecified text";
  @Input() public loadingTemplate: TemplateRef<any>;
  @Input() public iconTemplate: TemplateRef<any>;
}
