import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { HelpParams } from '@core-constants/url-param.const';
import { FaqCategoriesDataService } from '@core-data-services/faq.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { ISEOComponent } from '@core-models/seo.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { SEOService } from '@shared-services/seo.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit, ILanguageComponent, ISEOComponent
{
  public pageTitle: string = "FAQ Akky";
  public clicked: boolean = false;
  public menuOptions: any[] = [];
  public htmlId: string = "";

  private pageSEOTitle: string = "Preguntas frecuentes | Akky";
  private pageSEODescription: string = "Conoce las respuestas a nuestras preguntas frecuentes";

  @ViewChild('menuCollapsed', { static: true }) public menuCollapsed: any;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    public seoService: SEOService,
    private faqCategoriesDataService: FaqCategoriesDataService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroyRef$: DestroyRef
  )
  {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef$)
      ).subscribe({
      next: (event: any) =>
      {
        if (event.url.includes('/faq'))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);
        }
      }
    });

    this.activatedRoute.queryParams.subscribe({
      next: (params) =>
      {
        const categoryHtmlIdParam = String(params[HelpParams.Category]);
        this.htmlId = categoryHtmlIdParam !== "undefined" ? categoryHtmlIdParam : "";

        if (Object.keys(params).length == 0)
        {
          this.setDefaultItemHtmlId();
        }
      }
    });
  }

  @HostListener('document:click', ['event'])
  public clickedOutside(): void
  {
    if (this.clicked)
    {
      this.clicked = false;
      this.menuCollapsed.nativeElement.classList.toggle('show');
    }
  }

  public ngOnInit(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnNavColorChanges);

    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
     this.translateService.onTranslateChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
          next: () =>
          {
            this.seoService.updateSeoElements(this.getSEOTitle(), this.getSEODescription());
            this.getMenuOptions();
          }
      });
  }

  public getSEOTitle(): string
  {
    return this.translateService.getElement(this.pageSEOTitle);
  }

  public getSEODescription(): string
  {
    return this.translateService.getElement(this.pageSEODescription);
  }

  public buttonCollapseClicked(event: any): void
  {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = !this.clicked;
  }

  public setDefaultItemHtmlId(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      if (!this.htmlId && this.menuOptions[0] != undefined)
      {
        this.htmlId = this.menuOptions[0].htmlId;

        this.router.navigate(['/ayuda/faq'], {
          queryParams: {
            categoria: this.htmlId
          },
          replaceUrl: true
        });
      }
    }
  }

  public getMenuOptions(): void
  {
    const menu = this.localStorageService.getItem(LocalStorageConst.FaqMenu + this.translateService.languageCode);

    if (!menu)
    {
      this.faqCategoriesDataService.getFaqCategories(this.translateService.languageCode).subscribe({
        next: (result: any) =>
        {
          if (result && !Tools.isNullOrEmpty(result.response))
          {
            this.menuOptions = result.response
              .map((c: any) =>
              {
                const category: any = {};
                category.id = c.id;
                category.title = c.traducciones[0]?.titulo;
                category.htmlId = c.html_id;

                return category;
              });

            this.localStorageService.setItem(LocalStorageConst.FaqMenu + this.translateService.languageCode, JSON.stringify(this.menuOptions));
            this.setDefaultItemHtmlId();
          }
        }
      });
    }
    else
    {
      this.menuOptions = JSON.parse(menu);
      this.setDefaultItemHtmlId();
    }
  }
}
