import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EventLocationSite } from '@core-constants/gtm-const';
import { SuccessMessageConst } from '@core-constants/success-message.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { UpdateSuggestionResponse } from '@core-models/add-to-cart.model';
import { IPurchaseSuggestionRate, IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { IItemProductLite } from '@core-models/shopping-cart-items.model';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { ToastService } from '@shared-services/toast.service';
import { finalize } from 'rxjs';
import { ShoppingCartSuggestionItemBase } from '../base/shopping-cart-suggestion-item';

@Component({
  selector: 'app-individual-suggestion-item',
  templateUrl: './individual-suggestion-item.component.html',
  styleUrls: ['./individual-suggestion-item.component.css']
})
export class IndividualSuggestionItemComponent extends ShoppingCartSuggestionItemBase
{
  @Output() public onAddSuggestion = new EventEmitter<string>();

  @Input('parentId') public set setParentId(value: number)
  {
    this.parentId = value;
  }

  @Input('itemProduct') public set setItemProduct(value: IItemProductLite)
  {
    super.itemProduct = value;
  }

  @Input('suggestion') public set setSuggestion(value: IShoppingCartIndividualStoredSuggestionPlus)
  {
     super.suggestion = value;
  }

  constructor(
    private shoppingCartDataService: ShoppingCartDataService,
    private toast: ToastService,
    private checkoutManager: CheckoutManager
  ) { super();  }

  public get isIndividualSuggestion(): boolean
  {
    return !this.isUpgradeSuggestion && !this.itemProduct.containUpgradeSuggestion;
  }

  public get isAdditionalServiceSuggestion(): boolean
  {
    return !this.isUpgradeSuggestion && this.itemProduct.containUpgradeSuggestion && this.isAdded;
  }

  public get suggestionRate(): IPurchaseSuggestionRate
  {
    return this.suggestion.coverage.length > 0 ? this.suggestion.coverage.find(x => x.id === this.suggestion.coverageId)?.rate : null;
  }

  private get suggestionServiceAdd(): string
  {
    return this.isDomain ? this.suggestion.serviceToOffer.slice(this.suggestion.serviceToOffer.indexOf("=") + 1) : this.suggestion.serviceToOffer;
  }

  public onCheckChanges($event: boolean): void
  {
    this.suggestion.individualFeatures.isAdded = $event;
    $event === true ? this.addSuggestionToCart() : this.removeSuggestionFromCart();
  }

  private addSuggestionToCart(): void
  {
    if (this.addedIsChanging) { return; }

    this.addedIsChanging = true;
    this.isAdded = true;

    const itemId = this.parentId;
    const suggestionId = this.suggestion.suggestionId;

    this.shoppingCartDataService.addIndividualSuggestion(itemId, suggestionId)
      .pipe(finalize(() => this.addedIsChanging = false))
      .subscribe({
        next: ({ operationResult }: UpdateSuggestionResponse) =>
        {
          if (operationResult)
          {
            GtmTrackingService.addToCartSuggestionServiceEvent(this.suggestionServiceAdd, this.suggestionRate, EventLocationSite.IndividualSuggestion);

            if (this.isDomain)
            {
              const shoppingCartId = this.shoppingCartDataService.getShoppingCartId;
              BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, shoppingCartId);
              this.toast.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
              return;
            }

            this.suggestion.individualFeatures.isAdded = true;
            this.checkoutManager.addIndividualSuggestion(itemId, suggestionId);
          }
          else
          {
            this.suggestion.individualFeatures.isAdded = false;
            this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
          }
        },
        error: (response: HttpErrorResponse) =>
        {
          this.suggestion.individualFeatures.isAdded = false;
          this.isAdded = false;
          this.toast.setErrorToast(response?.error.description);
        }
      });
  }

  private removeSuggestionFromCart(): void
  {
    if (this.addedIsChanging) { return; }

    this.addedIsChanging = true;
    const itemId = this.parentId;
    const suggestionId = this.suggestion.suggestionId;
    this.shoppingCartDataService.removeIndividualSuggestion(itemId, suggestionId)
      .pipe(finalize(() => this.addedIsChanging = false))
      .subscribe({
        next: ({ operationResult }: UpdateSuggestionResponse) =>
        {
          if (operationResult)
          {
            this.suggestion.individualFeatures.isAdded = false;
            this.checkoutManager.removeIndividualSuggestion(itemId, suggestionId);
          }
          else
          {
            this.suggestion.individualFeatures.isAdded = true;
            this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
          }
        }
      });
  }
}
