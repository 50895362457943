import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-events.const';
import { PageHelper } from '@core-constants/page-helper.const';
import { AdditionalSettingsDataService } from '@core-data-services/additional-settings.data-service';
import { HomeDataService } from '@core-data-services/home.data-service';
import { SettingsManager } from '@core-managers/settings.manager';
import { AdditionalSettings } from '@core-models/additional-settings.model';
import { AssetsService } from '@shared-services/assets.service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { loadScripts } from '@shared-utils/hubspot-scripts.util';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent
{
  public isLoaded: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId,
    public additionalSettingsDataService: AdditionalSettingsDataService,
    public assetsService: AssetsService,
    public settingsManager: SettingsManager,
    public homeDataService: HomeDataService,
    private router: Router,
    private destroyRef$: DestroyRef)
  {
    this.getBaseSettings();

    if (isPlatformBrowser(this.platformId))
    {
      this.getAdditionalSettings();
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef$)
    ).subscribe({
      next: (event: NavigationEnd) =>
      {
        this.setRouteInfo(event.url);
      }
    });
  }

  public getBaseSettings(): void
  {
    this.additionalSettingsDataService.getBase().subscribe({
      next: (response: AdditionalSettings.IBaseResponse) =>
      {
        if (response)
        {
          this.setBaseSettingsConfig(response);
          BroadcastService.Instance.broadcast(EAppEventName.SettingsDone, response.isSiteEnabled);
        }
      }
    });
  }

  public getAdditionalSettings(): void
  {
    this.additionalSettingsDataService.getEcommerceConfig().subscribe({
      next: (response: AdditionalSettings.IEcommerceConfigResponse) =>
      {
        this.setSettingsConfig(response);
      }
    });
  }

  private setSettingsConfig(response: AdditionalSettings.IEcommerceConfigResponse): void
  {
    if (response)
    {
      this.settingsManager.setItemsLimit = response.maxItems;
      this.settingsManager.setChatIconEnabled = response.chatEnabled;
      this.settingsManager.setPurchaseSuggestionPageEnabled = response.purchaseSuggestionPageEnabled;
      this.settingsManager.setWhatsappIconEnabled = response.whatsAppEnabled;
      this.settingsManager.setSuggestionsLimit = response.maxSuggestionsLimit;
    }
  }

  private setBaseSettingsConfig(response: AdditionalSettings.IBaseResponse): void
  {
    if (response)
    {
      this.settingsManager.setSiteEnabled = response.isSiteEnabled;
      this.assetsService.setBlobUrl = response.blobStorageUrl;
      this.assetsService.setDirectusUrl = response.directusUrl;

      if (response.isSiteEnabled === false)
      {
        this.settingsManager.setMaintenanceData = response.maintenanceData;
      }
    }
  }

  public setRouteInfo(url: string): void
  {
    if (isPlatformBrowser(this.platformId) &&
      ((url.includes('ayuda/contacto') ||
        url.includes('/boletin') ||
        url.includes('landing/formulario')) && !this.isLoaded))
    {
      this.isLoaded = loadScripts();
      this.isLoaded = true;
    }
  }

  public isValidToLoadHubspotScripts(width: number): boolean
  {
    return width > PageHelper.MobileWidth && !this.isLoaded && this.settingsManager.isSiteEnabled;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(e: UIEvent): void
  {
    const element = e.target as Window;

    if (this.isValidToLoadHubspotScripts(element.innerWidth))
    {
      this.isLoaded = loadScripts();
      this.isLoaded = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      if (this.isValidToLoadHubspotScripts(window.innerWidth))
      {
        this.isLoaded = loadScripts();
        this.isLoaded = true;
      }
    }
  }
}
