import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class LandingRedirectGuard
{
    constructor(private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Observable<UrlTree>
    {
        if(route.routeConfig.path === 'mimarcapersonal')
        {
            return of(this.router.createUrlTree(['/digitalizate'], { queryParams: route.queryParams }));
        }
        else if(route.routeConfig.path === 'emprendeconakky')
        {
            return of(this.router.createUrlTree(['/digitalizatepyme'], { queryParams: route.queryParams }));
        }
        else if (route.routeConfig.path === 'comite-directivo')
        {
          return of(this.router.createUrlTree(['/acerca/nuestroequipo'], { queryParams: route.queryParams }));
        }

        return of(true);
    }
}
