import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DomainsManager } from '@core-managers/domains.manager';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-search-domains-input',
  templateUrl: './search-domains-input.component.html',
  styleUrls: ['./search-domains-input.component.css']
})

export class SearchDomainsInputComponent implements OnDestroy
{
  public simpleSearch: boolean = true;
  public simpleDomainSearchText$: Observable<string>;
  public multipleDomainSearchText$: Observable<string>;
  public isSingleSearch$: Observable<boolean>;
  public domainsSearchList$: Observable<boolean[]>;
  public transferDomain: string = "https://www.akky.mx/servicios/transferencias";

  private _domainsSearchList: boolean[] = [];

  @Input() public multipleSearchWhite: boolean = true;
  @Input() public placeHolderInput: string = "Encuentra tu dominio ideal";
  @Input() public textButton: string = "Buscar";
  @Input() public textMultipleSearch: string = "Búsqueda múltiple";
  @Input() public textSimpleSearch: string = "Búsqueda simple";

  @Output() public onMultipleSearch = new EventEmitter();
  @Output() public onSingleSearch = new EventEmitter();
  @Output() public onSearchChangeMode = new EventEmitter();

  @ViewChild('searchDomainsInput', { read: ElementRef }) public searchDomainsInput: ElementRef;

  constructor(private domainsManager: DomainsManager,
    protected translateService: TranslateService,
    protected toast: ToastService,
    private router: Router,
    private destroyRef$: DestroyRef)
  {
    this.isSingleSearch$ = domainsManager.isSingleSearch();
    this.simpleDomainSearchText$ = this.domainsManager.getSigleSearchText();
    this.multipleDomainSearchText$ = this.domainsManager.getMultipleSearchText();
    this.domainsSearchList$ = this.domainsManager.getShowDomainsSearchList();
  }

  public get ariaLabelForSearchButton(): string
  {
    return this.translateService.getElement(this.textButton);
  }

  public setMultipleDomainSearchText(value: string): void
  {
    this.domainsManager.setMultipleDomainSearchText(value);
  }

  public setSingleDomainSearchText(): void
  {
    this.domainsSearchList$
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (elem: any) =>
      {
        this._domainsSearchList = [...elem];
      }
    });
  }

  public searchToggle(): void
  {
    this.domainsManager.searchToggle();
    this.onSearchChangeMode.emit();
  }

  public searchMultipleDomains(): void
  {
    this.onMultipleSearch.emit();
  }

  public searchSimpleDomain(): void
  {
    if (this._domainsSearchList)
    {
      const value = this.searchDomainsInput?.nativeElement.value;

      this.domainsManager.setSingleDomainSearchText(value);
    }

    this.onSingleSearch.emit();
  }

  public ngOnDestroy(): void
  {
    this.isSingleSearch$ = undefined;
    this.simpleDomainSearchText$ = undefined;
    this.multipleDomainSearchText$ = undefined;
    this.domainsSearchList$ = undefined;
  }

  public onTransferDomainClicked(): void
  {
    const link = ['/servicios/transferencias'];
    const params = { queryParams: { tema: "Solicitar" } };

    this.router.navigate(link, params);
  }
}
