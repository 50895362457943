import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ClickActions } from '@core-constants/action-type.const';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EGtmSection } from '@core-constants/gtm-const';
import { InteractiveToasterConfigurations } from '@core-constants/interactive-toaster.const';
import { PageHelper } from '@core-constants/page-helper.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { PromotionHelper } from '@core-helpers/promotion.helper';
import { ServicePlanInfo } from '@core-models/generic.model';
import { ICupon } from '@core-models/service-package.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { InteractiveToasterService } from '@shared-services/interactive-toast.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { GtmTrackingService } from '@shared-base/gtm-tracking.service';

@Component({
  selector: 'app-mkt-plan-item',
  templateUrl: './app-mkt-service-plan-item.component.html',
  styleUrls: ['./app-mkt-service-plan-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MktServicePlanItemComponent 
{
  private _planData: ServicePlanInfo | undefined = undefined;
  public recommendedImage: string = '/images/recomendado.svg';

  public comboServices: ICupon = undefined;

  public eventLocationSite: string = "";

  @Input() public verticalStyle: boolean = false;

  @Input() public set plan(value: any)
  {
    const planInfo = value;

    if (planInfo)
    {
      this._planData = new ServicePlanInfo();

      this._planData.recommended = planInfo.recomendado;
      this._planData.recommendedColor = planInfo.color_recomendado;
      this._planData.priceBackground = planInfo.color_area_precio ?? "#ECEDEF";
      this._planData.cardBackground = planInfo.color_fondo ?? "#F9F9F9";
      this._planData.gtmKey = planInfo.clave_gtm;
      this._planData.cartServiceData = planInfo.id_servicio_carrito?.valor;
      this._planData.action = planInfo.tipo_accion;
      this._planData.url = planInfo.url_redireccionamiento;
      this._planData.comboId = planInfo.id_paquetes?.id;

      const planTranslation = planInfo?.traducciones[0];

      if (planTranslation)
      {
        this._planData.title = planTranslation.titulo;
        this._planData.currentPrice = planTranslation.precio_actual;
        this._planData.lastPrice = planTranslation.antes_precio;
        this._planData.textButton = planTranslation.texto_boton;
        this._planData.description = planTranslation.descripcion;
        this._planData.characteristics = planTranslation.caracteristicas;
        this._planData.img = planTranslation?.img_plan;
        this._planData.textButtonCombo = planTranslation.texto_boton_combo;
      }

      if (this._planData.comboId)
      {
        this.getServicesPackage();
      }
    }
  }

  public get plan(): ServicePlanInfo
  {
    return this._planData;
  }

  @Input() public set location(value: string)
  {
    this.eventLocationSite = value;
  } 

  @HostListener('window:resize', ['$event'])
  public onResize(): void
  {
    this.changeDetectorRef.detectChanges();
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected toast: ToastService,
    private shoppingCartDataService: ShoppingCartDataService,
    private servicesDataService: ServicesDisplayDataService,
    private promotionHelper: PromotionHelper,
    protected translateService: TranslateService,
    protected interactiveToast: InteractiveToasterService,
    private changeDetectorRef: ChangeDetectorRef) { }

  public get isMobile(): boolean
  {
    let isMob: boolean = false;

    if (isPlatformBrowser(this.platformId))
    {
      isMob = window.innerWidth <= PageHelper.MobileWidth;
    }

    return isMob;
  }

  public get recommended(): boolean
  {
    return this._planData.recommended == "si";
  }

  public get showRecommendedBorder(): boolean
  {
    return this._planData.recommendedColor && this._planData.recommendedColor != '';
  }

  public onButtonClick(): void
  {
    switch (this.plan.action)
    {
      case ClickActions.Types.AddToCart:
        this.addToCart();
        break;
      case ClickActions.Types.Redirect:
        if (isPlatformBrowser(this.platformId))
        {
          window.location.href = this.plan.url;
        }
        break;
      default: break;
    }
  }

  public addToCart(): void
  {
    this.shoppingCartDataService.addService(this.plan.cartServiceData).subscribe({
      next: (response: any) =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
        this.interactiveToast.showMessage(InteractiveToasterConfigurations.GoToCart);
        GtmTrackingService.addToCartPresenceServiceEvent(this.plan.cartServiceData, this._planData.currentPrice, 1, this.eventLocationSite);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  public getServicesPackage(): void
  {
    this.servicesDataService.getPackageById(this._planData.comboId).subscribe({
      next: (response: ICupon) =>
      {
        if (response)
        {
          this.comboServices = response;
        }
      }
    });
  }

  public onComboButtonClicked(): void
  {
    this.promotionHelper.addToCart(this.comboServices, EGtmSection.Market, this.eventLocationSite);
  }
}
