<h2 class="deal-title">
    {{title | translate | titlecase}}
</h2>
<span class="deal-subtitle mb-3">
    {{description | translate}}
</span>

<div class="d-none d-md-flex position-relative w-100 my-3 my-md-5 justify-content-evenly align-items-center">
    <button *ngIf="dealItems.length>1" (click)="onPreviousClicked()" class="button-arrow left-40">
        <span class="d-block i-arrow-right rotate-180"></span>
    </button>
    <img *ngFor="let image of items; let i = index" (click)="onButtonClick(i)" [src]="image.src | directus"
        [alt]="image.alt" [title]="image.alt" [ngClass]="{'activeDeal': activeIndex === i}"
        class="border clickable border-0 bg-transparent deal-image" />
    <div *ngIf="dealItems.length === 2" class="deal-image"></div>
    <button *ngIf="dealItems.length>1" (click)="onNextClicked()" class="button-arrow right-40">
        <span class="d-block i-arrow-right"></span>
    </button>
</div>
<div *ngIf="activeDeal" class="d-none d-md-block text-center">
    <h5 class="title">{{activeDeal.title}}</h5>
    <button (click)="onButtonClick(activeIndex)" class="btn btn-primary text-uppercase ml-5">
        {{activeDeal.button}}
    </button>
    <div class="mt-3" [innerHTML]="activeDeal.description | safe: 'html'"></div>
</div>

<div class="d-block d-md-none pb-2">
    <div id="carousel-deals-movil" class="carousel dealsCarousel slide" data-bs-ride="carousel">

        <ol class="carousel-indicators">
            <li data-bs-target="#carousel-deals-movil" *ngFor="let image of items; let i=index; trackBy:identify"
                [attr.data-bs-slide-to]="i" [attr.aria-label]="i + 1" [ngClass]="{'active' : activeIndex === i}"></li>
        </ol>
        <div class="carousel-inner">
            <div *ngFor="let image of items; let i=index; trackBy:identify" class="carousel-item"
                 [ngClass]="{'active' : activeIndex === i}" data-bs-interval="10000">
                <div class="row justify-content-center">
                    <button (click)="onButtonClick(image)" class="border border-0 bg-transparent">
                        <img class="img-movil-carousel" [src]="image.src | directus" [alt]="image.alt"
                            [title]="image.alt">
                    </button>
                    <div class="text-center">
                        <h5> {{image.title}}</h5>
                        <button (click)="onButtonClick(activeIndex)" class="btn btn-primary text-uppercase">
                            {{image.button}}
                        </button>
                        <div class="mt-2 img-movil-carousel" [innerHTML]="image.description | safe: 'html'"> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
