import { isPlatformBrowser } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PromoBannerDataService } from "@core-data-services/promo-banner.data-service";
import { ILanguageComponent } from "@core-models/generic.model";
import { IPromoBannerModel } from "@core-models/promo-banner.model";
import { TranslateService } from "@shared-services/translate.service";

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoBannerComponent implements OnInit, AfterViewInit, ILanguageComponent
{
  public banner: string = "";
  public isPromoBannerEnabled: boolean = false;

  @ViewChild('promobanner') private promoBanner: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId,
    protected translateService: TranslateService,
    private promoBannerDataService: PromoBannerDataService,
    private ref: ChangeDetectorRef,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getBanner();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.updateHeight();
    }
  }

  private getBanner(): void
  {
    this.promoBannerDataService.getBanner().subscribe({
      next: (result: IPromoBannerModel) =>
      {
        this.banner = result.response[0]?.traducciones[0]?.banner;
        if (this.banner)
        {
          this.isPromoBannerEnabled = true;
          if (isPlatformBrowser(this.platformId))
          {
            document.documentElement.style.setProperty('--promo-banner-color', result.response[0].color_banner);
          }
        }
        this.ref.detectChanges();
        this.updateHeight();
      }
    });
  }

  public ngAfterViewInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.updateHeight();
    }
  }

  private updateHeight(): void
  {
    if (this.promoBanner)
    {
      const height = `${this.promoBanner.nativeElement.offsetHeight}px`;
      if (isPlatformBrowser(this.platformId))
      {
        document.documentElement.style.setProperty('--height-promobanner', height);
        this.ref.detectChanges();
      }
    }
  }

  public onClicked(): void
  {
    this.isPromoBannerEnabled = false;
    if (isPlatformBrowser(this.platformId))
    {
      document.documentElement.style.setProperty('--height-promobanner', '0px');
    }
  }
}
