import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateCouponDto } from "@core-models/coupon.model";
import { ICouponStudent, ICouponStudentPostman, IDomainsToApply, IPresenceServicesToApply } from "@core-models/personal-brand.model";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { format } from "date-fns";
import { Observable, share } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CouponsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'coupons');
  }

  public create(dto: CreateCouponDto): Observable<any>
  {
    const subject = this.http.post(this.createMicroserviceURL(''), dto).pipe(share());
    this._handleObservableResponse(subject);
    return subject;
  }

  public createStudentCoupon(couponStudent: ICouponStudent): Observable<any>
  {
    const couponStudentPostman: ICouponStudentPostman = {
      prefix: "TEC24",
      id: couponStudent.enrollment.trim(),
      startingDate: this.getCurrentDateStr(),
      expirationDate: '2024-12-31T23:59:59.000Z',
      internalCode: 14,
      multipleServices: true,
      domainsToApply: [],
      presenceServicesToApply: []
    };

    const domainsToApply: IDomainsToApply = {
      tld: ".mx",
      category: 1,
      coverage: 12
    };

    couponStudentPostman.domainsToApply.push(domainsToApply);

    const presenceServicesToApply: IPresenceServicesToApply = {
      additionalServicePkg: 11,
      additionalServicePlan: 54,
      category: 14,
      coverage: 3
    };

    const presenceServicesToApply2: IPresenceServicesToApply = {
      additionalServicePkg: 17,
      additionalServicePlan: 51,
      category: 14,
      coverage: 3
    };

    couponStudentPostman.presenceServicesToApply.push(presenceServicesToApply);
    couponStudentPostman.presenceServicesToApply.push(presenceServicesToApply2);

    const subject = this.http.post(this.createMicroserviceURL(""), couponStudentPostman).pipe(share());

    this._handleObservableResponse(subject);
    return subject;
  }

  public getCurrentDateStr(): string
  {
    const strDate = format(new Date(), 'yyyy-MM-dd');
    const strHour = format(new Date(), 'HH:mm:ss.SSS');
    return strDate + 'T' + strHour + 'Z';
  }
}
