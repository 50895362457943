import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INavBarBlogModel } from '@core-models/navbar-blog.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomeDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'home');
  }

  public getBundle(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode
    };

    const subject = this.http.get(this.createUrl("home"), queryDef.getRequestConfig());

    return subject;
  }

  public getSlides(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("slides"), queryDef.getRequestConfig());

    return subject;
  }

  public getServices(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("services"), queryDef.getRequestConfig());

    return subject;
  }

  public getCards(): Observable<any>
  {
    const subject = this.http.get(this.createUrl("cards"));
    return subject;
  }

  public getSolutions(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("solutions"), queryDef.getRequestConfig());

    return subject;
  }

  public getPromotions(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("promotions"), queryDef.getRequestConfig());

    return subject;
  }

  public getTestimonials(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("testimonials"), queryDef.getRequestConfig());

    return subject;
  }

  public getBlogs(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode
    };

    const subject = this.http.get<INavBarBlogModel>(this.createUrl("blogs"), queryDef.getRequestConfig());

    return subject;
  }
}

