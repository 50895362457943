import { IToasterData } from "@core-models/interactive-toaster.model";

export const enum EInteractiveToasterType 
{
  Success = 0,
  Error
}

export const enum EInteractiveToasterOperation
{
  NoOperation = 0,
  OpenCart,
  Redirect,
  CustomOperation
}

export const enum EInteractiveToasterDefaultMessage 
{
  GoToCart = "Ver carrito",
  TotalItems = "Tienes @ artículos en el carrito."
}

export namespace InteractiveToasterConfigurations
{
  export const GoToCart: IToasterData =
  {
    message: EInteractiveToasterDefaultMessage.TotalItems,
    buttonMessage: EInteractiveToasterDefaultMessage.GoToCart,
    operation: EInteractiveToasterOperation.OpenCart,
    showTime: 5000,
    type: EInteractiveToasterType.Success
  };
}


