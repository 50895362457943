<app-chat></app-chat>
<div id="pyme-navbar" class="pyme-header">
  <a href="{{homeURL}}" class="fas d-inline-block akky-logo"> </a>

  <img loading="lazy" class="pyme-logo-movil d-inline-block d-md-none ms-2" alt="Digitaliza tu PYME Logo"
       src="{{ '/images/pyme-dgt-logo-mobile.png' | blobstorage }}" />
  <img loading="lazy" class="pyme-logo-desktop d-none d-md-inline-block" alt="Digitaliza tu PYME Logo"
       src="{{ '/images/pyme-dgt-logo.png' | blobstorage }}" />

  <div class="ms-auto pyme-dropdown landing-dropdown">
    <button class="dropbtn" (click)="toggleDropdown()">
      Descarga las guías de usuario
      <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="[!isOpen ? 'dgt-arrow-rotate' : 'dgt-arrow-rotate-rev']"
           width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4.07992 15.05L10.5999 8.53C11.3699 7.76 12.6299 7.76 13.3999 8.53L19.9199 15.05" stroke="#fff"
              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <div class="landing-dropdown-content" [class.show]="isOpen">
      <a target="_blank"
         href="{{ accountRegistrationPDF | blobstorage}}">
        Alta de cuenta en Akky
      </a>
      <a target="_blank"
         href="{{ domainRegistrationPDF | blobstorage}}">
        Alta de dominio
      </a>
      <a target="_blank"
         href="{{ emailConfigurationPDF | blobstorage}}">
        Configuración de E-mail
      </a>
      <a target="_blank"
         href="{{ wixConfigurationPDF | blobstorage}}">
        Habilitación de sitio WIX
      </a>
    </div>
  </div>
</div>

<div>
  <div class="pyme-hero-container container-fluid" [style.--background-image-url]="pymeBackgroundImgUrl"  [style.--background-image-url-mobile]="pymeBackgroundImgUrlMobile">
    <div class="pyme-hero-content container d-flex">
      <div class="col-9 col-md-6 my-auto">
        <span class="pyme-hero-text">
          Incrementa tus ventas y llega a más clientes digitalizando tu negocio
        </span>
        <br />
        <button class="btn purple-btn p-2 p-md-3 mt-4 d-flex flex-row justify-content-center align-items-center" (click)="scrollToBenefits()">
          Activa tus beneficios
          <span class="fas d-inline-block i-short-arrow-right-white ms-2"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid my-5">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <h1>
        ¿Por qué es importante digitalizarte como PYME?
      </h1>
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center ">
      <div class="w-75 bg-body-tertiary rounded-3 ">
        <img alt="icono ventas" src="{{ '/icons/pymes-ic-ventas 1.png' | blobstorage }}" class="ms-3 hideme" />
        <p class="purple-txt fw-bold fs-5 ms-3">
          Incrementa tus ventas
        </p>
        <p class="fs-6 ms-3">
          Accede a nuevos mercados
          y llega a más clientes con disponibilidad las 24 horas los
          7 días de la semana.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center ">
      <div class="w-75 bg-body-tertiary rounded-3">
        <img alt="icono reputacion" src="{{ '/icons/pymes-ic-reputacion 2.png' | blobstorage }}" class="ms-3 hideme" />
        <p class="purple-txt fw-bold fs-5 ms-3">
          Mejora tu reputación online
        </p>
        <p class="fs-6 ms-3">
          Podrás leer rápidamente las opiniones o dudas de los clientes, generando mayor transparencia
          y por lo tanto confianza.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center ">
      <div class="w-75 bg-body-tertiary rounded-3">
        <img alt="icono productividad" src="{{ '/icons/pymes-ic-productividad 3.png' | blobstorage }}"
          class="ms-3 hideme" />
        <p class="purple-txt fw-bold fs-5 ms-3">
          Aumenta tu productividad
        </p>
        <p class="fs-6 ms-3">
          Moderniza procesos, automatiza
          y acelera la comercialización, volviéndote una empresa más competitiva.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
      <div class="w-75 bg-body-tertiary rounded-3">
        <img alt="icono experiencia a clientes" src="{{ '/icons/pymes-ic-experiencia-clientes 4.png' | blobstorage }}"
          class="ms-3 hideme" />
        <p class="purple-txt fw-bold fs-5 ms-3">
          Mejora la experiencia con tus clientes
        </p>
        <p class="fs-6 ms-3">
          Haz que tus clientes conecten más rápido contigo, de manera sencilla y en su solo lugar.
        </p>
      </div>
    </div>
  </div>
</div>
<div #pymeBenefits class="container mt-5">
  <div class="row">
    <div class="col-12 col-md-6 hideme">
      <img class="img-fluid float-end image-max-size " alt="chica grabando video"
        src="{{ '/images/pymes-third-image.png' | blobstorage }}" />
    </div>
    <div class="col-12 col-md-5 pt-md-4">
      <div>
        <p class="h1 fw-bold">
          Beneficios<br />
        </p>
        <p class="h4 pb-2 fw-bold purple-underline-txt">
          Servicios que te ayudarán en tu transformación&nbsp;digital
        </p>
      </div>
      <div class="w-100">
        <div class="d-flex flex-row fs-5 justify-content-start my-3">
          <div><span class="align-middle fas d-inline-block i-check-circle"></span></div>
          <div class="ms-2">Dominio <img alt="logo mx" src="{{ '/icons/pymes-mx-logo.png' | blobstorage }}" /> primer año sin costo</div>
        </div>
        <div class="d-flex flex-row fs-5 justify-content-start my-3">
          <div><span class="align-middle fas d-inline-block i-check-circle"></span></div>
          <div class="ms-2">E-mail personalizado 3 meses sin costo</div>
        </div>
        <div class="d-flex flex-row fs-5 justify-content-start my-3">
          <div><span class="align-middle fas d-inline-block i-check-circle"></span></div>
          <div class="ms-2">Creador de sitios web <img alt="logo wix" src="{{ '/icons/pymes-wix-logo.png' | blobstorage }}" />. Incluye certificado SSL, Soporte Técnico y Asesoría para iniciar</div>
        </div>
        <div class="d-flex flex-row fs-5 justify-content-start my-3">
          <div><span class="align-middle fas d-inline-block i-check-circle"></span></div>
          <div class="ms-2">Guías y herramientas digitales</div>
        </div>
        <button type="button" class="d-flex align-items-center justify-content-center btn purple-btn p-2 p-md-3 mt-4">
          <a class="m-0 text-white" href="{{homePackageURL}}">Activa tus beneficios</a>
          <span class="fas d-inline-block i-short-arrow-right-white ms-2"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="my-5 text-center">
  <a class="purple-txt fs-4" target="_blank" href="{{ termsConditionsPDF | blobstorage}}">
    <u>Consulta los términos y condiciones</u>
  </a>
</div>
<app-footer></app-footer>
