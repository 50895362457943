<app-base-service [serviceData]="_serivceData" [showSpecsTitle]="false">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serivceData">

      <!-- Toggler -->
      <div class="d-flex w-100 justify-content-center align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <app-service-plans [plans]="plans" [imgOnBottom]="true" [locationInit]="locationServicePage"></app-service-plans>

      <div class="row justify-content-center my-3" *ngIf="selectedService">
        <div class="mx-auto col-10 col-md-12" [innerHTML]="selectedService.title | safe: 'html'"></div>
      </div>

      <div class="container-fluid" *ngIf="selectedService">
        <div class="container">
          <div class="row d-md-flex"></div>
          <div *ngIf="selectedService" class="row d-md-flex text-center pb-2"
            [innerHTML]="selectedService.description | safe: 'html'"></div>
        </div>
      </div>
    </ng-container>

  </ng-template>
</app-base-service>
