export class CreateCouponDto
{
  public prefix: string;
  public id: string;
  public expirationDate: Date;
  public useLimit: number;
  public userUseLimit: number;
  public multipleServices: boolean;
  public comments: string;
  public internalCode: number;
  public domainsToApply: ICouponDomainModel[];
  public presenceServicesToApply: ICouponPresenceServiceModel[];
}
export interface ICouponDomainModel
{
  tld: string;
  category: number;
  coverage: number;
}
export interface ICouponPresenceServiceModel
{
  additionalServicePkg: number;
  additionalServicePlan: number;
  category: number;
  coverage: number;
}
