<ng-container *ngIf="interactiveToasterService.data && interactiveToasterService.data.show">
    <div class="main-container successfull-bg pt-2 pb-2 ps-3 pe-3 shadow-effect responsive-border"
        [ngClass]="startAnimation ? 'fade-out-animation' : 'fade-in-animation'">
        <div class="d-flex align-items-center">
            <img loading="lazy" data-src="{{ '/icons/Colores/check-circle-aqua.svg' | blobstorage }}" alt="Check circle"
                src="{{ '/icons/Colores/check-circle-thin.svg' | blobstorage }}" class="me-3" width="24">
            <span class="text-white me-3 message-container" [innerHTML]="interactiveToasterService.data.message">
            </span>
            <button class="btn btn-secondary me-3 button-modifier" (click)="onOpenCartClicked()">
                {{interactiveToasterService.data.buttonMessage}}
            </button>
            <span class="text-white clickable align-self-start align-self-start align-self-md-center"
                (click)="hideAndClearTime()">
                <img loading="lazy" data-src="{{ '/icons/White/x.svg' | blobstorage }}" alt="Close icon"
                    src="{{ '/icons/White/x.svg' | blobstorage }}" width="15">
            </span>
        </div>
    </div>
</ng-container>