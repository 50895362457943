import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationConst } from '@core-constants/navigation.const';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { AditionalServiceInfo, IServiceHelper } from '@core-models/aditional-service.model';
import { IBaseServiceBundleComponent, IToggleData } from '@core-models/generic.model';
import { IPlan } from '@core-models/plans.model';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { filter } from 'rxjs';

@Component({
  selector: 'app-digital-mkt-service',
  templateUrl: './digital-mkt-service.component.html',
  styleUrls: ['./digital-mkt-service.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalMarketingComponent implements OnInit, IBaseServiceBundleComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.digitalMtkServices;
  public _serviceID: EAdditionalService = EAdditionalService.DigitalMarketing;
  public _serivceData: AditionalServiceInfo = undefined;

  public plans: IPlan[] = [];
  public services: IServiceHelper[] = [];
  public selectedService: any = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: any) => this.router.url.startsWith(x.path));

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: any) =>
      {
        const url: string = event.url;

        if (isPlatformBrowser(this.platformId) && url.includes('#'))
        {
          const elementId = event.url.split('#').pop();
          const element = document.querySelector(`#${elementId}`);

          if (element)
          {
            element.scrollIntoView();
          }
        }

        if (this.router.url == NavigationConst.SiteRoutes.MTKService)
        {
          this.router.navigate([NavigationConst.SiteRoutes.MKTBranding]);
        }
      }
    });
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getMainServiceBundleInfo();
        this.getServiceBundleInfo();
      }
    });

    this.toggleHelper.onToggleChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (index) =>
      {
        this.onSelectTab(index);
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    this.services = [];
    const servicesNames = this.dataToggle.map(item => item.service);
    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: ({ response }: any) =>
      {
        this.services = response.map(item => ({ serviceData: item.service, plans: item.plans }));
        this.orderServices();
        this.onSelectTab(this.toggleHelper.changeToggle);
        this.addViewItemListEvent(response.plans);
      }
    });
  }

  public getMainServiceBundleInfo(): void
  {
    const serviceName: string[] = [EServiceCode.DigitalMKT];

    this.servicesDataService.getBundle(serviceName).subscribe({
      next: ({ response }: any) =>
      {
        this.plans = response[0].plans;
      }
    });
  }

  public orderServices(): void
  {
    const newArray: any[] = [];

    this.dataToggle.forEach((x: any) =>
    {
      const service = this.services.find(s => s.serviceData.clave_interna == x.service);
      newArray.push(service);
    });

    this.services = newArray;
  }

  public onSelectTab(index: number): void
  {
    if (!Tools.isNullOrEmpty(this.services))
    {
      const serviceCode: string = this.dataToggle[index].service;

      let selectedService = this.services.find((x: any) => x.serviceData?.clave_interna === serviceCode);

      if (!selectedService)
      {
        selectedService = this.services[0];
        this.toggleHelper.changeToggle = 0;
      }

      this.selectedService = selectedService;
    }

    this.ref.detectChanges(); // forces change detection to run
  }

  public addViewItemListEvent(items: any[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
