<app-base-service [serviceData]="selectedService?.serviceData" [showSpecsTitle]="false" [showExamples]="false" [showSpecs]="false">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="selectedService">

      <div class="d-flex w-100 justify-content-center align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <!-- Specific service plans -->
      <app-mkt-service-plans [plans]="selectedService?.plans"></app-mkt-service-plans>

      <!-- Main plans -->
      <app-mkt-service-plans [plans]="plans"></app-mkt-service-plans>

      <app-scheduler *appShellNoRender></app-scheduler>
    </ng-container>
  </ng-template>
</app-base-service>
