import { Injectable } from '@angular/core';
import { IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { GroupedShoppingCart, ICoverageOption, ShoppingCartItem } from '../models/shopping-cart-items.model';

@Injectable({ providedIn: 'root' })
export class IndividualSuggestionManager
{
    constructor() { }

    // ********************************************************************
    //#region CART UPDATES
    // ********************************************************************
    public addIndividualSuggestion(shoppingCartItems: GroupedShoppingCart[], cartItemId: number, suggestionId: string): GroupedShoppingCart[]
    {
      return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, true);
    }

    public addIndividualSuggestionUpgrade(shoppingCartItems: GroupedShoppingCart[], cartItemId: number, suggestionId: string, quantity: number = undefined, coverageId = undefined, coverageMonths: number = undefined): GroupedShoppingCart[]
    {
       return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, true, quantity, coverageId, coverageMonths);
    }

    public removeIndividualSuggestion(shoppingCartItems: GroupedShoppingCart[], cartItemId: number, suggestionId: string): GroupedShoppingCart[]
    {
       return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, false);
    }

    private updateItemSuggestions(shoppingCartItems: GroupedShoppingCart[], cartItemId: number, suggestionId: string, added: boolean, quantity: number = undefined, coverageId: number = undefined, coverageMonths: number = undefined): GroupedShoppingCart[]
    {
        const items = JSON.stringify(shoppingCartItems);
        const cartItems: GroupedShoppingCart[] = JSON.parse(items);

        const itemIndex = cartItems?.findIndex(x => x.product.id == cartItemId);

        if (cartItems[itemIndex])
        {
            const suggestionIndex: number = cartItems[itemIndex].product.suggestions.findIndex(x => x.suggestionId == suggestionId);

            if (cartItems[itemIndex].product.suggestions[suggestionIndex])
            {
                cartItems[itemIndex].product.suggestions[suggestionIndex].individualFeatures.isAdded = added;

                if(quantity && coverageId && coverageMonths && added)
                {
                cartItems[itemIndex].product.suggestions[suggestionIndex].individualFeatures.quantity = quantity;
                cartItems[itemIndex].product.suggestions[suggestionIndex].coverageId = coverageId;
                cartItems[itemIndex].product.suggestions[suggestionIndex].coverageMonths = coverageMonths;
                }
            }
            return cartItems;
        }

        return cartItems;
    }

    public setCartItemUpgradeCoverage(shoppingCartItems: GroupedShoppingCart[], parentId: number, suggestionId: string, coverageId: number): GroupedShoppingCart[]
    {
        const items = JSON.stringify(shoppingCartItems);
        const cartItems: GroupedShoppingCart[] = JSON.parse(items);

        const parentItem: GroupedShoppingCart = cartItems.find((x: GroupedShoppingCart) => x.product.id == parentId);

        if (!parentItem) { return; }

        const selectedSuggestion = parentItem.product.suggestions.find((x: IShoppingCartIndividualStoredSuggestionPlus) => x.suggestionId == suggestionId);
        if (!selectedSuggestion) { return; }

        const modifiedSuggestions = parentItem.product.suggestions.map((x: IShoppingCartIndividualStoredSuggestionPlus) =>
        {
            if (x.suggestionId == suggestionId)
            {
                x.coverageId = coverageId;
                x.coverageMonths = x.coverage.find((x: ICoverageOption) => x.id == coverageId).value;
            }
            return x;
        });

        const updatedCartItems = shoppingCartItems.map((x: GroupedShoppingCart) =>
            (x.product.id === parentId ? { ...x, product: { ...x.product, suggestions: modifiedSuggestions } } : x)
        );

        return updatedCartItems;
    }

    public setCartItemUpgradeQuantity(shoppingCartItems: GroupedShoppingCart[], parentId: number, suggestionId: string, quantity: number): GroupedShoppingCart[]
    {
        const items = JSON.stringify(shoppingCartItems);
        const cartItems: GroupedShoppingCart[] = JSON.parse(items);
        const parentItem: GroupedShoppingCart = cartItems.find((x: GroupedShoppingCart) => x.product.id == parentId);
        if (!parentItem) { return shoppingCartItems; }

        const selectedSuggestion = parentItem.product.suggestions.find((x: IShoppingCartIndividualStoredSuggestionPlus) => x.suggestionId == suggestionId);
        if (!selectedSuggestion) { return shoppingCartItems; }

        const modifiedSuggestions = parentItem.product.suggestions.map((x: IShoppingCartIndividualStoredSuggestionPlus) =>
        {
            if (x.suggestionId == suggestionId)
            {
                x.individualFeatures.quantity = quantity;
            }
            return x;
        });

        const updatedCartItems = shoppingCartItems.map((x: GroupedShoppingCart) =>
            (x.product.id === parentId ? { ...x, product: { ...x.product, suggestions: modifiedSuggestions } } : x)
        );

        return updatedCartItems;
    }

    // ********************************************************************
    //#endregion
    // ********************************************************************


    // ********************************************************************
    //#region CALCULATIONS
    // ********************************************************************

    private calculateTotalIndividualSuggestionsAdded(product: ShoppingCartItem): number
    {
        const individualSuggestions = product.suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);
        return individualSuggestions.length || 0;
    }

    private calculateTotalUpgradeSuggestionsAdded(product: ShoppingCartItem) : number
    {
        const upgradeSuggestion = product.suggestions?.find(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);
        return upgradeSuggestion ? upgradeSuggestion.individualFeatures.quantity : 0;
    }

    public calculateTotalSuggestionsAdded(product: ShoppingCartItem) : number
    {
        return this.calculateTotalIndividualSuggestionsAdded(product) + this.calculateTotalUpgradeSuggestionsAdded(product);
    }

    private calculateTotalAmountIndividualSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]) : number
    {
        const suggestionsAddedFiltered = suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);

        const suggestionsAdded = suggestionsAddedFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const amount = currentCoverage?.rate?.finalAmount > 0
                ? currentCoverage.rate.finalAmount
                : 0;

            return sum + amount;
        }, 0);

        return suggestionsAdded;
    }

    private calculateTotalAmountUpgradeSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]) : number
    {
        const upgradeSuggestionsFiltered = suggestions.filter(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);

        const upgradeSuggestionAdded = upgradeSuggestionsFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const amount = currentCoverage?.rate?.finalAmount > 0
                ? currentCoverage.rate.finalAmount
                : 0;

            return sum + (amount * current.individualFeatures.quantity);
        }, 0);

        return upgradeSuggestionAdded;
    }

    public calculateTotalAmountSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]) : number
    {
        return this.calculateTotalAmountIndividualSuggestionsAdded(suggestions) + this.calculateTotalAmountUpgradeSuggestionsAdded(suggestions);
    }

    // ********************************************************************
    //#endregion
    // ********************************************************************
}