import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StructuredDataProduct, StructuredDataProductPrice } from '@core-models/generic.model';
import { HomeResponse } from '@core-models/home.model';
import { ServiceResponse } from '@core-models/services.model';
import { DirectusPipe } from '@core-pipes/directus.pipe';
import { AssetsService } from '@shared-services/assets.service';

@Component({
  selector: 'app-structured-data',
  templateUrl: './structured-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StructuredDataComponent implements OnInit
{
  public html: SafeHtml;

  @Input() public sourceScript: { [key: string]: any };
  @Input() public servicesDE: HomeResponse.IServiceED[];
  @Input() public serviceRateInfo: ServiceResponse.IRateInfo[];

  private productData: StructuredDataProduct[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    public assetsService: AssetsService) { }

  public ngOnInit(): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      this.initializeScript();
    }
  }

  public getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml
  {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public initializeScript(): void
  {
    const directusPipe = new DirectusPipe(this.assetsService);
    let lastIndex = 0;

    this.servicesDE.map((row, index) =>
    {
      const temp = {} as StructuredDataProduct;
      const tempOffer = {} as StructuredDataProductPrice;

      const rateinfo: ServiceResponse.IRateInfo = this.serviceRateInfo.find(x => x.serviceId == row.id_servicio);

      temp['@type'] = "Product";
      temp.image = directusPipe.transform(row.imagen.id, null);
      temp.description = rateinfo?.detail?.message;
      temp.name = row.nombre;
      temp.url = row.url;
      temp.position = (index + 1).toString();
      temp.offers = tempOffer;
      temp.offers['@type'] = "Offer";
      temp.offers.priceCurrency = "MXN";

      //Prices
      const tempFinal: number = rateinfo?.detail?.finalAmount;
      const tempGross: number = rateinfo?.detail?.grossAmount;

      temp.offers.price = tempFinal ? tempFinal.toString() : tempGross?.toString();
      temp.offers.availability = "https://schema.org/InStock";
      this.productData.push(temp);

      lastIndex = index;
    });

    this.sourceScript.numberOfItems = (lastIndex + 1).toString();
    this.sourceScript.itemListElement = this.productData;
    this.html = this.getSafeHTML(this.sourceScript);

    this.ref.detectChanges();
  }
}
