import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-events.const';
import { EServiceType } from '@core-constants/product-service.const';
import { ECartItemStatus } from '@core-constants/shopping-cart.const';
import { UpdateDomainServiceDto } from '@core-models/generic.model';
import { DomainServiceItem, GroupedShoppingCart, ICoverageOption, ShoppingCarBaseContent, ShoppingCartItem } from '@core-models/shopping-cart-items.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { Tools } from '@shared-utils/tools.util';
import { CookieService } from 'ngx-cookie-service';
import { IndividualSuggestionManager } from './individual-suggestion.manager';
import { TokenManager } from './token.manager';

@Injectable({ providedIn: 'root' })
export class CheckoutManager
{
  constructor(
    public tokenManager: TokenManager,
    public cookieService: CookieService,
    public individualSuggestionsManager: IndividualSuggestionManager
  ) { }

  public initializeCartItems(data: GroupedShoppingCart[] = undefined): void
  {
    if (data)
    {
      this.setCartItems = data;
    }
  }

  public initializeCartBaseData(data: ShoppingCarBaseContent = undefined): void
  {
    if (data)
    {
      this.tokenManager.saveCartCookie(data.cartId);
      this.tokenManager.saveCartCount(data.cartItemsCounts);
      this.tokenManager.saveCartCoupon(data.couponToApply);
    }
  }

  // ********************************************************************
  //#region CART COUPON
  // ********************************************************************

  public get hasCouponToApply(): boolean
  {
    return this.tokenManager.getCartCoupon();
  }

  // #endregion

  // ********************************************************************
  //#region CART ITEMS COUNT
  // ********************************************************************

  public get cartItemsCount(): number
  {
    return this.tokenManager.getCartCount() ?? 0;
  }

  public get cartItems(): GroupedShoppingCart[]
  {
    return this.tokenManager.getCartItems();
  }

  private calculateTotalItems(items: GroupedShoppingCart[]): void
  {
    let count: number = 0;
    if (!Tools.isNullOrEmpty(items))
    {
      count = items
        .reduce((sum, current) =>
        {
          const isUpgraded = current.product.suggestions.length > 0
                                ? current.product.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)
                                : false;

          if (!current.product.isNotChargeableDomain && current.product.coverageId != 0)
          {
            current.counter = isUpgraded ? 0 : current.counter;
            sum += current.counter;
          }

          if (current.product.domainServices)
          {
            const added = current.product.domainServices.filter((x: DomainServiceItem) => x.isAdded && x.coverageId != 0).length;
            const various = current.product.domainServices.filter((x: DomainServiceItem) => x.isAdded && x.variousService != null).length;
            sum += added + various;
          }

          if (current.product.variousServices)
          {
            sum += current.product.variousServices.length;
          }

          if (current.product.suggestions)
          {
            // Individual Suggestions + Upgrades
            sum += this.individualSuggestionsManager.calculateTotalSuggestionsAdded(current.product);
          }

          return sum;
        }, 0);
    }
    this.tokenManager.saveCartCount(count);
  }

  // #endregion

  // ********************************************************************
  //#region AMOUNTS
  // ********************************************************************

  private calculateAmounts(items: GroupedShoppingCart[]): void
  {
    let total: number = 0;

    if (!Tools.isNullOrEmpty(items))
    {
      total = items.reduce((sum, current) =>
      {
        return sum + this.calculateTotalByProduct(current.product, current.counter);
      }, 0);
    }

    this.tokenManager.saveCartTotal(total);
  }

  // ********************************************************************
  //#region Total
  // ********************************************************************

  public get total(): number
  {
    return this.tokenManager.getCartTotal() ?? 0;
  }

  public calculateTotalByProduct(item: ShoppingCartItem, count: number): number
  {
    const isUpgrade = item.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);

    const currentCoverage = item.coverageOptions.find(x => x.id == item.coverageId);

    //the final amount is defined by coverage
    let total: number = item.isNotChargeableDomain || isUpgrade
                        ? 0
                        : currentCoverage.rate.finalAmount * item.quantity;

    let suggestionsTotal: number = 0;

    count = isUpgrade ? 0 : count;

    if (!Tools.isNullOrEmpty(item.domainServices))
    {
      const added = item.domainServices.filter((x: DomainServiceItem) => x.isAdded);

      const domainServTotal = added.reduce((sum, current) =>
      {
        const amount = current.rate.finalAmount;
        return sum + (amount > 0 ? amount : 0);
      }, 0);

      total += domainServTotal;

      //SUM various service amount of added  domain services
      const domainServVariousTotal = added.reduce((sum, current) =>
      {
        let amount = 0;

        if (current.variousService)
        {
          const final = current.variousService.coverage.rate.finalAmount;
          amount = final > 0 ? final : 0;
        }
        return sum + amount;
      }, 0);

      total += domainServVariousTotal;

    }

    //SUM added various services amount
    if (!Tools.isNullOrEmpty(item.variousServices))
    {
      const variousSerTotal = item.variousServices.reduce((sum, current) =>
      {
        let amount = current.coverage.rate.finalAmount;

        if (item.serviceType == EServiceType.Addon && item.isAccumulable)
        {
          amount = current.coverage.rate.finalAmount * item.quantity;
        }

        return sum + (amount > 0 ? amount : 0);
      }, 0);

      total += variousSerTotal;
    }

    if (!Tools.isNullOrEmpty(item.suggestions))
    {
      // Individual Suggestions + Upgrades
      suggestionsTotal = this.individualSuggestionsManager.calculateTotalAmountSuggestionsAdded(item.suggestions);
    }

    return ((total * count) + suggestionsTotal);
  }


  // #endregion

  // #endregion

  // ********************************************************************
  //#region CART ITEMS
  // ********************************************************************

  private set setCartItems(cartItems: GroupedShoppingCart[])
  {
    const count = 0;

    if (cartItems)
    {
      this.tokenManager.saveCartItems(cartItems);
      this.calculateTotalItems(cartItems);
      this.calculateAmounts(cartItems);
    }

    BroadcastService.Instance.broadcast(EAppEventName.OnUpdateCart, count);
  }

  // #endregion

  // ********************************************************************
  //#region CART UPDATES
  // ********************************************************************

  public addDomainService(parentId: number, serviceId: number): void
  {
    this.updateDomainService(parentId, serviceId, true);
  }

  public addIndividualSuggestion(cartItemId: number, suggestionId: string): void
  {
    this.setCartItems = this.individualSuggestionsManager.addIndividualSuggestion(this.cartItems, cartItemId, suggestionId);
  }

  public addIndividualSuggestionUpgrade(cartItemId: number, suggestionId: string, quantity: number = undefined, coverageId = undefined, coverageMonths: number = undefined): void
  {
    this.setCartItems = this.individualSuggestionsManager.addIndividualSuggestionUpgrade(this.cartItems, cartItemId, suggestionId, quantity, coverageId, coverageMonths);
  }

  public removeDomainService(parentId: number, serviceId: number): void
  {
    this.updateDomainService(parentId, serviceId, false);
  }

  public removeIndividualSuggestion(cartItemId: number, suggestionId: string): void
  {
    this.setCartItems = this.individualSuggestionsManager.removeIndividualSuggestion(this.cartItems, cartItemId, suggestionId);
  }

  public removeDomainServices(parentId: number, serviceIds: number[]): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: GroupedShoppingCart[] = JSON.parse(items);

    const parent = cartItems.find((x: GroupedShoppingCart) => x.product.id == parentId);
    const services: DomainServiceItem[] = parent?.product.domainServices?.filter((x: DomainServiceItem) => serviceIds.includes(x.id));

    if (services)
    {
      services.forEach((x: DomainServiceItem) => x.isAdded = false);
      this.setCartItems = cartItems;
    }
  }

  private updateDomainService(parentId: number, serviceId: number, added: boolean): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: any[] = JSON.parse(items);

    const parent = cartItems.find((x: any) => x.id == parentId);
    const item = parent?.domainServices?.find((x: any) => x.id == serviceId);

    if (item)
    {
      item.isAdded = added;
      this.setCartItems = cartItems;
    }
  }

  public removeDomainRenewalCartItem(domainId: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: GroupedShoppingCart[] = JSON.parse(items);

    const item = cartItems.find((x: GroupedShoppingCart) => x.product.id == domainId);

    if (item)
    {
      item.product.isNotChargeableDomain = true;
      item.product.status = ECartItemStatus.Registration;
      this.setCartItems = cartItems;
    }
  }

  public removeCartItem(id: number): void
  {
    this.setCartItems = this.cartItems.filter(i => i.product.id !== id);
  }

  public removeCartItemQuantityGroup(quantityGroup: number): void
  {
    this.setCartItems = this.cartItems.filter((x: GroupedShoppingCart) => x.quantityGroup != quantityGroup);
  }

  public setQuantityGroupCounter(quantityGroup: number, quantity: number): void
  {
    const currentItems: GroupedShoppingCart[] = this.cartItems;
    const index: number = currentItems.findIndex((x: GroupedShoppingCart) => x.quantityGroup == quantityGroup);

    if (index != -1)
    {
      currentItems[index].counter = quantity;
      this.setCartItems = currentItems;
    }
  }

  public setItem(item: ShoppingCartItem): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: GroupedShoppingCart[] = JSON.parse(items);

    const index: number = cartItems.findIndex((x: GroupedShoppingCart) => x.product.id == item.id);

    if (index >= 0)
    {
      cartItems[index].product = item;
      this.setCartItems = cartItems;
    }
  }

  public setCartItemCoverage(id: number, coverageId: number): void
  {
    try
    {
      const items = JSON.stringify(this.cartItems);
      const cartItems: GroupedShoppingCart[] = JSON.parse(items);

      const parent: GroupedShoppingCart = cartItems.find((x: GroupedShoppingCart) => x.product.id == id);

      if (parent)
      {
        const selectedCoverage: ICoverageOption = parent.product.coverageOptions?.find((x: ICoverageOption) => x.id == coverageId);
        const maxCoverage = selectedCoverage?.value;

        parent.product.coverageId = coverageId;
        parent.product.rate = selectedCoverage?.rate;

        if (parent?.product.domainServices)
        {
          parent.product.domainServices
            .forEach(item =>
            {
              const selected = item.coverageOptions?.find((x: ICoverageOption) => x.id <= item.coverageId);

              if (selected?.value >= maxCoverage)
              {
                const filtered = item.coverageOptions?.filter((x: ICoverageOption) => x.value <= maxCoverage);
                item.coverageId = filtered.pop()?.id;
              }
            });
        }
        this.setCartItems = cartItems;
      }
    }
    catch (err)
    {
      // TODO ERROR
    }
  }

  public setCartItemAddonQuantity(id: number, quantity: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: GroupedShoppingCart[] = JSON.parse(items);

    const parent: GroupedShoppingCart = cartItems.find((x: GroupedShoppingCart) => x.product.id == id);

    if (parent)
    {
      parent.product.quantity = quantity;
      this.setCartItems = cartItems;
    }
  }

  public setDomainServicesCoverage(parentId: number, domainServices: UpdateDomainServiceDto[]): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: GroupedShoppingCart[] = JSON.parse(items);

    //TODO: Validate the correct type comparison
    const parent = cartItems.find((x: any) => x.product.id == parentId && x.product.type == EServiceType.Domain);

    if (parent)
    {
      domainServices.forEach(service =>
      {
        const current = parent.product.domainServices.find((x: DomainServiceItem) => x.id == service.id);

        if (current)
        {
          current.coverageId = service.coverageId;
          current.rate = current.coverageOptions.find((x: ICoverageOption) => x.id == service.coverageId)?.rate;
        }
      });

      this.setCartItems = cartItems;
    }
  }

  public setCartItemUpgradeCoverage(parentId: number, suggestionId: string, coverageId: number): void
  {
    this.setCartItems = this.individualSuggestionsManager.setCartItemUpgradeCoverage(this.cartItems, parentId, suggestionId, coverageId);
  }

  public setCartItemUpgradeQuantity(parentId: number, suggestionId: string, quantity: number): void
  {
    this.setCartItems = this.individualSuggestionsManager.setCartItemUpgradeQuantity(this.cartItems, parentId, suggestionId, quantity);
  }

  // #endregion
}
