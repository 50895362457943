import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HelpCategoriesDataService } from '@core-data-services/help.data-service';
import { ILanguageComponent } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-help-detail',
  templateUrl: './help-detail.component.html',
  styleUrls: ['./help-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HelpDetailComponent implements OnInit, ILanguageComponent
{
  public text: string = '';
  public title: string = '';
  public htmlId: string = "";

  @Input() public set idHtml(value: string)
  {
    this.htmlId = value;
    if (this.htmlId)
    {
      this.translateService.onTranslateChange()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: () =>
        {
          this.getCategoryDetail(this.htmlId);
        }
      });
    }
  }

  constructor(protected translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private helpCategoriesDataService: HelpCategoriesDataService,
    private destroyRef$: DestroyRef
  ) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void { }

  public getCategoryDetail(htmlId: string): void
  {
    this.helpCategoriesDataService.getHelpItemById(htmlId, this.translateService.languageCode).subscribe({
      next: (result: any) =>
      {
        if (result)
        {
          const translate = result.response[0]?.traducciones[0];

          if (translate)
          {
            this.title = translate.titulo;
            this.text = translate.texto;
          }

          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }
}