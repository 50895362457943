import { EGtmEvent, EGtmSection, GtmConst } from '@core-constants/gtm-const';
import { EServiceCode, EServiceName, ServiceConst } from '@core-constants/product-service.const';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { IProduct, IProductAddGtm } from '@core-models/product.model';
import { Tools } from '@shared-utils/tools.util';
import sha256 from 'crypto-js/sha256';
import { IPurchaseSuggestionRate } from '@core-models/purchase-suggestion.model';

declare const dataLayer: any[];

export class GtmTrackingService
{
  private static readonly _domainNameService: string = "Dominio ";

  public static registerEvent(data: any): void
  {
    dataLayer.push(data);
  }

  public static buildItemList(productList: any[]): IProduct[]
  {
    const items: IProduct[] = [];
    productList.forEach(product =>
    {
      items.push(this.buildItem(product));
    });
    return items;
  }

  private static buildItem(item: any): any
  {
    return {
      id: item.item_id,
      name: item.item_name,
      price: item.price,
      isPublic: true,
      quantity: item.quantity
    };
  }

  public static selectPromotionEventProcess(creativeName: string, creativeSlot: EGtmSection, promotionId: string, promotionName: string, itemServiceData: any[], eventLocation: string): void
  {
    const productList: IProduct[] = [];
    if (itemServiceData)
    {
      itemServiceData.map(i =>
      {
        if (i.type == EServiceName.Service)
        {
          const splitArr = i.serviceData.split(',');
          const result = splitArr.map(e =>
          {
            const keyVal = e.split('=');

            if (keyVal[0] == 'ASP_ID')
            {
              return { ASP_ID: keyVal[0], value: keyVal[1] };
            }
            else
            {
              return { APL_ID: keyVal[0], value: keyVal[1] };
            }
          });
          productList.push({
            id: result.find(x => x.ASP_ID).value,
            name: ServiceConst.AdditionalServicePlans.get(Number(result.find(x => x.APL_ID).value)),
            category: ServiceConst.AdditionalServicePkg.get(Number(result.find(x => x.ASP_ID).value)),
            category3: i.coverageId == 12 ? '1y' : i.coverageId.toString().concat('m'),
            variant: 'Paquete',
            listName: ServiceConst.AdditionalServicePkg.get(Number(result.find(x => x.ASP_ID).value)),
            listId: result.find(x => x.ASP_ID).value,
            price: 0,
            isPublic: true,
            quantity: 1,
            eventLocation: eventLocation
          });
        }
        else
        {
          if (i.type == EServiceName.Domain)
          {
            productList.push({
              id: "Dominio",
              name: this._domainNameService + i.serviceData,
              price: 0,
              category: 'Registro Dominio',
              category3: i.coverageId == 12 ? '1y' : i.coverageId.toString().concat('m'),
              listName: 'Dominio',
              listId: 'Dominio',
              variant: 'Paquete',
              isPublic: true,
              quantity: 1,
              eventLocation: eventLocation
            });
          }
        }
      });
    }

    this.addToCartEvent(EGtmEvent.AddToCart, this.mapProducts(productList));
    this.selectPromotionEvent(creativeName, creativeSlot, promotionId, promotionName, this.mapProducts(productList));
  }

  public static addToCartPresenceServiceEvent(cartServiceData: string, currentPrice: string, quantity: number, eventLocation: string): void
  {
    const itemInfo: Document = new DOMParser().parseFromString(currentPrice, 'text/html');
    const coverage: string = itemInfo.getElementsByClassName('domCoverage')[0].innerHTML;
    const price: string = itemInfo.getElementsByClassName('domPrice')[0].innerHTML.replace(/\D/g, "").split(",").join("");
    const productList: IProduct[] = [];
    productList.push(GtmTrackingService.createIProductPresenceServices(ServiceHelper.splitAddServPkgAndPlan(cartServiceData), coverage, price, quantity,null, eventLocation));
    this.addToCartEvent(EGtmEvent.AddToCart, this.mapProducts(productList));
  }

  public static addToCartSuggestionServiceEvent(serviceData: string, productRate: IPurchaseSuggestionRate, eventLocation: string): void
  {
    const suggestionAdded = this.buildProductSendToGtm(serviceData, productRate);

    let itemProduct: IProduct;

    if (suggestionAdded.serviceData.includes(EServiceCode.ASP) && suggestionAdded.serviceData.includes(EServiceCode.APL))
    {
      itemProduct = this.createIProductPresenceServices(ServiceHelper.splitAddServPkgAndPlan(suggestionAdded.serviceData), 'year', suggestionAdded.price, 1, suggestionAdded.beforePrice, eventLocation);
    }
    else
    {
      itemProduct = this.buildAndStoreItems({ domainType: suggestionAdded.serviceData, price: suggestionAdded.price, beforePrice: suggestionAdded.beforePrice }, eventLocation);
    }

    itemProduct.isAddedBySuggestion = true;

    this.addToCartEvent(EGtmEvent.AddToCart, this.mapProducts([itemProduct]));
  }

  public static createIProductPresenceServices(serviceData: any, coverage: string, price: string, quantity: number, beforePrice: number, eventLocation: string): IProduct
  {
    const product: IProduct = {
      name: ServiceConst.AdditionalServicePlans.get(Number(serviceData.find(x => x.APL_ID).value)),
      id: serviceData.find(x => x.APL_ID).value,
      price: Number(price),
      discount: beforePrice ? (beforePrice - Number(price)) : 0 ,
      quantity: quantity,
      category: ServiceConst.AdditionalServicePkg.get(Number(serviceData.find(x => x.ASP_ID).value)),
      category3: coverage.includes('año') || coverage.includes('year') ? '1y' :
        coverage.includes('mes') || coverage.includes('month') ? '1m' : 'Pago Unico',
      variant: ServiceConst.AdditionalServicePlans.get(Number(serviceData.find(x => x.APL_ID).value)),
      listName: ServiceConst.AdditionalServicePkg.get(Number(serviceData.find(x => x.ASP_ID).value)),
      listId: serviceData.find(x => x.APL_ID).value,
      isPublic: true,
      eventLocation: eventLocation
    };

    return product;
  }


  public static buildAndStoreItems(domain: any, addLocation : string): any
  {
    return {
      id: 'Dominio',
      name: this._domainNameService + domain.domainType,
      price: Number(domain.price),
      discount: domain.beforePrice ? Number(domain.beforePrice) - Number(domain.price) : 0,
      category: 'Registro Dominio',
      category3: '1y',
      listName: 'Dominio',
      listId: 'Dominio',
      variant: 'Registro Dominio',
      isPublic: true,
      quantity: 1,
      addedByAI: domain.addedByAI,
      eventLocation: addLocation
    };
  }

  public static addToCartDomainEvent(domainStruct: any, multipleDomains: boolean, addLocation: string): void
  {
    let items: IProduct[] = [];

    if (multipleDomains)
    {
      items = [];

      domainStruct.forEach((domain: any) =>
      {
        items.push(this.buildAndStoreItems(domain, addLocation));
      });
    }
    else
    {
      items.push(this.buildAndStoreItems(domainStruct, addLocation));
    }

    this.addToCartEvent(EGtmEvent.AddToCart, this.mapProducts(items));
  }

  public static mapProducts(products: IProduct[]): any
  {
    return { items: products.map(this.mapProduct) };
  }

  private static mapProduct(product: IProduct, index: number): any
  {
    return {
      item_name: product.name,
      item_id: product.id,
      price: product.price,
      currency: 'MXN',
      quantity: product.quantity,
      discount: product.discount,
      item_brand: GtmConst.TrackingBrand,
      item_category: product.category,
      item_category2: product.coverage,
      item_category3: product.category3,
      item_variant: product.variant,
      item_list_name: product.listName,
      item_list_id: product.listId,
      addedByAI: product.addedByAI ?? false,
      added_by_suggestion: product.isAddedBySuggestion ?? false,
      event_location: product.eventLocation,
      index
    };
  }

  public static viewSearchResultsEvent(event: EGtmEvent, search_term: string, search_results: number): void
  {
    const data = {
      event,
      search_term,
      search_results
    };

    this.registerEvent(data);
  }

  public static signupEvent(event: EGtmEvent, method: string, user_email: string): void
  {
    const data = {
      event,
      method,
      user_Id: sha256(user_email).toString()
    };

    this.registerEvent(data);
  }

  public static viewCartEvent(event: EGtmEvent, items: any, value: number): void
  {
    dataLayer.push({ ecommerce: null });
    const data = {
      event,
      value,
      ecommerce: items
    };

    this.registerEvent(data);
  }

  public static viewItemListEvent(event: EGtmEvent, items: any, item_list_name: string, item_list_id: number): void
  {
    dataLayer.push({ ecommerce: null });

    const data = {
      event,
      item_list_name,
      item_list_id,
      ecommerce: items
    };

    this.registerEvent(data);
  }

  public static loginEvent(event: EGtmEvent, email: string): void
  {
    const data = {
      event,
      method: 'email',
      user_id: sha256(email).toString()
    };

    this.registerEvent(data);
  }

  public static addToCartEvent(event: EGtmEvent, items: any): void
  {
    dataLayer.push({ ecommerce: null });

    const data = {
      event,
      ecommerce: items
    };
    this.registerEvent(data);
  }

  public static selectPromotionEvent(creativeName: string, creativeSlot: EGtmSection, promotionId: string, promotionName: string, items: any): void
  {
    dataLayer.push({ ecommerce: null });

    const data: any = {
      event: EGtmEvent.SelectPromotion,
      ecommerce: items
    };

    data.ecommerce.creative_name = creativeName;
    data.ecommerce.creative_slot = creativeSlot;
    data.ecommerce.promotion_id = promotionId;
    data.ecommerce.promotion_name = promotionName;

    this.registerEvent(data);
  }

  public static beginCheckoutEvent(event: EGtmEvent, items: any[], value: number): void
  {
    this.sendDataLayerEcommerceEvent(event, items, value);
  }

  public static removeFromCartEvent(event: EGtmEvent, items: any[], value: number): void
  {
    this.sendDataLayerEcommerceEvent(event, items, value);
  }

  public static sendDataLayerEcommerceEvent(event: EGtmEvent, cartList: any[], value: number): void
  {
    const productList: IProduct[] = this.collectGTMCartItems(cartList);
    this.collectGTMDomainServices(cartList, productList);

    const items: any = this.mapProducts(productList);

    dataLayer.push({ ecommerce: null });

    const data = {
      event,
      value,
      ecommerce: items
    };

    dataLayer.push(data);
  }

  /**
   * Recolecta en una lista de tipo IProduct los items principales alojados en el carrito de compras
   * @param items
   * @returns
   */
  public static collectGTMCartItems(items: any[]): IProduct[]
  {
    const productList: IProduct[] = [];

    if (!Tools.isNullOrEmpty(items))
    {
      items.map(item =>
      {
        if (item.product.type == EServiceName.Service)
        {
          productList.push({
            id: item.product.serviceData.additionalServicePlan,
            name: ServiceConst.AdditionalServicePlans.get(Number(item.product.serviceData.additionalServicePlan)),
            listId: item.product.serviceData.additionalServicePkg,
            listName: ServiceConst.AdditionalServicePkg.get(Number(item.product.serviceData.additionalServicePkg)),
            coverage: this.formatCoverage(item.product.coverageOptions.find(i => i.id == item.product.coverageId).value),
            price: item.product.coverageOptions.find(i => i.id == item.product.coverageId).rate.finalAmount,
            category: ServiceConst.AdditionalServicePkg.get(Number(item.product.serviceData.additionalServicePkg)),
            variant: ServiceConst.AdditionalServicePlans.get(Number(item.product.serviceData.additionalServicePlan)),
            discount: item.product.couponAmount,
            isPublic: true,
            quantity: 1
          });
        }
        else if (item.product.type == EServiceName.Domain)
        {
          productList.push({
            id: "Dominio",
            name: item.product.fullName,
            price: item.product.coverageOptions.find(i => i.id == item.product.coverageId).rate.finalAmount,
            coverage: this.formatCoverage(item.product.coverageOptions.find(i => i.id == item.product.coverageId).value),
            listId: item.product.id,
            listName: item.product.domainType,
            category: item.product.type,
            discount: item.product.couponAmount,
            variant: item.product.name,
            isPublic: true,
            quantity: 1
          });
        }
      });
    }
    return productList;
  }

  /**
   * Recolecta en una lista de tipo IProduct los servicios de dominio agregados al carrito de compras
   * @param items
   * @param productList
   * @returns
   */
  public static collectGTMDomainServices(items: any[], productList: IProduct[]): IProduct[]
  {
    if (!Tools.isNullOrEmpty(items))
    {
      items.map(item =>
      {
        if (item.domainServices === null || item.domainServices === undefined)
        {
          return;
        }

        if (!Tools.isNullOrEmpty(item.domainServices))
        {
          item.domainServices.map((subItem: any) =>
          {
            //TODO optimizar esta consulta
            if (subItem.isAdded)
            {
              const product: IProduct = {
                name: subItem.name,
                price: subItem.rate.finalAmount,
                category: 'Servicio de dominio',
                coverage: this.formatCoverage(subItem.coverageOptions.find(i => i.id == subItem.coverageId).value),
                variant: subItem.type,
                quantity: 1,
                isPublic: false
              };

              productList.push(product);
            }
          });
        }
      });
    }
    return productList;
  }

  public static formatCoverage(coverage: number): string
  {
    let formatedCoverage: string = "";

    if (coverage >= 12)
    {
      formatedCoverage = (coverage / 12) + "y";
    }
    else
    {
      formatedCoverage = coverage + "m";
    }
    return formatedCoverage;
  }

  public static menuEvent(event: EGtmEvent, menu: string, submenu: string): void
  {
    const data = {
      event,
      menu,
      submenu
    };

    this.registerEvent(data);
  }

  public static controlPanelEvent(event: EGtmEvent, option: string): void
  {
    const data = {
      event,
      option
    };
    this.registerEvent(data);
  }

  public static errorMessageEvent(errorMessageText: string): void
  {
    const data =
    {
      event: EGtmEvent.ErrorMessage,
      error_message_text: errorMessageText,
      error_message_location: window.location.href
    };

    this.registerEvent(data);
  }

  public static buildProductSendToGtm(serviceToAdd: string, rate: IPurchaseSuggestionRate): IProductAddGtm
  {
    return {
      serviceData: serviceToAdd,
      price: rate?.finalAmount.toString(),
      beforePrice: rate?.offerAmount ? rate.offerAmount : null
    };
  }
}
