
export namespace SesionStorageConst
{
  export const TokenKey = 'auth-token';
  export const RefreshTokenKey = 'auth-refreshtoken';
  export const UserKey = 'auth-user';
  export const HubspotutkCookie = 'hubspotutk';
  export const TokenHeaderKey = 'Authorization';
  export const CartCookieKey = 'cart-cookie';
  export const UserId = 'user-id';
  export const SFAccount = 'sf-account';

  export const ToAddPackage = 'to-add-pkg';
  export const ToAddCoupoin = 'to-add-coupon';

  export const CartItems = 'e-cart-items';
  export const CartItemsCount = 'e-cart-items-count';
  export const CartTotal = 'e-cart-total';
  export const CartCoupon = 'e-cart-coupon-to-apply';

  export const MSVCToken = 'auth-msvc-token';
  export const MSVCRefreshToken = 'auth-msvc-refreshtoken';
}

export namespace LocalStorageConst
{
  export const AkkyRoutes = "akky-routes";
  export const DirectusURL = "akky-directus-url";
  export const CookiesAdd = "cookies-add";
  export const SelectedLang = "selectedLang";
  export const HubFormStorageKey = "hub-form-storage-key";
  export const PostBackUri = "post-back-uri";
  export const ErrorUri = "error-uri";
  export const TransferDomains = "transfer-domains";
  export const IsMultipleSearch = "is-multiple-search";
  export const DomainPackages = "domain-packages";

  export const HelpMenu = "help-menu-";
  export const FaqMenu = "faq-menu-";

  export const IsPurchaseSuggestionPageEnabled = "additional-services-suggestions-enabled";
  export const PurchaseSuggestionsDataSelected = "purchase-suggestions-selected";
}
