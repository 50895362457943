import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceRequest } from '@core-models/services.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServiceDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'service');
  }

  public getRates<T>(body: ServiceRequest.IGetRates): Observable<T>
  {
    const queryDef = new UrlQueryDef();

    const subject = this.http.post(this.createMicroserviceURL('rates'), body, queryDef.getRequestConfig());

    return subject as Observable<T>;
  }
}

