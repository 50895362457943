declare let didFinishLoading: boolean;

//TODO REMOVE HUBSPOT COMPONENTS
export function loadScripts(): boolean
{
  if (document.getElementById("hubspotVar") === null) 
  {
    const scriptHubspot: HTMLScriptElement = document.createElement('script');

    scriptHubspot.id = "hubspotVar";
    scriptHubspot.type = "text/javascript";
    scriptHubspot.text = `
        // override the hbspt functionality while hubspot is loading
        hbspt = {
          //push form to hubcache queue
          forms: { create: function (c) { hubspot.forms.push(c) } },
        };
      
        // cache hubspot forms here
        const hubcache = {
          forms: [],
          letsgo: function () {
            for (var i in hubspot.forms) {
              //hubspot is now loaded
              hbspt.forms.create(hubcache.forms[i]);
            }
          }
        };`;

    document.body.appendChild(scriptHubspot);
  }

  if (document.getElementById("hsforms") == null)
  {
    const script: HTMLScriptElement = document.createElement('script');

    script.defer = true;
    script.id = "hsforms";
    script.type = "text/javascript";
    script.src = "//js.hsforms.net/forms/v2.js";

    script.onload = function (): void
    {
      didFinishLoading = true;
    };

    document.body.appendChild(script);
  }
  return true;
}