export namespace ServiceSection
{
  export interface IBannerInfo
  {
    background: string;
    image: string;
    text: string;
  }

  export interface IServiceInfo
  {
    title: string;
    subtitle: string;
    description: string;
  }

  export class Section1 implements ISection1
  {
    public title?: string;
    public img_section?: string;
    public specs_reverse: boolean = false;
    public data: ISectionData[] = [];

    constructor()
    {
      this.title = '';
      this.img_section = '';
      this.specs_reverse = false;
      this.data = [];
    }
  }

  export interface ISection1
  {
    title?: string | undefined;
    img_section?: string | undefined;
    specs_reverse: boolean;
    data: ISectionData[];
  }

  export interface ISectionData
  {
    title: string;
    image: string;
    description: string;
  }
}
