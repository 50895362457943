import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { IBenefitItem } from '@core-models/benefits.model';

@Component({
  selector: 'app-promotion-month',
  templateUrl: './promotion-month.component.html',
  styleUrls: ['./promotion-month.component.css']
})
export class PromotionMonthComponent
{
  @Input() public title: string = "";
  @Input() public monthItems: IBenefitItem[] | undefined = undefined;
  public bigItem: IBenefitItem | undefined = undefined;

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  public onClickImg(url: string) : void
  {
    if (this.platformId === 'browser')
    {
      window.open(url, '_blank');
    }
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}
