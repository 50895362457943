import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ClickActions } from '@core-constants/action-type.const';
import { EAppEventName } from '@core-constants/app-events.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { BroadcastService } from '@shared-services/broadcast.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { IIterations } from '@core-models/generic.model';

@Component({
  selector: 'app-complementary-services',
  templateUrl: './complementary-services.component.html',
  styleUrls: ['./complementary-services.component.css']
})
export class ComplementaryServiceComponent implements IIterations
 {
  public complementaryServicesValue: any;
  public img: string = "";
  public title: string = "";
  public description: string = "";
  public price: string = "";
  public button: string = "";
  public url: string = "";
  public cartServiceData: string = "";
  public action: string = "";
  public gtmKey: string = "";

  @Input() public set complementaryServices(value: any)
   {
    this.setComplementaryService(value);
  }

  constructor(@Inject(PLATFORM_ID) private platformId,
  protected toast: ToastService,
  private shoppingCartDataService: ShoppingCartDataService,
  protected translateService: TranslateService) { }

  public identify(index: any, item: any): any 
  {
    return item;
  }

  public setComplementaryService(value: any): void 
  {
    if (value)
    {
      this.gtmKey = value?.clave_gtm;
      this.url = value?.url_redireccionamiento;
      this.action = value?.tipo_accion_boton;
      this.cartServiceData = value?.id_servicio_carrito?.valor;

      const complementaryServiceItem = value?.traducciones[0];

      if (complementaryServiceItem) 
      {
        this.img = complementaryServiceItem?.img_servicio;
        this.title = complementaryServiceItem?.titulo;
        this.description = complementaryServiceItem?.descripcion;
        this.price = complementaryServiceItem?.precio_actual;

        if (complementaryServiceItem?.texto_boton != null && complementaryServiceItem?.texto_boton != "") 
          {
          this.button = complementaryServiceItem.texto_boton;
        }
      }
    }
  }

  public onButtonClick(): void
  {
    switch (this.action)
    {
      case ClickActions.Types.AddToCart:
        this.addToCart();
        break;
      case ClickActions.Types.Redirect:
        if (isPlatformBrowser(this.platformId))
        {
          window.location.href = this.url;
        }
        break;
      default: break;
    }
  }

  public addToCart(): void 
  {
    this.shoppingCartDataService.addService(this.cartServiceData).subscribe({
      next: (response: any) => 
        {
        BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, response.cartId);
      },
      error: () => 
        {
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }
}
