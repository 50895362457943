import { ErrorMessageConst } from "./error-message.const";

export const enum EFormStudentsChallengeCodeError
{
  LinkStudentDuplicated = "PageLink",
  EmailStudentDuplicated = "InstitutionalEmail",
  EmailProjectStudentDuplicated = "ProjectEmail"
}

export const enum EFormStudentsCodeError
{
  CouponStudentDuplicated = "conflict",  /*409**/
  BadRqequest = "400"
}

export namespace SutentsChallengeConst
{
  export const FormStudentsChallengeCodeErrors = new Map<EFormStudentsChallengeCodeError, string>([
    [EFormStudentsChallengeCodeError.LinkStudentDuplicated, ErrorMessageConst.LinkStudentDuplicated],
    [EFormStudentsChallengeCodeError.EmailStudentDuplicated, ErrorMessageConst.LinkStudentDuplicated],
    [EFormStudentsChallengeCodeError.EmailProjectStudentDuplicated, ErrorMessageConst.LinkStudentDuplicated]
  ]);

  export const FormStudentsCodeErrors = new Map<EFormStudentsCodeError, string>([
    [EFormStudentsCodeError.CouponStudentDuplicated, ErrorMessageConst.CouponStudentDuplicated],
    [EFormStudentsCodeError.BadRqequest, ErrorMessageConst.BadRqequest]
  ]);
}